import React, { useState } from 'react'
import { Button } from '../Button'
import { Fields, FieldsProps } from '../Fields'
import { Accordion } from '../Accordion'
import { noop } from 'chart.js/helpers'

export interface PageFormProps<T> extends FieldsProps<T> {
  variant: 'form'
  button: string
  onSubmit?: (values: T) => void
  value?: T
  title?: React.ReactNode
}

export const PageForm = <T, >({
  button,
  onSubmit = noop,
  title,
  ...props
}: PageFormProps<T>) => {
  const [form, setForm] = useState<T>({} as T)

  const handlerSubmit = () => {
    onSubmit(form as T)
  }

  return (
    <Accordion label={title} expanded className="controller">
      <Fields
        {...props}
        onChange={setForm}
      />
      <Button onClick={handlerSubmit}>
        {button}
      </Button>
    </Accordion>
  )
}
