/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'
import { CircularProgress, Divider, Fab, Grid, List, ListItem, ListItemText, Paper, TextField } from '@mui/material'

import { DialogList } from './DialogList'
import { Send } from '@mui/icons-material'
import { useResize } from '../../../shared/hooks'
import { ChatId } from './ChatId'
import { clearMessagesFx, getChatFx, socketOpenFx } from '../../../states/Chat/event'
import { useParams } from 'react-router-dom'
import { useStore } from 'effector-react'
import { $my } from '../../../states/Chat/store'

const classes = {
  table: {
    minWidth: 650,
  },
  headBG: {
    backgroundColor: '#e0e0e0',
  },
  messageArea: {
    height: 'auto',
    overflowY: 'auto',
  },
  alingRight: {
    alignItems: 'right',
  },
  alingLeft: {
    alignItems: 'left',
  },
  root: {
    height: '90%',
    width: '100%',
    gap: '24px',
    maxHeight: '80vh',
  },
  chatMobile: {
    overflowY: 'hidden',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 5000,
    backgroundColor: '#FFFFFF',
  },
}

export const Root = () => {
  const { id } = useParams()
  const { isMobile } = useResize()
  const my = useStore($my)

  useEffect(() => {
    socketOpenFx(true)
  }, [])

  useEffect(() => {
    if (id) {
      getChatFx({ id: Number(id) })
    }
    return clearMessagesFx
  }, [id])

  return (
    <Grid container style={classes.root}>
      <Grid
        component={Paper}
        item
        xs={!id && isMobile ? 12 : 3}
        hidden={isMobile && !!id}
        style={{ maxHeight: 'inherit', height: '100%', overflow: 'auto' }}
      >
        <DialogList />
      </Grid>

      <Grid
        component={Paper}
        item
        xs={id && isMobile ? 12 : 9}
        hidden={isMobile && !id}
        sx={isMobile ? classes.chatMobile : {}}
        style={{ maxWidth: '720px', maxHeight: 'inherit' }}
      >
        {id
          ? my?.id ? <ChatId /> : <CircularProgress />
          : <div>Выберите диалог</div>
        }
      </Grid>
    </Grid>
  )
}
