import React from 'react'
import { NavLink } from 'react-router-dom'
import { Page } from '../../../../shared/UI'
import { useMoneyBox } from '../../../../services/api/MoneyBox'
import { Routes } from '../../../../shared/enum'
import { toDollar } from '../../../../shared/utils'

export const Balance = () => {
  const { boxId } = useMoneyBox()

  return <Page
    variant="root"
    title="Баланс в копилке"
    col={[
      { id: 'userId', label: 'Юзер ID', render: (id) => <NavLink to={`/${Routes.USER}/${id}`}>{id}</NavLink> },
      { id: 'balance', label: 'Баланс', render: (balance) => `${toDollar(balance)} $` },
    ]}
    rows={boxId.balances.data?.items}
    total={boxId.balances.data?.metadata.total}
    onChange={boxId.balances.fetch}
    filter={[
      {
        label: 'Фильтры',
        defaultExpanded: true,
        fields: [
          { name: 'id', type: 'number', label: 'Копилка ID' },
          { name: 'userIds', type: 'number', label: 'Юзер ID' },
        ],
      },
    ]}
  />
}
