/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { useNavigate, useRoutes } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Page } from '../../../shared/UI'
import { Routes } from '../../../shared/enum'
import { FaqCategory, PostCategoryReq, useFaqCategory } from '../../../services/api/Faq'

export const Category = () => {
  const [id, setId] = useState('')
  const navigate = useNavigate()

  const { get, getId, post, patch, del } = useFaqCategory(id)

  const handlerCreate = async (values: PostCategoryReq) => {
    const { data } = await post.mutateAsync(values)
    toast.success('Создано')
    navigate(data.data.id.toString())
  }

  const handlerDelete = async () => {
    await del.mutateAsync({ id })
    toast.success('Удалено')
    navigate('')
  }

  const handlerUpdate = async (value: FaqCategory) => {
    await patch.mutateAsync(value)
    await getId.refetch()
    toast.success('Обновлено')
  }

  return useRoutes([
    {
      path: `${Routes.ROOT}`,
      element: <Page
        variant="outlet"
        links={[
          { title: 'Категории', to: Routes.ROOT },
          { title: 'Создать', to: Routes.CREATE },
        ]}
      />,
      children: [
        {
          path: Routes.ROOT,
          element: <Page
            loading={[get.isLoading]}
            variant="root"
            col={[
              { id: 'id', label: 'ID' },
              { id: 'name', label: 'Название' },
              { id: 'isActive', label: 'Активность' },
            ]}
            rows={get.data?.data.data}
            title="Категории для faq"
          />,
        },
        {
          path: Routes.CREATE,
          element: <Page
            title="Создание категории"
            variant="form"
            button="Создать"
            loading={[post.isLoading]}
            onSubmit={handlerCreate}
            fields={[
              { name: 'name', type: 'string', label: 'Название (для админа)' },
              { name: 'title.content', type: 'content', label: 'Заголовок', mode: 'text' },
              { name: 'isActive', type: 'switch', label: 'Активно' },
            ]}
          />,
        },
        {
          path: Routes.ID,
          element: <Page
            title="Редактирование категории"
            variant="form"
            button="Сохранить"
            loading={[getId.isLoading, del.isLoading, patch.isLoading]}
            onInit={({ id }) => id && setId(id)}
            value={getId.data?.data.data}
            onSubmit={handlerUpdate}
            fields={[
              { name: 'id', type: 'string', label: 'ID', disabled: true },
              { name: 'name', type: 'string', label: 'Название (для админа)' },
              { name: 'isActive', type: 'switch', label: 'Активно' },
              { name: 'title.content', type: 'content', label: 'Заголовок', mode: 'text' },
            ]}
            actions={[
              {
                title: 'Удалить',
                type: 'confirm',
                onClick: handlerDelete,
                color: 'error',
                message: 'Так же будут удалены все Faq с данной категорией',
              },
            ]}
          />,
        },
      ],
    },
  ])
}
