import { createEffect } from 'effector'

import { AxiosError, APIResult } from '../../core/api/Result'
import {
  LanguageAPI,
  GetLanguagesReq,
  GetLanguageReq,
  PatchLanguageReq,
  PostLanguageReq,
  DeleteLanguageReq,
  PostImportLanguageReq,
} from '../../services/api/Language'
import { saveFile } from '../../shared/utils'
import { toast } from 'react-toastify'

export const getLanguagesFx = createEffect<GetLanguagesReq, APIResult<typeof LanguageAPI['getLanguages']>, AxiosError>(
  LanguageAPI.getLanguages,
)

export const getLanguageFx = createEffect<GetLanguageReq, APIResult<typeof LanguageAPI['getLanguage']>, AxiosError>(
  LanguageAPI.getLanguage,
)

export const patchLanguageFx = createEffect<PatchLanguageReq, APIResult<typeof LanguageAPI['patchLanguage']>, AxiosError>(
  LanguageAPI.patchLanguage,
)

export const postLanguageFx = createEffect<PostLanguageReq, APIResult<typeof LanguageAPI['postLanguage']>, AxiosError>(
  LanguageAPI.postLanguage,
)

export const deleteLanguageFx = createEffect<DeleteLanguageReq, APIResult<typeof LanguageAPI['deleteLanguage']>, AxiosError>(
  LanguageAPI.deleteLanguage,
)

export const getExportLanguageFx = createEffect<unknown, APIResult<typeof LanguageAPI['getExport']>, AxiosError>(
  LanguageAPI.getExport,
)

export const postImportLanguageFx = createEffect<PostImportLanguageReq, APIResult<typeof LanguageAPI['postImport']>, AxiosError>(
  LanguageAPI.postImport,
)

getExportLanguageFx.done.watch(({ result }) => {
  saveFile(result.data, `ExportLanguage-${Date.now()}.json`)
  toast.success('Загрузка завершена')
})

postImportLanguageFx.done.watch(() => {
  toast.success('Загрузка завершена')
})

// TODO: Add redirects and Notifications
postLanguageFx.done.watch(() => {
  // const navigate = $navigate.getState()
  // navigate(Routes.TRANSLATION)
  // toast.success('Перевод успешно удалён')
})
