/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'
import { useStore } from 'effector-react'
import dayjs from 'dayjs'

import { usePagination } from '../../../shared/hooks'
import {
  Avatar,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Grid,
  TextField,
  Divider,
  Badge,
  Typography, CircularProgress,
} from '@mui/material'
import { clearChatsFx, getChatsFx, setChatControlFx, updateMessageFx } from '../../../states/Chat/event'
import { $chat, $chatControl, $chats, $chatsLoading, $chatsMetadata, $my } from '../../../states/Chat/store'
import { Accordion, Input, InputAutocomplete, Select, Switch } from '../../../shared/UI'
import { $languages } from '../../../states/Language/store'
import { NavLink, useParams } from 'react-router-dom'
import { Routes } from '../../../shared/enum'
import { getChatName } from '../../../shared/utils'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import styles from './ChatId/ChatId.module.scss'
import DoneIcon from '@mui/icons-material/Done'
import useInfiniteScroll from 'react-infinite-scroll-hook'

const typeSort = [
  { key: 'created-time', value: 'Новые' },
  // { key: 'wait-time', value: 'Большое ождание' },
  // { key: 'unread', value: 'Непрочитанные' },
]

export const DialogList = () => {
  const { id: paramId } = useParams() as { id: string }
  const chats = useStore($chats)
  const chatsMetadata = useStore($chatsMetadata)
  const chatsLoading = useStore($chatsLoading)
  const language = useStore($languages)
  const my = useStore($my)
  const chatControl = useStore($chatControl)

  const { query, pagination, handlerPagination, handlerQuery } = usePagination({
    defaults: {
      orderBy: 'DESC',
      sort: typeSort[0].key,
      limit: '5',
      page: '1',
      language: '',
      notEmpty: chatControl.notEmpty,
    },
    observerQuery: (query) => getChatsFx(query),
  })

  const total = chatsMetadata?.total || 0
  const length = chats.length || 0
  const loadTotal = Number(chatsMetadata?.page) * Number(chatsMetadata?.limit)
  const hasNextPage = total > loadTotal && total > length

  const [refFist] = useInfiniteScroll({
    loading: chatsLoading,
    hasNextPage,
    onLoadMore: () => {
      handlerPagination('page', Number(pagination?.page) + 1)
    },
  })

  const handlerSort = (key: string, value: string) => {
    clearChatsFx()
    handlerQuery(key, value)
  }

  return (
    <>
      <Grid container spacing={2} style={{ padding: '10px' }}>
        <Grid item xs={12}>
          <InputAutocomplete
            fullWidth
            label="Поиск (айди, телефон, сообщение)"
            variant="outlined"
            size="small"
            onChange={(value) => {
              clearChatsFx()
              handlerQuery('search', value)
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <Select
            isEmpty
            fullWidth
            label="Язык"
            size="small"
            variant="outlined"
            value={query?.language}
            onSelect={(code) => handlerSort('language', code)}
            options={[
              ...language
                .filter(({ active }) => active)
                .map(({ language, code }) => ({ value: language, key: code })),
            ]}
          />
        </Grid>

        <Grid item xs={6}>
          <Select
            fullWidth
            label="Сортировка"
            size="small"
            variant="outlined"
            value={query?.sort}
            onSelect={(code) => handlerSort('sort', code)}
            options={typeSort}
          />
        </Grid>

        <Grid item xs={12}>
          <Accordion label="Настройки" className="controller">
            <Switch
              size="small"
              label="Скрывать пустые"
              checked={chatControl.notEmpty}
              onChange={(event, checked) => setChatControlFx({ notEmpty: checked })}
            />
          </Accordion>
        </Grid>
      </Grid>

      <Divider />

      <List>
        {chats
          .map((chat, idx) => {
            const {
              id,
              lastMessage,
              ownerUser,
              unreadCount,
            } = chat
            const timeMsg = dayjs(lastMessage?.createdAt)
            const isToday = dayjs().diff(timeMsg, 'hour') < 24
            const isMyMsg = lastMessage?.user?.id !== ownerUser?.id
            const isMyChat = chat?.ownerUser?.accountId === my?.accountId
            const isAttachments = !!lastMessage?.attachments.length

            const msgSlice = lastMessage?.content?.slice(0, isAttachments ? 15 : 25) || ''
            let msgContent = isMyMsg ? `Вы: ${msgSlice}` : msgSlice
            if (isAttachments) {
              msgContent = `${msgContent} [Вложения]`
            }

            const time = isToday
              ? timeMsg.format('HH:mm')
              : timeMsg.format('D MMM')
            const timeLabel = isMyMsg
              ? <>
                {lastMessage?.firstReadAt
                  ? <DoneAllIcon className={styles.read} />
                  : <DoneIcon className={styles.read} />
              }
                {time}
              </>
              : time

            return (
              <NavLink
                key={id}
                to={`/${Routes.CHAT}/${id}`}
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                }}
              >
                <ListItem button selected={id === Number(paramId)}>
                  <ListItemIcon>
                    <Avatar src={ownerUser?.avatar} />
                  </ListItemIcon>
                  <ListItemText
                    primary={isMyChat ? 'Избранное' : getChatName(ownerUser)}
                    secondary={msgContent}
                  />
                  <ListItemText
                    style={{
                      textAlign: 'end',
                    }}
                    primary={
                      <Badge
                        badgeContent={unreadCount}
                        color="secondary"
                        componentsProps={{
                          badge: {
                            style: {
                              right: '16px',
                            },
                          },
                        }}
                      />
                }
                    secondary={timeLabel}
                  />
                </ListItem>
              </NavLink>
            )
          })}

        {(chatsLoading || hasNextPage) &&
        <Grid ref={refFist}>
          <CircularProgress />
        </Grid>
        }
      </List>
    </>
  )
}
