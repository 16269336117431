import promiseToResult from '../../core/api/promiseToResults'
import AxiosInstance from '../../core/api/axiosinstance'

const getHeapsnapshotApplication = () => {
  return promiseToResult(
    AxiosInstance().get(
      'application/__check/__private/heapsnapshot',
    ),
  )
}

const getHeapsnapshotProjects = () => {
  return promiseToResult(
    AxiosInstance().get(
      'projects/__check/__private/heapsnapshot',
    ),
  )
}

export const AppAPI = {
  getHeapsnapshotApplication,
  getHeapsnapshotProjects,
}
