import React from 'react'
import { Navigate, Outlet, useRoutes } from 'react-router-dom'
import { useStore } from 'effector-react'

import { Role, Routes } from '../../shared/enum'
import { $isAuthorized } from '../../states/Auth/store'
import LoginController from '../Login'
import UserController from '../User'
import LanguageController from '../Language'
import TranslationController from '../Translation'
import PaymentController from '../Payment'
import PaymentCreate from '../../shared/components/Payment/PaymentCreate'
import PaymentEdit from '../../shared/components/Payment/PaymentEdit'
import { VerificationController } from '../Verification'
import ProjectController from '../Project'
import { ProjectCreate, ProjectEdit, ProjectId, ProjectPrice, ProjectRoot } from '../../shared/components/Project'
import RecoveryController from '../Recovery/Recovery.controller'
import RecoveryEdit from '../../shared/components/Recovery/RecoveryEdit'
import { WhatsappController } from '../Whatsapp'
import { WhatsappPhone, WhatsappRoot } from '../../shared/components/Whatsapp'
import { FilesId } from '../../shared/components/Files'
import { FilesController } from '../Files'
import { LanguageCreate, LanguageEdit } from '../../shared/components/Language'
import { TranslationCreate, TranslationEdit, TranslationRoot } from '../../shared/components/Translation'
import { UserDashboard, UserHeir, UserRoot } from '../../shared/components/User'
import { VerificationEdit, VerificationRoot } from '../../shared/components/Verification'
import { TransactionController } from '../Transaction'
import {
  TransactionId,
  TransactionRoot,
  WithdrawalEdit,
  WithdrawalRoot,
} from '../../shared/components/Transaction'
import { DashboardController } from '../Dashboard'
import { DashboardRoot } from '../../shared/components/Dashboard/DashboardRoot'
import { CompanyController } from '../Company'
import ChatController from '../Chat'
import LandingController from '../Landing'
import { TempCode } from '../_TempCode'
import AnalyticController from '../Analytic'
import CandidateController from '../Candidate'
import { RootRouter as Rr } from '../../shared/UI/RootRouter'
import NewsController from '../News'
import { InstallmentController } from '../Installment'
import { FaqController } from '../Faq'
import { AuthHistoryController } from '../AuthHistory'
import { MoneyBox } from '../MoneyBox'
import { NotificationController } from '../Notification'
import { SupervisoryController } from '../Supervisory'
import { StoryController } from '../Story'
import { TemplateController } from '../Template'

export const AppRouter = () => {
  const isAuthorized = useStore($isAuthorized)

  return useRoutes([
    {
      path: Routes.ROOT,
      element: <Rr role={Role.ADMIN} component={<DashboardController />} />,
      children: [
        { path: Routes.ROOT, element: <Rr role={Role.ADMIN} component={<DashboardRoot />} /> },
      ],
    },
    {
      path: Routes.USER,
      element: <Rr role={Role.SUPPORT} component={<UserController />} />,
      children: [
        { path: Routes.ROOT, element: <UserRoot /> },
        { path: Routes.ID, element: <UserDashboard /> },
        { path: `${Routes.ID}/${Routes.HEIR}`, element: <UserHeir /> },
      ],
    },
    {
      path: Routes.PAYMENT,
      element: <Rr role={Role.ADMIN} />,
      children: [
        { path: Routes.ROOT, element: <PaymentController /> },
        { path: Routes.CREATE, element: <PaymentCreate /> },
        { path: Routes.ID, element: <PaymentEdit /> },
      ],
    },
    {
      path: Routes.PROJECT,
      element: <Rr role={Role.ADMIN} component={<ProjectController />} />,
      children: [
        { path: Routes.ROOT, element: <ProjectRoot /> },
        { path: Routes.CREATE, element: <Rr role={Role.ADMIN} component={<ProjectCreate />} /> },
        {
          path: Routes.ID,
          element: <ProjectId />,
          children: [
            { path: Routes.ROOT, element: <ProjectEdit /> },
            { path: Routes.PRICE, element: <Rr role={Role.ADMIN} component={<ProjectPrice />} /> },
          ],
        },
      ],
    },
    {
      path: Routes.VERIFICATION,
      element: <Rr role={Role.SUPPORT} component={<VerificationController />} />,
      children: [
        { path: Routes.ROOT, element: <VerificationRoot /> },
        { path: Routes.ID, element: <VerificationEdit /> },
      ],
    },
    {
      path: Routes.RECOVERY,
      element: <Rr role={Role.SUPPORT} />,
      children: [
        { path: Routes.ROOT, element: <RecoveryController /> },
        { path: Routes.ID, element: <RecoveryEdit /> },
      ],
    },
    {
      path: Routes.LANGUAGE,
      element: <Rr role={Role.ADMIN} />,
      children: [
        { path: Routes.ROOT, element: <LanguageController /> },
        { path: Routes.CREATE, element: <LanguageCreate /> },
        { path: Routes.ID, element: <LanguageEdit /> },
      ],
    },
    {
      path: Routes.TRANSLATION,
      element: <Rr role={Role.MANAGER} component={<TranslationController />} />,
      children: [
        { path: Routes.ROOT, element: <TranslationRoot /> },
        { path: Routes.CREATE, element: <TranslationCreate /> },
        { path: Routes.ID, element: <TranslationEdit /> },
      ],
    },
    {
      path: `${Routes.CHAT}/*`,
      element: <Rr role={Role.SUPPORT} component={<ChatController />} />,
    },
    {
      path: `${Routes.LANDING}/*`,
      element: <Rr role={Role.MANAGER} component={<LandingController />} />,
    },
    {
      path: `${Routes.CANDIDATE}/*`,
      element: <Rr role={Role.SUPPORT} component={<CandidateController />} />,
    },
    {
      path: `${Routes.INSTALLMENT}/*`,
      element: <Rr role={Role.SUPPORT} component={<InstallmentController />} />,
    },
    {
      path: `${Routes.COMPANY}/*`,
      element: <Rr role={Role.ADMIN} component={<CompanyController />} />,
    },
    {
      path: `${Routes.NEWS}/*`,
      element: <Rr role={Role.MANAGER} component={<NewsController />} />,
    },
    {
      path: `${Routes.FAQ}/*`,
      element: <Rr role={Role.MANAGER} component={<FaqController />} />,
    },
    {
      path: `${Routes.NOTIFICATION}/*`,
      element: <Rr role={Role.SUPPORT} component={<NotificationController />} />,
    },
    {
      path: `${Routes.SUPERVISORY}/*`,
      element: <Rr role={Role.SUPPORT} component={<SupervisoryController />} />,
    },
    {
      path: Routes.TRANSACTION,
      element: <Rr role={Role.SUPPORT} component={<TransactionController />} />,
      children: [
        { path: Routes.ROOT, element: <TransactionRoot /> },
        {
          path: Routes.ID,
          element: <TransactionId />,
        },
        {
          path: Routes.WITHDRAWAL,
          element: <Rr role={Role.ADMIN} />,
          children: [
            { path: Routes.ROOT, element: <WithdrawalRoot /> },
            { path: Routes.ID, element: <WithdrawalEdit /> },
          ],
        },
      ],
    },
    {
      path: Routes.WHATSAPP,
      element: <Rr role={Role.MANAGER} component={<WhatsappController />} />,
      children: [
        { path: Routes.ROOT, element: <WhatsappRoot /> },
        { path: Routes.PHONE, element: <Rr role={Role.ADMIN} component={<WhatsappPhone />} /> },
      ],
    },
    {
      path: Routes.FILES,
      element: <Rr role={Role.ADMIN} />,
      children: [
        { path: Routes.ROOT, element: <FilesController /> },
        { path: Routes.ID, element: <FilesId /> },
      ],
    },
    {
      path: Routes.LOGIN,
      element: !isAuthorized ? <Outlet /> : <Navigate to={'/'} />,
      children: [
        { path: Routes.ROOT, element: <LoginController /> },
      ],
    },
    {
      path: 'code',
      element: <Rr role={Role.SUPPORT} component={<TempCode />} />,
    },
    {
      path: `${Routes.ANALYTIC}/*`,
      element: <Rr role={Role.ADMIN} component={<AnalyticController />} />,
    },
    {
      path: `${Routes.HISTORY}/*`,
      element: <Rr role={Role.SUPPORT} component={<AuthHistoryController />} />,
    },
    {
      path: `${Routes.MONEYBOX}/*`,
      element: <Rr role={Role.SUPPORT} component={<MoneyBox />} />,
    },
    {
      path: `${Routes.TEMPLATE}/*`,
      element: <Rr role={Role.MANAGER} component={<TemplateController />} />,
    },
    {
      path: `${Routes.STORY}/*`,
      element: <Rr role={Role.MANAGER} component={<StoryController />} />,
    },
    {
      path: '*',
      element: <Navigate to={Routes.ROOT} />,
    },
  ])
}
