import React from 'react'
import { useStore } from 'effector-react'

import { Role } from '../enum'
import { isRoleGuard } from '../utils'
import { $my } from '../../states/My/store'

interface RoleGuardProps {
  message?: string
  role: Role
}

export const RoleGuard: React.FC<React.PropsWithChildren<RoleGuardProps>> = ({
  children,
  role,
  message,
}) => {
  const my = useStore($my)
  if (!isRoleGuard(role, my?.role)) {
    return message ? <>{message}</> : null
  }
  return <>{children}</>
}
