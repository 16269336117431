import React from 'react'
import { NavLink } from 'react-router-dom'
import { Page } from '../../../../shared/UI'
import { useMoneyBox } from '../../../../services/api/MoneyBox'
import { Routes } from '../../../../shared/enum'

export const Participants = () => {
  const { boxId } = useMoneyBox()

  return <Page
    variant="root"
    title="Участники в копилке"
    col={[
      { id: 'id', label: 'ID', render: (id) => <NavLink to={`/${Routes.USER}/${id}`}>{id}</NavLink> },
      { id: 'phone', label: 'Телефон' },
      { id: 'social.email', label: 'Почта' },
      { id: 'login', label: 'Логин' },
    ]}
    rows={boxId.participants.data?.items}
    total={boxId.participants.data?.metadata.total}
    onChange={boxId.participants.fetch}
    filter={[
      {
        label: 'Фильтры',
        defaultExpanded: true,
        fields: [
          { name: 'id', type: 'number', label: 'Копилка ID' },
          { name: 'userId', type: 'number', label: 'Юзер ID' },
        ],
      },
    ]}
  />
}
