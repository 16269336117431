/* eslint-disable max-len */
import React, { useState } from 'react'
import dayjs from 'dayjs'
import { useQuery } from '@tanstack/react-query'

import { Page } from '../../../shared/UI/Page'
import { GetInstallmentsReq, InstallmentsAPI } from '../../../services/api/Installments'
import { getOptionsValue, orderDirectionOptions, toDollar } from '../../../shared/utils'
import { NavLink } from 'react-router-dom'
import { Routes } from '../../../shared/enum'
import { useStore } from 'effector-react'
import { $projectsOptions } from '../../../states/Project/store'
import { Option } from '../../../shared/UI/Input'
import { defaultQuery } from '../../../shared/hooks'
import { Stack, Typography } from '@mui/material'
import { $defaults } from '../../../states/Defaults/store'

const orderByOptions: Option<string>[] = [
  { key: 'createdAt', value: 'Времени создания' },
  { key: 'nextPaymentDate', value: 'Следующий платеж' },
  { key: 'paidSumLeft', value: 'Осталось оплатить' },
]

export const Root = () => {
  const defaults = useStore($defaults)
  const projectsOptions = useStore($projectsOptions)
  const [query, setQuery] = useState<GetInstallmentsReq | null>(null)
  const get = useQuery(['getInstallments', query], () => query && InstallmentsAPI.getInstallments(query))

  return <Page
    variant="root"
    download
    onChange={(values) => setQuery(values)}
    loading={[get.isLoading]}
    defaultFilter={{
      ...defaultQuery,
      orderBy: orderByOptions[1].key,
      orderDirection: orderDirectionOptions[1].key,
      isClosed: false,
      isPaid: false,
    }}
    filter={[
      {
        defaultExpanded: true,
        label: 'Фильтры',
        fields: [
          { name: 'userId', type: 'number', label: 'Юзера ID' },
          { name: 'projectIds', type: 'select', label: 'Проекты', options: projectsOptions, isEmpty: true },
          { name: 'isPaid', type: 'switch', label: 'Оплачено', isEmpty: true },
          { name: 'isClosed', type: 'switch', label: 'Закрыто', isEmpty: true },
          { name: 'orderBy', type: 'select', label: 'Сортировать по', options: orderByOptions, isEmpty: true },
          { name: 'orderDirection', type: 'select', label: 'Упорядочить', options: orderDirectionOptions, isEmpty: true },
          { name: 'nextPaymentDateStart', type: 'date', label: 'Сл платеж с' },
          { name: 'nextPaymentDateEnd', type: 'date', label: 'Сл платеж по' },
          { name: 'adminStatus', type: 'select', label: 'Статус', options: defaults.installments.adminStatuses, isEmpty: true },
        ],
      },
    ]}
    col={[
      { id: 'id', label: 'ID' },
      { id: 'user', label: 'Юзер', render: (id) => <NavLink to={`/${Routes.USER}/${id}`}>{id}</NavLink> },
      { id: 'project', label: 'Проект', render: (project) => projectsOptions.find(({ key }) => key === project)?.value },
      { id: 'isPaid', label: 'Оплачено', render: (isPaid) => isPaid ? 'Да' : 'Нет' },
      { id: 'shares', label: 'Долей' },
      {
        id: 'price',
        label: 'Цена',
        render: (price) => `${toDollar(price)} $`,
      },
      {
        id: 'calendar',
        label: 'Период',
        render: (calendar = []) => `${calendar?.filter(({ isPaid = false }) => isPaid).length}/${calendar?.length}`,
      },
      {
        id: 'price',
        label: '',
        render: (price, { calendar }) =>
          <Stack>
            <Typography variant="body2">
              Оплачено: {toDollar(calendar.reduce((acc, { isPaid, price }) => isPaid ? acc + price : acc, 0))} $
            </Typography>
            <Typography variant="body2">
              Недоплачено: {toDollar(calendar.reduce((acc, { isPaid, price }) => !isPaid ? acc + price : acc, 0))} $
            </Typography>
          </Stack>,
      },
      { id: 'minimumPayment', label: 'Мин платеж', render: (price) => `${toDollar(price)} $` },
      { id: 'createdAt', label: 'Открыт', render: (date) => dayjs(date).format('DD.MM.YY HH:mm') },
      {
        id: 'nextPaymentDate',
        label: 'Сл платеж',
        render: (date) => date &&
          <Stack>
            <div>{dayjs(date).format('DD.MM.YY HH:mm')}</div>
            <div>{dayjs(date).fromNow()}</div>
          </Stack>,
      },
      { id: 'closedAt', label: 'Закрыта', render: (date) => date && dayjs(date).format('DD.MM.YY HH:mm') },
      { id: 'paidAt', label: 'Оплачена', render: (date) => date && dayjs(date).format('DD.MM.YY HH:mm') },
      { id: 'adminStatus', label: 'Статус', render: (status) => getOptionsValue(defaults.installments.adminStatuses, status) },
      { id: 'comment', label: 'Коммент', render: (comment) => comment },
    ]}
    title="Резервы"
    rows={get.data?.data.data.items}
    total={get.data?.data.data.metadata.total}
  />
}
