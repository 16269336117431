import React, { useRef, useState } from 'react'
import { useStore } from 'effector-react'
import AvatarEditor from 'react-avatar-editor'
import { Drawer, Slider, Typography } from '@mui/material'

import { Page } from '../../../shared/UI/Page'
import { $defaults } from '../../../states/Defaults/store'
import { useSupervisory } from '../../../services/api/Supervisory'
import { Routes } from '../../../shared/enum'
import { Button, InputFile } from '../../../shared/UI'
import { postFilesFx } from '../../../states/Files/event'

export const Id = () => {
  const defaults = useStore($defaults)
  const { supervisoryId } = useSupervisory()

  const [open, setOpen] = useState(false)
  const editor = useRef<AvatarEditor>(null)

  const [rotate, setRotate] = useState(0)
  const [scale, setScale] = useState(100)

  const handlerCrop = () => {
    editor.current?.getImage().toBlob(async (blob) => {
      if (!blob) { return }
      const file = new File([blob], 'supervisory_crop.png', {
        type: 'image/png',
      })
      const { data: { data: { url } } } = await postFilesFx({ file })

      supervisoryId.patch.fetch({
        id: supervisoryId.get.data?.id as number,
        photoPreview: url,
      })
    })
  }

  const handlerPhotoPreview = (url: string) => {
    supervisoryId.patch.fetch({
      id: supervisoryId.get.data?.id as number,
      photoPreview: url,
    })
  }

  return (
    <>
      <Page
        variant="form"
        title="Редактирование наблюдателя"
        button="Сохранить"
        actions={[
          { type: 'link', title: 'Наблюдатели', to: `/${Routes.SUPERVISORY}` },
          { type: 'link', title: 'Юзер', to: `/${Routes.USER}/${supervisoryId.get.data?.userId}` },
          { type: 'button', title: 'Фото', onClick: () => setOpen(true) },
        ]}
        loading={[supervisoryId.get.loading, supervisoryId.patch.loading]}
        value={supervisoryId.get.data as any}
        onInit={supervisoryId.get.fetch}
        onSubmit={supervisoryId.patch.fetch}
        fields={[
          { name: 'id', type: 'number', label: 'ID', disabled: true },
          { name: 'userId', type: 'string', label: 'Юзер ID', disabled: true },
          { name: 'updatedAt', type: 'date', label: 'Обновлено', disabled: true },
          { name: 'name', type: 'string', label: 'Имя', disabled: true },
          { name: 'surname', type: 'string', label: 'Фамилия', disabled: true },
          { name: 'country', type: 'string', label: 'Страна', disabled: true },
          { name: 'priority', type: 'number', label: 'Приоритет' },
          { name: 'status', type: 'select', label: 'Статус', options: defaults.supervisory.statuses },
          { name: 'city', type: 'string', label: 'Город' },
          {
            name: 'links',
            type: 'fields',
            label: 'Соц сети',
            fields: [
              { name: 'type', type: 'select', label: 'Тип', options: defaults.supervisory.socials },
              { name: 'url', type: 'string', label: 'Ссылка' },
            ],
          },
          { name: 'rejectComment', type: 'string', label: 'Комментарий' },
        ]}
      />

      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: { padding: 24 },
        }}
      >
        <Typography gutterBottom>
          Отредактируйте фото
          <br />
          Лицо должно быть по центру и смотреть прямо,
          <br />
          нижняя граница по началу плеч
        </Typography>
        <AvatarEditor
          ref={editor}
          image={supervisoryId.get.data?.photo || ''}
          width={360}
          height={360}
          scale={scale / 100}
          rotate={rotate}
          crossOrigin="anonymous"
        />

        <Typography gutterBottom>
          Поворот ({rotate}°)
        </Typography>
        <Slider
          min={-360}
          value={rotate}
          max={360}
          onChange={(_, rotate) => setRotate(Number(rotate))}
        />

        <Typography gutterBottom>
          Зум ({scale / 100})
        </Typography>
        <Slider
          min={50}
          value={scale}
          max={300}
          onChange={(_, scale) => setScale(Number(scale))}
        />

        <Button
          onClick={handlerCrop}
          loading={supervisoryId.get.loading || supervisoryId.patch.loading}
          sx={{
            mb: 2,
          }}
        >
          Сохранить
        </Button>

        <InputFile
          label="это фото будет отображаться на сайте"
          onFile={handlerPhotoPreview}
          value={supervisoryId.get.data?.photoPreview}
        />

        {supervisoryId.get.data?.photoPreview &&
        <img
          src={supervisoryId.get.data?.photoPreview}
          width={360}
          height={360}
        />
        }
      </Drawer>
    </>
  )
}
