import React from 'react'
import { useStore } from 'effector-react'
import dayjs from 'dayjs'

import { Col, Page } from '../../../UI'
import { getTransactionsFx } from '../../../../states/Transaction/event'
import { $transactions } from '../../../../states/Transaction/store'
import { $defaults } from '../../../../states/Defaults/store'
import { $projectsOptions } from '../../../../states/Project/store'
import { $paymentsOptions } from '../../../../states/Payment/store'
import { toDollar } from '../../../utils'

const col: Col[] = [
  { id: 'id', label: 'ID' },
  { id: 'user', label: 'Юзер ID' },
  { id: 'price', label: 'Цена (центы)', render: (p) => `${toDollar(p)}$` },
  { id: 'shares', label: 'Доли кол-во' },
  { id: 'project', label: 'Проект', render: (project) => project?.symbol },
  {
    id: 'type',
    label: 'Тип',
    render: (value) => {
      const defaults = $defaults.getState()
      return defaults?.transactions?.types?.find(({ key }: any) => key === value)?.value || value
    },
  },
  {
    id: 'status',
    label: 'Статус',
    render: (value) => {
      const defaults = $defaults.getState()
      return defaults?.transactions?.statuses.find(({ key }: any) => key === value)?.value || value
    },
  },
  {
    id: 'paymentSystem',
    label: 'Платежка',
    render: (ps) => {
      const defaults = $paymentsOptions.getState()
      return defaults.find(({ key }: any) => key === ps?.id)?.value || ps?.id
    },
  },
  { id: 'comment', label: 'Комментарий' },
  {
    id: 'createdAt',
    label: 'Время создания',
    render: (value) => dayjs(value as string).format('HH:mm DD.MM.YYYY'),
  },
]

export const TransactionRoot = () => {
  const defaults = useStore($defaults)
  const transactions = useStore($transactions)
  const projectsOptions = useStore($projectsOptions)
  const paymentsOptions = useStore($paymentsOptions)

  return <Page
    variant="root"
    title="Новости"
    onChange={(query) => getTransactionsFx({ query })}
    rows={transactions.items || []}
    col={col}
    total={transactions.metadata?.total}
    defaultFilter={{
      limit: '15',
      page: '1',
    }}
    filter={[
      {
        defaultExpanded: true,
        label: 'Фильтры',
        fields: [
          { name: 'id', type: 'number', label: 'ID' },
          { name: 'userId', type: 'number', label: 'ID юзера' },
          { name: 'type', type: 'select', label: 'Тип', options: defaults?.transactions?.types, isEmpty: true },
          { name: 'status', type: 'select', label: 'Статус', options: defaults?.transactions?.statuses, isEmpty: true },
          { name: 'projectId', type: 'select', label: 'Проект', options: projectsOptions, isEmpty: true },
          { name: 'paymentSystemIds', type: 'select', label: 'Платежная система', options: paymentsOptions, isEmpty: true },
          { name: 'dateStart', type: 'date', label: 'С время создания' },
          { name: 'dateEnd', type: 'date', label: 'До время создания' },
        ],
      },
      {
        defaultExpanded: false,
        label: 'Metadata',
        fields: [
          { name: 'paymentId', type: 'string', label: 'paymentId' },
          { name: 'wallet', type: 'string', label: 'wallet' },
        ],
      },
    ]}
  />
}
