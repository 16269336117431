/* eslint-disable @typescript-eslint/no-unused-vars,no-await-in-loop,no-restricted-syntax */
import React, { ChangeEventHandler, KeyboardEventHandler, useCallback, useEffect, useRef } from 'react'
import { IconButton, TextareaAutosize, CircularProgress } from '@mui/material'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import SendIcon from '@mui/icons-material/Send'
import { useForm } from 'react-hook-form'
import { useStore } from 'effector-react'
import DeleteIcon from '@mui/icons-material/Delete'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'

import styles from './Input.module.scss'

import { socketSendFx } from '../../../../../states/Chat/event'
import { $chat, $my } from '../../../../../states/Chat/store'
import { postFilesFx } from '../../../../../states/Files/event'
import { $filesPostLoading } from '../../../../../states/Files/store'
import { getFileExtension, getFileName, MyFile } from '../../../../../shared/utils'

interface Form {
  input: string
  files: { name: string, src: string, file: File }[]
}

export const Input = () => {
  const my = useStore($my)
  const chat = useStore($chat)
  const filesPostLoading = useStore($filesPostLoading)

  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>

  const form = useForm<Form>({
    defaultValues: {
      input: '',
      files: [],
    },
  })
  const { setValue, register, watch, handleSubmit } = form
  const { files, input } = watch()

  const disabledSend = !chat.id || filesPostLoading || !(input.trim().trimEnd() || files.length)

  const addFiles = (f: Form['files']) => {
    const fFilter = f.filter((fileInfo) => {
      const myFile = new MyFile(fileInfo.file)
      return myFile.isImage() || myFile.isVideo()
    })

    setValue('files', [...files, ...fFilter].slice(0, 3))
  }

  useEffect(() => {
    const handlePasteOnDocument = (e: ClipboardEvent) => {
      if (e.clipboardData) {
        const [firstItem] = e.clipboardData.items
        const file = firstItem.getAsFile()
        if (file) {
          const blob = URL.createObjectURL(file)
          addFiles([{ name: file.name, src: blob, file }])
        }
      }
    }

    document.addEventListener('paste', handlePasteOnDocument)
    return () => {
      document.removeEventListener('paste', handlePasteOnDocument)
    }
  }, [files])

  const handlerSend = async (values: Form) => {
    const content = values.input.trim().trimEnd() || undefined
    if (content || files.length) {
      const attachedFiles: string[] = []

      if (files.length) {
        // eslint-disable-next-line no-unreachable-loop
        for (const f of files) {
          const myFile = new MyFile(f.file)
          const { data: { data: { url } } } = await postFilesFx({
            filename: `user=${my?.accountId}::chat=${chat.id}::${getFileName(f.name)}.${getFileExtension(f.name)}`,
            file: await myFile.compress(),
          })
          attachedFiles.push(url)
        }
      }

      socketSendFx({
        chatId: chat.id as number,
        content,
        attachedFiles,
      })

      setValue('input', '')
      setValue('files', [])
    }
  }

  const handlerKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (e.key === 'Enter' && !e.ctrlKey && !e.altKey && !e.shiftKey) {
      e.preventDefault()
      handleSubmit(handlerSend)()
    }
    if (e.key === 'Enter' && (e.ctrlKey || e.altKey || e.shiftKey)) {
      setValue('input', `${input}\n`)
    }
  }

  const handlerButton = () => {
    inputRef.current.click()
  }

  const handlerUpload: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { files } = e.target

    if (files?.length) {
      const srcArray = Array.from(files).map((file) => {
        return {
          name: file.name,
          src: URL.createObjectURL(file),
          file,
        }
      })
      addFiles(srcArray)
    }
  }

  const handlerRemoveFile = (idx: number) => {
    setValue('files', files.filter((_, i) => i !== idx))
  }

  return (
    <form
      className={styles.footer}
      onSubmit={handleSubmit(handlerSend)}
    >
      {!!files?.length &&
      <div className={styles.files}>
        {files.map(({ src, file, name }, idx) => {
          const myFile = new MyFile(file)
          return (
            <div
              className={styles.preview}
              key={src}
            >
              {myFile.isImage() &&
              <img src={src} />
              }
              {myFile.isVideo() &&
              <PlayArrowIcon
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  alignItems: 'center',
                  height: '100%',
                }}
              />
              }

              {filesPostLoading
                ? (
                  <div className={styles.loading}>
                    <CircularProgress />
                  </div>
                )
                : (
                  <DeleteIcon
                    className={styles.del}
                    onClick={() => handlerRemoveFile(idx)}
                  />
                )
              }
            </div>
          )
        })}
      </div>
      }

      <div className={styles.input}>
        <TextareaAutosize
          {...register('input')}
          autoFocus
          maxRows={3}
          minRows={1}
          placeholder="Сообщение"
          className={styles.textAria}
          onKeyDown={handlerKeyDown}
          disabled={!chat.id || filesPostLoading}
          maxLength={700}
        />

        <IconButton onClick={handlerButton}>
          <input
            type="file"
            accept="image/*,video/*"
            multiple
            hidden
            ref={inputRef}
            onChange={handlerUpload}
          />
          <AttachFileIcon />
        </IconButton>

        <IconButton
          type="submit"
          color="primary"
          disabled={disabledSend}
        >
          <SendIcon />
        </IconButton>
      </div>
    </form>
  )
}
