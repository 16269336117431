import React from 'react'
import { useMoneyBox } from '../../../../../services/api/MoneyBox'
import { Page } from '../../../../../shared/UI'
import { Routes } from '../../../../../shared/enum'
import { $defaults } from '../../../../../states/Defaults/store'
import { useStore } from 'effector-react'

export const UserTask = () => {
  const defaults = useStore($defaults)

  const { questsId } = useMoneyBox()

  return (
    <>
      <Page
        variant="form"
        title="Добавить задание юзеру"
        button="Сохранить"
        actions={[
          { title: 'Задачи', to: `/${Routes.MONEYBOX}/${Routes.TASK}`, type: 'link' },
        ]}
        loading={[questsId.post.loading]}
        onSubmit={questsId.post.fetch}
        fields={[
          { name: 'userId', type: 'number', label: 'Юзер ID' },
          { name: 'questEntryId', type: 'string', label: 'Шаблон ID' },
          { name: 'status', type: 'select', label: 'Статус', options: defaults.moneyboxes.questStatuses },
        ]}
      />
    </>
  )
}
