import React from 'react'
import { useRoutes } from 'react-router-dom'

import { Routes } from '../../shared/enum'
import { Root } from './pages'

export const AuthHistoryController = () => {
  return useRoutes([
    { path: Routes.ROOT, element: <Root /> },
  ])
}
