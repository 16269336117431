import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useStore } from 'effector-react'

import { environment } from '../../../../../environment'
import { $authToken } from '../../../../states/Auth/store'
import { Button } from '../../../UI/Button'
import { Box } from '@mui/material'
import { getInfoFx } from '../../../../states/Whatsapp/event'

enum Status {
  AWAIT,
  LOADING,
  CONFIRM,
}

export const WhatsappPhone = () => {
  const authToken = useStore($authToken)

  const [qr, setQr] = useState('')
  const [loading, setLoading] = useState<Status>(Status.AWAIT)

  const createWebSocket = () => {
    const socket = new WebSocket(
      `${environment.WEBSOCKET_PREFIX}/application/admin/whatsapp-qr?token=${authToken}`,
    )

    socket.onopen = () => {
      socket.send(JSON.stringify({ event: 'start' }))
      setLoading(Status.LOADING)
    }
    socket.onclose = () => {
      setLoading(Status.AWAIT)
    }

    socket.onmessage = (msg) => {
      const { data, event } = JSON.parse(msg.data)

      if (event === 'qr') {
        setQr(data)
        setLoading(Status.AWAIT)
      }
      if (event === 'authenticated') {
        setQr('')
        setLoading(Status.CONFIRM)
        getInfoFx(null)
      }
      if (event === 'error') {
        toast.error(data)
        socket.close()
      }
    }
  }

  return (
    <div>
      <Box sx={{ my: 3 }}>
        <Button
          onClick={createWebSocket}
          variant="contained"
          color="warning"
          disabled={loading !== Status.AWAIT}
        >
          Установить Whatsapp
        </Button>
      </Box>
      {(qr && loading === Status.AWAIT) &&
      <Box>
        <Box sx={{ mb: 2 }}>
          Отсканируйте QR CODE камерой в Whatsapp
        </Box>

        <img
          style={{ maxWidth: '320px' }}
          src={`data:image/png;base64, ${qr}`}
          alt=""
        />
      </Box>
      }
      {loading === Status.LOADING &&
      <Box>
        <Box sx={{ mb: 2 }}>
          Приготовьтесь сканировать QR-CODE
        </Box>
        <Box>
          Загрузка...
        </Box>
        </Box>
      }
      {loading === Status.CONFIRM &&
      <Box>
        <Box sx={{ mb: 2 }}>
          Телефон успешно привязан
        </Box>
      </Box>
      }
    </div>
  )
}
