import React from 'react'
import { useStore } from 'effector-react'
import { Page } from '../../../shared/UI'
import { $defaults } from '../../../states/Defaults/store'
import { useTemplate } from '../../../services/api/Template'
import { Routes } from '../../../shared/enum'

export const Create = () => {
  const defaults = useStore($defaults)
  const { post } = useTemplate()

  return (
    <Page
      variant="form"
      actions={[
        {
          title: 'Шаблоны',
          type: 'link',
          to: `/${Routes.TEMPLATE}`,
        },
      ]}
      loading={[post.loading]}
      onSubmit={post.fetch}
      fields={[
        { name: 'type', type: 'select', label: 'Тип', options: defaults.social.templates },
        { name: 'telegram.text.content', type: 'content', mode: 'text', label: 'Телеграм. Текст' },
        { name: 'whatsapp.text.content', type: 'content', mode: 'text', label: 'Ватцап. Текст' },
        { name: 'sms.text.content', type: 'content', mode: 'text', label: 'Смс. Текст' },
        { name: 'email.subject.content', type: 'content', mode: 'text', label: 'Почта. Заголовок' },
        { name: 'email.html.content', type: 'content', label: 'Почта. Содержание' },
        { name: 'email.from', type: 'string', label: 'Почта. Отправитель' },
      ]}
      button="Создать"
    />
  )
}
