import React from 'react'
import { useStore } from 'effector-react'
import { Box, Container, CssBaseline, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import { Navigate } from 'react-router-dom'

import { Button, Input, RootLayout } from '../../shared/UI'
import { Routes } from '../../shared/enum'
import { $isAuthorized } from '../../states/Auth/store'
import { logInFx } from '../../states/Login/event'
import { $isPending } from '../../states/Login/store'

import styles from './Login.module.scss'

interface LoginForm {
  login: string
  password: string
}

const LoginController = () => {
  const methods = useForm<LoginForm>()
  const {
    handleSubmit,
    watch,
    setValue,
  } = methods
  const { login, password } = watch()

  const isAuthorized = useStore($isAuthorized)

  const isPending = useStore($isPending)

  const handlerSubmit = () => {
    const phone = login.includes('+') ? login : `+${login}`
    logInFx({ phone, password })
  }

  if (isAuthorized) {
    return <Navigate to={Routes.ROOT} />
  }
  return (
    <RootLayout>
      <div className={styles.wrapper}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5" sx={{ marginBottom: 10 }}>
              Вход
            </Typography>

            <form onSubmit={handleSubmit(handlerSubmit)}>

              <PhoneInput
                inputProps={{
                  name: 'login',
                  required: true,
                  autoFocus: true,
                }}
                country={'ru'}
                specialLabel={'Телефон'}
                value={login}
                onChange={(value) => setValue('login', value)}
              />

              <Input
                sx={{ my: 2 }}
                onChange={(e) => setValue('password', e.target.value)}
                value={password}
                required
                fullWidth
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />

              <Button
                loading={isPending}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Войти
              </Button>
            </form>
          </Box>
        </Container>
      </div>
    </RootLayout>
  )
}

export default LoginController
