import AxiosInstance from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'

export type GetDefaultsRes = Record<string, any>
const getDefaults = () => {
  return promiseToResult(
    AxiosInstance().get<GetDefaultsRes>(
      '/application/admin/defaults/values',
    ),
  )
}

interface GetCode {
  key: string
  value: string
  staticKey:string
}
const getCode = () => {
  return promiseToResult(
    AxiosInstance().get<GetCode[]>(
      '/application/admin/users/codes',
    ),
  )
}

export const DefaultsAPI = {
  getDefaults,
  getCode,
}
