import React from 'react'
import { useStore } from 'effector-react'
import { useRoutes } from 'react-router-dom'
import dayjs from 'dayjs'

import { Page, Switch } from '../../../shared/UI'
import {
  getCategoriesFx,
  getReportFx,
  getReportsFx,
  patchReportFx,
  postReportFx,
} from '../../../states/FinReport/event'
import { $isPendingCategories, $reports, $categories, $isPendingReport, $report } from '../../../states/FinReport/store'
import { Routes } from '../../../shared/enum'
import { PatchReportReq, PostReportReq } from '../../../services/api/FinReport'
import { Category as ReportCategory } from './Category'

export const Report = () => {
  const reports = useStore($reports)
  const report = useStore($report)
  const categories = useStore($categories)
  const isPendingCategories = useStore($isPendingCategories)
  const isPendingReport = useStore($isPendingReport)

  return useRoutes([
    {
      path: `${Routes.ROOT}/*`,
      element: <Page
        variant="outlet"
        links={[
          { title: 'Главная', to: Routes.ROOT },
          { title: 'Создать отчет', to: Routes.CREATE },
          { title: 'Категории', to: Routes.CATEGORY },
        ]}
      />,
      children: [
        {
          path: Routes.ROOT,
          element: <Page
            loading={[isPendingCategories]}
            onInit={() => getReportsFx()}
            variant="root"
            col={[
              { id: 'id', label: 'ID' },
              { id: 'Дата', label: 'Дата', render: (date) => dayjs(date).format('MMMM YYYY') },
              { id: 'isActive', label: 'Активна', render: (isActive) => <Switch disabled checked={isActive} label="" /> },
            ]}
            rows={reports}
            title="Финансовые отчеты"
          />,
        },
        {
          path: Routes.CREATE,
          element: <Page
            title="Создание категории для фин. отчета"
            variant="form"
            button="Создать"
            onInit={() => getCategoriesFx()}
            onSubmit={(v) => postReportFx(v as PostReportReq)}
            fields={[
              { name: 'isActive', type: 'switch', label: 'Активна' },
              { name: 'reportedAt', type: 'date', label: 'Дата отчета' },
              {
                type: 'fields',
                name: 'statements',
                label: 'Траты',
                fields: [
                  { name: 'price', type: 'number', label: 'Сумма $' },
                  {
                    name: 'categoryId',
                    type: 'select',
                    label: 'Категория',
                    options: categories.map((c) => ({ key: c.id, value: c.name })),
                  },
                ],
              },
            ]}
          />,
        },
        {
          path: Routes.ID,
          element: <Page
            title="Редактирование фин. отчета"
            variant="form"
            button="Сохранить"
            loading={[isPendingReport]}
            onInit={({ id }) => {
              getCategoriesFx()
              getReportFx({ id: String(id) })
            }}
            value={report}
            onSubmit={(v) => patchReportFx(v as PatchReportReq)}
            fields={[
              { name: 'isActive', type: 'switch', label: 'Активна' },
              { name: 'reportedAt', type: 'date', label: 'Дата отчета' },
              {
                type: 'fields',
                name: 'statements',
                label: 'Траты',
                fields: [
                  { name: 'price', type: 'number', label: 'Сумма $' },
                  {
                    name: 'categoryId',
                    type: 'select',
                    label: 'Категория',
                    options: categories.map((c) => ({ key: c.id, value: c.name })),
                  },
                ],
              },
            ]}
          />,
        },
        { path: `${Routes.CATEGORY}/*`, element: <ReportCategory /> },
      ],
    },
  ])
}
