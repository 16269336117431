import AxiosInstance from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'
import { Content, WithMetadata } from './_types'
import { querystring } from '../../shared/utils'

export interface User {
  id: number
  type: 'user' | 'bot' | 'support'
  language: string
  phone: string
  firstName: string
  lastName: string
  accountId: number
  avatar: string
}

export interface Message {
  id: 0
  user: User | null
  content?: string
  attachments: string[],
  chatId: 0,
  createdAt: string
  firstReadAt: string | null
}

export interface Chat {
  id: number
  createdAt: string
  lastMessage: Message | null
  unreadCount: number
  ownerUser: User | null
}

export interface UpdateMessage {
  chatId: number
  message: Message
}

export interface ChatSettings {
  key: string
  value: {
    botMessage: Content[]
  }
}

export interface GetChatsReq {
  limit: string
  page: string
  orderBy?: string
  sort?: string
  language?: string
  search?: string
}
export type GetChatsRes = WithMetadata<Chat>
const getChats = (data: GetChatsReq) => {
  return promiseToResult(
    AxiosInstance().get<GetChatsRes>(
      `/support/admin/chats?${querystring.stringify(data)}`,
    ),
  )
}

export interface GetChatReq {
  id: number
}
export type GetChatRes = Chat
const getChat = (data: GetChatReq) => {
  return promiseToResult(
    AxiosInstance().get<GetChatRes>(
      `/support/admin/chats/${data.id}`,
    ),
  )
}

export type PutChatUnreadRes = GetChatReq
const putChatUnread = (data: PutChatUnreadRes) => {
  return promiseToResult(
    AxiosInstance().put(
      `/support/admin/chats/${data.id}/unread`,
    ),
  )
}

export type PutChatReadRes = GetChatReq
const putChatRead = (data: PutChatReadRes) => {
  return promiseToResult(
    AxiosInstance().put(
      `/support/admin/chats/${data.id}/read`,
    ),
  )
}

export interface GetMessagesReq {
  limit: string | number
  page: string | number
  language: string
  chatId?: number
}
export type GetMessagesRes = WithMetadata<Message>
const getMessages = (data: GetMessagesReq) => {
  return promiseToResult(
    AxiosInstance().get<GetMessagesRes>(
      `/support/messages?${querystring.stringify(data)}`,
    ),
  )
}

export interface PostMessagesReadReq {
  ids: number[]
}
const postMessagesRead = (data: PostMessagesReadReq) => {
  return promiseToResult(
    AxiosInstance().post(
      '/support/messages/read',
      data,
    ),
  )
}

export type GetChatSettingsRes = ChatSettings[]
const getChatSettings = () => {
  return promiseToResult(
    AxiosInstance().get<GetChatSettingsRes>(
      '/support/admin/settings',
    ),
  )
}

export type PutChatSettingsReq = ChatSettings
const putChatSettings = (data: PutChatSettingsReq) => {
  return promiseToResult(
    AxiosInstance().put<ChatSettings>(
      `/support/admin/settings?key=${data.key}`,
      data,
    ),
  )
}

export const ChatAPI = {
  getChats,
  getChat,
  getMessages,
  putChatUnread,
  putChatRead,
  postMessagesRead,
  getChatSettings,
  putChatSettings,
}
