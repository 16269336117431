import { createEffect } from 'effector'
import { toast } from 'react-toastify'

import { APIResult, AxiosError } from '../../core/api/Result'
import {
  OfficeAPI,
  DeleteOfficeReq,
  GetOfficeIdReq,
  PatchOfficeReq,
  PostOfficeReq,
} from '../../services/api/Office'
import { $navigate } from '../History/store'
import { Routes } from '../../shared/enum'

export const getOfficeFx = createEffect<unknown, APIResult<typeof OfficeAPI['getOffice']>, AxiosError>(
  OfficeAPI.getOffice,
)

export const createOfficeFx = createEffect<PostOfficeReq, APIResult<typeof OfficeAPI['postOffice']>, AxiosError>(
  OfficeAPI.postOffice,
)

export const getOfficeIdFx = createEffect<GetOfficeIdReq, APIResult<typeof OfficeAPI['getOfficeId']>, AxiosError>(
  OfficeAPI.getOfficeId,
)

export const patchOfficeFx = createEffect<PatchOfficeReq, APIResult<typeof OfficeAPI['patchOffice']>, AxiosError>(
  OfficeAPI.patchOffice,
)

export const deleteOfficeFx = createEffect<DeleteOfficeReq, APIResult<typeof OfficeAPI['deleteOffice']>, AxiosError>(
  OfficeAPI.deleteOffice,
)

patchOfficeFx.done.watch(() => {
  toast.success('Офис успешно обновлен')
})

createOfficeFx.done.watch(() => {
  toast.success('Офис успешно создан')
  const navigate = $navigate.getState()
  navigate(`/${Routes.COMPANY}/${Routes.OFFICE}`)
})

deleteOfficeFx.done.watch(() => {
  toast.success('Офис успешно удалено')
  const navigate = $navigate.getState()
  navigate(`/${Routes.COMPANY}/${Routes.OFFICE}`)
})
