import React from 'react'
import { useNotification } from '../../../services/api/Notification'
import { Page } from '../../../shared/UI'
import { Routes } from '../../../shared/enum'
import { getOptionsValue } from '../../../shared/utils'
import { useStore } from 'effector-react'
import { $defaults } from '../../../states/Defaults/store'
import dayjs from 'dayjs'

export const Root = () => {
  const defaults = useStore($defaults)
  const { get } = useNotification()

  return (
    <Page
      variant="root"
      title="Уведомления"
      actions={[
        { type: 'link', title: 'Главная', to: `/${Routes.NOTIFICATION}` },
        { type: 'link', to: Routes.CREATE, title: 'Создать', color: 'secondary' },
      ]}
      onChange={get.fetch}
      rows={get.data?.items}
      col={[
        { id: 'id', label: 'ID' },
        { id: 'type', label: 'Тип', render: (type) => getOptionsValue(defaults.notifications.types, type) },
        { id: 'createdAt', label: 'Создана', render: (date) => dayjs(date).format('LLL') },
        { id: 'isActive', label: 'Активна' },
      ]}
    />
  )
}
