import React, { ChangeEventHandler, MutableRefObject, useRef } from 'react'
import { NavLink, Outlet } from 'react-router-dom'

import { Button } from '../../shared/UI'
import { Routes } from '../../shared/enum'
import { getExportLanguageFx, postImportLanguageFx } from '../../states/Language/event'
import { DownloadExcel } from '../../shared/components/Translation/DownloadExcel'
import { UploadExcel } from '../../shared/components/Translation/UploadExcel'

const TranslationController = () => {
  const inputFile = useRef() as MutableRefObject<HTMLInputElement>

  const handlerExport: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { files } = e.target
    if (files?.length) {
      const file = files[0]
      postImportLanguageFx({
        file,
      })
    }
  }

  return (
    <div>
      <div className="header">
        <NavLink to={`/${Routes.TRANSLATION}`}>
          <Button>
            Главная
          </Button>
        </NavLink>

        <NavLink to={`/${Routes.TRANSLATION}/${Routes.CREATE}`}>
          <Button color="secondary">
            Создать
          </Button>
        </NavLink>

        <Button color="inherit" onClick={() => getExportLanguageFx(null)}>
          Скачать
        </Button>
        <Button color="inherit" onClick={() => inputFile.current.click()}>
          <input hidden ref={inputFile} onChange={handlerExport} accept="application/JSON" type="file" />
          Загрузить
        </Button>

        <DownloadExcel />
        <UploadExcel />
      </div>

      <Outlet />
    </div>
  )
}

export default TranslationController
