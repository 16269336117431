import { createEffect } from 'effector'
import { toast } from 'react-toastify'

import { APIResult, AxiosError } from '../../core/api/Result'
import {
  GetVerificationReq,
  GetVerificationsReq,
  PutVerificationReq,
  VerificationAPI,
} from '../../services/api/Verification'

export const getVerificationsFx = createEffect<GetVerificationsReq, APIResult<typeof VerificationAPI['getVerifications']>, AxiosError>(
  VerificationAPI.getVerifications,
)

export const getVerificationFx = createEffect< GetVerificationReq, APIResult<typeof VerificationAPI['getVerification']>, AxiosError>(
  VerificationAPI.getVerification,
)

export const putVerificationFx = createEffect<PutVerificationReq, APIResult<typeof VerificationAPI['putVerification']>, AxiosError>(
  VerificationAPI.putVerification,
)

putVerificationFx.done.watch(({ result }) => {
  if (result.data.status) {
    toast.success('Успешно')
  }
})
