import AxiosInstance from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'
import { querystring } from '../../shared/utils'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { IdReq, WithMetadata } from './_types'
import { toast } from 'react-toastify'
import { Routes } from '../../shared/enum'
import { useNavigate } from 'react-router-dom'

export interface GetNotificationsReq {
  limit: string
  page: string
}
export type GetNotificationsRes = WithMetadata<any>
const getNotifications = (data: GetNotificationsReq) => {
  return promiseToResult(
    AxiosInstance().get<GetNotificationsRes>(
      `/application/admin/notifications?${querystring.stringify(data)}`,
    ),
  )
}

const postNotification = (data: Record<string, any>) => {
  return promiseToResult(
    AxiosInstance().post<any>(
      '/application/admin/notifications',
      data,
    ),
  )
}

const deleteNotification = (data: IdReq) => {
  return promiseToResult(
    AxiosInstance().delete(
      `/application/admin/notifications/${data.id}`,
    ),
  )
}

const getNotificationId = (data: IdReq) => {
  return promiseToResult(
    AxiosInstance().get<any>(
      `/application/admin/notifications/${data.id}`,
    ),
  )
}

const patchNotificationId = (data: IdReq) => {
  return promiseToResult(
    AxiosInstance().patch<any>(
      `/application/admin/notifications/${data.id}`,
      data,
    ),
  )
}

const putNotificationIdSocial = (data: IdReq) => {
  return promiseToResult(
    AxiosInstance().put<any>(
      `/application/admin/notifications/socials/${data.id}`,
      data,
    ),
  )
}

export const NotificationAPI = {
  getNotifications,
  postNotification,
  deleteNotification,
  getNotificationId,
  patchNotificationId,
  putNotificationIdSocial,
}

export const useNotification = () => {
  const [query, setQuery] = useState({
    notifications: {} as GetNotificationsReq,
    notificationId: {} as IdReq,
  })
  const navigate = useNavigate()

  const getNotifications = useQuery(
    ['NotificationAPI.getNotifications', query.notifications],
    () => NotificationAPI.getNotifications(query.notifications),
    { enabled: false },
  )
  const postNotification = useMutation(NotificationAPI.postNotification)
  const deleteNotification = useMutation(NotificationAPI.deleteNotification)
  const getNotificationId = useQuery(
    ['NotificationAPI.getNotificationId', query.notificationId],
    () => NotificationAPI.getNotificationId(query.notificationId),
    { enabled: false },
  )
  const putNotificationIdSocial = useMutation(NotificationAPI.putNotificationIdSocial)
  const patchNotificationId = useMutation(NotificationAPI.patchNotificationId)

  return {
    get: {
      fetch: (notifications: GetNotificationsReq) => {
        setQuery((prev) => ({ ...prev, notifications }))
        setTimeout(getNotifications.refetch, 600)
      },
      loading: getNotifications.isLoading,
      data: getNotifications.data?.data.data,
    },
    post: {
      fetch: async (data: any) => {
        data.notification.type = data.type
        const res = await postNotification.mutateAsync(data)
        toast.success('Уведомление создано')
        navigate(`/${Routes.NOTIFICATION}/${res.data.data.id}`)
        return res
      },
      loading: postNotification.isLoading,
      raw: postNotification,
    },
    id: {
      get: {
        fetch: (notificationId: IdReq) => {
          setQuery((prev) => ({ ...prev, notificationId }))
          setTimeout(getNotificationId.refetch, 600)
        },
        loading: getNotificationId.isLoading,
        data: getNotificationId.data?.data.data,
      },
      delete: {
        loading: deleteNotification.isLoading,
        raw: deleteNotification,
      },
      patch: {
        fetch: async (data: any) => {
          const body = {
            type: data.type,
            id: data.id,
            notification: {},
          } as Record<string, any> & IdReq
          Object.entries(data).forEach(([key, value]) => {
            body.notification[key] = value
          })
          await patchNotificationId.mutateAsync(body)
          toast.success('Сохранено')
          await getNotificationId.refetch()
        },
        loading: patchNotificationId.isLoading || getNotificationId.isFetching,
      },
      social: {
        put: {
          fetch: async (data: any) => {
            await putNotificationIdSocial.mutateAsync(data)
            toast.success('Рассылка начата')
          },
          loading: putNotificationIdSocial.isLoading,
        },
      },
    },
  }
}
