import React from 'react'
import { Page } from '../../../shared/UI/Page'
import { useAuthHistory } from '../../../services/api/AuthHistory'
import { NavLink } from 'react-router-dom'
import { Routes } from '../../../shared/enum'
import { $defaults } from '../../../states/Defaults/store'
import dayjs from 'dayjs'

export const Root = () => {
  const { get, handlerQuery } = useAuthHistory()

  return <Page
    variant="root"
    title="История авторизация"
    rows={get.data?.data.data.items}
    onChange={handlerQuery}
    total={get.data?.data.data.metadata.total}
    col={[
      { id: 'userId', label: 'Юзер', render: (id) => <NavLink to={`/${Routes.USER}/${id}`}>{id}</NavLink> },
      {
        id: 'authType',
        label: 'Способ',
        render: (type) => {
          const defaults = $defaults.getState()
          return defaults.auth?.types.find(({ key }: Record<string, string>) => String(key) === String(type))?.value
        },
      },
      { id: 'country', label: 'Страна' },
      { id: 'city', label: 'Город' },
      { id: 'timezone', label: 'timezone' },
      { id: 'isAllowedToLogin', label: 'Активный' },
      { id: 'ip', label: 'IP' },
      { id: 'longitude', label: 'longitude' },
      { id: 'latitude', label: 'latitude' },
      { id: 'createdAt', label: 'Время', render: (date) => dayjs(date).format('lll') },
    ]}
    filter={[
      {
        label: 'Фильтры',
        defaultExpanded: true,
        fields: [
          { name: 'userId', type: 'number', label: 'Юзер ID' },
          { name: 'userAgent', type: 'string', label: 'User-Agent' },
          { name: 'ip', type: 'string', label: 'ip' },
          { name: 'isActive', type: 'switch', label: 'Активна', isEmpty: true },
          { name: 'dateStart', type: 'date', label: 'Дата от' },
          { name: 'dateEnd', type: 'date', label: 'Дата до' },
        ],
      },
    ]}
  />
}
