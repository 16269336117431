import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Typography } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useStore } from 'effector-react'

import {
  Button,
  ButtonWithConfirm,
  TabPanel,
  RootLayout,
} from '../../../UI'
import { $project, $projectLoading } from '../../../../states/Project/store'
import { deleteProjectFx, updateProjectFx } from '../../../../states/Project/event'
import { Role, Routes } from '../../../enum'
import { Project } from '../../../../services/api/Project'

import { About, Development, Main } from './components'
import { Dividend } from './components/Dividend'
import { RoleGuard } from '../../../UI/RoleGuard'

export const ProjectEdit: React.FC = () => {
  const project = useStore($project)
  const projectLoading = useStore($projectLoading)

  const methods = useForm<Project>()
  const {
    getValues,
    setValue,
  } = methods

  const handlerUpdate = async () => {
    const values = getValues()
    updateProjectFx(values)
  }

  useEffect(() => {
    Object.keys(project).forEach((key) => {
      const value = (project as Record<string, any>)[key]
      setValue(key as keyof Project, value)
    })
  }, [project])

  const handlerDelete = async () => {
    if (project?.id) {
      deleteProjectFx({ id: project.id.toString() })
    }
  }

  return (
    <RootLayout loading={projectLoading} empty={!project?.id}>
      <FormProvider {...methods}>

        <Typography variant="h5" sx={{ marginBottom: '15px' }}>Редактирование проекта</Typography>

        <RoleGuard role={Role.ADMIN}>
          <div className="header">
            <ButtonWithConfirm
              color="error"
              variant="contained"
              onClick={handlerDelete}
              content="Вы действительно хотите удалить проект?"
              style={{ display: 'none' }}
            >
              Удалить
            </ButtonWithConfirm>
            <NavLink to={Routes.PRICE}>
              <Button
                variant="contained"
              >
                Цена долей
              </Button>
            </NavLink>
          </div>
        </RoleGuard>

        <TabPanel
          data={[
            { label: 'Основные', component: <Main /> },
            { label: 'Дивиденды', component: <Dividend /> },
            { label: 'О проекте', component: <About /> },
            { label: 'Развитие', component: <Development /> },
          ]}
        />

        <RoleGuard role={Role.ADMIN}>
          <Button color="primary" variant="contained" onClick={handlerUpdate} loading={false}>
            Сохранить
          </Button>
        </RoleGuard>

      </FormProvider>
    </RootLayout>
  )
}
