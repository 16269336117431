import { useEffect, useState } from 'react'

type QueryValue = string | number | boolean | undefined | number[] | string[]

export type ObserverFilter<T> = (query: T) => void

interface UseFilterProps<T> {
  defaultFilter?: T
  observer?: ObserverFilter<T>
}

export const useFilter = <T, >({
  defaultFilter = {} as T,
  observer = () => null,
}: UseFilterProps<T>) => {
  const [query, setQuery] = useState<T>(defaultFilter)

  const handlerQuery = (key: keyof T, value: QueryValue): void => {
    setQuery((prev) => {
      if (!value) {
        delete prev[key]
        return { ...prev }
      }
      return { ...prev, [key]: value }
    })
  }

  useEffect(() => {
    observer(query)
  }, [query])

  return {
    query,
    handlerQuery,
  }
}
