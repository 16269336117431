import AxiosInstance from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'
import { IdReq, WithMetadata } from './_types'
import { querystring } from '../../shared/utils'

export interface Installment {
  id: number
  user: number
  comment: string
  description: string
  isPaid: boolean
  payPeriod: {
    giftShares: number
    months: number
  },
  calendar: {
    price: number
    month: number
    isPaid: boolean
    shares: number
    transactionId: number
  }[]
  minimumPayment: number
  nextPaymentDate: string
  adminStatus: string | null
  project: number
  price: number
  shares: number
  paidAt: string
  closedAt: string
  updatedAt: string
  createdAt: string
}

export interface GetInstallmentsReq {
  limit: string
  page: string
  orderBy?: string
}
export type GetInstallmentsRes = WithMetadata<Installment>
const getInstallments = (data: GetInstallmentsReq) => {
  return promiseToResult(
    AxiosInstance().get<GetInstallmentsRes>(
      `/projects/admin/installments?${querystring.stringify(data)}`,
    ),
  )
}

export interface GetInstallmentReq {
  id: number | string
}
export type GetInstallmentRes = Installment
const getInstallment = (data: GetInstallmentReq) => {
  return promiseToResult(
    AxiosInstance().get<GetInstallmentRes>(
      `/projects/admin/installments/${data.id}`,
    ),
  )
}

export interface PatchInstallmentReq extends GetInstallmentReq {
  nextPaymentDate: string
  payPeriod: { monthsAmount: number }
  price: number
  adminStatus: string | null
}
const patchInstallment = (data: PatchInstallmentReq) => {
  return promiseToResult(
    AxiosInstance().patch(
      `/projects/admin/installments/${data.id}`,
      data,
    ),
  )
}

const postInstallmentCancel = (data: GetInstallmentReq) => {
  return promiseToResult(
    AxiosInstance().post<GetInstallmentRes>(
      `/projects/admin/installments/${data.id}/cancel`,
    ),
  )
}

const postInstallmentOpen = (data: GetInstallmentReq) => {
  return promiseToResult(
    AxiosInstance().post<GetInstallmentRes>(
      `/projects/admin/installments/${data.id}/open`,
    ),
  )
}

export type GetInstallmentCommentRes = {
  id: number
  comment: string
  isActive: boolean
  createdAt: string
  updatedAt: string
}[]
const getInstallmentComment = (data: IdReq) => {
  return promiseToResult(
    AxiosInstance().get<GetInstallmentCommentRes>(
      `/projects/admin/installment-comments/${data.id}`,
    ),
  )
}

export type PostInstallmentCommentRes = { text: string } & IdReq
const postInstallmentComment = (data: PostInstallmentCommentRes) => {
  return promiseToResult(
    AxiosInstance().post(
      `/projects/admin/installment-comments/${data.id}`,
      data,
    ),
  )
}

const postInstallmentRecalculate = (data: IdReq) => {
  return promiseToResult(
    AxiosInstance().post(
      `/projects/admin/installments/${data.id}/recalculate`,
    ),
  )
}

export const InstallmentsAPI = {
  getInstallments,
  getInstallment,
  patchInstallment,
  postInstallmentCancel,
  postInstallmentOpen,
  getInstallmentComment,
  postInstallmentComment,
  postInstallmentRecalculate,
}
