import React, { useEffect } from 'react'
import { useStore } from 'effector-react'

import { getOfficeFx } from '../../../states/Office/event'
import { RootLayout } from '../../../shared/UI/RootLayout'
import { Col, RootTable } from '../../../shared/UI'
import { $isPendingOffice, $office } from '../../../states/Office/store'

const col: Col[] = [
  { id: 'id', label: 'ID' },
  { id: 'name', label: 'Имя' },
]

export const Root = () => {
  const office = useStore($office)
  const isPendingOffice = useStore($isPendingOffice)

  useEffect(() => {
    getOfficeFx(null)
  }, [])

  return (
    <RootLayout loading={isPendingOffice}>
      <RootTable rows={office} col={col} orderBy="id" title="Офисы" />
    </RootLayout>
  )
}
