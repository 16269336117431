import React from 'react'
import { useStore } from 'effector-react'
import dayjs from 'dayjs'

import { $isPendingVerifications, $verifications } from '../../../../states/Verification/store'
import { $defaults } from '../../../../states/Defaults/store'
import { usePagination } from '../../../hooks'
import { getVerificationsFx } from '../../../../states/Verification/event'
import { Accordion, Col, DatePicker, InputAutocomplete, RootLayout, RootTable, Select } from '../../../UI'

const col: Col[] = [
  { id: 'id', label: 'ID' },
  {
    id: 'status',
    label: 'Статус',
    render: (status) => {
      const defaults = $defaults.getState()
      const { value } = defaults?.verifications?.types
        .find(({ key }: Record<string, string>) => key === String(status)) || {}
      return value
    },
  },
  {
    id: 'createdAt',
    label: 'Время создания',
    render: (createdAt) => dayjs(String(createdAt)).format('DD.MM.YYYY HH:mm'),
  },
  { id: 'comment', label: 'Комментарий' },
]

export const VerificationRoot = () => {
  const verifications = useStore($verifications)

  const isPendingVerifications = useStore($isPendingVerifications)
  const defaults = useStore($defaults)

  const { query, pagination, handlerPagination, handlerQuery } = usePagination({
    defaults: {
      orderBy: 'id',
      orderDirection: 'desc',
      limit: '10',
      page: '1',
      dateStart: '',
      dateEnd: '',
      status: '',
    },
    observerQuery: (query) => getVerificationsFx({ query }),
  })

  const onChange = (value: string, trigger: string) => {
    handlerQuery(trigger, value)
  }

  return (
    <RootLayout>
      <Accordion label="Фильтры" defaultExpanded>
        <div className="filter">
          <InputAutocomplete
            values={query}
            label="ID Верификации"
            options={verifications?.items}
            trigger="id"
            isLoading={isPendingVerifications}
            onChange={onChange}
          />
          <InputAutocomplete
            label="ID Пользователя"
            options={verifications?.items}
            trigger="userId"
            isLoading={isPendingVerifications}
            onChange={onChange}
            values={query}
          />
          <InputAutocomplete
            label="Логин"
            options={verifications?.items}
            trigger="userLogin"
            isLoading={isPendingVerifications}
            onChange={onChange}
            values={query}
          />
          <InputAutocomplete
            label="Телефон"
            options={verifications?.items}
            trigger="userPhone"
            isLoading={isPendingVerifications}
            onChange={onChange}
            values={query}
          />
          <Select
            value={query.status}
            name="status"
            label="Статус"
            options={defaults?.verifications?.types}
            onSelect={(value) => onChange(value, 'status')}
            isEmpty
          />
          <InputAutocomplete
            label="Комментарий"
            options={verifications?.items}
            trigger="comment"
            isLoading={isPendingVerifications}
            onChange={onChange}
            values={query}
          />
          <DatePicker
            value={query.dateStart}
            label="С время создания"
            onSelect={(date) => onChange(date.toISOString(), 'dateStart')}
          />
          <DatePicker
            value={query.dateEnd}
            label="До время создания"
            onSelect={(date) => onChange(date.toISOString(), 'dateEnd')}
          />
          <InputAutocomplete
            values={query}
            label="Имя"
            options={verifications?.items}
            trigger="userPassportName"
            isLoading={isPendingVerifications}
            onChange={onChange}
          />
          <InputAutocomplete
            values={query}
            label="Фамилия"
            options={verifications?.items}
            trigger="userPassportSurname"
            isLoading={isPendingVerifications}
            onChange={onChange}
          />
        </div>
      </Accordion>

      <RootTable
        rows={verifications?.items}
        col={col}
        title="Верификации"
        limitOptions={[10, 20, 30]}
        handleChangePage={(page) => handlerPagination('page', page)}
        handleChangeLimit={(limit) => handlerPagination('limit', limit)}
        handleOrderDirection={(limit) => handlerQuery('orderDirection', limit)}
        length={verifications?.metadata?.total}
        page={pagination.page}
        limit={pagination.limit}
        orderBy={query?.orderBy?.toString() || 'id'}
        orderDirection={query?.orderDirection?.toString() || 'desc'}
      />
    </RootLayout>
  )
}
