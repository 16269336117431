/* eslint-disable @typescript-eslint/no-unused-vars,jsx-a11y/media-has-caption */
import React, { CSSProperties, useEffect } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { Box, CircularProgress } from '@mui/material'
import BrokenImageIcon from '@mui/icons-material/BrokenImage'

import { blobToFile, MyFile } from '../utils'

interface RemoteFileForm {
  url: string
  type: string
  file: File
  error: boolean
}

export interface RemoteFileProps {
  src: string
  variant?: 'preview' | 'full'
  style?: CSSProperties;
}

export const RemoteFile: React.FC<RemoteFileProps> = ({
  src,
  variant = 'preview',
  style,
}) => {
  const methods = useForm<RemoteFileForm>({
    defaultValues: {
      file: new File(['1'], '1'),
    },
  })
  const { watch, setValue } = methods
  const { url, type, file: f, error } = watch()
  const file = new MyFile(f)

  useEffect(() => {
    const start = async () => {
      try {
        const res: unknown = await axios({
          url: src,
          method: 'GET',
          responseType: 'blob',
        })
        const blob = res as Blob

        setValue('url', URL.createObjectURL(blob))
        setValue('type', blob.type)
        setValue('file', blobToFile(blob, src))
      } catch (e) {
        setValue('error', true)
      }
    }
    start()

    return () => {
      URL.revokeObjectURL(url)
    }
  }, [src])

  if (error) {
    return <BrokenImageIcon />
  }
  if (!url) {
    return <CircularProgress />
  }
  return (
    <>
      {file.isImage() &&
      <img src={url} style={style} />
      }
      {file.isVideo() &&
      <video
        controls={variant === 'full'}
        muted
        style={style}
      >
        <source src={url} />
        Your browser does not support HTML5 video.
      </video>
      }
    </>
  )
}
