/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useStore } from 'effector-react'
import { NavLink } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import {
  AccessibleForward as AccessibleForwardIcon,
  AccountTree as AccountTreeIcon,
  Analytics as AnalyticsIcon,
  Assessment as AssessmentIcon,
  Business as BusinessIcon,
  Chat as ChatIcon,
  ChevronLeft as ChevronLeftIcon,
  Dashboard as DashboardIcon,
  FilePresent as FilePresentIcon,
  Group as GroupIcon,
  GroupAdd as GroupAddIcon,
  GTranslate as GTranslateIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Language as LanguageIcon,
  Login as LoginIcon,
  Menu as MenuIcon,
  Newspaper as NewspaperIcon,
  Notifications as NotificationsIcon,
  Payment as PaymentIcon,
  Quiz as QuizIcon,
  Receipt as ReceiptIcon,
  Savings as SavingsIcon,
  Settings as SettingsIcon,
  Source as SourceIcon,
  Timelapse as TimelapseIcon,
  Verified as VerifiedIcon,
  ViewCompact as ViewCompactIcon,
  Web as WebIcon,
  WhatsApp as WhatsAppIcon,
  NotificationsActive as NotificationsActiveIcon,
  EditNotifications as EditNotificationsIcon,
  ManageHistory as ManageHistoryIcon,
} from '@mui/icons-material'
import {
  AppBar as MuiAppBar,
  Badge,
  Box,
  Container,
  Drawer as MuiDrawer,
  IconButton,
  ListItem, ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Toolbar,
  Typography,
} from '@mui/material'

import { CommonEnum, Role, Routes } from '../../../enum'

import AppBarUser from '../AppBarUser'

import { $isAuthorized } from '../../../../states/Auth/store'
import { getLanguagesFx } from '../../../../states/Language/event'
import { getInfoFx } from '../../../../states/Whatsapp/event'
import { $info } from '../../../../states/Whatsapp/store'
import { localStore } from '../../../utils'
import { ButtonWithConfirm, RoleGuard } from '../../../UI'
import { getHeapsnapshotFx } from '../../../../states/App/event'

const menuList = [
  { title: 'Главная', to: Routes.ROOT, icon: <DashboardIcon /> },
  { title: 'Юзер', to: Routes.USER, icon: <GroupIcon /> },
  { title: 'Транзакции', to: Routes.TRANSACTION, icon: <ReceiptIcon /> },
  { title: 'Резервы', to: Routes.INSTALLMENT, icon: <TimelapseIcon /> },
  { title: 'Копилка', to: Routes.MONEYBOX, icon: <SavingsIcon /> },
  { title: 'Верификации', to: Routes.VERIFICATION, icon: <VerifiedIcon /> },
  { title: 'Восстановления', to: Routes.RECOVERY, icon: <AccessibleForwardIcon /> },
  { title: 'Регистрации', to: Routes.CANDIDATE, icon: <GroupAddIcon /> },
  { title: 'Авторизации', to: Routes.HISTORY, icon: <LoginIcon /> },
  { title: 'Чат', to: Routes.CHAT, icon: <ChatIcon /> },
  { title: 'Наблюдатели', to: Routes.SUPERVISORY, icon: <GroupIcon /> },
  {
    title: 'Уведолмения',
    icon: <NotificationsActiveIcon />,
    menu: [
      { title: 'Уведомления', to: Routes.NOTIFICATION, icon: <EditNotificationsIcon /> },
      { title: 'Стоирис', to: Routes.STORY, icon: <ManageHistoryIcon /> },
      { title: 'Шаблоны', to: Routes.TEMPLATE, icon: <SettingsIcon /> },
    ],
  },
  {
    title: 'Настройки',
    icon: <SettingsIcon />,
    menu: [
      { title: 'Проекты', to: Routes.PROJECT, icon: <AccountTreeIcon /> },
      { title: 'Языки', to: Routes.LANGUAGE, icon: <LanguageIcon /> },
      { title: 'WhatsApp', to: Routes.WHATSAPP, icon: <AccountTreeIcon /> },
      { title: 'Платежные системы', to: Routes.PAYMENT, icon: <PaymentIcon /> },
      { title: 'Файлы', to: Routes.FILES, icon: <FilePresentIcon /> },
      { title: 'Лендинг', to: Routes.LANDING, icon: <WebIcon /> },
    ],
  },
  {
    title: 'Контент',
    icon: <SourceIcon />,
    menu: [
      { title: 'Новости', to: Routes.NEWS, icon: <NewspaperIcon /> },
      { title: 'Faq', to: Routes.FAQ, icon: <QuizIcon /> },
      { title: 'Переводы', to: Routes.TRANSLATION, icon: <GTranslateIcon /> },
      {
        title: 'Компания',
        icon: <ViewCompactIcon />,
        menu: [
          { title: 'Офисы', to: `${Routes.COMPANY}/${Routes.OFFICE}`, icon: <BusinessIcon /> },
          { title: 'Фин. Отчет', to: `${Routes.COMPANY}/${Routes.REPORT}`, icon: <AssessmentIcon /> },
        ],
      },
    ],
  },
  { title: 'Коды', to: 'code', icon: <ChatIcon /> },
  {
    title: 'Аналитика',
    icon: <SourceIcon />,
    menu: [
      { title: 'Основная', to: `${Routes.ANALYTIC}/${Routes.PAYMENT}`, icon: <AnalyticsIcon /> },
      { title: 'Топ реф', to: `${Routes.ANALYTIC}/${Routes.USER}`, icon: <QuizIcon /> },
    ],
  },
]

interface ILink {
  to: string,
  icon: JSX.Element,
  title: string,
}

const Link: React.FC<ILink> = ({ to, icon, title }) => (
  <ListItem disablePadding>
    <ListItemButton component="a" href={`/${to}`}>
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItemButton>
  </ListItem>
)

const DropDownMenu: React.FC<any> = (props) => {
  const {
    icon,
    menu,
    title,
  } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <ListItem
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
      >
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText primary={title} />
        <ListItemIcon>
          <KeyboardArrowDownIcon />
        </ListItemIcon>
      </ListItem>
      <Menu
        elevation={10}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        keepMounted
      >
        {menu.map(({ title = '', to = '', icon, menu }: any) => {
          if (menu?.length) {
            return <DropDownMenu key={title} icon={icon} menu={menu} title={title} />
          }
          return (
            <div role="button" tabIndex={0} onKeyPress={handleClose} onClick={handleClose} key={to}>
              <Link title={title} to={to} icon={icon} />
            </div>
          )
        })}
      </Menu>
    </div>
  )
}

const drawerWidth = 240
const headerHeight = 64

const AppBar = styled(MuiAppBar as any, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }: any) => ({
  ...theme,
  zIndex: theme.zIndex.drawer,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
)

const Bar: React.FC<any> = (props) => {
  const { children } = props
  const [open, setOpen] = useState(localStore.get(CommonEnum.CONDITION_BAR))

  const toggleDrawer = () => {
    setOpen(!open)
  }

  const isAuthorized = useStore($isAuthorized)
  const info = useStore($info)

  useEffect(() => {
    if (isAuthorized) {
      getLanguagesFx({})
      getInfoFx(null)
    }
  }, [isAuthorized])

  useEffect(() => {
    localStore.set(CommonEnum.CONDITION_BAR, open)
  }, [open])

  if (!isAuthorized) {
    return children
  }
  return (
    <Box sx={{ display: 'flex' }} style={{ height: '100%' }}>
      <AppBar position="absolute" id="app-bar" open={open}>
        <Toolbar
          sx={{ pr: '24px', display: 'flex', gap: '8px' }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <NavLink to={Routes.WHATSAPP}>
            <WhatsAppIcon
              sx={{ backgroundColor: '#FFF', borderRadius: '5px' }}
              fontSize="large"
              color={info?.isInitialized ? 'success' : 'error'}
            />
          </NavLink>

          <RoleGuard role={Role.ADMIN}>
            <ButtonWithConfirm
              color="error"
              onClick={() => getHeapsnapshotFx()}
              content="НАЖИМАЯ ПОДТВЕРДИТЬ ВЫ ОСТАНОВИТЕ САЙТ! И МОЖЕТЕ ВСЕ СЛОМАТЬ! ЕСЛИ ВЫ НЕ ПОНИМАЕТЕ ЧТО МЫ ДЕЛАЕТЕ - УХОДИТЕ ОТ СЮДА"
            >
              ОЗУ
            </ButtonWithConfirm>
          </RoleGuard>

          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {/* df */}
          </Typography>

          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>

          <Toolbar />

          <AppBarUser />

        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        {menuList.map(({ title, to = '', icon, menu }) => {
          if (menu) {
            return <DropDownMenu key={title} icon={icon} menu={menu} title={title} />
          }
          return <Link title={title} to={to} icon={icon} key={to} />
        })}
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? '#F5F7FA' || theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          minHeight: '100vh',
          height: '100%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Toolbar />
        <Container
          maxWidth={false}
          style={{
            minHeight: '100%',
            height: '100%',
            padding: '24px',
            flexGrow: '1',
          }}
        >
          {children}
        </Container>
      </Box>
    </Box>
  )
}

export default Bar
