import { UserPrev } from '../../services/api/User'

export const getUserName = ({
  id,
  login,
  passport,
}: UserPrev) => {
  return passport && passport.surname && passport.name
    ? `${passport.name} ${passport.surname.slice(0, 1)}.`
    : login
      ? login
      : `ID: ${id}`
}
