import React from 'react'
import { useStore } from 'effector-react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Page } from '../../../shared/UI'

import { NewsAPI, PutNewsReq } from '../../../services/api/News'
import { getProjectsFx } from '../../../states/Project/event'
import { $projectsOptions } from '../../../states/Project/store'
import { $my } from '../../../states/My/store'

export const Edit = () => {
  const my = useStore($my)
  const projectsOptions = useStore($projectsOptions)
  const { id } = useParams()

  const put = useMutation(NewsAPI.putNews)
  const get = useQuery(['getNewsId', id], () => NewsAPI.getNewsId({ id: String(id) }))

  const handlerSubmit = async (data: PutNewsReq) => {
    await put.mutateAsync(data)
    toast.success('Сохранено')
  }

  return (
    <Page
      title="Редактировать"
      button="Сохранить"
      variant="form"
      actions={[
        { title: 'Удалить', disabled: true },
      ]}
      onInit={() => getProjectsFx({ query: { limit: 100, page: 1 } })}
      onSubmit={handlerSubmit}
      value={get.data?.data.data}
      fields={[
        { name: 'id', type: 'string', label: 'Id', disabled: true },
        { name: 'viewsCount', type: 'number', label: 'Количество просмотров' },
        { name: 'anonymsLikes', type: 'number', label: 'Анонимные лайки' },
        { name: 'isActive', type: 'switch', label: 'Активна' },
        { name: 'isSharePriceChanged', type: 'switch', label: 'Влияние на цену' },
        { name: 'influencedProjectIds', type: 'array', label: 'Проекты', options: projectsOptions },
        { name: 'publishedAt', type: 'date', label: 'Время публикации' },
        { name: 'title', type: 'content', label: 'Заголовок', mode: 'text' },
        { name: 'shortDescription', type: 'content', label: 'Краткое описание', mode: 'text' },
        { name: 'content', type: 'content', label: 'Содержание' },
        { name: 'header', type: 'content', label: 'Мета', mode: 'text' },
        { name: 'attachments', type: 'file', label: 'Вложения', multiple: true, filename: `news::userId=${my?.id}` },
        { name: 'metadata.hiddenAttachments', type: 'file', label: 'Скрытые файлы', multiple: true, filename: `news::userId=${my?.id}` },
        { name: 'metadata.mainImg', type: 'file', label: 'Главное фото', filename: `news::userId=${my?.id}` },
        { name: 'createdAt', type: 'date', label: 'Время создания', disabled: true },
        { name: 'updatedAt', type: 'date', label: 'Время изменения', disabled: true },
      ]}
    />
  )
}
