import { createStore } from 'effector'

import { GetCategoriesRes, GetCategoryRes, GetReportRes, GetReportsRes } from '../../services/api/FinReport'

import { getCategoriesFx, getCategoryFx, getReportFx, getReportsFx } from './event'

export const $categories = createStore<GetCategoriesRes>([])
  .on(getCategoriesFx.done, (_, { result }) => result.data.data)
export const $isPendingCategories = createStore(false)
  .on(getCategoriesFx.pending, (_, pending) => pending)

export const $category = createStore<GetCategoryRes | null>(null)
  .on(getCategoryFx.done, (_, { result }) => result.data.data)
export const $isPendingCategory = createStore(false)
  .on(getCategoryFx.pending, (_, pending) => pending)

export const $reports = createStore<GetReportsRes>([])
  .on(getReportsFx.done, (_, { result }) => result.data.data)
export const $isPendingReports = createStore(false)
  .on(getReportsFx.pending, (_, pending) => pending)

export const $report = createStore<GetReportRes | null>(null)
  .on(getReportFx.done, (_, { result }) => result.data.data)
export const $isPendingReport = createStore(false)
  .on(getReportFx.pending, (_, pending) => pending)
