import { combine, createStore } from 'effector'
import { GetTransactionRes, GetTransactionsRes } from '../../services/api/Transaction'

import {
  getTransactionFx,
  getTransactionsFx,
  postTransactionCancelFx,
  postTransactionCompleteFx,
  postTransactionRecalculateFx,
} from './event'
import { TransactionStatus } from '../../shared/enum'

export const $transactions = createStore<Partial<GetTransactionsRes>>({})
  .on(getTransactionsFx.done, (_, { result }) => result.data.data)
export const $transactionsLoading = createStore(false)
  .on(getTransactionsFx.pending, (_, pending) => pending)

export const $transaction = createStore<Partial<GetTransactionRes>>({})
  .on(getTransactionFx.done, (_, { result }) => result.data.data)
export const $transactionLoading = createStore(false)
  .on(getTransactionFx.pending, (_, pending) => pending)
  .on(postTransactionCompleteFx.pending, (_, pending) => pending)
  .on(postTransactionCancelFx.pending, (_, pending) => pending)
  .on(postTransactionRecalculateFx.pending, (_, pending) => pending)

interface WithdrawalLikeWallet {
  userId: number
  withdrawalCount: number
  priceCountPending: number
  priceCountCompleted: number
}
export const $withdrawalLikeWallet = combine(
  $transactions,
  (trs) => {
    const map = new Map<number, WithdrawalLikeWallet>()

    trs.items?.map(({ user, price, status }) => {
      const isCompleted = status === TransactionStatus.COMPLETED
      const isPending = status === TransactionStatus.IN_PROGRESS

      if (map.has(user)) {
        const data = map.get(user) as WithdrawalLikeWallet
        map.set(user, {
          ...data,
          withdrawalCount: data.withdrawalCount + 1,
          priceCountPending: isPending ? data.priceCountPending + price : data.priceCountPending,
          priceCountCompleted: isCompleted ? data.priceCountCompleted + price : data.priceCountCompleted,
        })
      } else {
        map.set(user, {
          userId: user,
          withdrawalCount: 1,
          priceCountPending: isPending ? price : 0,
          priceCountCompleted: isCompleted ? price : 0,
        })
      }
    })

    return Array.from(map.values())
  },
)
