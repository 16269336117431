import AxiosInstance from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'
import { querystring } from '../../shared/utils'
import { IdReq, WithMetadata } from './_types'
import { useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { defaultQuery } from '../../shared/hooks'
import { create } from 'lodash'
import { toast } from 'react-toastify'

export interface Supervisory {
  id: number
  photo: string
  photoPreview: string
  name: string
  surname: string
  userId: number
  status: string
  city: string
  country: string
  priority: number
  rejectComment: string
  links: string[]
  createdAt: string
  updatedAt: string
}

export interface GetSupervisoryReq extends Omit<Supervisory,
  'createdAt' | 'updatedAt' | 'links' | 'rejectComment' | 'priority'
  >{
  limit: string
  page: string
  dateStart: string
  dateEnd: string
  orderDirection: string
  orderBy: string
}
export type GetSupervisoryRes = WithMetadata<Supervisory>
const getSupervisory = (data: GetSupervisoryReq) => {
  return promiseToResult(
    AxiosInstance().get<GetSupervisoryRes>(
      `/application/admin/supervisory?${querystring.stringify(data)}`,
    ),
  )
}

export type GetSupervisoryIdRes = Supervisory
const getSupervisoryId = (data: IdReq) => {
  return promiseToResult(
    AxiosInstance().get<GetSupervisoryIdRes>(
      `/application/admin/supervisory/${data.id}`,
    ),
  )
}

export type PatchSupervisoryIdReq = IdReq & Partial<Pick<Supervisory, 'city' | 'photo' | 'links' | 'status' | 'photoPreview'>>
const patchSupervisoryId = (data: PatchSupervisoryIdReq) => {
  return promiseToResult(
    AxiosInstance().patch<GetSupervisoryRes>(
      `/application/admin/supervisory/${data.id}`,
      data,
    ),
  )
}

export const SupervisoryAPI = {
  getSupervisory,
  getSupervisoryId,
  patchSupervisoryId,
}

export const useSupervisory = () => {
  const [query, setQuery] = useState({
    supervisory: defaultQuery as GetSupervisoryReq,
    supervisoryId: {} as IdReq,
  })

  const getSupervisory = useQuery(
    ['SupervisoryAPI.getSupervisory', query.supervisory],
    () => SupervisoryAPI.getSupervisory(query.supervisory),
    { enabled: false },
  )
  const getSupervisoryId = useQuery(
    ['SupervisoryAPI.getSupervisory', query.supervisoryId],
    () => SupervisoryAPI.getSupervisoryId(query.supervisoryId),
    { enabled: false },
  )
  const patchSupervisoryId = useMutation(SupervisoryAPI.patchSupervisoryId)

  return {
    supervisory: {
      get: {
        fetch: (supervisory: GetSupervisoryReq) => {
          setQuery((prev) => create({ ...prev, supervisory }))
          setTimeout(getSupervisory.refetch, 300)
        },
        data: getSupervisory.data?.data.data,
      },
    },
    supervisoryId: {
      get: {
        fetch: (supervisoryId: IdReq) => {
          setQuery((prev) => create({ ...prev, supervisoryId }))
          setTimeout(getSupervisoryId.refetch, 300)
        },
        data: getSupervisoryId.data?.data.data,
        loading: getSupervisoryId.isLoading,
      },
      patch: {
        fetch: async (data: PatchSupervisoryIdReq) => {
          await patchSupervisoryId.mutateAsync(data)
          toast.success('Обновлено')
          await getSupervisoryId.refetch()
        },
        loading: patchSupervisoryId.isLoading || getSupervisoryId.isFetching,
      },
    },
  }
}
