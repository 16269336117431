import React, { useEffect, useMemo, useState } from 'react'
import { useStore } from 'effector-react'
import JoditEditor from 'jodit-react'
import connectLocalStorage from 'effector-localstorage'

import { $languages } from '../../states/Language/store'
import { Input, InputFile, Modal, TabPanel } from '../UI'
import { Content } from '../../services/api/_types'
import { createEvent, createStore } from 'effector'
import { useTheme } from '@mui/material'

const codeIdLocalStorage = connectLocalStorage('wtr-admin/code-editor')
const setCodeIdFx = createEvent<number>()
const $codeId = createStore<number>(codeIdLocalStorage.init() || 1)
  .on(setCodeIdFx, (_, codeId) => codeId)
$codeId.watch(codeIdLocalStorage)

export interface EditorContentProps {
  value?: Content[]
  onChange?: (values: Content[]) => void
  label?: string
  mode?: 'html' | 'text' | 'image'
}

type Data = Record<string, string>

export const EditorContent: React.FC<EditorContentProps> = React.memo(({
  value = [],
  onChange = () => null,
  label,
  mode = 'html',
}) => {
  if (!Array.isArray(value)) {
    value = []
  }
  const languages = useStore($languages)
  const codeId = useStore($codeId)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const theme = useTheme()

  const language = languages.find(({ id }) => id === codeId)

  const [data, setData] = useState<Data>({})
  const [open, setOpen] = useState(false)

  const config = useMemo(() => ({
    style: { color: 'black' },
    theme: theme.palette.mode === 'dark' ? 'dark' : 'default',
    zIndex: 2500,
    hidePoweredByJodit: true,
  }), [theme.palette.mode])

  useEffect(() => {
    const data: Data = {}

    languages.map(({ code }) => {
      data[code] = ''
    })

    value.map(({ language, content }) => {
      data[language] = content
    })

    setData(data)
  }, [Object.entries(value).map(([k, v]) => k + v).join(''), languages])

  const handlerContent = (value: string, code: string) => {
    setData((prev) => {
      prev[code] = value

      const content = Object.entries(prev).map(([key, value]) => ({
        language: key,
        content: value,
      })) as Content[]
      onChange(content)

      return { ...prev }
    })
  }

  const dataMemo = useMemo(() => languages.map(({ language, code, id }) => {
    const getEditor = (mode: EditorContentProps['mode']) => {
      switch (mode) {
        case 'html':
          return (
            <JoditEditor
              value={data[code]}
              onBlur={(text: string) => handlerContent(text, code)}
              config={config}
            />
          )
        case 'image':
          return (
            <InputFile
              autoFocus
              value={data[code] || ''}
              onFile={(e) => handlerContent(e, code)}
              fullWidth
            />
          )
        case 'text':
        default:
          return (
            <Input
              autoFocus
              value={data[code] || ''}
              onChange={(e) => handlerContent(e.target.value, code)}
              fullWidth
            />
          )
      }
    }

    return {
      value: id,
      label: language,
      component: getEditor(mode),
    }
  }), [data, config])

  return (
    <>
      <Input
        value={`[${language?.code}]: ${data[language?.code || 'en']?.slice(0, 100)}`}
        onClick={() => setOpen(true)}
        label={label}
        error={open}
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        minWidth={860}
      >
        <TabPanel
          onChange={setCodeIdFx}
          value={codeId}
          data={dataMemo}
        />
      </Modal>
    </>
  )
})
