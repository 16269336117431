import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Input, RootLayout, Switch } from '../../../../UI'
import { User } from '../../../../../services/api/User'

export const UserLegacy = () => {
  const { watch } = useFormContext<User>()

  const value = watch()

  return (
    <RootLayout>
      <Input
        disabled
        label="Логин WETER"
        value={value.attributes?.import?.weterLogin}
      />
      <Input
        disabled
        label="ID WETER"
        value={value.attributes?.import?.weterId}
      />
      <Input
        disabled
        label="Логин GOROD"
        value={value.attributes?.import?.gorodLogin}
      />
      <Input
        disabled
        label="ID GOROD"
        value={value.attributes?.import?.gorodId}
      />
      <Switch
        disabled
        label="Импортирован"
        checked={value.isImported}
      />
    </RootLayout>
  )
}
