import { createStore } from 'effector'

import { GetHeirRes, GetUserRes, GetUsersRes, TransactionsCorrectionRes } from '../../services/api/User'

import { getUsersFx, deleteUserFx, getUserFx, transactionsCorrectionFx, getHeirFx, postHeirFx } from './event'

const usersInit = {
  metadata: {
    limit: 0,
    page: 0,
    total: 0,
  },
  items: [],
}

export const $users = createStore<GetUsersRes>(usersInit)
  .on(getUsersFx.done, (_, { result }) => result.data.data)

export const $user = createStore<Partial<GetUserRes>>({})
  .on(getUserFx.done, (_, { result }) => result.data.data)
  .on(getUserFx, () => ({}))

export const $isPending = createStore(false)
  .on(getUsersFx.pending, (_, pending) => pending)
  .on(getUserFx.pending, (_, pending) => pending)
  .on(deleteUserFx.pending, (_, pending) => pending)

export const $transactionsCorrection = createStore<Partial<TransactionsCorrectionRes>>({})
  .on(transactionsCorrectionFx.done, (_, { result }) => result.data)
export const $transactionsCorrectionPending = createStore(false)
  .on(transactionsCorrectionFx.pending, (_, pending) => pending)

export const $heir = createStore<GetHeirRes>([])
  .on(getHeirFx.done, (_, { result }) => result.data.data)

export const $heirLoading = createStore(false)
  .on(getHeirFx.pending, (_, pending) => pending)
  .on(postHeirFx.pending, (_, pending) => pending)
