import React from 'react'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import dayjs from 'dayjs'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: 'График цен за долю в $',
    },
    tooltip: {
      // events: ['click'],
      callbacks: {
        label(context: any) {
          let label = context.dataset.label || ''
          if (label) {
            label += ': '
          }
          return `${label + context.parsed.y}$`
        },
      },
    },
  },
}

export const ProjectPriceGraph: React.FC<{data: any[]}> = ({ data }) => {
  const label = [] as string[]
  const values = [] as number[]

  // eslint-disable-next-line array-callback-return
  data.map((item: Record<string, any>) => {
    label.push(dayjs(item?.appliedAt).format('DD.MM'))
    values.push(Number(Number(item.price / 100).toFixed(8)))
  })

  const info = {
    labels: label,
    datasets: [
      {
        label: 'Цена',
        data: values,
        borderColor: '#1976d2',
        backgroundColor: '#1976d2',
      },
    ],
  }

  return (
    <Line options={options} data={info} />
  )
}
