import axios from 'axios'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

import { environment } from '../../../environment'
import { logOutFx } from '../../states/Login/event'
import { localStore } from '../../shared/utils'
import { CommonEnum } from '../../shared/enum'

import { MAIN_HEADER_REQUEST, ENC_METHODS } from './constants'

let fingerprint: null | string = null;
(async () => {
  // Get the visitor identifier when you need it.
  const fp = await FingerprintJS.load()
  fingerprint = (await fp.get()).visitorId
})()

export enum AxiosInstanceType {
  JSON = 'JSON',
  FORMDATA = 'FORM_DATA'
}

/**
 * Настройки для axios
 *
 * Если токен установлен, то условие : token !== '' вернет true
 *
 * @param {string} type 'JSON' | 'FORM_DATA'
 * @returns
 */
const AxiosInstance = (type: AxiosInstanceType = AxiosInstanceType.JSON) => {
  let contentType = 'application/json'
  if (type === AxiosInstanceType.FORMDATA) {
    contentType = 'multipart/form-data'
  }

  const token = localStore.get(CommonEnum.AUTH_TOKEN)

  // Set config defaults when creating the instance
  const instance = axios.create({
    baseURL: environment.API_PREFIX,
    headers: {
      ...MAIN_HEADER_REQUEST,
      'Content-Type': contentType,
      'x-trace-id': Date.now().toString(),
    },
    withCredentials: !!token,
    validateStatus: (status) => {
      if (status === 401 || status === 403) {
        logOutFx()
      }
      return status < 500 // Reject only if the status code is greater than or equal to 500
    },
  })

  if (token) {
    instance.defaults.headers.common.Authorization = `Bearer ${token}`
  }
  if (fingerprint) {
    instance.defaults.headers.common['x-fingerprint'] = fingerprint
  }

  instance.interceptors.request.use((config) => {
    const { method = '', data = {} } = config

    // eslint-disable-next-line no-prototype-builtins
    if ((ENC_METHODS.hasOwnProperty(method)) &&
        config?.headers?.['Content-Type'] !== 'multipart/form-data'
    ) {
      return {
        ...config,
        data: JSON.stringify({ ...data }),
      }
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  })

  return instance
}

export default AxiosInstance
