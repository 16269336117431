import AxiosInstance from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'

export interface GetUserRes {
  users: {
    today: number
    lastMonth: number
    total: number
  }
  candidates: {
    today: number
    lastMonth: number
    total: number
  }
}
const getUser = () => {
  return promiseToResult(
    AxiosInstance().get<GetUserRes>(
      '/application/admin/users/statistic',
    ),
  )
}

export interface GetRecoveryRes {
  today: number
  lastMonth: number
  total: number
}
const getRecovery = () => {
  return promiseToResult(
    AxiosInstance().get<GetRecoveryRes>(
      '/application/admin/recoveries/statistic',
    ),
  )
}

export interface GetVerificationRes {
  today: number
  lastMonth: number
  total: number
}
const getVerification = () => {
  return promiseToResult(
    AxiosInstance().get<GetVerificationRes>(
      '/application/admin/verifications/statistic',
    ),
  )
}

export interface GetTransactionRes {
  withdrawal: {
    today: number
    lastMonth: number
    total: number
    sum: number
  }
  payment: {
    today: number
    lastMonth: number
    total: number
  }
  installment: {
    received: {
      today: number
      lastMonth: number
      total: number
    }
    expected: {
      today: number
      nextMonth: number
      total: number
    }
  }
  shares: {
    today: number
    lastMonth: number
    total: number
  }
}
const getTransaction = () => {
  return promiseToResult(
    AxiosInstance().get<GetTransactionRes>(
      '/projects/admin/transactions/statistic',
    ),
  )
}
export const StatisticAPI = {
  getUser,
  getRecovery,
  getVerification,
  getTransaction,
}
