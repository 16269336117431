/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { useStore } from 'effector-react'
import { useNotification } from '../../../services/api/Notification'
import { Page } from '../../../shared/UI'
import { $defaults } from '../../../states/Defaults/store'
import { Routes } from '../../../shared/enum'

export const Id = () => {
  const defaults = useStore($defaults)
  const { id } = useNotification()

  return (
    <Page
      variant="form"
      title="Уведомления редактирование"
      button="Сохранить"
      loading={[id.social.put.loading, id.get.loading, id.patch.loading]}
      actions={[
        { type: 'link', title: 'Главная', to: `/${Routes.NOTIFICATION}` },
        {
          type: 'confirm',
          title: 'Разослать в ботов',
          onClick: () => id.social.put.fetch(id.get.data),
          color: 'secondary',
          disabled: id.get.data?.type !== 'social',
        },
      ]}
      onInit={id.get.fetch}
      value={id.get.data}
      onSubmit={id.patch.fetch}
      fields={[
        { name: 'type', type: 'select', label: 'Тип', options: defaults.notifications.types, disabled: true },
        { name: 'group', type: 'array', label: 'Юзеры ID' },
        { name: 'title.content', type: 'content', label: 'Заголовок', mode: 'text', hide: ({ type }) => !type || type === 'social' },
        { name: 'description.content', type: 'content', label: 'Описание', mode: 'text', hide: ({ type }) => !type || type === 'social' },
        { name: 'isActive', type: 'switch', label: 'Активен', hide: ({ type }) => !type || type === 'social' },
        { name: 'photo', type: 'file', label: 'Фото', accept: 'img', hide: ({ type }) => !type || type === 'social' },
        { name: 'link', type: 'string', label: 'Ссылка', hide: ({ type }) => !type || type === 'social' },
        { name: 'instance', type: 'select', label: 'Методы рассылки', options: defaults.notifications.socials, hide: ({ type }) => type !== 'social' },
        { name: 'text.content', type: 'content', label: 'Текст', mode: 'text', hide: ({ type }) => type !== 'social' },
        { name: 'activeUntil', type: 'date', label: 'Действует до', disabled: true, hide: ({ type }) => type !== 'promotion' },
        { name: 'variation', type: 'string', label: 'Вариант', hide: ({ type }) => !type || type !== 'promotion' },
        { name: 'createdAt', type: 'date', label: 'Создана', disabled: true },
        { name: 'lastMailed', type: 'date', label: 'Последняя рассылка', disabled: true, hide: ({ type }) => type !== 'social' },
      ]}
    />
  )
}
