import { createEffect } from 'effector'
import { toast } from 'react-toastify'

import {
  TranslationAPI,
  GetTranslationsReq,
  GetAliasTranslationsReq,
  PatchAliasTranslationsReq,
  PostAliasTranslationsReq,
  DeleteAliasTranslationsReq,
} from '../../services/api/Translation'
import { $navigate } from '../History/store'
import { Routes } from '../../shared/enum'
import { AxiosError, APIResult } from '../../core/api/Result'

export const getTranslationsFx = createEffect<GetTranslationsReq, APIResult<typeof TranslationAPI['getTranslations']>, AxiosError>(
  TranslationAPI.getTranslations,
)

export const getAliasTranslationsFx = createEffect<GetAliasTranslationsReq, APIResult<typeof TranslationAPI['getAliasTranslations']>, AxiosError>(
  TranslationAPI.getAliasTranslations,
)

export const patchAliasTranslationsFx = createEffect<PatchAliasTranslationsReq, APIResult<typeof TranslationAPI['patchAliasTranslations']>, AxiosError>(
  TranslationAPI.patchAliasTranslations,
)

export const postAliasTranslationsFx = createEffect<PostAliasTranslationsReq, APIResult<typeof TranslationAPI['patchAliasTranslations']>, AxiosError>(
  TranslationAPI.postAliasTranslations,
)

export const deleteAliasTranslationsFx = createEffect<DeleteAliasTranslationsReq, APIResult<typeof TranslationAPI['deleteAliasTranslations']>, AxiosError>(
  TranslationAPI.deleteAliasTranslations,
)

patchAliasTranslationsFx.done.watch(() => {
  toast.success('Перевод успешно обновлен')
})
patchAliasTranslationsFx.fail.watch(() => {
  toast.error('Ошибка обновления')
})

postAliasTranslationsFx.done.watch(({ params }) => {
  const navigate = $navigate.getState()
  navigate(`${Routes.TRANSLATION}/${params.alias}`)
  toast.success('Перевод успешно создан')
})
postAliasTranslationsFx.fail.watch(() => {
  toast.error('Ошибка создания')
})

deleteAliasTranslationsFx.done.watch(() => {
  const navigate = $navigate.getState()
  navigate(Routes.TRANSLATION)
  toast.success('Перевод успешно удалён')
})
deleteAliasTranslationsFx.fail.watch(() => {
  toast.error('Ошибка удаления')
})
