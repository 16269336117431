/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { useStore } from 'effector-react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { NavLink, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Page } from '../../../shared/UI'

import { NewsAPI, PutNewsReq } from '../../../services/api/News'
import { getProjectsFx } from '../../../states/Project/event'
import { $projectsOptions } from '../../../states/Project/store'
import { $my } from '../../../states/My/store'
import { InstallmentsAPI, Installment } from '../../../services/api/Installments'
import { toDollar } from '../../../shared/utils'
import { Routes } from '../../../shared/enum'
import { Drawer } from '@mui/material'
import dayjs from 'dayjs'
import { useDebounce } from '../../../shared/hooks'
import { $defaults } from '../../../states/Defaults/store'

interface InstallmentWithInfo extends Installment {
  transactions?: number[]
  sharePrice: number
  paymentPrice?: number
}

export const Edit = () => {
  const defaults = useStore($defaults)
  const projectsOptions = useStore($projectsOptions)
  const { id = '' } = useParams()
  const [openComment, setCommentOpen] = useState(false)

  const get = useQuery(['getInstallmentId', id], () => InstallmentsAPI.getInstallment({ id: String(id) }))
  const getComment = useQuery(['getInstallmentComment', id], () => InstallmentsAPI.getInstallmentComment({ id }))
  const put = useMutation(InstallmentsAPI.patchInstallment)
  const cancel = useMutation(InstallmentsAPI.postInstallmentCancel)
  const open = useMutation(InstallmentsAPI.postInstallmentOpen)
  const postComment = useMutation(InstallmentsAPI.postInstallmentComment)
  const postRecalculate = useMutation(InstallmentsAPI.postInstallmentRecalculate)

  const isPaid = !!get.data?.data.data.paidAt
  const isClosed = !!get.data?.data.data.closedAt

  const handlerSubmit = async (data: InstallmentWithInfo) => {
    await put.mutateAsync({
      id: data.id,
      nextPaymentDate: data.nextPaymentDate,
      price: data.price,
      payPeriod: { monthsAmount: data.payPeriod.months },
      adminStatus: data.adminStatus,
    })
    get.refetch()
    toast.success('Сохранено')
  }

  const handlerCancel = async () => {
    await cancel.mutateAsync({ id })
    get.refetch()
    toast.success('Закрыта')
  }

  const handlerOpen = async () => {
    await open.mutateAsync({ id })
    get.refetch()
    toast.success('Открыта')
  }

  const handlerRecalculate = async () => {
    await postRecalculate.mutateAsync({ id })
    get.refetch()
    toast.success('Перерасчитанно')
  }

  return (
    <>
      <Page
        title="Редактировать резерв"
        button="Сохранить"
        variant="form"
        loading={[get.isLoading]}
        actions={[
          {
            title: 'Открыть рассрочку',
            type: 'confirm',
            onClick: handlerOpen,
            disabled: !isClosed && !isPaid,
            style: { display: 'none' },
          },
          {
            type: 'button',
            title: 'Комментарии',
            onClick: () => setCommentOpen(true),
          },
          {
            title: 'Закрыть рассрочку',
            color: 'secondary',
            type: 'confirm',
            onClick: handlerCancel,
            disabled: isClosed || isPaid,
          },
          {
            title: 'Перерасчитать',
            message: 'Цена останется не изменной, а доли будет перерасчитаны по текущему курсу',
            color: 'secondary',
            type: 'confirm',
            onClick: handlerRecalculate,
            disabled: isClosed || isPaid,
          },
          {
            title: `Юзер ID ${get.data?.data.data.user}`,
            type: 'link',
            to: `/${Routes.USER}/${get.data?.data.data.user}`,
          },
        ]}
        onSubmit={handlerSubmit}
        value={{
          ...get.data?.data.data,
          transactions: get.data?.data.data.calendar.map(({ transactionId }) => transactionId).filter(Boolean),
          sharePrice: toDollar(get.data?.data.data.price) / Number(get.data?.data.data.shares),
          paymentPrice: get.data?.data.data.calendar.reduce((acc, { isPaid, price }) => isPaid ? price + acc : acc, 0),
        } as InstallmentWithInfo}
        fields={[
          { name: 'id', type: 'number', label: 'ID', disabled: true },
          { name: 'user', type: 'number', label: 'Юзер ID', disabled: true },
          { name: 'project', type: 'select', label: 'Проект', disabled: true, options: projectsOptions },
          { name: 'isPaid', type: 'switch', label: 'Оплачена', disabled: true },
          { name: 'price', type: 'cent', label: 'Цена', disabled: true },
          { name: 'shares', type: 'number', label: 'Долей', disabled: true },
          { name: 'paymentPrice', type: 'cent', label: 'Внесено', disabled: true },
          { name: 'transactions', type: 'array', label: 'Транзакции оплаты', disabled: true },
          { name: 'payPeriod.months', type: 'number', label: 'Период, месяцы (2-10)', disabled: isClosed || isPaid },
          { name: 'nextPaymentDate', type: 'date', label: 'Дата сл платежа', disabled: isClosed || isPaid },
          { name: 'minimumPayment', type: 'cent', label: 'Мин платеж', disabled: true },
          { name: 'adminStatus', type: 'select', label: 'Статус', options: defaults.installments.adminStatuses },
          { name: 'sharePrice', type: 'string', label: 'Цена за долю', disabled: true },
          { name: 'paidAt', type: 'date', label: 'Дата оплаты', disabled: true },
          { name: 'closedAt', type: 'date', label: 'Дата закрытия', disabled: true },
          { name: 'createdAt', type: 'date', label: 'Дата открытия', disabled: true },
        ]}
      />

      <Drawer
        open={openComment}
        onClose={() => setCommentOpen(false)}
      >
        <Page
          variant="form"
          title="Добавить комментарий"
          button="Сохранить"
          onSubmit={async (data: any) => {
            await postComment.mutateAsync({ ...data, id: get.data?.data.data.id })
            toast.success('Комментарий сохранен')
            get.refetch()
            getComment.refetch()
          }}
          fields={[
            { name: 'text', type: 'string', label: 'Comment' },
          ]}
        />
        <Page
          variant="root"
          title="Комментарии"
          rows={getComment.data?.data.data}
          total={getComment.data?.data.data.length}
          col={[
            { id: 'userId', label: 'Автор', render: (id) => <NavLink to={`/${Routes.USER}/${id}`}>{id}</NavLink> },
            { id: 'comment', label: 'Текст' },
            { id: 'isActive', label: 'Активный' },
            { id: 'createdAt', label: 'Создана', render: (date) => dayjs(date).format('LLL') },
          ]}
        />
      </Drawer>
    </>
  )
}
