import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useStore } from 'effector-react'

import { $language, $languageLoading } from '../../../../states/Language/store'
import { deleteLanguageFx, getLanguageFx, patchLanguageFx } from '../../../../states/Language/event'
import {
  Switch,
  Input,
  InputFile,
  Button,
  ButtonWithConfirm,
  RootLayout,
} from '../../../UI'
import { Typography } from '@mui/material'
import { Language } from '../../../../services/api/Language'
import { InputArray } from '../../../UI/Input/InputArray'

export const LanguageEdit: React.FC = () => {
  const { id } = useParams() as { id: string }

  const languageData = useStore($language)
  const languageLoading = useStore($languageLoading)

  const methods = useForm<Language>({
    defaultValues: {
      code: '',
      language: '',
      active: false,
      icon: '',
      displayName: '',
      countries: [],
    },
  })
  const {
    getValues,
    setValue,
    watch,
  } = methods
  const { code, language, active, icon, displayName, countries } = watch()

  useEffect(() => {
    getLanguageFx({ id })
  }, [])

  useEffect(() => {
    Object.keys(languageData).forEach((key) => {
      const value = (languageData as Record<string, any>)[key]
      setValue(key as keyof Language, value)
    })
  }, [languageData])

  const handleSave = async () => {
    patchLanguageFx(getValues())
  }

  const handleDelete = async () => {
    deleteLanguageFx({ id })
  }

  return (
    <RootLayout loading={languageLoading} empty={!languageData.id}>
      <Typography variant="h5">Редактирование языка</Typography>

      <ButtonWithConfirm
        color="error"
        variant="contained"
        onClick={handleDelete}
        loading={languageLoading}
        content="Вы действительно ходите удалить язык?"
        style={{ display: 'none' }}
      >
        Удалить
      </ButtonWithConfirm>

      <Input
        label="Код языка"
        value={code}
        onChange={(e) => setValue('code', e.target.value)}
        InputLabelProps={{ shrink: !!code }}
      />
      <Input
        label="Язык"
        value={language}
        onChange={(e) => setValue('language', e.target.value)}
        InputLabelProps={{ shrink: !!language }}
      />
      <Input
        label="displayName"
        value={displayName}
        onChange={(e) => setValue('displayName', e.target.value)}
        InputLabelProps={{ shrink: !!displayName }}
      />
      <InputArray
        label="Страны"
        value={countries}
        onArray={(arr) => setValue('countries', arr)}
        InputLabelProps={{ shrink: !!countries.length }}
      />
      <InputFile
        label="Флаг"
        filename={`flag-lang-${watch('code')}`}
        value={icon}
        onFile={(url) => setValue('icon', url)}
      />
      <Switch
        checked={active}
        onChange={() => setValue('active', !active)}
        label={active ? 'Включен' : 'Выключен'}
        title="Состояние"
      />

      <Button
        onClick={handleSave}
        loading={languageLoading}
        disabled={!code || !language}
      >
        Сохранить
      </Button>
    </RootLayout>
  )
}
