import React from 'react'
import { TextField, TextFieldProps } from '@mui/material'

export type InputProps = TextFieldProps

export const Input: React.FC<InputProps> = ({
  variant = 'standard',
  ...props
}) => {
  if (props.value) {
    props.InputLabelProps = {}
    props.InputLabelProps.shrink = !!props.value
  }
  return (
    <TextField
      variant={variant}
      {...props}
    />
  )
}
