import React from 'react'
import { useFormContext } from 'react-hook-form'
import { isValidNumber } from 'libphonenumber-js'

import { DateTimePicker, Input, RootLayout, Select, Switch } from '../../../../UI'
import { User } from '../../../../../services/api/User'
import { useStore } from 'effector-react'
import { $defaults } from '../../../../../states/Defaults/store'

export const UserMain = () => {
  const defaults = useStore($defaults)

  const { watch, setValue } = useFormContext<User>()

  const value = watch()

  return (
    <RootLayout>
      <Input
        label="ID"
        value={value?.id}
        disabled
      />
      <Select
        label="Роль"
        options={defaults.users.roles}
        value={value?.role}
        onSelect={(key) => setValue('role', key)}
      />
      <Input
        label="Логин"
        value={value?.login}
        disabled
      />
      <Input
        label="Телефон"
        value={value?.phone}
        onChange={(e) => setValue('phone', e.target.value)}
        error={!isValidNumber(value?.phone || '')}
      />
      <Input
        label="Почта"
        value={value?.socials?.email}
        onChange={(e) => setValue('socials.email', e.target.value || null)}
      />
      <Switch
        label="Заблокирован"
        checked={value.isBlocked}
        onChange={(_, checked) => setValue('isBlocked', checked)}
      />
      <Input
        label="Язык"
        value={value?.language}
        disabled
      />
      <Input
        label="Страна"
        value={value?.attributes?.phoneCountry}
        disabled
      />
      <DateTimePicker
        disabled
        label="Дата регистрации"
        value={value.createdAt}
      />
    </RootLayout>
  )
}
