import React from 'react'
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select as SelectMUI,
  SelectProps as SelectPropsMui,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import { Option } from './Input'

export interface SelectProps<T> extends Omit<SelectPropsMui, 'title' | 'onSelect' | 'options' | 'value'> {
  label: React.ReactNode
  options?: Option<T>[]
  value?: T
  onSelect?: ((key: T, event: SelectChangeEvent<unknown>) => void) | undefined
  isEmpty?: boolean
  fullWidth?: boolean
}

const w100 = { width: '100%' }

export const Select = <T, >({
  label,
  variant = 'standard',
  className,
  style,
  options = [],
  onSelect,
  isEmpty,
  sx,
  value,
  multiple,
  fullWidth,
  ...props
}: SelectProps<T>) => {
  return (
    <FormControl
      variant={variant}
      className={className}
      style={{ ...style, ...(fullWidth ? w100 : {}) }}
      sx={sx}
      size={props.size}
    >
      <InputLabel>
        {label}
      </InputLabel>
      <SelectMUI
        {...props}
        fullWidth
        label={label}
        value={value !== undefined ? value : multiple ? [] : ''}
        multiple={multiple}
        onChange={(...arg) => {
          const e = arg[0]

          if (typeof props.onChange === 'function') {
            props.onChange(...arg)
          }
          if (typeof onSelect === 'function') {
            onSelect(e?.target?.value as T, e)
          }
        }}
      >
        {isEmpty && <MenuItem value="">Не выбрано</MenuItem>}
        {options.map(({ key, value }) => (
          <MenuItem key={String(key)} value={key as any}>{value}</MenuItem>
        ))}
      </SelectMUI>
    </FormControl>
  )
}
