import React, { useEffect } from 'react'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useStore } from 'effector-react'
import { NavLink, useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import { Routes } from '../../../enum'
import { Button, Input, DateTimePicker, RootLayout, Page } from '../../../UI'
import { getProjectShareFx, createProjectShareFx, getProjectShareTimerFx } from '../../../../states/Project/event'
import { $projectLoading, $project, $projectShare, $projectShareTimer } from '../../../../states/Project/store'

import { ProjectPriceGraph } from './ProjectPrice.graph'
import styles from './ProjectPrice.module.scss'
import { usePagination } from '../../../hooks'

export const ProjectPrice: React.FC = () => {
  const { id } = useParams()

  const project = useStore($project)
  const projectShare = useStore($projectShare)
  const projectLoading = useStore($projectLoading)
  const getProjectShareTimer = useStore($projectShareTimer)

  const { handlerQueries, defaultQuery } = usePagination({
    defaults: { limit: '15', page: '1' },
    observerQuery: (q: any) => getProjectShareTimerFx({ id, ...q }),
  })

  const method = useForm({
    defaultValues: {
      percent: '',
      applyAt: dayjs().toISOString(),
    },
  })
  const {
    getValues,
    watch,
    setValue,
    reset,
  } = method

  const values = watch()

  const buttonDisabled = !values.applyAt
    || !(Number.isFinite(Number(values.percent)) && !!values.percent)
    || dayjs(values.applyAt)
      .isBefore(dayjs(projectShare.length && projectShare[projectShare.length - 1]?.applyAt))

  const handlerCreate = async () => {
    const values = getValues()
    if (dayjs(values.applyAt).isBefore(dayjs())) {
      values.applyAt = dayjs().toISOString()
      setValue('applyAt', values.applyAt)
    }
    if (id) {
      await createProjectShareFx({
        id,
        percent: Number(values.percent),
        appliedAt: values.applyAt,
      })
      reset()
    }
  }

  useEffect(() => {
    if (id) {
      getProjectShareFx({ id })
    }
  }, [id])

  return (
    <RootLayout loading={projectLoading} empty={!project.id}>
      <div className={styles.wrapper}>
        <Typography variant="h5" sx={{ mb: '15px' }}>
          Цена долей проекта {project?.name}
        </Typography>

        <div className={styles.buttons}>
          <NavLink to={`/${Routes.PROJECT}/${id}`}>
            <Button variant="contained">
              К проекту
            </Button>
          </NavLink>
        </div>

        <div className={styles.inputs}>

          <ProjectPriceGraph data={projectShare} />

          <Typography variant="h5">Добавить новую цену</Typography>

          <Input
            label="Проценты. Целое число"
            variant="standard"
            value={values.percent}
            onChange={(e) => setValue('percent', e.target.value)}
          />

          <DateTimePicker
            label={`Запланированное время. Кратное 10 минутам (UTC - ${dayjs.utc(values?.applyAt).format('DD.MM.YYYY HH:mm')})`}
            variant="standard"
            value={values?.applyAt}
            onSelect={(date) => setValue('applyAt', date.toISOString())}
          />

          <Button
            color="primary"
            variant="contained"
            onClick={handlerCreate}
            disabled={buttonDisabled}
          >
            Создать
          </Button>
        </div>
      </div>

      <Page
        title="Список запланированых"
        variant="root"
        onChange={handlerQueries}
        rows={getProjectShareTimer.items}
        total={getProjectShareTimer.metadata?.total}
        defaultFilter={defaultQuery}
        col={[
          { id: 'percent', label: 'Процентов' },
          { id: 'appliedAt', label: 'Время добавления', render: (time) => dayjs(time).format('LLL') },
          { id: 'status', label: 'Выполнено' },
        ]}
      />
    </RootLayout>
  )
}
