import AxiosInstance from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'
import { querystring } from '../../shared/utils'

import { Query, WithMetadata } from './_types'

export interface Recovery {
  id: number
  photo: string
  comment: string
  status: string
  createdAt: string
}

export type GetRecoveryAllReq = Query
export type GetRecoveryAllRes = WithMetadata<Recovery>

const getRecoveryAll = (data: GetRecoveryAllReq) => {
  const { query } = data
  return promiseToResult(
    AxiosInstance().get<GetRecoveryAllRes>(
      `/application/admin/recoveries?${querystring.stringify(query)}`,
    ),
  )
}

export interface GetRecoveryReq {
  id: string | number
}
export interface GetRecoveryRes extends Recovery {
  user: {
    id: number
    login: string
    phone: string
  },
  passport: {
    id: 0,
    birthDate: string
    name: string
    surname: string
  }
}
const getRecovery = (data: GetRecoveryReq) => {
  const { id } = data
  return promiseToResult(
    AxiosInstance().get<GetRecoveryRes>(
      `/application/admin/recoveries/${id}`),
  )
}

export interface PutRecoveryReq {
  id: string | number
  status: string
  comment: string
}
export interface PutRecoveryRes {
  status: boolean
}
const putRecovery = (data: PutRecoveryReq) => {
  const { id, ...body } = data
  return AxiosInstance().put<PutRecoveryRes>(
    `/application/admin/recoveries/${id}/confirm`,
    body,
  )
}

export const RecoveryAPI = {
  getRecoveryAll,
  getRecovery,
  putRecovery,
}
