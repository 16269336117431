/* eslint-disable @typescript-eslint/no-unused-vars */
import AxiosInstance from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'
import { Content, WithMetadata } from './_types'
import { querystring } from '../../shared/utils'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Option } from '../../shared/UI/Input'
import { usePagination } from '../../shared/hooks'

export interface FaqCategory {
  id: number
  title: { content: Content[] }
  isActive: boolean
  name: string
}

export interface Faq {
  id: number
  isActive: boolean
  title: { content: Content[] }
  description: { content: Content[] }
  shortDescription: { content: Content[] }
  tags: { content: Content[] }
  name: string
  categoryId: number
}

export type GetCategoriesRes = FaqCategory[]
const getCategories = () => {
  return promiseToResult(
    AxiosInstance().get<GetCategoriesRes>(
      `/application/admin/faq/category?${querystring.stringify({})}`,
    ),
  )
}

export interface GetCategoryRes {
  id: number | string
}
export type GetCategoryReq = FaqCategory
const getCategory = (data: GetCategoryRes) => {
  return promiseToResult(
    AxiosInstance().get<GetCategoryReq>(
      `/application/admin/faq/category/${data.id}`,
    ),
  )
}

export type PostCategoryReq = Omit<FaqCategory, 'id'>
export type PostCategoryRes = FaqCategory
const postCategory = (data: PostCategoryReq) => {
  return promiseToResult(
    AxiosInstance().post<PostCategoryRes>(
      '/application/admin/faq/category',
      data,
    ),
  )
}

const deleteCategory = (data: GetCategoryRes) => {
  return promiseToResult(
    AxiosInstance().delete(
      `/application/admin/faq/category/${data.id}`,
    ),
  )
}

export type PatchCategoryRes = FaqCategory
const patchCategory = (data: PatchCategoryRes) => {
  return promiseToResult(
    AxiosInstance().patch<PostCategoryRes>(
      `/application/admin/faq/category/${data.id}`,
      data,
    ),
  )
}

export interface GetFaqsReq {
  limit: number
  page: number
  search: string
  active: boolean
  id: number
  name: string
}
export type GetFaqsRes = WithMetadata<Faq>
const getFaqs = (data: GetFaqsReq) => {
  return promiseToResult(
    AxiosInstance().get<GetFaqsRes>(
      `/application/admin/faq?${querystring.stringify(data)}`,
    ),
  )
}

export type GetFaqRes = Faq
const getFaq = (data: GetCategoryRes) => {
  return promiseToResult(
    AxiosInstance().get<GetFaqRes>(
      `/application/admin/faq/${data.id}`,
    ),
  )
}

export type PostFaqReq = Omit<Faq, 'id'>
export type PostFaqRes = Faq
const postFaq = (data: PostFaqReq) => {
  return promiseToResult(
    AxiosInstance().post<PostFaqRes>(
      '/application/admin/faq',
      data,
    ),
  )
}

export type PatchFaqReq = Faq
export type PatchFaqRes = Faq
const patchFaq = (data: PatchFaqReq) => {
  return promiseToResult(
    AxiosInstance().patch<PatchFaqRes>(
      `/application/admin/faq/${data.id}`,
      data,
    ),
  )
}

const deleteFaq = (data: GetCategoryRes) => {
  return promiseToResult(
    AxiosInstance().delete(
      `/application/admin/faq/${data.id}`,
    ),
  )
}

export const FaqAPI = {
  getCategories,
  getCategory,
  postCategory,
  deleteCategory,
  patchCategory,
  getFaqs,
  getFaq,
  postFaq,
  patchFaq,
  deleteFaq,
}

export const useFaq = (faqId: number | string = '') => {
  const defaultQuery = {
    limit: '15',
    page: '1',
  }

  const { query, handlerQueries } = usePagination({
    defaults: defaultQuery,
  })
  const get = useQuery(['FaqAPI.getFaqs', query], () => FaqAPI.getFaqs(query))
  const getId = useQuery(
    ['FaqAPI.getFaq', faqId],
    () => faqId ? FaqAPI.getFaq({ id: faqId }) : undefined,
  )
  const post = useMutation(FaqAPI.postFaq)
  const del = useMutation(FaqAPI.deleteFaq)
  const patch = useMutation(FaqAPI.patchFaq)

  return {
    get,
    getId,
    post,
    del,
    patch,
    handlerQueries,
    defaultQuery,
  }
}

export const useFaqCategory = (categoryId: number | string = '') => {
  const get = useQuery(['FaqAPI.getCategories'], FaqAPI.getCategories)
  const getId = useQuery(
    ['FaqAPI.getCategory', categoryId],
    () => categoryId ? FaqAPI.getCategory({ id: categoryId }) : undefined,
  )
  const post = useMutation(FaqAPI.postCategory)
  const del = useMutation(FaqAPI.deleteCategory)
  const patch = useMutation(FaqAPI.patchCategory)

  return {
    get,
    getId,
    post,
    del,
    patch,
    options: get.data?.data.data.map(({ id, name }) => ({ key: id, value: name })) as Option<number>[] || [],
  }
}
