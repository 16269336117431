/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Role } from '../../enum'
import { Button, ButtonProps, ButtonPropsWithConfirm, ButtonWithConfirm } from '../Button'
import { RoleGuard } from '../RoleGuard'

type ButtonType = ButtonDefaultProps | ButtonConfirmProps | ButtonLinkProps

type ButtonBase = {
  title: string
  role?: Role,
} & ButtonType

export interface PageActionProps {
  buttons?: ButtonBase[]
}

export const Actions: React.FC<PageActionProps> = ({
  buttons,
}) => {
  if (!buttons?.length) {
    return null
  }
  return (
    <div className="header">
      {buttons?.map(({ title, type = 'default', role, ...props }) => {
        const getButton = () => {
          switch (type) {
            case 'button':
              return <ButtonDefault {...props} key={title}>{title}</ButtonDefault>
            case 'confirm':
              return <ButtonConfirm {...props} key={title}>{title}</ButtonConfirm>
            case 'link':
              return <ButtonLink {...props} key={title}>{title}</ButtonLink>
            default:
              return null
          }
        }
        if (!role) {
          return getButton()
        }
        return (
          <RoleGuard role={role} key={title}>
            {getButton()}
          </RoleGuard>
        )
      })}
    </div>
  )
}

interface ButtonLinkProps extends Omit<ButtonProps, 'type' | 'role'> {
  type?: 'link'
  to?: string
}
const ButtonLink: React.FC<ButtonLinkProps> = ({
  to = '',
  ...props
}) => {
  return (
    <NavLink to={to}>
      <Button {...props} type="button" />
    </NavLink>
  )
}

interface ButtonDefaultProps extends Omit<ButtonProps, 'type' | 'role'> {
  type?: 'button'
}
const ButtonDefault: React.FC<ButtonDefaultProps> = (props) => {
  return <Button {...props} type="button" />
}

interface ButtonConfirmProps extends Omit<ButtonPropsWithConfirm, 'type' | 'title' | 'content' | 'role'> {
  type?: 'confirm'
  message?: React.ReactNode
}
const ButtonConfirm: React.FC<ButtonConfirmProps> = ({
  message,
  ...props
}) => {
  return (
    <ButtonWithConfirm
      {...props}
      type="button"
      title="Подтвердите действие"
      content={message}
    />
  )
}
