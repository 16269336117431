import React from 'react'
import { AccordionDetails, AccordionSummary, Typography, Accordion as AccordionMUI } from '@mui/material'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { AccordionProps as AccordionPropsMUI } from '@mui/material/Accordion/Accordion'

export interface AccordionProps extends AccordionPropsMUI{
  label: React.ReactNode
}

export const Accordion: React.FC<AccordionProps> = ({
  label,
  children,
  className,
  ...props
}) => {
  return (
    <AccordionMUI {...props}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography style={{ width: '100%' }}>{label}</Typography>
      </AccordionSummary>
      <AccordionDetails className={className}>
        {children}
      </AccordionDetails>
    </AccordionMUI>
  )
}
