/* eslint-disable @typescript-eslint/no-unused-vars */
import { Autocomplete } from '@mui/material'
import React, { KeyboardEventHandler, useEffect, useState } from 'react'
import { Input, InputProps } from './Input'

export interface Option<T> { key: T, value: string }

export interface InputArrayProps<T, > extends Omit<InputProps, 'value' | 'options' | 'onArray'> {
  value?: T[]
  onArray?: (arr: T[]) => void
  options?: Option<T>[]
}

export const InputArray = <T, >({
  value,
  options = [],
  onArray = () => null,
  ...props
}: InputArrayProps<T>) => {
  type Item = Map<T, Option<T>>
  const [set, setSet] = useState<Item>(() => {
    const mapNew = new Map()
    value?.forEach((key) => {
      const item = options?.find(({ key: k }) => key === k)
      mapNew.set(key, { key, value: item?.value || key })
    })
    return mapNew
  })
  const arr = Array.from(set).map(([key, options]) => options)

  const handlerOnArray = (map: Item) => {
    onArray(Array.from(map).map(([key, option]) => option.key))
  }

  useEffect(() => {
    const mapNew = new Map()
    value?.forEach((key) => {
      const item = options?.find(({ key: k }) => key === k)
      mapNew.set(key, { key, value: item?.value || key })
    })
    setSet(mapNew)
  }, [value])

  const handlerInput: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.code === 'Enter') {
      const target = e.target as HTMLInputElement
      const { key, value } = target as Record<string, any>
      const item = options?.find(({ key: k }) => key.toLowerCase() === String(k).toLowerCase())
      const option: Option<T> = {
        key: (key || item?.key || value) as T,
        value: item?.value || key || value,
      }

      const mapNew = new Map(set)
      mapNew.set(option.key, option)
      setSet(mapNew)
      handlerOnArray(mapNew)
      target.value = ''
    }
  }

  const handlerRemove = (
    e: unknown,
    f: unknown,
    reason: unknown,
    detail: { option: Option<T> },
  ) => {
    const mapNew = new Map(set)

    if (reason === 'clear') {
      mapNew.clear()
    }

    if (reason === 'selectOption') {
      const { key } = detail.option
      const item = options?.find(({ key: k }) => String(k).toLowerCase() === String(key).toLowerCase())
      const option: Option<T> = {
        key: (key || item?.key) as T,
        value: item?.value || String(key),
      }
      mapNew.set(option.key, option)
    }

    if (reason === 'removeOption') {
      mapNew.delete(detail.option.key)
    }

    setSet(mapNew)
    handlerOnArray(mapNew)
  }

  return (
    <Autocomplete
      autoComplete={false}
      multiple
      options={options}
      getOptionLabel={(option: Option<T>) => String(option.value || option.key)}
      value={arr as []}
      onKeyDown={handlerInput}
      onChange={handlerRemove as any}
      disabled={props.disabled}
      renderInput={(params) => {
        return (
          <Input
            {...props}
            {...params}
          />
        )
      }}
    />
  )
}
