import AxiosInstance from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'
import { querystring } from '../../shared/utils'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useMutation, useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { Content, IdReq, PaginationQuery, WithMetadata } from './_types'
import { defaultQuery } from '../../shared/hooks'
import { toast } from 'react-toastify'
import { $navigate } from '../../states/History/store'
import { Routes } from '../../shared/enum'

export interface Template {
  type: string
  telegram: {
    text: { content: Content[] }
  }
  whatsapp: {
    text: { content: Content[] }
  }
  sms: {
    text: { content: Content[] }
  }
  email: {
    subject: { content: Content[] }
    html: { content: Content[] }
    from: string
  }
}

export type GetTemplatesRes = WithMetadata<string>
const getTemplates = (data: PaginationQuery) => {
  return promiseToResult(
    AxiosInstance().get<GetTemplatesRes>(
      `/application/admin/templates?${querystring.stringify(data)}`,
    ),
  )
}

const getTemplateId = (data: IdReq) => {
  return promiseToResult(
    AxiosInstance().get<Template>(
      `/application/admin/templates/${data.id}`,
    ),
  )
}

const patchTemplateId = (data: Template) => {
  return promiseToResult(
    AxiosInstance().patch<Template>(
      `/application/admin/templates/${data.type}`,
      data,
    ),
  )
}

const deleteTemplateId = (data: IdReq) => {
  return promiseToResult(
    AxiosInstance().delete(
      `/application/admin/templates/${data.id}`,
    ),
  )
}

const postTemplate = (data: Template) => {
  return promiseToResult(
    AxiosInstance().post<Template>(
      '/application/admin/templates',
      data,
    ),
  )
}

export const TemplateAPI = {
  getTemplates,
  getTemplateId,
  patchTemplateId,
  deleteTemplateId,
  postTemplate,
}

export const useTemplate = () => {
  const [query, setQuery] = useState({
    templates: defaultQuery as PaginationQuery,
    templateId: {} as IdReq,
  })

  const getTemplates = useQuery(
    ['TemplateAPI.getTemplates', query.templates],
    () => TemplateAPI.getTemplates(query.templates),
    { enabled: false },
  )
  const getTemplateId = useQuery(
    ['TemplateAPI.getTemplateId', query.templateId],
    () => TemplateAPI.getTemplateId(query.templateId),
    { enabled: false },
  )
  const patchTemplateId = useMutation(TemplateAPI.patchTemplateId)
  const deleteTemplateId = useMutation(TemplateAPI.deleteTemplateId)
  const postTemplate = useMutation(TemplateAPI.postTemplate)

  return {
    get: {
      fetch: (templates: PaginationQuery) => {
        setQuery((prev) => ({ ...prev, templates }))
        setTimeout(getTemplates.refetch, 500)
      },
      loading: getTemplates.isFetching,
      data: getTemplates.data?.data.data.items.map((type) => ({ id: type, type })),
    },
    post: {
      fetch: async (template: Template) => {
        await postTemplate.mutateAsync(template)
        toast.success('Шаблон создан')
        const navigate = $navigate.getState()
        navigate(`/${Routes.TEMPLATE}/${template.type}`)
      },
      loading: postTemplate.isLoading,
    },
    id: {
      get: {
        fetch: (templateId: IdReq) => {
          setQuery((prev) => ({ ...prev, templateId }))
          setTimeout(getTemplateId.refetch, 500)
        },
        loading: getTemplateId.isFetching,
        data: getTemplateId.data?.data.data,
      },
      patch: {
        fetch: async (data: Template) => {
          await patchTemplateId.mutateAsync(data)
          await getTemplateId.refetch()
          toast.success('Шаблон обновлен')
        },
        loading: patchTemplateId.isLoading,
      },
      delete: {
        fetch: async (data: IdReq) => {
          await deleteTemplateId.mutateAsync(data)
          toast.success('Шаблон удален')
          const navigate = $navigate.getState()
          navigate(`/${Routes.TEMPLATE}`)
        },
        loading: deleteTemplateId.isLoading,
      },
    },
  }
}
