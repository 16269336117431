import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useStore } from 'effector-react'
import { FormProvider, useForm } from 'react-hook-form'

import { Button, ButtonWithConfirm, RootLayout, TabPanel, RoleGuard, ModalWithTrigger } from '../../../UI'
import { deleteUserFx, disallowLoginFx, getUserFx, imposterFx, mustReloignFx, patchUserFx } from '../../../../states/User/event'
import { $isPending, $user } from '../../../../states/User/store'

import { User } from '../../../../services/api/User'
import { Role, Routes } from '../../../enum'
import { UserMain } from './UserMain'
import { UserReferral } from './UserReferral'
import { UserBalance } from './UserBalance'
import { UserLegacy } from './UserLegacy'
import { UserPassport } from './UserPassport'
import { UserMore } from './UserMore'
import { UserAuthStats } from '../UserAuthStats'
import { getChatsFx } from '../../../../states/Chat/event'

export const UserDashboard = () => {
  const { id } = useParams()

  const methods = useForm<User>()
  const {
    getValues,
    setValue,
    reset,
  } = methods

  const isPending = useStore($isPending)
  const user = useStore($user)

  const [chatId, setChatId] = useState(0)

  useEffect(() => {
    if (id) {
      getUserFx({ id: Number(id) })
      getChatsFx({
        page: '1',
        limit: '100',
        search: id,
      }).then(({ data: { data: { items } } }) => {
        const chat = items.find(({ ownerUser }) => String(ownerUser?.accountId) === String(id))
        if (chat?.id) {
          setChatId(chat.id)
        }
      })
    }
    return reset
  }, [id])

  useEffect(() => {
    reset()
    Object.keys(user).forEach((key) => {
      const value = (user as Record<string, any>)[key]
      setValue(key as keyof User, value)
    })
  }, [user])

  const handlerUpdate = async () => {
    const values = getValues()
    patchUserFx({
      id: values.id,
      isRestricted: values.isRestricted,
      phone: values.phone,
      includeInSearch: values.privacy.includeInSearch,
      isBlocked: values.isBlocked,
      restrictedDepth: values.restrictedDepth,
      email: values.socials.email || null,
      isWithdrawalEnabled: values.isWithdrawalEnabled,
      role: values.role,
    })
  }

  const handlerUserId = (fn: (obj: { id: number }) => void) => {
    if (user.id) {
      fn({ id: user.id })
    }
  }

  return (
    <RootLayout loading={isPending} empty={!user.id}>
      <FormProvider {...methods}>
        <div className="controller">
          <div className="header">
            <RoleGuard role={Role.ADMIN}>
              <Button color="info" variant="contained" onClick={() => handlerUserId(imposterFx)}>
                Залогиниться
              </Button>

              <ButtonWithConfirm
                color="secondary"
                onClick={() => handlerUserId(disallowLoginFx)}
                content="Вы действительно хотите сбросить номер пользователя?"
              >
                Сбросить телефон
              </ButtonWithConfirm>

              <ButtonWithConfirm
                color="error"
                variant="contained"
                content="Вы действительно хотите удалить пользователя?"
                onClick={() => handlerUserId(deleteUserFx)}
                style={{ display: 'none' }}
              >
                Удалить
              </ButtonWithConfirm>
            </RoleGuard>

            <ButtonWithConfirm
              onClick={() => handlerUserId(mustReloignFx)}
              content="Вы действительно хотите закончить все сессии пользователя?"
            >
              Разлогинить
            </ButtonWithConfirm>

            <ModalWithTrigger
              trigger={<Button color="info">Авторизации</Button>}
            >
              <UserAuthStats userId={user.id as number} />
            </ModalWithTrigger>
          </div>

          <div className="header">
            <Button href={`/${Routes.TRANSACTION}#userId=${user.id}`}>
              Транзакции
            </Button>
            <Button href={`/${Routes.INSTALLMENT}#userId=${user.id}`}>
              Рассрочки
            </Button>
            <Button href={`/${Routes.VERIFICATION}#userId=${user.id}`}>
              Верификации
            </Button>
            <Button href={`${user.id}/${Routes.HEIR}`}>
              Наследники
            </Button>
            {!!chatId &&
            <Button href={`/${Routes.CHAT}/${chatId}`}>
              Чат
            </Button>
            }
          </div>

          <TabPanel
            data={[
              { label: 'Основные', component: <UserMain /> },
              { label: 'Баланс', component: <UserBalance /> },
              { label: 'Паспорт', component: <UserPassport /> },
              { label: 'Реферралка', component: <UserReferral /> },
              { label: 'Больше', component: <UserMore /> },
              { label: 'Легаси', component: <UserLegacy /> },
            ]}
          />

          <RoleGuard role={Role.ADMIN}>
            <Button onClick={handlerUpdate}>
              Сохранить
            </Button>
          </RoleGuard>
        </div>
      </FormProvider>
    </RootLayout>
  )
}
