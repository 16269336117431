import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Fields, RootLayout } from '../../../../UI'
import { Project } from '../../../../../services/api/Project'
import { useStore } from 'effector-react'
import { $defaults } from '../../../../../states/Defaults/store'

export const About = () => {
  const defaults = useStore($defaults)
  const { watch, setValue } = useFormContext()

  const values = watch() as Project

  return (
    <RootLayout>
      <Fields
        isDebounce
        value={values?.sections?.about}
        onChange={(value) => {
          Object.entries(value).forEach(([key, value]) => {
            setValue(`sections.about.${key}`, value)
          })
        }}
        fields={[
          { type: 'content', name: 'videoUrl', label: 'Сылка на видео', mode: 'text' },
          { type: 'content', name: 'content', label: 'Описание. Стили для Заголовка - class="title"' },
          { type: 'content', name: 'presentationUrl', label: 'Ссылка для "Подробнее"', mode: 'text' },
          { type: 'file', name: 'awards', label: 'Награды', multiple: true, accept: 'img' },
          {
            type: 'fields',
            label: 'Патенты',
            name: 'patents',
            fields: [
              { type: 'string', name: 'code', label: 'Страна, код' },
              { type: 'select', name: 'status', label: 'Статус', options: defaults.patents?.statuses },
              { type: 'file', name: 'url', label: 'Фото', accept: 'img' },
              { type: 'file', name: 'pdf', label: 'PDF', accept: 'pdf' },
            ],
          },
        ]}
      />
    </RootLayout>
  )
}
