import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useStore } from 'effector-react'

import { Button, ButtonWithConfirm, Input, RootLayout } from '../../../UI'
import {
  $aliasTranslations,
  $isPendingGetAliasTranslations,
  $isPendingPatchAliasTranslations,
  $isPendingPostAliasTranslations,
  $isPendingDeleteAliasTranslations,
} from '../../../../states/Translation/store'
import { $languages } from '../../../../states/Language/store'
import {
  deleteAliasTranslationsFx,
  getAliasTranslationsFx,
  patchAliasTranslationsFx,
} from '../../../../states/Translation/event'
import { getLanguagesFx } from '../../../../states/Language/event'

interface FormInputs {
  alias: string
  [x: string]: string
}

export const TranslationEdit = () => {
  const { id } = useParams() as { id: string }

  const translations = useStore($aliasTranslations)
  const languages = useStore($languages)

  const isPendingGet = useStore($isPendingGetAliasTranslations)
  const isPendingPatch = useStore($isPendingPatchAliasTranslations)
  const isPendingPost = useStore($isPendingPostAliasTranslations)
  const isPendingDelete = useStore($isPendingDeleteAliasTranslations)

  const {
    getValues,
    setValue,
    watch,
  } = useForm<FormInputs>()
  const { alias } = watch()

  useEffect(() => {
    getLanguagesFx({})
    getAliasTranslationsFx({ id })
  }, [])

  useEffect(() => {
    if (Array.isArray(translations) && translations.length) {
      setValue('alias', translations[0]?.alias)

      translations.forEach((item: any) => {
        setValue(item.language.code, item?.text)
      })
    }
  }, [translations])

  const handleSave = async () => {
    const values = getValues()
    const translations = languages.map((item) => ({
      code: item.code,
      text: values[item.code],
    })).filter(({ text }) => text)
    const newAlias = { alias: values.alias, translations }

    patchAliasTranslationsFx({ id, ...newAlias })
  }

  const handleDelete = async () => {
    deleteAliasTranslationsFx({ id })
  }

  return (
    <RootLayout loading={isPendingGet} empty={!Object.values(translations).length}>
      <Typography variant="h5">
        Редактирование перевода
      </Typography>

      <ButtonWithConfirm
        color="error"
        variant="contained"
        onClick={handleDelete}
        loading={isPendingDelete}
        content="Вы действительно хотите удалить перевод?"
      >
        Удалить
      </ButtonWithConfirm>

      <Input
        value={alias}
        onChange={(e) => setValue('alias', e.target.value)}
        label="Алиас"
        InputLabelProps={{ shrink: !!alias }}
      />

      {languages.map(({ code, language, displayName }) => {
        const value = watch(code)
        return (
          <Input
            key={id}
            label={`${language} (${displayName})`}
            value={value}
            onChange={(e) => setValue(code, e.target.value)}
            InputLabelProps={{ shrink: !!value }}
          />
        )
      })}

      <Button color="primary" variant="contained" onClick={handleSave} loading={isPendingPatch || isPendingPost}>
        Сохранить
      </Button>
    </RootLayout>
  )
}
