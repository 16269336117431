import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'

import { Page } from '../../../shared/UI'
import { GetNewsReq, NewsAPI } from '../../../services/api/News'
import { getContent } from '../../../shared/utils'

const defaultQuery = {
  limit: '15',
  page: '1',
  language: 'ru',
}

export const Root = () => {
  const [query, setQuery] = useState<GetNewsReq>(defaultQuery)
  const get = useQuery([query, 'news-get'], () => NewsAPI.getNews(query))

  return (
    <Page
      variant="root"
      title="Новости"
      onChange={(values) => setQuery(values as GetNewsReq)}
      rows={get.data?.data.data?.items || []}
      total={get.data?.data.data?.metadata.total}
      col={[
        { id: 'id', label: 'ID' },
        { id: 'title', label: 'Заголовок', render: (title) => getContent(title)?.slice(0, 15) },
        { id: 'publishedAt', label: 'Время', render: (date, raw) => dayjs(date || raw.createdAt).format('HH:mm DD.MM.YYYY') },
        { id: 'isActive', label: 'Опубликована', render: (isActive) => isActive ? 'Да' : 'Нет' },
        { id: 'isSharePriceChanged', label: 'Повлияло на цену', render: (isActive) => isActive ? 'Да' : 'Нет' },
        { id: 'viewsCount', label: 'Просмотры' },
      ]}
      defaultFilter={defaultQuery}
      filter={[
        {
          defaultExpanded: true,
          label: 'Фильтры',
          fields: [
            { name: 'search', type: 'string', label: 'Поиск' },
            { name: 'projectIds', type: 'string', label: 'Проекты', disabled: true },
            { name: 'isInfluencedShares', type: 'string', label: 'Влияние на цену', disabled: true },
          ],
        },
      ]}
    />
  )
}
