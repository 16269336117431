import React from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import CancelIcon from '@mui/icons-material/Cancel'

import { Button, ButtonWithConfirm, RootLayout, RootTable } from '../../shared/UI'
import { CreatePipelinesReq, LandingApi, Pipeline } from '../../services/api/Landing'

const statusRun = ['pending', 'running']

const LandingController = () => {
  const pipelines = useQuery(
    ['LandingApi.getPipelines'],
    LandingApi.getPipelines,
    { refetchInterval: 1000 * 60 * 10 },
  )
  const cancel = useMutation(LandingApi.cancelPipeline)
  const create = useMutation(LandingApi.createPipelines)

  const isRunPipeline = !!pipelines?.data?.find(({ status }) => {
    return statusRun.find((s) => s === status)
  })

  const isDisableStart = isRunPipeline || create.isLoading

  const handlerCancel = async (id: number) => {
    await cancel.mutate({ id })
    setTimeout(() => pipelines.refetch(), 500)
  }

  const handlerProject = async (project: CreatePipelinesReq['project']) => {
    await create.mutate({ project })
    setTimeout(() => pipelines.refetch(), 2000)
  }

  return (
    <RootLayout>

      <div className="header">
        <ButtonWithConfirm
          onClick={() => handlerProject('enter')}
          disabled={isDisableStart}
          content={'Билд будет идти орентировочка 30 минут. Проверетве позже, как все прошло'}
        >
          Билд
        </ButtonWithConfirm>
      </div>

      <RootTable
        rows={pipelines.data?.slice(0, 10)}
        title="Последние запуски"
        col={[
          { id: 'iid', label: 'id' },
          { id: 'status', label: 'Статус' },
          { id: 'ref', label: 'Ветка' },
          { id: 'created_at', label: 'Время', render: (date) => dayjs(date).format('HH:mm DD.MM.YYYY') },
          { id: 'web_url', label: 'Ссылка', render: (url) => <a href={url} target="_blank" rel="noreferrer">GitLab</a> },
          {
            id: 'i',
            label: '',
            render: (_, raw: Pipeline) => {
              const { status } = raw
              if (statusRun.find((s) => s === status)) {
                return (
                  <Button
                    color="error"
                    onClick={() => handlerCancel(raw.id)}
                    disabled={cancel.isLoading}
                  >
                    <CancelIcon />
                  </Button>
                )
              }
              return null
            },
          },
        ]}
      />
    </RootLayout>
  )
}

export default LandingController
