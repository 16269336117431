import React from 'react'
import { Grid, Paper as PaperMui, Stack, styled, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useStore } from 'effector-react'

import { useMoneyBox } from '../../../services/api/MoneyBox'
import { Page } from '../../../shared/UI/Page'
import { Role, Routes } from '../../../shared/enum'
import { Button, ButtonWithConfirm, RoleGuard } from '../../../shared/UI'
import { getOptionsValue, toDollar } from '../../../shared/utils'
import { $defaults } from '../../../states/Defaults/store'
import { NavLink } from 'react-router-dom'

const Paper = styled(PaperMui)({
  padding: '12px',
})

export const Root = () => {
  const defaults = useStore($defaults)

  const { active, boxes, quests, boxId } = useMoneyBox()

  const info = [
    { title: 'Сумма', value: `${toDollar(active.get.data?.balance)} $` },
    { title: 'Статус', value: getOptionsValue(defaults.moneyboxes?.statuses, active.get.data?.status) },
    { title: 'Начало', value: dayjs(active.get.data?.createdAt).format('L LT') },
    { title: 'Распределиние', value: dayjs(active.get.data?.deadlineRelease).format('L LT') },
    { title: 'Дедлайн задач', value: dayjs(active.get.data?.deadlineQuestDone).format('L LT') },
    { title: 'ID', value: active.get.data?.id },
  ]

  return (
    <Page
      variant="custom"
      actions={[
        { title: 'Задачи', to: Routes.TASK, type: 'link' },
        { title: 'Настройки', to: Routes.SETTINGS, type: 'link' },
      ]}
      loading={[active.get.loading]}
      onInit={active.get.fetch}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Paper elevation={2}>
            <Stack gap={1}>
              <Typography variant="h5">Активная копилка</Typography>
              {info.map(({ title, value }) =>
                <Typography variant="h6" key={title}>
                  {title}: <b>{value}</b>
                </Typography>,
              )}
              <RoleGuard role={Role.ADMIN}>
                <Stack direction="row" gap={2}>
                  <Button
                    fullWidth
                    onClick={active.post.fetch}
                    disabled={!!active.get.data?.id}
                  >
                    Старт
                  </Button>
                  <ButtonWithConfirm
                    fullWidth
                    onClick={() => boxId.postRelease.fetch({ id: Number(active.get.data?.id) })}
                  >
                    Распределить
                  </ButtonWithConfirm>
                </Stack>
              </RoleGuard>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Page
            variant="root"
            title="Задачи на проверки для текущей копилки"
            onChange={quests.get.fetch}
            defaultFilter={{
              moneyboxId: String(active.get.data?.id),
              page: '1',
              limit: '5',
              status: 'in_progress_to_verify',
            }}
            col={[
              { id: 'id', label: 'ID', render: (id) => <NavLink to={`/${Routes.MONEYBOX}/${Routes.TASK}/${id}`}>{id}</NavLink> },
              { id: 'userId', label: 'Юзер ID', render: (id) => <NavLink to={`/${Routes.USER}/${id}`}>{id}</NavLink> },
              { id: 'status', label: 'Статус', render: (key) => getOptionsValue(defaults.moneyboxes?.questStatuses, key) },
              { id: 'updatedAt', label: 'Обновлено', render: (date) => dayjs(date).format('L LT') },
            ]}
            total={quests.get.data?.metadata.total}
            rows={quests.get.data?.items}
          />
        </Grid>
        <Grid item xs={12}>
          <Page
            variant="root"
            title="Все копилки"
            onChange={boxes.get.fetch}
            col={[
              { id: 'id', label: 'ID', render: (id) => id },
              { id: 'balance', label: 'Сумма', render: (price) => `${toDollar(price)} $` },
              { id: 'status', label: 'Статус', render: (key) => getOptionsValue(defaults.moneyboxes?.statuses, key) },
              { id: 'createdAt', label: 'Начало', render: (date) => dayjs(date).format('L LT') },
              { id: 'deadlineRelease', label: 'Дедлайн релиза', render: (date) => dayjs(date).format('L LT') },
              { id: 'deadlineQuestDone', label: 'Дедлайн задач', render: (date) => dayjs(date).format('L LT') },
              { id: 'closedQuestsAt', label: 'Задачи закрыты', render: (date) => date && dayjs(date).format('L LT') },
              { id: 'releasedAt', label: 'Релиз', render: (date) => date && dayjs(date).format('L LT') },
              {
                id: 'id',
                label: '',
                render: (id, { releasedAt }) => (
                  <RoleGuard role={Role.ADMIN}>
                    <ButtonWithConfirm
                      size="small"
                      onClick={() => boxId.postRelease.fetch({ id })}
                      disabled={!!releasedAt}
                    >
                      Распределить
                    </ButtonWithConfirm>
                  </RoleGuard>
                ),
              },
              {
                id: 'id',
                label: '',
                render: (id, { winnersIds }) => (
                  <NavLink to={`${Routes.WINNERS}#id=${id}`}>
                    Победители, {winnersIds.length}
                  </NavLink>
                ),
              },
              {
                id: 'id',
                label: '',
                render: (id, { participantsIds }) => (
                  <NavLink to={`${Routes.PARTICIPANTS}#id=${id}`}>
                    Участники, {participantsIds.length}
                  </NavLink>
                ),
              },
              {
                id: 'id',
                label: '',
                render: (id) => (
                  <NavLink to={`${Routes.TRANSACTION}#id=${id}`}>
                    Транзакции
                  </NavLink>
                ),
              },
              {
                id: 'id',
                label: '',
                render: (id) => (
                  <NavLink to={`${Routes.BALANCE}#id=${id}`}>
                    Баланс
                  </NavLink>
                ),
              },
            ]}
            total={boxes.get.data?.metadata.total}
            rows={boxes.get.data?.items}
          />
        </Grid>
      </Grid>
    </Page>
  )
}
