import React from 'react'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useStore } from 'effector-react'

import { Button, Input, RootLayout } from '../../../UI'
import { createProjectFx } from '../../../../states/Project/event'
import { $projectLoading } from '../../../../states/Project/store'

export const ProjectCreate: React.FC = () => {
  const projectLoading = useStore($projectLoading)

  const method = useForm({
    defaultValues: {
      allShares: 1,
      allSharesForSelling: 1,
      name: '',
      symbol: '',
    },
  })
  const {
    getValues,
    watch,
    setValue,
  } = method
  const {
    name,
    symbol,
  } = watch()

  const handlerCreate = async () => {
    const values = getValues()
    createProjectFx(values)
  }

  return (
    <RootLayout>
      <Typography variant="h5">Создание проекта</Typography>

      <Input
        name="name"
        label="Название"
        variant="standard"
        value={name}
        onChange={(e) => setValue('name', e.target.value)}
      />

      <Input
        name="symbol"
        label="Аббревиатура"
        variant="standard"
        value={symbol}
        onChange={(e) => setValue('symbol', e.target.value)}
      />

      <Button
        color="primary"
        variant="contained"
        onClick={handlerCreate}
        disabled={!symbol || !name}
        loading={projectLoading}
      >
        Создать
      </Button>
    </RootLayout>
  )
}
