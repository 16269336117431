import AxiosInstance from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'
import { querystring } from '../../shared/utils'
import { useQuery } from '@tanstack/react-query'
import { WithMetadata } from './_types'
import { useEffect, useState } from 'react'

export type GetHistoryRes = WithMetadata<{
  id: number
  createdAt: string
  userId: number
  authType: number
  city: string
  country: string
  ip: string
  timezone: string
  latitude: string
  longitude: string
  isAllowedToLogin: boolean
  userAgent: string
}>
const getHistory = (data: Record<string, string>) => {
  return promiseToResult(
    AxiosInstance().get<GetHistoryRes>(
      `/application/admin/users/auth-history?${querystring.stringify(data)}`,
    ),
  )
}

export const AuthHistoryAPI = {
  getHistory,
}

export const useAuthHistory = () => {
  const [query, setQuery] = useState({})

  const get = useQuery(
    ['AuthHistoryAPI.getHistory', query],
    () => AuthHistoryAPI.getHistory(query),
    { enabled: false },
  )

  useEffect(() => {
    if (Object.entries(query).length >= 2) {
      get.refetch()
    }
  }, [query])

  return {
    get,
    handlerQuery: setQuery,
  }
}
