import React from 'react'

export interface PageCustomProps {
  children: React.ReactNode
  variant: 'custom'
}

export const PageCustom: React.FC<PageCustomProps> = ({
  children,
}) => {
  return <>{children}</>
}
