import React from 'react'
import { getFilesFx } from '../../states/Files/event'
import { Col, RootLayout, RootTable } from '../../shared/UI'
import { useStore } from 'effector-react'
import { $files, $filesLoading } from '../../states/Files/store'
import { usePagination } from '../../shared/hooks'

const col: Col[] = [
  { id: 'id', label: 'id' },
  { id: 'name', label: 'Название' },
  {
    id: 'url',
    label: 'Сылка',
    render: (value) => <a href={String(value)} target="_blank" rel="noreferrer">Сылка</a>,
  },
]

export const FilesController = () => {
  const files = useStore($files)
  const filesLoading = useStore($filesLoading)

  const { pagination, handlerPagination, handlerQuery } = usePagination({
    defaults: { limit: '20', page: '1' },
    observerQuery: getFilesFx,
  })

  return (
    <RootLayout loading={filesLoading}>

      <RootTable
        rows={files?.items || []}
        col={col}
        title="Файлы"
        limitOptions={[10, 20, 30]}
        handleChangePage={(page) => handlerPagination('page', page)}
        handleChangeLimit={(limit) => handlerPagination('limit', limit)}
        handleOrderDirection={(limit) => handlerQuery('orderDirection', limit)}
        length={files?.metadata?.total || 0}
        page={pagination.page}
        limit={pagination.limit}
      />
    </RootLayout>
  )
}
