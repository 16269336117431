import { createEffect } from 'effector'
import { toast } from 'react-toastify'

import { APIResult, AxiosError } from '../../core/api/Result'
import { DeleteUserReq, GetUserReq, GetUsersReq, ImposterReq, TransactionsCorrectionReq, UserAPI } from '../../services/api/User'
import { environment } from '../../../environment'
import { openUrl } from '../../shared/utils'

export const getUsersFx = createEffect<GetUsersReq, APIResult<typeof UserAPI['getUsers']>, AxiosError>(
  UserAPI.getUsers,
)

export const getUserFx = createEffect<GetUserReq, APIResult<typeof UserAPI['getUser']>, AxiosError>(
  UserAPI.getUser,
)

export const deleteUserFx = createEffect<DeleteUserReq, APIResult<typeof UserAPI['deleteUser']>, AxiosError>(
  UserAPI.deleteUser,
)

export const patchUserFx = createEffect(UserAPI.patchUser)

export const imposterFx = createEffect<ImposterReq, APIResult<typeof UserAPI['imposter']>, AxiosError>(
  UserAPI.imposter,
)

export const disallowLoginFx = createEffect(UserAPI.disallowLogin)
export const mustReloignFx = createEffect(UserAPI.mustReloign)
export const getHeirFx = createEffect(UserAPI.getHeir)
export const postHeirFx = createEffect(UserAPI.postHeir)

export const transactionsCorrectionFx = createEffect<TransactionsCorrectionReq, APIResult<typeof UserAPI['transactionsCorrection']>, AxiosError>(
  UserAPI.transactionsCorrection,
)

mustReloignFx.done.watch(() => {
  toast.success('Юзер разлогинен')
})
disallowLoginFx.done.watch(({ params }) => {
  getUserFx(params)
  toast.success('Телефон сброшен')
})
imposterFx.done.watch(({ result }) => {
  openUrl(`${environment.CABINET_PREFIX}/-/imposter/${result.data.data.token}`)
})

patchUserFx.done.watch(() => {
  toast.success('Данные обновлены')
})
patchUserFx.fail.watch(() => {
  toast.error('Ошибка')
})

transactionsCorrectionFx.done.watch(({ params }) => {
  toast.success('Баланс успешно изменен')
  getUserFx({ id: params.userId })
})
transactionsCorrectionFx.fail.watch(() => {
  toast.error('Произошла ошибка при изменение баланса')
})
