import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useStore } from 'effector-react'
import { useParams } from 'react-router-dom'

import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps'

import { Button, ButtonWithConfirm, EditorContent, Input, InputFile, RootLayout } from '../../../shared/UI'
import { deleteOfficeFx, getOfficeIdFx, patchOfficeFx } from '../../../states/Office/event'
import { useResize } from '../../../shared/hooks'
import { Office } from '../../../services/api/Office'
import { $isPendingOfficeId, $officeId } from '../../../states/Office/store'

export const Id = () => {
  const officeId = useStore($officeId)
  const isPendingOfficeId = useStore($isPendingOfficeId)

  const { id } = useParams()
  const { isMobile } = useResize()

  const form = useForm<Office>()
  const {
    setValue,
    watch,
    getValues,
  } = form

  const point = [
    watch('geomark.lat') || 0,
    watch('geomark.lon') || 0,
  ]

  const instanceRef = useCallback((ref: any) => {
    if (!ref) {
      return
    }
    ref?.events.add('click', (e: any) => {
      const coords = e.get('coords') as number[]
      setValue('geomark.lat', coords[0])
      setValue('geomark.lon', coords[1])
    })
  }, [])

  const handlerUpdate = () => {
    patchOfficeFx(getValues())
  }

  const handlerDelete = () => {
    deleteOfficeFx({ id: getValues('id') })
  }

  useEffect(() => {
    Object.entries(officeId).map(([key, value]) => {
      setValue(key as keyof Office, value)
    })
  }, [officeId?.id])

  useEffect(() => {
    if (id) {
      getOfficeIdFx({ id: Number(id) })
    }
  }, [id])

  return (
    <RootLayout loading={isPendingOfficeId} empty={!officeId?.id}>
      <div className="header">
        <ButtonWithConfirm
          color="error"
          variant="contained"
          onClick={handlerDelete}
          content="Вы действительно хотите удалить офис?"
        >
          Удалить
        </ButtonWithConfirm>
      </div>

      <Input
        label="Id"
        value={watch('id')}
        disabled
      />

      <Input
        label="Название (для админа)"
        value={watch('name')}
        onChange={(e) => setValue('name', e.target.value)}
      />

      <EditorContent
        label="Заголовок"
        value={watch('title')?.content}
        onChange={(content) => setValue('title', { content })}
      />

      <EditorContent
        label="Описание"
        value={watch('description')?.content}
        onChange={(content) => setValue('description', { content })}
      />

      <Input
        label="Аддресс"
        value={watch('geomark.address')}
        onChange={(e) => setValue('geomark.address', e.target.value)}
      />

      <InputFile
        label="Фото"
        value={watch('icon')}
        onFile={(url) => setValue('icon', url)}
      />

      <YMaps>
        <Map
          defaultState={{
            center: point,
            zoom: 10,
          }}
          width={'100%'}
          height={isMobile ? 200 : 500}
          instanceRef={instanceRef}
        >
          <Placemark
            geometry={point}
          />
        </Map>
      </YMaps>

      <Button onClick={handlerUpdate}>
        Сохранить
      </Button>
    </RootLayout>
  )
}
