import React from 'react'
import { useRoutes } from 'react-router-dom'

import { Routes } from '../../shared/enum'
import { Category, Create, Edit, Root } from './pages'
import { Page } from '../../shared/UI/Page'

export const FaqController = () => {
  return useRoutes([
    {
      path: `${Routes.ROOT}/*`,
      element: <Page
        variant="outlet"
        links={[
          { title: 'FAQ', to: Routes.ROOT },
          { title: 'Создать', to: Routes.CREATE },
          { title: 'Категории', to: Routes.CATEGORY },
        ]}
      />,
      children: [
        { path: Routes.ROOT, element: <Root /> },
        { path: Routes.CREATE, element: <Create /> },
        { path: Routes.ID, element: <Edit /> },
        { path: `${Routes.CATEGORY}/*`, element: <Category /> },
      ],
    },
  ])
}
