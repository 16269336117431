import { createStore } from 'effector'
import { GetFilesIdRes, GetFilesRes } from '../../services/api/Files'
import { getFilesFx, getFilesIdFx, postFilesFx } from './event'

export const $files = createStore<Partial<GetFilesRes>>({})
  .on(getFilesFx.done, (_, { result }) => result.data.data)
export const $filesLoading = createStore<boolean>(false)
  .on(getFilesFx.pending, (_, pending) => pending)

export const $filesId = createStore<Partial<GetFilesIdRes>>({})
  .on(getFilesIdFx.done, (_, { result }) => result.data.data)
export const $filesIdLoading = createStore<boolean>(false)
  .on(getFilesIdFx.pending, (_, pending) => pending)

export const $filesPost = createStore<Partial<GetFilesIdRes>>({})
  .on(postFilesFx.done, (_, { result }) => result.data.data)
export const $filesPostLoading = createStore<boolean>(false)
  .on(postFilesFx.pending, (_, pending) => pending)
