import React from 'react'
import { Autocomplete, CircularProgress } from '@mui/material'

import { Input, InputProps } from '../Input'

interface IRootAutocomplete extends Omit<InputProps, 'onChange'> {
  isLoading?: boolean,
  options?: any[],
  values?: Record<string, string | string[]>
  trigger?: string,
  onChange?: (value: string, trigger: string) => void
}

let timeout: any

export const InputAutocomplete: React.FC<IRootAutocomplete> = ({
  isLoading,
  options = [],
  values = {},
  trigger = '',
  onChange = () => null,
  ...props
}) => {
  const [open, setOpen] = React.useState<boolean>(false)
  const [value, setValue] = React.useState<string>(String(values[trigger] || ''))

  const onInputChange = (
    _: React.SyntheticEvent,
    value: string,
  ) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    setValue(value)

    timeout = setTimeout(() => {
      onChange(value, trigger)
    }, 600)
  }

  return (
    <Autocomplete
      id={trigger}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      freeSolo
      disableClearable
      inputValue={value}
      onInputChange={onInputChange}
      isOptionEqualToValue={(option: any, value: any) => option[trigger] === value[trigger]}
      getOptionLabel={(option: any) => option[trigger].toString()}
      options={options.filter((item) => item[trigger])}
      loading={isLoading}
      noOptionsText="Не найдено"
      loadingText="Загрузка"
      renderInput={(params) => (
        <Input
          {...params}
          {...props}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
