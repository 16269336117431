import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Button, Input, ModalWithTrigger, RootLayout, Switch } from '../../../../UI'
import { User } from '../../../../../services/api/User'
import { NavLink } from 'react-router-dom'
import { Routes } from '../../../../enum'
import { Tree } from './Tree'

export const UserReferral = () => {
  const { watch, setValue } = useFormContext<User>()
  const { privacy, isRestricted, parent, id, restrictedDepth } = watch()

  return (
    <RootLayout>
      <div className="header">
        <Switch
          label="Получение реф. призов по всей ветке"
          checked={isRestricted}
          onChange={(_, value) => setValue('isRestricted', value)}
        />

        <Switch
          label="Показывать юзера в списке поиска"
          checked={privacy?.includeInSearch}
          onChange={(_, value) => setValue('privacy.includeInSearch', value)}
        />
        <ModalWithTrigger
          trigger={<Button>Реф. дерево</Button>}
        >
          <Tree userId={id} />
        </ModalWithTrigger>

      </div>

      <div className="controller">
        <Input
          disabled
          label="ID Родителя"
          value={parent?.id}
          InputProps={{
            endAdornment: (!!parent?.id && (
            <NavLink to={`/${Routes.USER}/${parent?.id}`}>
              <Button>
                Go
              </Button>
            </NavLink>
            )),
          }}
        />
        <Input
          label="Глубина начисления реф. призов"
          value={restrictedDepth}
          onChange={(e) => setValue('restrictedDepth', Number(e.target.value))}
        />
      </div>
    </RootLayout>
  )
}
