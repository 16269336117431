import { createEvent } from 'effector'
import { getDefaultsFx } from '../Defaults/event'
import { getMyFx } from '../My/event'
import { getProjectsFx } from '../Project/event'
import { getPaymentsFx } from '../Payment/event'

export const setAuthTokenFx = createEvent<string>()

setAuthTokenFx.watch((token) => {
  if (token) {
    getMyFx()
    getDefaultsFx(null)
    getProjectsFx({ query: { limit: 1000, page: 1 } })
    getPaymentsFx({ query: { limit: 1000, page: 1 } })
  }
})
