export function isRichValue(value: any) {
  return Boolean(value && Array.isArray(value.richText))
}

export function richToString(rich: any) {
  return rich.richText.map(({ text = '' }) => text).join('')
}

export function richToHtml(rich: any) {
  let str = rich.richText.map(({ font = {} as any, text = '' }) => {
    return [
      font.bold ? '<strong>' : '',
      font.italic ? '<em>' : '',
      text,
      font.italic ? '</em>' : '',
      font.bold ? '</strong>' : '',
    ].join('')
  }).join('')

  // simple tag combining where possible
  return str.replace(/<\/strong><strong>/g, '').replace(/<\/em><em>/g, '')
}
