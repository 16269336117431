import React from 'react'

import { Page } from '../../shared/UI'
import { Routes } from '../../shared/enum'

export const TransactionController = () => {
  return <Page
    variant="outlet"
    links={[
      { to: `/${Routes.TRANSACTION}`, title: 'Главная' },
      { to: `/${Routes.TRANSACTION}/${Routes.WITHDRAWAL}`, title: 'Выводы' },
    ]}
  />
}
