export const MAIN_HEADER_REQUEST = {
  'X-Requested-With': 'XMLHttpRequest',
  'Cache-Control': 'no-cache',
}

export const ENC_METHODS: Record<string, string> = Object.freeze({
  put: 'put',
  post: 'post',
  PUT: 'PUT',
  POST: 'POST',
})
