import { createStore } from 'effector'
import { setInitFx, setThemeModeFx } from './event'
import connectLocalStorage from 'effector-localstorage'
import { getMyFx } from '../My/event'

export const $isInit = createStore(false)
  .on(setInitFx.done, () => true)
  .on(setInitFx.fail, () => true)
  .on(getMyFx.pending, (_, pending) => !pending)

const themeModeLocalStorage = connectLocalStorage('wtr/admin::theme-mode')
export const $themeMode = createStore<'dark' | 'light'>(themeModeLocalStorage.init(''))
  .on(setThemeModeFx, (_, mode) => mode)
$themeMode.watch(themeModeLocalStorage)
