import { createStore } from 'effector'

import { GetOfficeIdRes, GetOfficeRes } from '../../services/api/Office'

import { getOfficeFx, getOfficeIdFx } from './event'

export const $office = createStore<GetOfficeRes>([])
  .on(getOfficeFx.done, (_, { result }) => result.data.data)
export const $isPendingOffice = createStore(false)
  .on(getOfficeFx.pending, (_, pending) => pending)

export const $officeId = createStore<Partial<GetOfficeIdRes>>({})
  .on(getOfficeIdFx.done, (_, { result }) => result.data.data)
export const $isPendingOfficeId = createStore(false)
  .on(getOfficeIdFx.pending, (_, pending) => pending)
