import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Page } from '../../../shared/UI'

import { PatchFaqReq, useFaq, useFaqCategory } from '../../../services/api/Faq'
import { Routes } from '../../../shared/enum'

export const Edit = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { options: optionsCategory } = useFaqCategory()
  const { getId, patch, del } = useFaq(id)

  const handlerUpdate = async (data: PatchFaqReq) => {
    await patch.mutateAsync(data)
    getId.refetch()
    toast.success('Сохранено')
  }

  const handlerDelete = async () => {
    if (!id) { return }
    await del.mutateAsync({ id })
    toast.success('Удалено')
    navigate(`/${Routes.FAQ}`)
  }

  return (
    <Page
      button="Сохранить"
      title="Редактирование faq"
      variant="form"
      loading={[getId.isLoading, patch.isLoading, del.isLoading]}
      onSubmit={handlerUpdate}
      value={getId.data?.data.data}
      actions={[
        { title: 'Удалить', type: 'confirm', onClick: handlerDelete, color: 'error' },
      ]}
      fields={[
        { name: 'id', type: 'number', label: 'ID', disabled: true },
        { name: 'symbol', type: 'string', label: 'Уникальный ключ. Работает как ID' },
        { name: 'isActive', type: 'switch', label: 'Активна' },
        { name: 'name', type: 'string', label: 'Название (для админа)' },
        { name: 'categoryId', type: 'select', label: 'Категория', options: optionsCategory },
        { name: 'title.content', type: 'content', label: 'Заголовок', mode: 'text' },
        { name: 'shortDescription.content', type: 'content', label: 'Краткое описание', mode: 'text' },
        { name: 'description.content', type: 'content', label: 'Описание' },
        { name: 'tags.content', type: 'content', label: 'Теги (Мета для поиска по схожим словам)', mode: 'text' },
        { name: 'metadata.isHelp', type: 'switch', label: 'Активна кнопка "Написать в сапорт"' },
        { name: 'metadata.onlyFull', type: 'switch', label: 'Только полное описание' },
      ]}
    />
  )
}
