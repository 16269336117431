import React from 'react'
import { useStore } from 'effector-react'
import dayjs from 'dayjs'

import { $defaults } from '../../../../../states/Defaults/store'
import { useMoneyBox } from '../../../../../services/api/MoneyBox'
import { Page } from '../../../../../shared/UI'
import { getOptionsValue } from '../../../../../shared/utils'
import { Routes } from '../../../../../shared/enum'
import { NavLink } from 'react-router-dom'

export const Root = () => {
  const defaults = useStore($defaults)

  const { quests } = useMoneyBox()

  return <Page
    variant="root"
    title="Задачи юзеров"
    rows={quests.get.data?.items}
    total={quests.get.data?.metadata.total}
    actions={[
      { title: 'Копилка', to: `/${Routes.MONEYBOX}`, type: 'link' },
      { title: 'Выдать', to: `${Routes.USER}`, type: 'link' },
    ]}
    onChange={quests.get.fetch}
    filter={[
      {
        label: 'Фильтры',
        defaultExpanded: true,
        fields: [
          { name: 'id', type: 'number', label: 'ID' },
          { name: 'userId', type: 'number', label: 'Юзер ID' },
          { name: 'entryId', type: 'number', label: 'Шаблон ID' },
          { name: 'moneyboxId', type: 'number', label: 'Копилка ID' },
          { name: 'status', type: 'select', label: 'Статус', isEmpty: true, options: defaults.moneyboxes.questStatuses },
        ],
      },
    ]}
    col={[
      { id: 'id', label: 'ID' },
      { id: 'userId', label: 'Юзер ID', render: (id) => <NavLink to={`/${Routes.USER}/${id}`}>{id}</NavLink> },
      { id: 'moneyboxId', label: 'Копилка ID' },
      { id: 'questEntryId', label: 'Щаблон ID' },
      { id: 'questType', label: 'Тип', render: (key) => getOptionsValue(defaults.moneyboxes.questTypes, key) },
      { id: 'answerType', label: 'Формат ответа', render: (key) => getOptionsValue(defaults.moneyboxes.questAnswersTypes, key) },
      { id: 'status', label: 'Статус', render: (key) => getOptionsValue(defaults.moneyboxes.questStatuses, key) },
      { id: 'completedAt', label: 'Выполнено', render: (date) => date && dayjs(date).format('L LT') },
      { id: 'cancelledAt', label: 'Отменено', render: (date) => date && dayjs(date).format('L LT') },
      { id: 'createdAt', label: 'Создано', render: (date) => dayjs(date).format('L LT') },
      { id: 'updatedAt', label: 'Обновлено', render: (date) => dayjs(date).format('L LT') },
    ]}
  />
}
