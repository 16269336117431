import React from 'react'
import { useRoutes } from 'react-router-dom'

import { PaymentCountry, Referral } from './pages'

import { Routes } from '../../shared/enum'

const AnalyticController = () => {
  return useRoutes([
    {
      path: Routes.PAYMENT,
      element: <PaymentCountry />,
    },
    {
      path: Routes.USER,
      element: <Referral />,
    },
  ])
}

export default AnalyticController
