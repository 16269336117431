import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

import { ButtonProps, Button } from './index'

interface IProps {
  defaultOpen?: boolean
  title?: React.ReactNode
  content?: React.ReactNode
}

export type ButtonPropsWithConfirm = ButtonProps & IProps

const Transition = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) => {
  return (
    <Slide direction="up" ref={ref} {...props} />
  )
})

export const ButtonWithConfirm: React.FC<ButtonPropsWithConfirm> = ({
  defaultOpen = false,
  title = 'Подтвердите ваши действие',
  content = '',
  onClick = () => null,
  ...props
}) => {
  const [open, setOpen] = useState(defaultOpen)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = (e: any) => {
    handleClose()
    onClick(e)
  }

  return (
    <>
      <Button
        {...props}
        onClick={handleClickOpen}
      />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="button-with-confirm"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="button-with-confirm">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm} color="error">Продолжить</Button>
          <Button onClick={handleClose} color="success">Отмена</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
