/* eslint-disable camelcase */
import axios from 'axios'
import dayjs from 'dayjs'
import promiseToResult from '../../core/api/promiseToResults'
import { environment } from '../../../environment'

const GIT_TOKEN = 'glpat-psszsJtVxFxAbqRbfgq5'

axios.defaults.headers.common['PRIVATE-TOKEN'] = GIT_TOKEN
axios.interceptors.response.use((response) => response.data)

const ref = environment.PRODUCTION ? 'master' : 'develop'
const repId = 13
const host = 'git.tiagl.in'

export interface Pipeline {
  created_at: string
  id: number
  iid: number
  project_id: number
  ref: 'develop' | 'master'
  sha: string
  source: 'web'
  status: 'created' | 'waiting_for_resource' | 'preparing' | 'pending' | 'running' | 'success' | 'failed' | 'canceled' | 'skipped' | 'manual' | 'scheduled'
  updated_at: string
  web_url: string
}

export type GetPipelinesRes = Pipeline[]
const getPipelines = () => {
  const date = dayjs().add(-2, 'd')
  return axios.get<unknown, GetPipelinesRes>(`https://${host}/api/v4/projects/${repId}/pipelines?updated_after=${date.toISOString()}&ref=${ref}`)
}

export interface CancelPipelineReq {
  id: number
}
export type CancelPipelineRes = Pipeline[]
const cancelPipeline = ({ id }: CancelPipelineReq) => {
  return axios.post<CancelPipelineReq, CancelPipelineRes>(`https://${host}/api/v4/projects/${repId}/pipelines/${id}/cancel`)
}

export interface CreatePipelinesReq {
  project: 'enter' | 'weter' | 'gorod'
}
const createPipelines = (data: CreatePipelinesReq) => {
  const body = {
    ref,
    variables: [
      {
        key: 'ENV_LANDING',
        value: data.project,
      },
    ],
  }
  return promiseToResult(
    axios.post(
      `https://${host}/api/v4/projects/${repId}/pipeline?ref=${ref}`,
      body,
    ),
  )
}

export const LandingApi = {
  getPipelines,
  cancelPipeline,
  createPipelines,
}
