import { APIDone, APIError } from './Result'
import { toast } from 'react-toastify'

const promiseToResult = <T> (promise: Promise<T>): Promise<APIDone<T>> => {
  return promise
    .then((v: any) => {
      if (v.status !== 200) {
        throw v
      }

      return v
    })
    .catch((error) => {
      const xId = error.config.headers['x-trace-id']
      const { url } = error.config
      const { errors }: APIError = error.data

      toast.error(`Ошибка запроса ${url}. X-ID: ${xId}`)
      toast.error(error.data.message)

      Object.entries(errors).map(([key, value]) => {
        toast.error(`${key}: ${value.messages[0]}`)
      })

      throw error
    })
}

export default promiseToResult
