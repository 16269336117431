import AxiosInstance from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'
import { querystring } from '../../shared/utils'

import { Query, WithMetadata } from './_types'

export interface Candidate {
  id: number
  userId: number
  phone: string
  hash: string
  invitedUserId: number
  telegramChatId: string
  whatsAppChatId: string
  lang: string
  createdAt: string
  updatedAt: string
}

export type GetCandidatesReq = Query
export type GetCandidatesRes = WithMetadata<Candidate[]>
const getCandidates = (data: GetCandidatesReq) => {
  const { query } = data
  return promiseToResult(
    AxiosInstance().get<GetCandidatesRes>(
      `/application/admin/candidates?${querystring.stringify(query)}`,
    ),
  )
}

export interface PutApproveReq { id: number | string }
const putApprove = (data: PutApproveReq) => {
  return promiseToResult(
    AxiosInstance().put<GetCandidatesRes>(
      `/application/admin/candidates/${data.id}/approve`,
    ),
  )
}

export const CandidateAPI = {
  getCandidates,
  putApprove,
}
