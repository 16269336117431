export enum TransactionType {
  SINGLE_PAYMENT = 'single_payment',
  INSTALLMENT_PAYMENT = 'installment_payment',
  INSTALLMENT_PAID = 'installment_paid',
  INSTALLMENT_OPEN = 'installment_open', // Его на беке нет, используется на определения компонент
  EXTERNAL_ADD_BALANCE = 'balance',
  TRANSFER_BALANCE = 'transfer_balance',
  WITHDRAW = 'withdrawal',
  CORRECTION = 'correction',
  REFERRAL_PRIZE = 'referral_prize',
  RECEIVED_TRANSFER = 'received_transfer',
  GIFT_SHARES = 'gift_shares',
  GIFT_BALANCE = 'gift_balance',
}
