import React from 'react'
import { Box, Grid, Slider } from '@mui/material'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { Project } from '../../../../../services/api/Project'
import { Button, ButtonWithConfirm, Input, ModalWithTrigger, EditorContent, RootLayout, Switch } from '../../../../UI'
import { ArrowDownward, ArrowUpward, DeleteForever, FormatListBulleted } from '@mui/icons-material'
import { isNumber } from '../../../../utils'

const name = 'sections.development.steps'

interface ProgressProps {
  name: string
  step: number
}

const Progress: React.FC<ProgressProps> = ({
  name,
  step,
}) => {
  const { watch, setValue, control } = useFormContext()
  const { fields, append, remove, move } = useFieldArray({
    control,
    name,
  })

  const values = watch() as Project

  return (
    <ModalWithTrigger
      trigger={
        <Button
          color="secondary"
          variant="contained"
          sx={{ mt: 'auto' }}
        >
          <FormatListBulleted />
        </Button>
      }
    >
      {fields.map((field, idx) =>
        <Box key={field.id} sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', padding: '15px', marginBottom: '20px' }}>
          <EditorContent
            value={values?.sections?.development?.steps[step]?.progresses[idx].content}
            onChange={(value) => setValue(`${name}.${idx}.content`, value)}
            mode="text"
          />
          <Grid container spacing={2} alignItems="center" sx={{ mt: '10px' }}>
            <Grid item xs>
              <Slider
                value={values?.sections?.development?.steps[step]?.progresses[idx].progress}
                onChange={(_, value) => setValue(`${name}.${idx}.progress`, value)}
              />
            </Grid>
            <Grid item>
              <Input
                value={values?.sections?.development?.steps[step]?.progresses[idx].progress}
                onChange={(event) => setValue(`${name}.${idx}.progress`, Number(event.target.value))}
                size="small"
                inputProps={{
                  step: 10,
                  min: 0,
                  max: 100,
                  type: 'number',
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => move(idx, idx - 1)}
                disabled={!idx}
              >
                <ArrowUpward />
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => move(idx, idx + 1)}
                disabled={fields.length - 1 === idx}
              >
                <ArrowDownward />
              </Button>
            </Grid>
            <Grid item>
              <ButtonWithConfirm
                color="error"
                variant="contained"
                onClick={() => remove(idx)}
                content="Вы действительно хотите удалить блок?"
              >
                <DeleteForever />
              </ButtonWithConfirm>
            </Grid>
          </Grid>
        </Box>,
      )}

      <Button
        fullWidth
        color="success"
        variant="contained"
        onClick={() => append({
          progress: 0,
          content: [],
        })}
      >
        Добавить
      </Button>
    </ModalWithTrigger>
  )
}

export const Development = () => {
  const { watch, setValue, control } = useFormContext()
  const { fields, append, remove, move } = useFieldArray({
    control,
    name,
  })

  const values = watch() as Project

  return (
    <RootLayout>
      {fields.map((field, idx) => (
        <Box key={field.id} sx={{ display: 'flex', mb: '40px', borderBottom: '1px solid rgba(0, 0, 0, 0.12)', py: '10px' }}>
          <Box sx={{ width: '100%', mr: '10px' }}>
            <Switch
              label="Активен"
              checked={values?.sections?.development?.steps[idx]?.isActive}
              onChange={(_, value) => setValue(`${name}.${idx}.isActive`, value)}
            />
            <Input
              fullWidth
              label="Цена, прогноз"
              value={values?.sections?.development?.steps[idx]?.price}
              onChange={(e) => isNumber(e.target.value) && setValue(`${name}.${idx}.price`, Number(e.target.value))}
            />
            <EditorContent
              label="Этап"
              value={values?.sections?.development?.steps[idx]?.title}
              onChange={(value) => setValue(`${name}.${idx}.title`, value)}
              mode="text"
            />
            <EditorContent
              label="Контент. class='title' class='description'"
              value={values?.sections?.development?.steps[idx]?.content}
              onChange={(value) => setValue(`${name}.${idx}.content`, value)}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <Button
              variant="contained"
              onClick={() => move(idx, idx - 1)}
              disabled={!idx}
            >
              <ArrowUpward />
            </Button>
            <Button
              variant="contained"
              onClick={() => move(idx, idx + 1)}
              disabled={fields.length - 1 === idx}
            >
              <ArrowDownward />
            </Button>
            <ButtonWithConfirm
              color="error"
              variant="contained"
              onClick={() => remove(idx)}
              content="Вы действительно хотите удалить блок?"
            >
              <DeleteForever />
            </ButtonWithConfirm>

            <Progress
              name={`${name}.${idx}.progresses`}
              step={idx}
            />

          </Box>
        </Box>
      ))}

      <Button
        color="success"
        variant="contained"
        onClick={() => append({
          title: '',
          content: [],
          progresses: [],
        })}
      >
        Добавить
      </Button>
    </RootLayout>
  )
}
