import React from 'react'
import { useStore } from 'effector-react'

import { $transaction, $transactionLoading } from '../../../../states/Transaction/store'
import { Page } from '../../../UI'
import { $defaults } from '../../../../states/Defaults/store'
import { Role, Routes, TransactionStatus, TransactionType } from '../../../enum'
import {
  getTransactionFx,
  postTransactionCancelFx,
  postTransactionCompleteFx,
  postTransactionRecalculateFx,
} from '../../../../states/Transaction/event'
import { $paymentsOptions } from '../../../../states/Payment/store'
import { $projectsOptions } from '../../../../states/Project/store'

export const TransactionId = () => {
  const transaction = useStore($transaction)
  const defaults = useStore($defaults)
  const paymentsOptions = useStore($paymentsOptions)
  const projectsOptions = useStore($projectsOptions)
  const transactionLoading = useStore($transactionLoading)

  const handlerCancel = () => {
    postTransactionCancelFx({ id: transaction.id as number })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handlerComplete = () => {
    postTransactionCompleteFx({ id: transaction.id as number })
  }

  const handlerRecalculate = () => {
    postTransactionRecalculateFx({ id: transaction.id as number })
  }

  return <Page
    title="Транзакция"
    variant="form"
    button={'Бесполезная кнопка'}
    loading={[transactionLoading]}
    value={transaction}
    onInit={getTransactionFx}
    actions={[
      { type: 'link', to: `/${Routes.USER}/${transaction.user}`, title: 'К юзеру' },
      {
        type: 'confirm',
        title: 'Отменить',
        onClick: handlerCancel,
        disabled: transaction.status !== TransactionStatus.IN_PROGRESS,
        role: Role.ADMIN,
      },
      // {
      //   type: 'confirm',
      //   title: 'Подтвердить',
      //   onClick: handlerComplete,
      //   disabled: transaction.status !== TransactionStatus.IN_PROGRESS,
      //   role: Role.ADMIN,
      // },
      {
        type: 'confirm',
        title: 'Пересчитать реф.',
        onClick: handlerRecalculate,
        disabled: transaction.status !== TransactionStatus.COMPLETED
          || ![TransactionType.INSTALLMENT_PAYMENT, TransactionType.SINGLE_PAYMENT]
            .includes(transaction.type as TransactionType),
        role: Role.SUPPORT,
      },
    ]}
    fields={[
      { name: 'id', type: 'number', label: 'ID', disabled: true },
      { name: 'type', type: 'select', label: 'Тип', options: defaults?.transactions?.types, disabled: true },
      { name: 'status', type: 'select', label: 'Статус', options: defaults?.transactions?.statuses, disabled: true },
      { name: 'price', type: 'cent', label: 'Сумма', disabled: true },
      { name: 'payingTransactionId', type: 'number', label: 'Транзкция ID которая оплачивает эту транзакцию', disabled: true, hide: ({ payingTransactionId }) => !payingTransactionId },
      { name: 'metadata.redirectUrl', type: 'string', label: 'Сылка оплаты', disabled: true, hide: ({ type }) => type !== TransactionType.EXTERNAL_ADD_BALANCE },
      { name: 'metadata.discount', type: 'cent', label: 'Скидка', disabled: true, hide: ({ type, metadata }) => type !== TransactionType.EXTERNAL_ADD_BALANCE || !metadata?.discount },
      { name: 'metadata.paymentWithDiscount', type: 'cent', label: 'К оплате с учетом скидки', disabled: true, hide: ({ type }) => type !== TransactionType.EXTERNAL_ADD_BALANCE },
      { name: 'metadata.referralTransactionId', type: 'number', label: 'Транзакциия ID от которой начислена рефералка', disabled: true, hide: ({ type }) => type !== TransactionType.REFERRAL_PRIZE },
      { name: 'share', type: 'number', label: 'Доли', disabled: true, hide: ({ share }) => !share },
      { name: 'installment', type: 'number', label: 'Расрочка ID', disabled: true, hide: ({ installment }) => !installment },
      { name: 'project.id', type: 'select', label: 'Проект', options: projectsOptions, disabled: true, hide: ({ project }) => !project?.id },
      { name: 'paymentSystem.id', type: 'select', label: 'Платежная система', options: paymentsOptions, disabled: true, hide: ({ paymentSystem }) => !paymentSystem?.id },
      { name: 'createdAt', type: 'date', label: 'Создана', disabled: true },
      { name: 'updatedAt', type: 'date', label: 'Изменена', disabled: true },
      { name: 'comment', type: 'string', label: 'Комментарий', disabled: true },
      { name: 'metadata', type: 'json', label: 'Метадата', disabled: true },
      { name: 'linkUser', type: 'json', label: 'Связанный юзер', disabled: true, hide: ({ linkUser }) => !linkUser },
      { name: 'nftId', type: 'number', label: 'NFT ID', disabled: true, hide: ({ nftId }) => !nftId },
      { name: 'moneyboxId', type: 'number', label: 'Копилка ID', disabled: true, hide: ({ moneyboxId }) => !moneyboxId },
    ]}
  />
}
