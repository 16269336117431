import React from 'react'
import { Page } from '../../../shared/UI'
import { useStory } from '../../../services/api/Story'
import { useStore } from 'effector-react'
import { $defaults } from '../../../states/Defaults/store'
import { Routes } from '../../../shared/enum'

export const Id = () => {
  const defaults = useStore($defaults)
  const { id } = useStory()

  return <Page
    variant="form"
    title="Редактирование сторис"
    button="Сохранить"
    actions={[
      { type: 'link', to: `/${Routes.STORY}`, title: 'Сторис' },
      // { type: 'confirm', color: 'error', title: 'Удалить', onClick: () => id.delete.fetch({ id: id.get.data?.id }) },
    ]}
    loading={[id.get.loading, id.patch.loading, id.delete.loading]}
    onInit={id.get.fetch}
    onSubmit={id.patch.fetch}
    value={id.get.data}
    fields={[
      { name: 'isActive', type: 'switch', label: 'Активно' },
      { name: 'adminName', type: 'string', label: 'Название. Для админа' },
      { name: 'likes', type: 'number', label: 'Лайки', disabled: true },
      { name: 'views', type: 'number', label: 'Просмотры', disabled: true },
      { name: 'url', type: 'content', mode: 'image', label: 'Превью 1:1' },
      {
        name: 'steps',
        label: 'Слайды',
        type: 'fields',
        fields: [
          { name: 'url', type: 'content', mode: 'image', label: 'Слайд 9:16' },
          { name: 'type', type: 'select', label: 'Тип слайда', options: defaults.stories.stepTypes },
          { name: 'duration', type: 'number', label: 'Время для слайда, милисекунды (опицонально)' },
          { name: 'header.heading', type: 'content', mode: 'text', label: 'Шапка(опционально). Заголовок' },
          { name: 'header.subheading', type: 'content', mode: 'text', label: 'Шапка. Описание' },
          { name: 'header.profileImage', type: 'file', label: 'Шапка. Фото' },
          { name: 'more.body', type: 'content', label: 'Больше(опционально). Слайд с описанием (без сылки)' },
          { name: 'more.url', type: 'content', mode: 'text', label: 'Больше(опционально). Сылка(без описанием)' },
        ],
      },
      { name: 'createdAt', label: 'Создана', type: 'date', disabled: true },
    ]}
  />
}
