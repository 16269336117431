import React, { useEffect } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useStore } from 'effector-react'

import styles from './RecoveryEdit.module.scss'

import { Accordion, Button, DatePicker, Input, ModalWithTrigger, RootLayout, Select, RoleGuard } from '../../../UI'
import { $defaults } from '../../../../states/Defaults/store'
import { $isPendingRecovery, $recovery } from '../../../../states/Recovery/store'
import { getRecoveryFx, updateRecoveryFx } from '../../../../states/Recovery/event'

import { Role, Routes, VerificationStatus } from '../../../enum'
import { getVerificationsFx } from '../../../../states/Verification/event'
import { $verifications } from '../../../../states/Verification/store'

const RecoveryEdit: React.FC = () => {
  const defaults = useStore($defaults)
  const recovery = useStore($recovery)
  const verifications = useStore($verifications)
  const isPendingRecovery = useStore($isPendingRecovery)

  const { id } = useParams()
  const navigate = useNavigate()

  const method = useForm({
    defaultValues: {
      status: '',
      comment: '',
      id: 0,
    },
  })
  const {
    getValues,
    watch,
    setValue,
  } = method

  const values = watch()

  const isPending = recovery.status === 'pending'

  useEffect(() => {
    setValue('id', recovery.id || 0)
    setValue('status', recovery.status || '')
    setValue('comment', recovery.comment || '')
  }, [recovery])

  useEffect(() => {
    if (id) {
      getRecoveryFx({ id })
    }
  }, [id])

  useEffect(() => {
    if (recovery.user?.id) {
      getVerificationsFx({ query: { userId: recovery.user?.id, limit: 10, page: 1 } })
    }
  }, [recovery?.user?.id])

  const handlerUpdate = async () => {
    let { status, comment, id } = getValues()
    if (status === VerificationStatus.REJECTED) {
      comment = `Password recovery rejected. ${comment}`
    }
    updateRecoveryFx({ status, comment, id })
  }

  const handlerInput = (name: string, value: string | any[]) => {
    setValue(name as any, value)
  }

  return (
    <RootLayout loading={isPendingRecovery} empty={!recovery.id}>
      <div className="header">
        <Typography variant="h5" sx={{ marginBottom: '15px' }}>
          Верификация пользователя
        </Typography>
        <NavLink to={`/${Routes.USER}/${recovery.user?.id}`}>
          <Button>
            К юзеру: ID {recovery.user?.id}
          </Button>
        </NavLink>
      </div>

      <div className={styles.wrapper}>

        <div>
          <RoleGuard role={Role.ADMIN}>
            <Accordion label="Фотография" defaultExpanded>
              <ModalWithTrigger trigger={<img src={recovery.photo} />}>
                <img src={recovery.photo} />
              </ModalWithTrigger>
            </Accordion>
          </RoleGuard>
        </div>

        <div className={styles.inputs}>
          <Select
            label="Сравните это фото с фото из верификации"
            options={verifications?.items?.map(({ id, status }) => ({
              key: id,
              value: `ID: ${id} - ${defaults?.verifications?.types
                .find(({ key }: Record<string, string>) => key === status)?.value}`,
            }))}
            onSelect={(id) => navigate(`/${Routes.VERIFICATION}/${id}`)}
          />
          <Input
            disabled
            label="ID"
            variant="standard"
            value={recovery.id}
            className={styles.input}
          />

          <Input
            disabled
            name="name"
            label="Имя"
            variant="standard"
            value={recovery.passport?.name}
            className={styles.input}
          />

          <Input
            disabled
            name="surname"
            label="Фамилия"
            variant="standard"
            value={recovery.passport?.surname}
            className={styles.input}
          />

          <DatePicker
            disabled
            label="Дата рождения"
            value={recovery.passport?.birthDate}
            variant="standard"
            className={styles.input}
          />

          <Input
            disabled={!isPending}
            name="comment"
            label="Комментарий"
            variant="standard"
            value={values.comment}
            onChange={(e) => handlerInput('comment', e.target.value)}
            className={styles.input}
          />

          <Select
            disabled={!isPending}
            variant="standard"
            name="status"
            label="Статус"
            options={defaults?.verifications?.types}
            value={values?.status}
            onSelect={(value) => handlerInput('status', value)}
          />

          <RoleGuard role={Role.ADMIN}>
            <div className={styles.buttons}>
              <Button
                disabled={!isPending}
                color="primary"
                variant="contained"
                onClick={handlerUpdate}
              >
                Сохранить
              </Button>
            </div>
          </RoleGuard>

        </div>
      </div>
    </RootLayout>
  )
}

export default RecoveryEdit
