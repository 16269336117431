import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'

import { Routes } from '../../shared/enum'

import { Button } from '../../shared/UI'

const UserController = () => {
  return (
    <div>
      <div className="header">
        <NavLink to={`/${Routes.USER}`}>
          <Button>
            Главная
          </Button>
        </NavLink>
      </div>

      <Outlet />
    </div>
  )
}

export default UserController
