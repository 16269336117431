/* eslint-disable @typescript-eslint/no-unused-vars,@typescript-eslint/no-use-before-define */
import React from 'react'
import { UserAPI } from '../../../../../services/api/User'
import { useQuery } from '@tanstack/react-query'
import { Accordion, Button, Col, Input, ModalWithTrigger, Page } from '../../../../UI'
import { getUserName } from '../../../../utils'
import { CircularProgress } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { Routes } from '../../../../enum'
import dayjs from 'dayjs'

export interface TreeProps {
  userId: number
}

const col: Col[] = [
  { id: 'id', label: 'ID', render: (id) => <NavLink to={`/${Routes.USER}/${id}`}>{id}</NavLink> },
  { id: 'phone', label: 'Номер', render: (phone) => phone },
  { id: 'login', label: 'Логин', render: (login) => login },
  { id: 'passport', label: 'Имя', render: (passport = {}) => passport?.name },
  { id: 'passport', label: 'Фамилия', render: (passport = {}) => passport?.surname },
  { id: 'createdAt', label: 'Дата реги', render: (date) => dayjs(date).format('L') },
  {
    id: 'referrals',
    label: 'Рефералов',
    render: (referrals, row) => (
      <>
        {!!referrals &&
        <ModalWithTrigger trigger={<Button size="small" variant="text">{referrals}</Button>}>
          <Tree userId={row?.id} />
        </ModalWithTrigger>
        }
      </>
    ),
  },
]

export const Tree: React.FC<TreeProps> = ({
  userId,
}) => {
  const user = useQuery(['getUser', userId], () => UserAPI.getUser({ id: userId }))
  const referrals = useQuery(['getUserReferrals', userId], () => UserAPI.getUserReferrals({ id: userId }))
  const referralsData = referrals.data?.data.data.items?.sort((a, b) => (b?.referrals || 0) - (a?.referrals || 0)) || []

  if (user.isLoading || referrals.isLoading) {
    return <CircularProgress />
  }
  if (!user.data || !referrals.data) {
    return <div>нет данных</div>
  }
  return (
    <div>
      <Accordion
        label={<>Родитель <b>{getUserName(user.data?.data?.data)}</b></>}
        className="controller"
        sx={{ mb: 3 }}
      >
        <Input
          disabled
          label="ID"
          value={userId.toString()}
          InputProps={{
            endAdornment: (
              <NavLink to={`/${Routes.USER}/${userId}`}>
                <Button>
                  Go
                </Button>
              </NavLink>
            ),
          }}
        />
      </Accordion>

      {!referrals.data.data.data.items.length &&
        <div>Нет рефералов</div>
      }
      <Page
        title={`Рефералы. Кол-во: ${referralsData.length}`}
        variant="root"
        col={col}
        rows={referralsData}
      />
    </div>
  )
}
