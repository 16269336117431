import AxiosInstance, { AxiosInstanceType } from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'
import { querystring } from '../../shared/utils'
import { WithMetadata } from './_types'

export interface Files {
  id: number
  name: string
  path: string
  url: string
  contentType: string
  size: number
}

export interface PostFilesReq {
  filename?: string
  file: File
}
export type PostFilesRes = Files
const postFiles = (data: PostFilesReq) => {
  const body = new FormData()
  body.append('file', data.file)
  body.append('filename', data.filename || data.file.name)

  return promiseToResult(
    AxiosInstance(AxiosInstanceType.FORMDATA).post<PostFilesRes>(
      '/application/admin/files',
      body,
    ),
  )
}

export interface GetFilesReq {
  page: string
  limit: string
}
export type GetFilesRes = WithMetadata<Files[]>
const getFiles = (data: GetFilesReq) => {
  return promiseToResult(
    AxiosInstance().get<GetFilesRes>(
      `/application/admin/files?${querystring.stringify(data)}`,
    ),
  )
}

export interface GetFilesIdReq {
  id: number
}
export type GetFilesIdRes = Files
const getFilesId = (data: GetFilesIdReq) => {
  const { id } = data
  return promiseToResult(
    AxiosInstance().get<GetFilesIdRes>(
      `/application/admin/files/${id}`,
    ),
  )
}

export const FilesAPI = {
  postFiles,
  getFiles,
  getFilesId,
}
