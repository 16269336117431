import React, { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { BrowserRouter } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { createRoot } from 'react-dom/client'

import './index.scss'

import App from './App'
import { serviceWorker } from './serviceWorker'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CssBaseline, useMediaQuery } from '@mui/material'
import { useStore } from 'effector-react'
import { $themeMode } from './app/states/App/store'
import { setThemeModeFx } from './app/states/App/event'

Sentry.init({
  dsn: 'https://56fec598039148ea8d5c1dc1884749fa@o1073556.ingest.sentry.io/6073277',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
})

const container = document.getElementById('root')
const root = createRoot(container!)

const Init = () => {
  const themeMode = useStore($themeMode)
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  useEffect(() => {
    if (!themeMode) {
      setThemeModeFx(prefersDarkMode ? 'dark' : 'light')
    }
  }, [prefersDarkMode])

  const theme = React.useMemo(() => createTheme({
    palette: {
      mode: themeMode || 'light',
    },
  }), [themeMode])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  )
}

root.render(<Init />)

serviceWorker.register()
