/* eslint-disable no-restricted-syntax,no-await-in-loop,@typescript-eslint/no-unused-vars */
import React, { ChangeEventHandler, useEffect, useRef, useState } from 'react'
import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon,
  Delete as DeleteIcon,
  Upload,
} from '@mui/icons-material'

import { Input, InputProps } from './Input'
import { Button } from '../Button'
import { postFilesFx } from '../../../states/Files/event'
import { useStore } from 'effector-react'
import { $filesPostLoading } from '../../../states/Files/store'
import { noop } from 'chart.js/helpers'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import { Modal } from '../Modal'
import { Page } from '../Page'
import { useFieldArray, useForm } from 'react-hook-form'
import { IconButton, Slider, Typography } from '@mui/material'
import { humanFileSize, MyFile } from '../../utils'
import { toast } from 'react-toastify'

interface Base<T> {
  filename?: string
  onFile?: (url: T) => void
  multiple?: boolean
  value?: T
}

export type InputFileProps<T> = Base<T> & Omit<InputProps, 'onChange' | 'multiline' | 'value'> & {
  accept?: 'img' | 'pdf' | 'any'
}

export const InputFile = <T extends string | string[], >({
  filename,
  value,
  onFile = noop,
  multiple,
  accept,
  ...props
}: InputFileProps<T>) => {
  let acceptInput = ''
  if (accept === 'img') {
    acceptInput = 'image/*'
  }
  if (accept === 'pdf') {
    acceptInput = '.pdf'
  }

  const [open, setOpen] = useState(false)
  const filesPostLoading = useStore($filesPostLoading)
  const inputRef = useRef<HTMLInputElement>(null)
  const valueArr = (Array.isArray(value)
    ? value
    : value ? [value] : []) as string[]
  const valueString = Array.isArray(value)
    ? `Файлов: ${value.length}`
    : value

  const { control, getValues, register, watch, setValue } = useForm({
    defaultValues: {
      data: valueArr?.filter(Boolean).map((url) => ({ url })),
      quality: 30,
    },
  })
  const { fields, append, remove, move } = useFieldArray({ control, name: 'data' })
  const { quality } = watch()

  const handlerButton = () => {
    inputRef.current?.click()
  }

  const handlerUpload: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const { files } = e.target
    const quality = getValues('quality')

    if (!multiple) {
      fields.forEach((_, idx) => remove(idx))
    }
    for (const file of files || []) {
      const myFile = new MyFile(file)
      const { data } = await postFilesFx({
        filename: filename ? `${filename}_${myFile.getFilename()}` : myFile.getFilename(),
        file: await myFile.compress({ initialQuality: quality }),
      })
      append({ url: data.data.url })
      toast.success(`Загружено. Размер: ${humanFileSize(data.data.size)}`)

      if (!multiple) {
        onFile(data.data.url as T)
      }
    }
  }

  const handlerInput = (str: string) => {
    if (!multiple) {
      onFile(str as T)
    }
  }

  useEffect(() => {
    const data = getValues('data')

    valueArr.forEach((u: string) => {
      const isFind = data.filter(Boolean).find(({ url }) => url === u)
      if (!isFind) {
        append({ url: u })
      }
    })
  }, [JSON.stringify(valueArr)])

  useEffect(() => {
    const data = fields.map(({ url }) => url).filter(Boolean) as string[]
    if (multiple) {
      onFile(data as T)
    }
  }, [fields])

  return (
    <>
      <Input
        {...props}
        type="string"
        value={valueString}
        disabled={multiple || props.disabled}
        onChange={(e) => handlerInput(e.target.value)}
        InputProps={{
          endAdornment: (
            <>
              {multiple &&
              <Button onClick={() => setOpen(!open)} disabled={props.disabled}>
                <FormatListBulletedIcon />
              </Button>
              }
              <input
                accept={acceptInput}
                multiple={multiple}
                type="file"
                hidden
                ref={inputRef}
                onChange={handlerUpload}
              />
              <Button
                color="secondary"
                variant="contained"
                onClick={handlerButton}
                disabled={filesPostLoading || props.disabled}
                size="small"
              >
                <Upload />
              </Button>
            </>
          ),
        }}
      />

      <Modal
        open={open}
        onClose={() => setOpen(!open)}
      >
        <Page
          variant="root"
          title={(
            <>
              Файлы
              <IconButton
                color="secondary"
                onClick={handlerButton}
                disabled={filesPostLoading || props.disabled}
              >
                <Upload />
              </IconButton>

              <div>
                <Typography gutterBottom>Качество {quality}%</Typography>
                <Slider
                  {...register('quality')}
                  defaultValue={quality}
                  valueLabelDisplay="auto"
                  min={10}
                  max={100}
                  step={5}
                />
              </div>
            </>
          )}
          col={[
            { id: 'url', label: 'Сылка', render: (url) => <a href={url} target="_blank" rel="noreferrer">{url}</a> },
            {
              id: 'key',
              label: 'Действия',
              render: (index) => (
                <>
                  <div>
                    <Button size="small" color="error" onClick={() => remove(index)}>
                      <DeleteIcon />
                    </Button>
                    <Button
                      size="small"
                      disabled={!index}
                      onClick={() => move(index, index - 1)}
                    >
                      <ArrowUpwardIcon />
                    </Button>
                    <Button
                      size="small"
                      disabled={fields.length - 1 === index}
                      onClick={() => move(index, index - 1)}
                    >
                      <ArrowDownwardIcon />
                    </Button>
                  </div>
                </>
              ),
            },
          ]}
          rows={fields.map((el, idx) => ({ ...el, key: idx }))}
        />
      </Modal>
    </>
  )
}
