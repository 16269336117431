import React from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Page } from '../../../shared/UI'

import { PostFaqReq, useFaq, useFaqCategory } from '../../../services/api/Faq'
import { Routes } from '../../../shared/enum'

export const Create = () => {
  const { options: optionsCategory } = useFaqCategory()
  const { post } = useFaq()

  const navigate = useNavigate()

  const handlerCreate = async (data: PostFaqReq) => {
    const { data: { data: { id } } } = await post.mutateAsync(data)
    toast.success('Успешно')
    navigate(`/${Routes.FAQ}/${id}`)
  }

  return (
    <Page
      button="Создать"
      title="Создание faq"
      variant="form"
      loading={[post.isLoading]}
      onSubmit={handlerCreate}
      fields={[
        { name: 'isActive', type: 'switch', label: 'Активна' },
        { name: 'symbol', type: 'string', label: 'Уникальный ключ. Работает как ID' },
        { name: 'name', type: 'string', label: 'Название (для админа)' },
        { name: 'categoryId', type: 'select', label: 'Категория', options: optionsCategory },
        { name: 'title.content', type: 'content', label: 'Заголовок', mode: 'text' },
        { name: 'shortDescription.content', type: 'content', label: 'Краткое описание', mode: 'text' },
        { name: 'description.content', type: 'content', label: 'Описание' },
        { name: 'tags.content', type: 'content', label: 'Теги (Мета для поиска по схожим словам)', mode: 'text' },
        { name: 'metadata.isHelp', type: 'switch', label: 'Активна кнопка "Написать в сапорт"' },
        { name: 'metadata.isFull', type: 'switch', label: 'Только полное описание' },
      ]}
    />
  )
}
