import React from 'react'

import { Button } from '../../shared/UI'
import { NavLink, Outlet } from 'react-router-dom'
import { Routes } from '../../shared/enum'

export const VerificationController = () => {
  return (
    <div>
      <div className="header">
        <NavLink to={`/${Routes.VERIFICATION}`}>
          <Button>
            Главная
          </Button>
        </NavLink>
      </div>

      <Outlet />
    </div>
  )
}
