/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { useMoneyBox } from '../../../../../services/api/MoneyBox'
import { Routes } from '../../../../../shared/enum'
import { Page } from '../../../../../shared/UI'
import { getOptionsValue } from '../../../../../shared/utils'
import { useStore } from 'effector-react'
import { $defaults } from '../../../../../states/Defaults/store'

export const Root = () => {
  const defaults = useStore($defaults)

  const { questsEntries } = useMoneyBox()

  return (
    <Page
      variant="root"
      actions={[
        { title: 'Копилка', to: `/${Routes.MONEYBOX}`, type: 'link' },
        { title: 'Создать шаблон', to: Routes.CREATE, type: 'link' },
      ]}
      title="Шаблоны задач"
      onChange={questsEntries.get.fetch}
      rows={questsEntries.get.data?.items}
      col={[
        { id: 'id', label: 'ID' },
        { id: 'isActive', label: 'Активно' },
        { id: 'adminDescription', label: 'Описание' },
        { id: 'limit', label: 'Лимит' },
        { id: 'questType', label: 'Тип', render: (key) => getOptionsValue(defaults.moneyboxes.questTypes, key) },
        { id: 'answerType', label: 'Формат ответа', render: (key) => getOptionsValue(defaults.moneyboxes.questAnswersTypes, key) },
      ]}
    />
  )
}
