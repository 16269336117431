import React, { useEffect } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { Input, InputProps } from '../Input'
import {
  DatePicker as DatePickerMUI,
  DatePickerProps as DatePickerPropsMUI,
} from '@mui/x-date-pickers'

export interface DatePickerProps extends Omit<DatePickerPropsMUI<InputProps, Dayjs>, 'label' | 'onChange' | 'renderInput' | 'value'> {
  label: string,
  onSelect?: (date: Dayjs) => void
  variant?: 'outlined' | 'filled' | 'standard'
  value?: string | Dayjs
}

export const DatePicker: React.FC<DatePickerProps> = ({
  label,
  onSelect,
  variant = 'standard',
  value: valueProps,
  ...props
}) => {
  const [value, setValue] = React.useState<Dayjs | null>(valueProps ? dayjs(valueProps) : null)

  useEffect(() => {
    setValue(dayjs(valueProps ? dayjs(valueProps) : null))
  }, [valueProps])

  const onChange: any = (date: Dayjs): void => {
    if (date && date.isValid()) {
      setValue(date)
      if (typeof onSelect === 'function') {
        onSelect(date)
      }
    }
  }

  return (
    <DatePickerMUI
      {...props}
      label={label}
      onChange={onChange}
      value={value as any}
      inputFormat="DD.MM.YYYY"
      renderInput={(params) => <Input {...params} variant={variant} />}
    />
  )
}
