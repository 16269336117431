import React, { useEffect } from 'react'
import { useStore } from 'effector-react'
import dayjs from 'dayjs'
import { Stack } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { toast } from 'react-toastify'
import { NavLink, useNavigate, useParams } from 'react-router-dom'

import { $transaction, $withdrawalLikeWallet } from '../../../../states/Transaction/store'
import {
  Accordion,
  Button,
  ButtonWithConfirm,
  DatePicker,
  Input,
  Modal,
  ModalWithTrigger,
  Page,
  Select,
} from '../../../UI'
import { $defaults } from '../../../../states/Defaults/store'
import { Routes, TransactionStatus, TransactionType } from '../../../enum'
import {
  getTransactionFx,
  getTransactionsFx,
  postTransactionCancelFx,
  postTransactionCompleteFx,
} from '../../../../states/Transaction/event'
import { TransactionMetadataType } from '../../../../services/api/Transaction'
import { getUserFx } from '../../../../states/User/event'
import { $user } from '../../../../states/User/store'
import { UserAuthStats } from '../../User/UserAuthStats'
import { toDollar } from '../../../utils'
import { useForm } from 'react-hook-form'
import { useBinance } from '../../../../services/api/Binance'
import { environment } from '../../../../../environment'

export const WithdrawalEdit = () => {
  const methods = useForm({
    defaultValues: {
      password: '',
      open: false,
    },
  })
  const { watch, setValue } = methods
  const { password, open } = watch()

  const bin = useBinance()

  const transaction = useStore($transaction)
  const withdrawalLikeWallet = useStore($withdrawalLikeWallet)
  const user = useStore($user)
  const defaults = useStore($defaults)

  const navigate = useNavigate()
  const { id } = useParams()

  const disabledButton = transaction.status !== TransactionStatus.IN_PROGRESS
  const disabledAuto = disabledButton
  const disabledManual = disabledButton

  const typeCrypto = transaction.metadata?.type === TransactionMetadataType.CRYPTO
  const trxPrice = (Number(transaction.price) / 100) * -1

  const handlerCancel = () => {
    postTransactionCancelFx({ id: transaction.id as number })
  }

  const handlerConfirm = () => {
    postTransactionCompleteFx({ id: transaction.id as number })
  }

  const handlerCopy = () => {
    const id = `Транзакция: ${transaction.id}`
    const price = `Сумма: ${trxPrice} $`
    const user = `Юзер: ${transaction.user}`
    const data = `Дата: ${dayjs(transaction.updatedAt).format('HH:mm DD.MM.YYYY')}`
    let address = ''
    let type = ''

    if (transaction.metadata?.type === TransactionMetadataType.CRYPTO) {
      type = 'Тип: USDT trc20'
      address = `Кошелек: \n\n${transaction.metadata?.wallet}\n`
    }

    const text = [id, user, data, type, address, price].join('\n')

    navigator.clipboard.writeText(text)
    toast.info('Скопировано')
  }

  const handlerAutoWithdrawalCrypto = async () => {
    const { data } = await bin.withdraw.mutateAsync({
      password,
      address: transaction.metadata?.wallet as string,
      value: trxPrice,
    })

    if (data.status) {
      toast.success('Успешно отправлены USDT')
      setValue('open', false)
      handlerConfirm()
    } else {
      toast.error(`Ошибка: ${data.error}`)
    }
  }

  useEffect(() => {
    if (transaction.id && transaction.type !== TransactionType.WITHDRAW) {
      navigate(-1)
    }
    if (transaction.id) {
      getUserFx({ id: transaction.user as number })
    }
  }, [transaction.id])

  useEffect(() => {
    if (transaction.metadata?.wallet) {
      getTransactionsFx({
        query: {
          limit: 100000,
          page: 1,
          wallet: transaction.metadata?.wallet,
        },
      })
    }
  }, [transaction.metadata?.wallet])

  useEffect(() => {
    if (open) {
      bin.balance.refetch()
    }
  }, [open])

  useEffect(() => {
    id && getTransactionFx({ id: Number(id) })
  }, [id])

  return (
    <>
      <div className="header">
        <NavLink to={`/${Routes.USER}/${transaction.user}`}>
          <Button>
            К юзеру #{transaction.user}
          </Button>
        </NavLink>

        <ButtonWithConfirm
          disabled={transaction.status !== TransactionStatus.IN_PROGRESS}
          content="Отменить транзакцию?"
          color="error"
          onClick={handlerCancel}
        >
          Отменить
        </ButtonWithConfirm>

        <ModalWithTrigger
          trigger={<Button color="info">Авторизации</Button>}
        >
          <UserAuthStats userId={user.id as number} />
        </ModalWithTrigger>
      </div>

      <Accordion
        label={
          <span style={{ color: withdrawalLikeWallet.length > 1 ? 'red' : 'green' }}>
            Юзеры с такими кошельками: {withdrawalLikeWallet.length} шт
          </span>
        }
      >
        <Page
          variant="root"
          title="Выводы с таким кошельком"
          rows={withdrawalLikeWallet}
          col={[
            {
              id: 'userId',
              label: 'Юзер Id',
              render: (userId) => <NavLink to={`/${Routes.USER}/${userId}`}>{userId} {userId === transaction.user && '(текущий)'}</NavLink>,
            },
            { id: 'withdrawalCount', label: 'Кол-во выводов' },
            { id: 'priceCountCompleted', label: 'Уже выведено', render: (price) => `${toDollar(price)} $` },
            { id: 'priceCountPending', label: 'Ожидает вывода', render: (price) => `${toDollar(price)} $` },
          ]}
        />
      </Accordion>

      <Accordion label="Транзакция" className="filter">
        <Input
          disabled
          label="ID"
          value={transaction.id}
        />
        <Select
          disabled
          label="Статус"
          options={defaults?.transactions?.statuses}
          value={transaction.status}
        />
        <Input
          disabled
          label="Время создания"
          value={dayjs(transaction.createdAt).format('HH:mm:ss DD.MM.YYYY')}
        />
      </Accordion>

      <Accordion label="Юзер" className="filter">
        <Input
          disabled
          label="ID"
          value={user.id}
        />
        <Input
          disabled
          label="Телефон"
          value={user.phone}
        />
        <Input
          disabled
          label="Логин"
          value={user.login}
        />
        <Input
          disabled
          label="Почта"
          value={user.socials?.email}
        />
        <Input
          disabled
          label="Имя"
          value={user.passport?.name}
        />
        <Input
          disabled
          label="Фамилия"
          value={user.passport?.surname}
        />
        <DatePicker
          disabled
          label="Дата рождения"
          value={user.passport?.birthDate}
        />
        <Input
          disabled
          label="Баланс"
          value={`${Number(user.balance || 0) / 100} $`}
        />
      </Accordion>

      {typeCrypto &&
        <>
          <Input
            disabled
            label="Валюта"
            value={transaction.metadata?.currencyId}
          />
          <Input
            disabled
            label="Сеть"
            value={transaction.metadata?.network}
          />
          <Input
            disabled
            label="Кошелек"
            value={transaction.metadata?.wallet}
          />
        </>
      }
      <Input
        disabled
        label="Сумма $"
        value={trxPrice}
      />

      <Stack spacing={2} direction="row">
        <Button onClick={handlerCopy}>
          <ContentCopyIcon />
        </Button>

        {typeCrypto &&
        <Button
          fullWidth
          disabled={disabledAuto}
          onClick={() => setValue('open', true)}
          loading={!environment.PRODUCTION || open}
        >
          Автоматический вывод
        </Button>
        }

        <ButtonWithConfirm
          color="info"
          fullWidth
          content="Подтвердить вывод?"
          onClick={handlerConfirm}
          disabled={disabledManual}
        >
          Ручной вывод
        </ButtonWithConfirm>
      </Stack>

      {typeCrypto &&
      <Modal
        open={open}
        onClose={() => setValue('open', false)}
      >
        <Stack spacing={4}>
          <Input
            label="Binance USDT"
            value={bin.balance.data?.data?.balance}
            disabled
            error={trxPrice >= Number(bin.balance.data?.data?.balance)}
          />

          <Input
            label="Будет выведено USDT"
            value={trxPrice}
            disabled
          />

          <Input
            label="Пароль"
            value={password}
            name="password"
            onChange={(e) => setValue('password', e.target.value)}
          />

          <ButtonWithConfirm
            content="Вывести средства автоматический"
            disabled={!password || trxPrice >= Number(bin.balance.data?.data?.balance)}
            loading={bin.balance.isFetching || bin.withdraw.isLoading}
            onClick={handlerAutoWithdrawalCrypto}
          >
            Подтвердить
          </ButtonWithConfirm>
        </Stack>
      </Modal>
      }
    </>
  )
}
