import { combine, createStore } from 'effector'
import connectLocalStorage from 'effector-localstorage'
import {
  clearChatsFx,
  clearMessagesFx,
  getChatFx, getChatSettingsFx,
  getChatsFx,
  getMessagesFx,
  putChatReadFx,
  putChatUnreadFx,
  setChatControlFx,
  setMyFx,
  updateMessageFx,
} from './event'
import { Chat, GetChatRes, GetChatSettingsRes, GetMessagesRes, User } from '../../services/api/Chat'
import { createMessages, messageNotification } from '../../shared/utils'
import { PaginationMetadata } from '../../services/api/_types'
import dayjs from 'dayjs'
import { environment } from '../../../environment'

export const ChatControlInit = {
  notEmpty: true,
}
const chatControlLocalStorage = connectLocalStorage('chat-settings')
export const $chatControl = createStore(chatControlLocalStorage.init() || ChatControlInit)
  .on(setChatControlFx, (prev, next) => ({ ...prev, ...next }))
$chatControl.watch(chatControlLocalStorage)

export const $my = createStore<User | null>(null)
  .on(setMyFx, (_, my) => my)

export const $chatsMetadata = createStore<Partial<PaginationMetadata>>({})
  .on(getChatsFx.done, (_, { result }) => result.data.data.metadata)

export const $chatsMap = createStore<Map<number, Chat>>(new Map())
  .on(getChatsFx.done, (prev, { result }) => {
    const map = new Map(prev)
    result.data.data.items.forEach((chat) => map.set(chat.id, chat))
    return map
  })
  .on(updateMessageFx, (prev, [updMsg, event]) => {
    const current = new Map(prev)
    const my = $my.getState()
    const isMyMsg = my?.id === updMsg?.message?.user?.id
    const isUser = updMsg?.message?.user?.type === 'user'

    if (environment.PRODUCTION && event === 'send' && isUser) {
      messageNotification()
    }

    if (prev.has(updMsg.chatId)) {
      const chat = current.get(updMsg.chatId) as Chat
      chat.lastMessage = updMsg.message
      chat.unreadCount = !isMyMsg ? chat.unreadCount + 1 : 0
      current.set(updMsg.chatId, chat)
      return current
    }
    if (isUser) {
      const chat: Chat = {
        id: updMsg.chatId,
        createdAt: updMsg.message.createdAt,
        lastMessage: updMsg.message,
        ownerUser: updMsg.message.user,
        unreadCount: 1,
      }
      current.set(chat.id, chat)
      return current
    }
  })
  .on(putChatUnreadFx.done, (prev, { params }) => {
    const map = new Map(prev)
    const chatId = params.id

    if (map.has(chatId)) {
      const chat = map.get(chatId)
      if (chat) {
        chat.unreadCount++
        if (chat.lastMessage) {
          chat.lastMessage.firstReadAt = null
        }
        map.set(chatId, chat)
      }
    }

    return map
  })
  .on(putChatReadFx.done, (prev, { params }) => {
    const map = new Map(prev)
    const chatId = params.id

    if (map.has(chatId)) {
      const chat = map.get(chatId)
      if (chat) {
        chat.unreadCount = 0
        if (chat.lastMessage) {
          chat.lastMessage.firstReadAt = dayjs().toISOString()
        }
        map.set(chatId, chat)
      }
    }

    return map
  })
  .on(clearChatsFx, () => new Map())
export const $chatsLoading = createStore(false)
  .on(getChatsFx.pending, (_, pending) => pending)

export const $chats = combine(
  $chatsMap, (chats) => {
    return Array.from(chats.values())
      .sort((a, b) => dayjs(b?.lastMessage?.createdAt).diff(a?.lastMessage?.createdAt))
  },
)

export const $chat = createStore<Partial<GetChatRes>>({})
  .on(getChatFx.done, (_, { result }) => result.data.data)
  .on(putChatReadFx.done, (_, { params }) => { getChatFx({ id: params.id }) })
  .on(putChatUnreadFx.done, (_, { params }) => { getChatFx({ id: params.id }) })
  .on(clearMessagesFx, () => ({}))

export const $messagesMap = createStore<GetMessagesRes | null>(null)
  .on(clearMessagesFx, () => null)
  .on(getMessagesFx.done, (prev, { result }) => {
    result.data.data.items.reverse()
    const { data } = result.data
    if (!prev) {
      return data
    }
    return {
      ...data,
      items: createMessages([...data.items, ...prev.items]),
    }
  })
  .on(updateMessageFx, (prev, [data]) => {
    const chat = $chat.getState()
    if (data.chatId === chat.id) {
      return {
        items: createMessages([...(prev?.items || []), data.message]),
        metadata: {
          ...prev?.metadata,
          limit: Number(prev?.metadata?.limit || 0) + 1,
        },
      } as GetMessagesRes
    }
  })
export const $isPendingMessages = createStore(false)
  .on(getMessagesFx.pending, (_, pending) => pending)

export const $messages = combine(
  $messagesMap, $chat, (messagesMap, chat) => {
    return {
      ...messagesMap,
      items: messagesMap?.items.filter((m) => m.chatId === chat.id) || [],
    } as GetMessagesRes
  },
)

export const $chatSettings = createStore<GetChatSettingsRes>([{ key: 'bot', value: { botMessage: [] } }])
  .on(getChatSettingsFx.done, (_, { result }) => result.data.data)
