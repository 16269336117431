import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { NavLink } from 'react-router-dom'

import { UserAPI } from '../../../services/api/User'
import { Accordion } from '../../UI'
import { Routes } from '../../enum'

export interface AuthStatsProps {
  userId: number
}

export const UserAuthStats: React.FC<AuthStatsProps> = ({
  userId,
}) => {
  const get = useQuery([userId, 'getAuthStats'], () => UserAPI.getAuthStats({ userId }))

  return (
    <>
      <Accordion
        label="С одного браузера"
        className="filter"
      >
        <>
          {get.data?.data.data.byFingerprint.map((userId) => {
            return <NavLink to={`/${Routes.USER}/${userId}`} key={userId}>{userId}</NavLink>
          })}
        </>
      </Accordion>

      <Accordion
        label="С одного айпи"
        className="filter"
      >
        <>
          {get.data?.data.data.byIp.map((userId) => {
            return <NavLink to={`/${Routes.USER}/${userId}`} key={userId}>{userId}</NavLink>
          })}
        </>
      </Accordion>

      <Accordion
        label="Браузера + айпи"
        className="filter"
      >
        <>
          {get.data?.data.data.byExact.map((userId) => {
            return <NavLink to={`/${Routes.USER}/${userId}`} key={userId}>{userId}</NavLink>
          })}
        </>
      </Accordion>
    </>
  )
}
