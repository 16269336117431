import React from 'react'
import { NavLink, Outlet as OutletDom } from 'react-router-dom'
import { Button } from '../../../shared/UI/Button'
import { Routes } from '../../../shared/enum'

export const Outlet = () => {
  return (
    <div className="controller">
      <div className="header">
        <NavLink to={Routes.ROOT}>
          <Button>
            Главная
          </Button>
        </NavLink>

        <NavLink to={Routes.CREATE}>
          <Button color="secondary">
            Создать
          </Button>
        </NavLink>
      </div>

      <OutletDom />
    </div>
  )
}
