import React from 'react'
import { useRoutes } from 'react-router-dom'
import { Routes } from '../../shared/enum'
import { Root } from './Pages'

const CandidateController = () => {
  return useRoutes([
    { path: Routes.ROOT, element: <Root /> },
  ])
}

export default CandidateController
