import React, { forwardRef } from 'react'
import cx from 'classnames'
import dayjs from 'dayjs'
import { Typography } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import { useStore } from 'effector-react'
import { LinkItUrl } from 'react-linkify-it'

import styles from './Msg.module.scss'

import { Message } from '../../../../../services/api/Chat'
import { $chat, $my } from '../../../../../states/Chat/store'
import { ModalWithTrigger } from '../../../../../shared/UI/Modal'
import { RemoteFile } from '../../../../../shared/UI'
import { decodeContent, getChatName } from '../../../../../shared/utils'

export interface MsgProps extends Message {
  prevMsg?: Message
}

export const Msg = forwardRef<HTMLDivElement, MsgProps>(({
  prevMsg,
  ...msg
}, ref) => {
  if (!prevMsg) {
    prevMsg = msg
  }

  const my = useStore($my)
  const chat = useStore($chat)

  const nextDay = dayjs(msg.createdAt).format('DD.MM') !== dayjs(prevMsg?.createdAt).format('DD.MM')
  const isSupportMsg = msg?.user?.type === 'support'
  const isCustomerMsg = msg?.user?.id === chat.ownerUser?.id
  const isMyMsg = msg?.user?.id === my?.id

  return (
    <div
      ref={ref}
      className={cx(
        styles.msg,
        isSupportMsg && styles.right,
        msg?.user?.id === chat?.ownerUser?.id && styles.left,
      )}
    >
      <div
        className={cx(
          styles.content,
          nextDay && styles.day,
        )}
      >
        {!isMyMsg && !isCustomerMsg &&
          <Typography className={styles.name}>
            {getChatName(msg.user)}
          </Typography>
        }
        {!!msg.attachments.length &&
        <div className={styles.files}>
          {msg.attachments.map((src) =>
            <div
              key={src}
              className={styles.preview}
            >
              <ModalWithTrigger trigger={<RemoteFile src={src} />}>
                <RemoteFile src={src} variant="full" />
              </ModalWithTrigger>
            </div>,
          )}
        </div>
        }

        {!!msg?.content &&
        <LinkItUrl>
          <span>{decodeContent(msg?.content)}</span>
        </LinkItUrl>
        }

        <div className={styles.time}>
          {dayjs(msg.createdAt).format('HH:mm')}

          {isSupportMsg &&
            <>
              {msg.firstReadAt
                ? <DoneAllIcon className={styles.read} />
                : <DoneIcon className={styles.read} />
              }
            </>
          }
        </div>
        {nextDay &&
          <div className={styles.date}>
            <div>
              {dayjs(msg.createdAt).format('DD MMMM')}
            </div>
          </div>
        }
      </div>
    </div>
  )
})
