import AxiosInstance from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'
import { Content } from './_types'

export interface Office {
  id: number
  name: string
  title: {
    content: Content[],
  },
  description: {
    content: Content[]
  },
  geomark: {
    lat: number,
    lon: number,
    address: string
  },
  icon?: string
}

export type GetOfficeRes = Office[]
const getOffice = () => {
  return promiseToResult(
    AxiosInstance().get<GetOfficeRes>(
      '/application/admin/buildings',
    ),
  )
}

export type PostOfficeReq = Omit<Office, 'id'>
export type PostOfficeRes = Office
const postOffice = (data: PostOfficeReq) => {
  return promiseToResult(
    AxiosInstance().post<PostOfficeRes>(
      '/application/admin/buildings',
      data,
    ),
  )
}

export interface GetOfficeIdReq {
  id: number
}
export type GetOfficeIdRes = Office
const getOfficeId = (data: GetOfficeIdReq) => {
  const { id } = data
  return promiseToResult(
    AxiosInstance().get<GetOfficeIdRes>(
      `/application/admin/buildings/${id}`,
    ),
  )
}

export type PatchOfficeReq = Office
export type PatchOfficeRes = Office
const patchOffice = (data: PatchOfficeReq) => {
  const { id, ...body } = data
  return promiseToResult(
    AxiosInstance().patch<PatchOfficeRes>(
      `/application/admin/buildings/${id}`,
      body,
    ),
  )
}

export type DeleteOfficeReq = GetOfficeIdReq
export type DeleteOfficeRes = Office
const deleteOffice = (data: DeleteOfficeReq) => {
  const { id } = data
  return promiseToResult(
    AxiosInstance().delete<DeleteOfficeRes>(
      `/application/admin/buildings/${id}`,
    ),
  )
}

export const OfficeAPI = {
  getOffice,
  getOfficeId,
  postOffice,
  patchOffice,
  deleteOffice,
}
