import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useStore } from 'effector-react'

import { Select, Switch, DateTimePicker, Input, InputFile, RootLayout } from '../../../../UI'
import { $defaults } from '../../../../../states/Defaults/store'
import { Project } from '../../../../../services/api/Project'

export const Main = () => {
  const defaults = useStore($defaults)
  const { watch, setValue } = useFormContext()

  const values = watch() as Project

  return (
    <RootLayout>
      <Switch
        name="isEnabled"
        checked={values?.settings?.isEnabled}
        onChange={() => setValue('settings.isEnabled', !values.settings?.isEnabled)}
        label={values.settings?.isEnabled ? 'Включен' : 'Выключен'}
        title="Состояние"
      />

      <Input
        disabled
        name="id"
        label="ID"
        variant="standard"
        value={values.id}
        InputLabelProps={{ shrink: !!values.id }}
      />

      <Input
        name="name"
        label="Название"
        variant="standard"
        value={values.name}
        onChange={(e) => setValue('name', e.target.value)}
        InputLabelProps={{ shrink: !!values.name }}
      />

      <Input
        name="symbol"
        label="Аббревиатура"
        variant="standard"
        value={values.symbol}
        onChange={(e) => setValue('symbol', e.target.value)}
        InputLabelProps={{ shrink: !!values.symbol }}
      />

      <Input
        name="allShare"
        label="Количество долей всего возможное"
        variant="standard"
        value={values.allShares}
        onChange={(e) => setValue('allShares', e.target.value)}
        InputLabelProps={{ shrink: !!values.allShares }}
      />

      <Input
        name="allShare"
        label="Количество долей для продажи"
        variant="standard"
        value={values.allSharesForSelling}
        onChange={(e) => setValue('allSharesForSelling', e.target.value)}
        InputLabelProps={{ shrink: !!values.allSharesForSelling }}
      />

      <Input
        disabled
        name="saleShare"
        label="Количество проданных долей"
        variant="standard"
        value={values.soldShares}
        InputLabelProps={{ shrink: !!values.soldShares }}
      />

      <Input
        name="allShare"
        label="Доход компании с 1 доли, %"
        variant="standard"
        value={values.percentCompanyToOneShare}
        onChange={(e) => setValue('percentCompanyToOneShare', Number(e.target.value))}
        InputLabelProps={{ shrink: !!values.percentCompanyToOneShare }}
      />

      <Input
        name="allShare"
        label="Стоймость реализации одного проекта"
        variant="standard"
        value={values.pricePerOneBuilding}
        onChange={(e) => setValue('pricePerOneBuilding', Number(e.target.value))}
        InputLabelProps={{ shrink: !!values.pricePerOneBuilding }}
      />

      <Input
        name="allShare"
        label="Стоймость лицении на строительство от стоймости проекта, %"
        variant="standard"
        value={values.percentCompanyToOneBuilding}
        onChange={(e) => setValue('percentCompanyToOneBuilding', Number(e.target.value))}
        InputLabelProps={{ shrink: !!values.percentCompanyToOneBuilding }}
      />

      <Select
        multiple
        name="availableForRoles"
        label="Доступность"
        variant="standard"
        value={values?.settings?.availableForRoles || []}
        onChange={(e) => setValue('settings.availableForRoles', e.target.value as any[])}
        options={defaults?.users?.roles || []}
      />

      <InputFile
        label="Иконка"
        value={values.icon}
        onFile={(url) => setValue('icon', url)}
      />

      <InputFile
        label="Фото для нфт"
        value={values.coin}
        onFile={(url) => setValue('coin', url)}
      />

      <DateTimePicker
        disabled
        label="Дата создания"
        variant="standard"
        value={values?.createdAt}
      />

      <DateTimePicker
        disabled
        label="Дата последнего изменения"
        variant="standard"
        value={values?.updatedAt}
      />
    </RootLayout>
  )
}
