import { createEffect } from 'effector'
import { toast } from 'react-toastify'

import { APIResult, AxiosError } from '../../core/api/Result'
import {
  GetRecoveryAllReq,
  GetRecoveryReq,
  PutRecoveryReq,
  RecoveryAPI,
} from '../../services/api/Recovery'

export const getRecoveryAllFx = createEffect<GetRecoveryAllReq, APIResult<typeof RecoveryAPI['getRecoveryAll']>, AxiosError>(
  RecoveryAPI.getRecoveryAll,
)

export const getRecoveryFx = createEffect<GetRecoveryReq, APIResult<typeof RecoveryAPI['getRecovery']>, AxiosError>(
  RecoveryAPI.getRecovery,
)

export const updateRecoveryFx = createEffect<PutRecoveryReq, APIResult<typeof RecoveryAPI['putRecovery']>, AxiosError>(
  RecoveryAPI.putRecovery,
)

updateRecoveryFx.done.watch(({ params }) => {
  getRecoveryFx({ id: params.id })
  toast.success('Успешно')
})
