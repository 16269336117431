import React from 'react'

import { Input, RootLayout, Switch, RoleGuard } from '../../../../UI'
import { BalanceUpdate } from './BalanceUpdate'
import { useFormContext } from 'react-hook-form'
import { User } from '../../../../../services/api/User'
import { Role } from '../../../../enum'

export const UserBalance = () => {
  const { watch, setValue } = useFormContext<User>()
  const { balance, projects, isWithdrawalEnabled } = watch()

  return (
    <RootLayout>

      <RoleGuard role={Role.ADMIN}>
        <div className="header">
          <BalanceUpdate />
        </div>
      </RoleGuard>

      <div className="controller">
        <Input
          disabled
          label="Баланс"
          value={`${balance / 100} $`}
        />

        {projects.map(({ project, shares = 0 }) =>
          <Input
            key={project.id}
            disabled
            label={project.name}
            value={`${shares} долей`}
          />,
        )}

        <Switch
          label="Возможность выводить"
          checked={isWithdrawalEnabled}
          onChange={(_, checked) => setValue('isWithdrawalEnabled', checked)}
        />
      </div>
    </RootLayout>
  )
}
