/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { useStore } from 'effector-react'

import {
  Button,
  ModalWithTrigger,
  ModalWithTriggerHandler,
  Page,
} from '../../../../../UI'

import { $projectsOptions } from '../../../../../../states/Project/store'
import { transactionsCorrectionFx } from '../../../../../../states/User/event'
import { $transactionsCorrectionPending } from '../../../../../../states/User/store'
import { $defaults } from '../../../../../../states/Defaults/store'
import { TransactionType } from '../../../../../enum'
import { TransactionsCorrectionReq } from '../../../../../../services/api/User'
import { toast } from 'react-toastify'

const allowType = [
  TransactionType.GIFT_SHARES,
  TransactionType.GIFT_BALANCE,
  TransactionType.CORRECTION,
]

export const BalanceUpdate = () => {
  const defaults = useStore($defaults)
  const transactionsCorrectionPending = useStore($transactionsCorrectionPending)
  const projectsOptions = useStore($projectsOptions)
  const { getValues } = useFormContext()
  const ref = createRef<ModalWithTriggerHandler>()

  const userId = getValues('id')

  return (
    <ModalWithTrigger
      ref={ref}
      trigger={
        <Button color="secondary">
          Изменить
        </Button>
      }
    >
      <Page
        variant="form"
        title="Изменение баланса"
        button="Начислить"
        loading={[transactionsCorrectionPending]}
        fields={[
          { name: 'type', label: 'Тип', type: 'select', options: defaults.transactions.types.filter(({ key }: any) => allowType.find((at) => at === key)) },
          { name: 'price', label: 'Сумма', type: 'cent', hide: ({ type }) => !type || type === TransactionType.GIFT_SHARES },
          { name: 'shares', label: 'Кол-во долей', type: 'number', hide: ({ type }) => !type || type !== TransactionType.GIFT_SHARES },
          { name: 'projectId', label: 'Проект', type: 'select', options: projectsOptions, hide: ({ type }) => !type || type !== TransactionType.GIFT_SHARES },
          { name: 'comment', label: 'Коментарий для юзера', type: 'string', hide: ({ type }) => !type },
        ]}
        onSubmit={(value: Record<string, any>) => {
          const body: TransactionsCorrectionReq = {
            type: value.type,
            comment: value.comment,
            userId,
          }
          if (value.type !== TransactionType.GIFT_SHARES) {
            body.price = value.price
          } else {
            body.projectId = value.projectId
            body.shares = value.shares
          }
          if (value.type !== TransactionType.CORRECTION && (value.price < 1 || value.shares < 1)) {
            toast.error('Подарок не может быть отрицательным')
            return
          }

          transactionsCorrectionFx(body)
        }}
      />
    </ModalWithTrigger>
  )
}
