import AxiosInstance from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'
import { Content, WithMetadata } from './_types'
import { querystring } from '../../shared/utils'

export interface News {
  id: number
  title: Content[]
  header: Content[]
  shortDescription: Content[]
  content: Content[]
  attachments: string[],
  isActive: boolean,
  isSharePriceChanged: boolean,
  influencedProjectIds: number[],
  metadata: {
    mainImg: string
  },
  publishedAt: string
  updatedAt: string
  createdAt: string
}

export interface GetNewsReq {
  limit: string
  page: string
  language: string
  search?: string
  projectIds?: News['influencedProjectIds']
  isInfluencedShares?: News['isSharePriceChanged']
}
export type GetNewsRes = WithMetadata<News>
const getNews = (data: GetNewsReq) => {
  return promiseToResult(
    AxiosInstance().get<GetNewsRes>(
      `/application/admin/news?${querystring.stringify(data)}`,
    ),
  )
}

export interface GetNewsIdReq { id: string | number }
export type GetNewsIdRes = News
const getNewsId = (data: GetNewsIdReq) => {
  return promiseToResult(
    AxiosInstance().get<GetNewsIdRes>(
      `/application/admin/news/${data.id}`,
    ),
  )
}

export type PostNewsReq = Omit<News, 'id' | 'createdAt' | 'updatedAt'>
export type PostNewsRes = News
const postNews = (data: PostNewsReq) => {
  return promiseToResult(
    AxiosInstance().post<PostNewsRes>(
      '/application/admin/news',
      data,
    ),
  )
}

export type PutNewsReq = Omit<News, 'createdAt' | 'updatedAt'>
export type PutNewsRes = News
const putNews = (data: PutNewsReq) => {
  return promiseToResult(
    AxiosInstance().put<PutNewsRes>(
      `/application/admin/news/${data.id}`,
      data,
    ),
  )
}

export const NewsAPI = {
  getNews,
  getNewsId,
  postNews,
  putNews,
}
