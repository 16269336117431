import React from 'react'
import { useStore } from 'effector-react'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Page } from '../../../shared/UI'

import { NewsAPI, PostNewsReq } from '../../../services/api/News'
import { getProjectsFx } from '../../../states/Project/event'
import { $projectsOptions } from '../../../states/Project/store'
import { Routes } from '../../../shared/enum'
import { $my } from '../../../states/My/store'

export const Create = () => {
  const my = useStore($my)
  const projectsOptions = useStore($projectsOptions)
  const navigate = useNavigate()

  const post = useMutation(NewsAPI.postNews)

  const handlerSubmit = async (data: PostNewsReq) => {
    const { data: { data: { id } } } = await post.mutateAsync(data)

    toast.success('Успешно')
    navigate(`/${Routes.NEWS}/${id}`)
  }

  return (
    <Page
      button="Создать"
      title="Создание новости"
      variant="form"
      onInit={() => getProjectsFx({ query: { limit: 100, page: 1 } })}
      onSubmit={handlerSubmit}
      fields={[
        { name: 'viewsCount', type: 'number', label: 'Количество просмотров' },
        { name: 'anonymsLikes', type: 'number', label: 'Анонимные лайки' },
        { name: 'isActive', type: 'switch', label: 'Активна' },
        { name: 'isSharePriceChanged', type: 'switch', label: 'Влияние на цену' },
        { name: 'influencedProjectIds', type: 'array', label: 'Проекты', options: projectsOptions },
        { name: 'publishedAt', type: 'date', label: 'Время публикации' },
        { name: 'title', type: 'content', label: 'Заголовок', mode: 'text' },
        { name: 'shortDescription', type: 'content', label: 'Краткое описание', mode: 'text' },
        { name: 'content', type: 'content', label: 'Содержание' },
        { name: 'attachments', type: 'file', label: 'Файлы', multiple: true, filename: `news::userId=${my?.id}` },
        { name: 'metadata.mainImg', type: 'file', label: 'Главное фото', filename: `news::userId=${my?.id}` },
        { name: 'header', type: 'content', label: 'Мета', mode: 'text' },
      ]}
    />
  )
}
