import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useStore } from 'effector-react'
import { Avatar, IconButton, ListItemText, Menu, MenuItem, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import PersonIcon from '@mui/icons-material/Person'
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt'
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead'

import styles from '../ChatId.module.scss'

import { Routes } from '../../../../../shared/enum'
import { $chat, $my } from '../../../../../states/Chat/store'
import { $languages } from '../../../../../states/Language/store'
import { getChatName } from '../../../../../shared/utils'
import { putChatReadFx, putChatUnreadFx } from '../../../../../states/Chat/event'

export const Header = () => {
  const my = useStore($my)
  const chat = useStore($chat)
  const languages = useStore($languages)

  const isMyChat = chat.ownerUser?.accountId === my?.accountId

  const [userEl, setUserEl] = useState<HTMLElement | null>(null)

  const userLanguage = languages.find(({ code }) => code === chat.ownerUser?.language)

  return (
    <div className={styles.header}>
      <div className={styles.box}>
        <NavLink to={`/${Routes.CHAT}`}>
          <IconButton>
            <ArrowBackIcon />
          </IconButton>
        </NavLink>

        <Avatar
          src={chat.ownerUser?.avatar}
          onClick={(e) => setUserEl(e.currentTarget)}
          sx={{ cursor: 'pointer' }}
        />

        {chat.id &&
        <Menu
          anchorEl={userEl}
          open={!!userEl}
          onClose={() => setUserEl(null)}
        >
          {chat.ownerUser?.accountId && <NavLink
            target="_blank"
            to={`/${Routes.USER}/${chat.ownerUser?.accountId}`}
          >
            <MenuItem
              className={styles.menu}
            >
              <PersonIcon />
              Профиль
            </MenuItem>
          </NavLink>}

          <MenuItem
            disabled={!!chat.unreadCount}
            className={styles.menu}
            onClick={() => {
              putChatUnreadFx({ id: chat.id as number })
              setUserEl(null)
            }}
          >
            <MarkUnreadChatAltIcon />
            Пометить как непрочитанное
          </MenuItem>

          <MenuItem
            disabled={!chat.unreadCount}
            className={styles.menu}
            onClick={() => {
              putChatReadFx({ id: chat.id as number })
              setUserEl(null)
            }}
          >
            <MarkChatReadIcon />
            Прочитать все
          </MenuItem>
        </Menu>
        }

        <Typography variant="h6">
          <ListItemText
            primary={isMyChat ? 'Избранное' : getChatName(chat?.ownerUser)}
            secondary={!isMyChat && `${userLanguage?.language} (${userLanguage?.displayName})`}
          />
        </Typography>
      </div>
    </div>
  )
}
