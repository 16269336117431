import { createEffect } from 'effector'

import {
  TransactionAPI,
  GetTransactionsReq,
  GetTransactionReq,
  PostTransactionCancelReq,
} from '../../services/api/Transaction'
import { AxiosError, APIResult } from '../../core/api/Result'
import { toast } from 'react-toastify'

export const getTransactionsFx = createEffect<GetTransactionsReq, APIResult<typeof TransactionAPI['getTransactions']>, AxiosError>(
  TransactionAPI.getTransactions,
)

export const getTransactionFx = createEffect<GetTransactionReq, APIResult<typeof TransactionAPI['getTransaction']>, AxiosError>(
  TransactionAPI.getTransaction,
)

export const postTransactionCancelFx = createEffect<PostTransactionCancelReq, APIResult<typeof TransactionAPI['postTransactionCancel']>, AxiosError>(
  TransactionAPI.postTransactionCancel,
)

export const postTransactionCompleteFx = createEffect<PostTransactionCancelReq, APIResult<typeof TransactionAPI['postTransactionComplete']>, AxiosError>(
  TransactionAPI.postTransactionComplete,
)

export const postTransactionRecalculateFx = createEffect<PostTransactionCancelReq, APIResult<typeof TransactionAPI['postTransactionComplete']>, AxiosError>(
  TransactionAPI.postTransactionRecalculate,
)

const reload = [
  postTransactionCompleteFx,
  postTransactionCancelFx,
  postTransactionRecalculateFx,
]

reload.map((fx) => {
  fx.done.watch(({ params }) => {
    getTransactionFx({ id: params.id })
    toast.success('Успешно')
  })
})
