import { Outlet, useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import { getProjectFx } from '../../../../states/Project/event'

export const ProjectId = () => {
  const { id } = useParams()

  useEffect(() => {
    if (id) {
      getProjectFx({ id })
    }
  }, [id])

  return (
    <Outlet />
  )
}
