/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import DeleteIcon from '@mui/icons-material/Delete'
import FilterListIcon from '@mui/icons-material/FilterList'
import { Download } from '@mui/icons-material'
import { visuallyHidden } from '@mui/utils'
import { NavLink } from 'react-router-dom'
import { createEvent, createStore } from 'effector'
import connectLocalStorage from 'effector-localstorage'
import { useStore } from 'effector-react'
import { get } from 'lodash'

const disabledPaddingLocalStore = connectLocalStorage('table-disabled-padding')
const handlerDisabledPaddingFx = createEvent()
const $disabledPadding = createStore(disabledPaddingLocalStore.init() || true)
  .on(handlerDisabledPaddingFx, (prev) => !prev)
$disabledPadding.watch(disabledPaddingLocalStore)

interface Data {
  calories: number;
  carbs: number;
  fat: number;
  name: string;
  protein: number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

interface EnhancedTableToolbarProps {
  numSelected: number
  title: React.ReactNode
  onDownload?: () => void
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected, title, onDownload } = props

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}
      {!!onDownload &&
      <Tooltip title="Download">
        <IconButton onClick={onDownload}>
          <Download />
        </IconButton>
      </Tooltip>
      }
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  )
}

export interface Col<T = Record<string, any>> {
  id: string
  label: string
  render?: (value: any, raw: T) => React.ReactNode
}

export interface RootTableProps<T> {
  rows?: T[]
  orderBy?: string
  orderDirection?: string
  col: Col<T>[]
  title: React.ReactNode
  toLink?: string
  limitOptions?: number[]
  handleChangePage?: (page: number) => void
  handleChangeLimit?: (limit: number) => void
  handleOrderDirection?: (orderDirection: string) => void
  onOrderBy?: (event: 'orderBy', orderBy: string) => void
  page?: number
  limit?: number
  length?: number
  onDownload?: () => void
}

export const RootTable = <T extends Record<string, any>, >(props: RootTableProps<T>) => {
  let {
    rows = [],
    col,
    orderBy: orderByDefault = '',
    orderDirection = 'desc',
    title,
    toLink = 'id',
    limitOptions = [15, 25, 40],
    handleChangePage: handleChangePageProps,
    handleChangeLimit: handleChangeLimitProps,
    handleOrderDirection,
    onOrderBy = () => null,
    page = 1,
    limit = 10,
    length = rows.length,
    onDownload,
  } = props

  const [order, setOrder] = React.useState<Order>(orderDirection as Order)
  const [orderBy, setOrderBy] = React.useState(orderByDefault)
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const dense = useStore($disabledPadding)
  page -= 1

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    const orderDirection = isAsc ? 'desc' : 'asc'
    setOrder(orderDirection)
    setOrderBy(property)
    if (typeof handleOrderDirection === 'function') {
      handleOrderDirection(orderDirection)
    }
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    return
    const selectedIndex = selected.indexOf(name)
    let newSelected: readonly string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    if (typeof handleChangePageProps === 'function') {
      handleChangePageProps(newPage + 1)
    }
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLimit = parseInt(event.target.value, 10)
    if (typeof handleChangeLimitProps === 'function') {
      handleChangeLimitProps(newLimit)
    }
    if (typeof handleChangePageProps === 'function') {
      handleChangePageProps(1)
    }
  }

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    handlerDisabledPaddingFx()
  }

  const isSelected = (name: string) => selected.indexOf(name) !== -1

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (page + 1) * limit - length) : 0

  // eslint-disable-next-line react/no-unstable-nested-components
  const EnhancedTableHead: React.FC<EnhancedTableProps> = ({
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  }) => {
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

    return (
      <TableHead>
        <TableRow>
          {col.map((headCell: any) => (
            <TableCell
              key={`${headCell.label}-${headCell.id}`}
              align={headCell.num ? 'left' : 'center'}
              // padding={headCell.disablePadding ? 'normal' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar onDownload={onDownload} numSelected={selected.length} title={title} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows as any, getComparator(order, orderBy))
                // .slice(page * limitPage, page * limitPage + limitPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row?.id?.toString())
                  const labelId = `enhanced-table-checkbox-${index}`
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row?.id?.toString())}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${row?.id}-${index}`}
                      selected={isItemSelected}
                    >
                      {col.map((item: any) => {
                        const value = get(row, item.id)
                        return <TableCell
                          key={item.id}
                          component="th"
                          id={item.id === 'id' ? labelId : undefined}
                          scope="row"
                          align={item.num ? 'left' : 'center'}
                        >
                          {item.id === toLink && typeof item.render !== 'function' ?
                            <NavLink to={`${value}`}>{value}</NavLink>
                            : typeof item.render === 'function'
                              ? item.render(value, row)
                              : String(value)
                            }
                        </TableCell>
                      },
                      )}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={limitOptions}
          component="div"
          count={length}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Показывать"
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Отключить отступы"
      />
    </Box>
  )
}
