import AxiosInstance from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'
import { querystring } from '../../shared/utils'

import { Content, Query, WithMetadata } from './_types'

interface Discount {
  discountType: string
  discountConditions: string
  discountValue: number
  min: number
  max: number
}

interface Settings {
  discounts: Discount[]
  isEnabled: boolean
  isWithdrawal: boolean
  visibleRoles: string[]
  secrets: Record<string, never>
}
export interface Payment {
  id: number
  name: string
  title: {
    content: Content[]
  }
  description: {
    content: Content[]
  }
  type: string
  icon: string
  settings: Settings | null
}
export type GetPaymentsReq = Partial<Query>
export type GetPaymentsRes = WithMetadata<Payment>

const getPayments = (data: GetPaymentsReq) => {
  const { query } = data
  return promiseToResult(
    AxiosInstance().get<GetPaymentsRes>(
      `/projects/admin/payments?${querystring.stringify(query)}`,
    ),
  )
}

export interface GetPaymentReq {
  id: string
}
export type GetPaymentRes = Payment
const getPayment = (data: GetPaymentReq) => {
  const { id } = data
  return promiseToResult(
    AxiosInstance().get<GetPaymentRes>(
      `/projects/admin/payments/${id}`,
    ),
  )
}

export interface PostPaymentReq {
  name: string
  description?: string
  type: string
  settings?: Settings
}
export type PostPaymentRes = Payment
const postPayment = (data: PostPaymentReq) => {
  const { ...body } = data
  return promiseToResult(
    AxiosInstance().post<PostPaymentRes>(
      '/projects/admin/payments',
      body,
    ),
  )
}

export type PatchPaymentReq = Payment
export type PatchPaymentRes = Payment
const patchPayment = (data: PatchPaymentReq) => {
  const { id, ...body } = data
  return promiseToResult(
    AxiosInstance().patch<PatchPaymentRes>(
      `/projects/admin/payments/${id}`,
      body,
    ),
  )
}

export interface DeletePaymentReq {
  id: string
}
export interface DeletePaymentRes {
  status: boolean
}
const deletePayment = (data: DeletePaymentReq) => {
  const { id } = data
  return AxiosInstance().delete<DeletePaymentRes>(`/projects/admin/payments/${id}`)
}

export const PaymentAPI = {
  getPayments,
  getPayment,
  postPayment,
  patchPayment,
  deletePayment,
}
