/* eslint-disable @typescript-eslint/no-use-before-define */
import { createEffect } from 'effector'
import { APIResult, AxiosError } from '../../core/api/Result'
import { StatisticAPI } from '../../services/api/Statistic'

export const getStatisticFx = createEffect(() => {
  getUserFx(null)
  getRecoveryFx(null)
  getVerificationFx(null)
  getTransactionFx(null)
})

export const getUserFx = createEffect<unknown, APIResult<typeof StatisticAPI['getUser']>, AxiosError>(
  StatisticAPI.getUser,
)

export const getRecoveryFx = createEffect<unknown, APIResult<typeof StatisticAPI['getRecovery']>, AxiosError>(
  StatisticAPI.getRecovery,
)

export const getVerificationFx = createEffect<unknown, APIResult<typeof StatisticAPI['getVerification']>, AxiosError>(
  StatisticAPI.getVerification,
)

export const getTransactionFx = createEffect<unknown, APIResult<typeof StatisticAPI['getTransaction']>, AxiosError>(
  StatisticAPI.getTransaction,
)
