import { createStore } from 'effector'

import { Recovery, GetRecoveryRes } from '../../services/api/Recovery'
import { PaginationMetadata } from '../../services/api/_types'

import { getRecoveryAllFx, getRecoveryFx } from './event'

export const $recoveryAll = createStore<Recovery[]>([])
  .on(getRecoveryAllFx.done, (_, { result }) => result.data.data.items)

export const $recoveryAllMetadata = createStore<Partial<PaginationMetadata>>({})
  .on(getRecoveryAllFx.done, (_, { result }) => result.data.data.metadata)

export const $isPendingRecoveryAll = createStore(false)
  .on(getRecoveryAllFx.pending, (_, pending) => pending)

export const $recovery = createStore<Partial<GetRecoveryRes>>({})
  .on(getRecoveryFx.done, (_, { result }) => result.data.data)

export const $isPendingRecovery = createStore(false)
  .on(getRecoveryFx.pending, (_, pending) => pending)
