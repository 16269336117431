import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import { Routes } from '../../enum'
import { Button, ButtonProps } from '../Button'

export interface PageOutletProps {
  variant: 'outlet'
  links?: {
    title: React.ReactNode
    to: Routes | string
    color?: ButtonProps['color']
  }[]
}

export const PageOutlet: React.FC<PageOutletProps> = ({
  links,
}) => {
  return (
    <>
      <div className="header">
        {links?.map(({ title, to, color }) => (
          <NavLink to={to} key={to}>
            <Button color={color}>
              {title}
            </Button>
          </NavLink>
        ))}
      </div>

      <Outlet />
    </>
  )
}
