import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps'

import { Office } from '../../../services/api/Office'
import { Button, EditorContent, Input, InputFile } from '../../../shared/UI'
import { RootLayout } from '../../../shared/UI/RootLayout'
import { createOfficeFx } from '../../../states/Office/event'
import { useResize } from '../../../shared/hooks'

type Form = Omit<Office, 'id'>

export const Create = () => {
  const { isMobile } = useResize()

  const form = useForm<Form>()
  const {
    setValue,
    watch,
    getValues,
  } = form

  const point = [
    watch('geomark.lat') || 0,
    watch('geomark.lon') || 0,
  ]

  const instanceRef = useCallback((ref: any) => {
    if (!ref) {
      return
    }
    ref?.events.add('click', (e: any) => {
      const coords = e.get('coords') as number[]
      setValue('geomark.lat', coords[0])
      setValue('geomark.lon', coords[1])
    })
  }, [])

  const handlerSave = () => {
    createOfficeFx(getValues())
  }

  return (
    <RootLayout>
      <div className="header">
        Создание офиса
      </div>

      <Input
        label="Название (для админа)"
        value={watch('name')}
        onChange={(e) => setValue('name', e.target.value)}
      />

      <EditorContent
        label="Заголовок"
        value={watch('title')?.content}
        onChange={(content) => setValue('title', { content })}
      />

      <EditorContent
        label="Описание"
        value={watch('description')?.content}
        onChange={(content) => setValue('description', { content })}
      />

      <Input
        label="Аддресс"
        value={watch('geomark.address')}
        onChange={(e) => setValue('geomark.address', e.target.value)}
      />

      <InputFile
        label="Фото"
        value={watch('icon')}
        onFile={(url) => setValue('icon', url)}
      />

      <YMaps>
        <Map
          defaultState={{
            center: point,
            zoom: 10,
          }}
          width={'100%'}
          height={isMobile ? 200 : 500}
          instanceRef={instanceRef}
        >
          <Placemark
            geometry={point}
          />
        </Map>
      </YMaps>

      <Button onClick={handlerSave}>
        Создать
      </Button>
    </RootLayout>
  )
}
