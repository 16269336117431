import AxiosInstance from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'
import { Role } from '../../shared/enum'

export interface GetMyRes {
  id: number
  role: Role
  avatar?: string
  passport: null | {
    name: string
    surname: string
    birthDate: string
  }
}
const getMy = () => {
  return promiseToResult(
    AxiosInstance().get<GetMyRes>(
      '/application/users/@my',
    ),
  )
}

export const MyAPI = {
  getMy,
}
