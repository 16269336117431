import React from 'react'
import { useStore } from 'effector-react'
import dayjs from 'dayjs'

import { DatePicker, InputAutocomplete, RootTable, Accordion, Select, RootLayout, Col } from '../../shared/UI'
import { usePagination } from '../../shared/hooks'
import {
  $defaults,
} from '../../states/Defaults/store'
import {
  $recoveryAll,
  $recoveryAllMetadata,
  $isPendingRecoveryAll,
} from '../../states/Recovery/store'
import { getRecoveryAllFx } from '../../states/Recovery/event'

const col: Col[] = [
  { id: 'id', label: 'ID' },
  {
    id: 'status',
    label: 'Статус',
    render: (status) => {
      const defaults = $defaults.getState()
      const { value } = defaults?.verifications?.types
        .find(({ key }: Record<string, string>) => key === status) || {}
      return value
    },
  },
  {
    id: 'createdAt',
    label: 'Время создания',
    render: (createdAt) => dayjs(String(createdAt)).format('DD.MM.YYYY HH:mm'),
  },
  { id: 'comment', label: 'Комментарий' },
]

const RecoveryController = () => {
  const recoveryAll = useStore($recoveryAll)
  const recoveryAllMetadata = useStore($recoveryAllMetadata)
  const isPendingRecoveryAll = useStore($isPendingRecoveryAll)
  const defaults = useStore($defaults)

  const { query, pagination, handlerPagination, handlerQuery } = usePagination({
    defaults: {
      orderBy: 'id',
      orderDirection: 'desc',
      limit: '10',
      page: '1',
      status: '',
    },
    observerQuery: (query) => getRecoveryAllFx({ query }),
  })

  const onChange = (value: string, trigger: string) => {
    handlerQuery(trigger, value)
  }

  return (
    <RootLayout>
      <Accordion label="Фильтры" defaultExpanded className="filter">
        <InputAutocomplete
          label="ID Заявки"
          options={recoveryAll}
          values={query}
          trigger="id"
          isLoading={isPendingRecoveryAll}
          onChange={onChange}
        />
        <InputAutocomplete
          label="ID Пользователя"
          options={recoveryAll}
          values={query}
          trigger="userId"
          isLoading={isPendingRecoveryAll}
          onChange={onChange}
        />
        <InputAutocomplete
          label="Логин"
          options={recoveryAll}
          values={query}
          trigger="userLogin"
          isLoading={isPendingRecoveryAll}
          onChange={onChange}
        />
        <InputAutocomplete
          label="Телефон"
          options={recoveryAll}
          values={query}
          trigger="userPhone"
          isLoading={isPendingRecoveryAll}
          onChange={onChange}
        />
        <Select
          name="status"
          label="Статус"
          value={query.status}
          options={defaults?.verifications?.types}
          onSelect={(value) => onChange(value, 'status')}
          isEmpty
        />
        <InputAutocomplete
          label="Комментарий"
          options={recoveryAll}
          trigger="comment"
          isLoading={isPendingRecoveryAll}
          onChange={onChange}
        />
        <DatePicker
          label="С время создания"
          onSelect={(date) => onChange(date.toISOString(), 'dateStart')}
        />
        <DatePicker
          label="До время создания"
          onSelect={(date) => onChange(date.toISOString(), 'dateEnd')}
        />
        <InputAutocomplete
          label="Имя"
          options={recoveryAll}
          trigger="userPassportName"
          isLoading={isPendingRecoveryAll}
          onChange={onChange}
        />
        <InputAutocomplete
          label="Фамилия"
          options={recoveryAll}
          trigger="userPassportSurname"
          isLoading={isPendingRecoveryAll}
          onChange={onChange}
        />
      </Accordion>

      <RootTable
        rows={recoveryAll}
        col={col}
        title="Заявки на востановление пароля"
        limitOptions={[10, 20, 30]}
        handleChangePage={(page) => handlerPagination('page', page)}
        handleChangeLimit={(limit) => handlerPagination('limit', limit)}
        handleOrderDirection={(limit) => handlerQuery('orderDirection', limit)}
        length={recoveryAllMetadata?.total}
        page={pagination.page}
        limit={pagination.limit}
        orderBy={query?.orderBy?.toString() || 'id'}
        orderDirection={query?.orderDirection?.toString() || 'desc'}
      />
    </RootLayout>
  )
}

export default RecoveryController
