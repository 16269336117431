import React, { useState } from 'react'
import { Box, Tab } from '@mui/material'
import { TabContext, TabList, TabPanel as TabPanelMui } from '@mui/lab'

interface IDate {
  label: string,
  component: React.ReactNode,
  value?: number
}

interface ITabPanel {
  data: IDate[],
  onChange?: (key: number) => void
  value?: number
}

export const TabPanel: React.FC<ITabPanel> = ({
  data,
  onChange = () => null,
  value: valueProps,
}) => {
  const [value, setValue] = useState('0')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    onChange(Number(newValue))
    setValue(newValue)
  }

  return (
    <TabContext value={String(valueProps || value)}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleChange}>
          {data.map((item, idx) =>
            <Tab label={item.label} key={item.label} value={String(item?.value || idx)} />,
          )}
        </TabList>
      </Box>
      {data.map((item, idx) =>
        <TabPanelMui
          value={String(item?.value || idx)}
          key={item.label}
          sx={{
            padding: '24px 0',
          }}
        >
          {item.component}
        </TabPanelMui>,
      )}
    </TabContext>
  )
}
