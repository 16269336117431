import * as React from 'react'
import { CircularProgress, Grid } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useStore } from 'effector-react'
import useInfiniteScroll from 'react-infinite-scroll-hook'

import styles from './ChatId.module.scss'

import { unreadMessageIdsFx, getMessagesFx, updateMessageFx } from '../../../../states/Chat/event'
import { $chat, $isPendingMessages, $messages, $my } from '../../../../states/Chat/store'
import { Msg } from './Msg'
import { Input } from './Input'
import { Header } from './Header'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'

export const ChatId = () => {
  const { id } = useParams() as { id: string }
  const my = useStore($my)
  const chat = useStore($chat)
  const messages = useStore($messages)
  const isPendingMessages = useStore($isPendingMessages)

  const refBody = useRef<HTMLDivElement>(null)
  const refLast = useRef<HTMLDivElement>(null)

  const [page, setPage] = useState(1)
  const total = messages?.metadata?.total || 0
  const length = messages?.items?.length || 0
  const loadTotal = Number(messages?.metadata?.page) * Number(messages?.metadata?.limit)
  const hasNextPage = total > loadTotal && total > length

  const [refFist, { rootRef }] = useInfiniteScroll({
    loading: isPendingMessages,
    hasNextPage,
    onLoadMore: () => {
      setPage((prev) => prev + 1)
      getMessagesFx({
        page: page + 1,
        limit: 50,
        chatId: Number(id),
        language: 'ru',
      })
    },
    rootMargin: '0px 0px 400px 0px',
  })

  useEffect(() => {
    if (refLast.current && page === 1) {
      refLast.current?.scrollIntoView()

      updateMessageFx.watch(([updMsg]) => {
        if (updMsg.chatId === chat.id) {
          setTimeout(() => refLast.current?.scrollIntoView(), 150)
        }
      })
    }
  }, [refLast.current])

  useEffect(() => {
    const chatId = Number(id)
    if (chatId) {
      setPage(1)
      getMessagesFx({
        page: 1,
        limit: 50,
        chatId,
        language: 'ru',
      })
    }
  }, [id])

  useEffect(() => {
    const set = new Set<number>()

    messages?.items.forEach((msg) => {
      const isMyMsg = msg?.user?.id === my?.id
      if (!isMyMsg && !msg.firstReadAt) {
        set.add(msg.id)
      }
    })

    unreadMessageIdsFx(Array.from(set))
  }, [messages])

  return (
    <div className={styles.root} ref={refBody}>
      <Header />

      <div
        className={styles.body}
        ref={rootRef}
        style={{ maxHeight: `${refBody.current?.offsetHeight}px` }}
      >
        {(isPendingMessages || hasNextPage) &&
        <Grid ref={refFist}>
          <CircularProgress />
        </Grid>
        }

        {messages?.items
          ?.sort((a, b) => dayjs(a.createdAt).diff(b.createdAt))
          .map((msg, idx) => {
            const data = messages?.items || []
            const prevMsg = data[idx - 1]

            return (
              <Msg
                key={msg.id}
                prevMsg={prevMsg}
                {...msg}
              />
            )
          })}
        <div ref={refLast} />
      </div>

      <Input />
    </div>
  )
}
