import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useStore } from 'effector-react'

import { DatePicker, Input, RootLayout, Select } from '../../../../UI'
import { User } from '../../../../../services/api/User'
import { $defaults } from '../../../../../states/Defaults/store'

export const UserPassport = () => {
  const defaults = useStore($defaults)

  const { watch } = useFormContext<User>()
  const { passport } = watch()

  return (
    <RootLayout>
      <Input
        disabled
        label="Имя"
        value={passport?.name}
      />
      <Input
        disabled
        label="Фамилия"
        value={passport?.surname}
      />
      <DatePicker
        disabled
        label="Дата рождения"
        value={passport?.birthDate}
      />
      <Select
        disabled
        label="Статус"
        options={defaults.verifications?.types}
        value={passport?.verificationStatus}
      />
    </RootLayout>
  )
}
