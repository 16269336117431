import AxiosInstance from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'
import { querystring } from '../../shared/utils'

import { IdReq, Query, WithMetadata } from './_types'
import { VerificationStatus } from '../../shared/enum'

export interface Passport {
  id: number
  name: string
  surname: string
  birthDate: string
  verificationStatus: VerificationStatus | null
}

export interface UserPrev {
  id: number
  role: string
  phone: string
  login: string | null
  avatar: string | null
  language: string
  createdAt: string
  passport: Passport | null
}

export interface User extends UserPrev {
  attributes: {
    import?: {
      gorodId: string
      gorodLogin: string
      weterId: string
      weterLogin: string
    }
    phoneCountry?: {
      phoneCountry: string
    }
  }
  socials: {
    whatsApp: boolean
    telegram: boolean
    sms: boolean
    email: string | null
  }
  projects: {
    project: {
      id: number
      name: string
      symbol: string
    }
    shares: number
  }[]
  balance: number
  parent: UserPrev | null
  privacy: {
    includeInSearch: boolean
  }
  isBlocked: boolean
  isImported: boolean
  isRestricted: boolean
  isWithdrawalEnabled: boolean
  hasPassword: boolean
  restrictedDepth: number
}

export type GetUsersReq = Query
export type GetUsersRes = WithMetadata<User[]>

const getUsers = (data: GetUsersReq) => {
  const { query } = data
  return promiseToResult(
    AxiosInstance().get<GetUsersRes>(
      `/application/admin/users?${querystring.stringify(query)}`,
    ),
  )
}

export interface GetUserReq {
  id: User['id']
}
export type GetUserRes = User
const getUser = (data: GetUserReq) => {
  const { id } = data
  return promiseToResult(
    AxiosInstance().get<GetUserRes>(
      `/application/admin/users/${id}`,
    ),
  )
}

export type DeleteUserReq = GetUserReq
export interface DeleteUserRes {
  status: boolean
}
const deleteUser = (data: DeleteUserReq) => {
  const { id } = data
  return promiseToResult(
    AxiosInstance().delete<DeleteUserRes>(
      `/application/admin/users/${id}`,
    ),
  )
}

export interface PatchUserReq extends GetUserReq {
  isRestricted: boolean
  phone: string
  includeInSearch: boolean
  isBlocked: boolean
  restrictedDepth: number
  email: string | null
  isWithdrawalEnabled?: boolean
  role: string
}
export interface PatchUserRes {
  status: boolean
}
const patchUser = (data: PatchUserReq) => {
  const { id, ...body } = data
  return promiseToResult(
    AxiosInstance().patch<PatchUserRes>(
      `/application/admin/users/${id}`,
      body,
    ),
  )
}

export type ImposterReq = GetUserReq
export interface ImposterRes {
  token: string
}
const imposter = (data: ImposterReq) => {
  const { id } = data
  return promiseToResult(
    AxiosInstance().post<ImposterRes>(
      `/application/admin/users/imposter/${id}`,
    ),
  )
}

export type DisallowLoginReq = GetUserReq
const disallowLogin = (data: DisallowLoginReq) => {
  const { id } = data
  return promiseToResult(
    AxiosInstance().put(
      `/application/admin/users/${id}/disallow-login`,
    ),
  )
}
// AxiosInstance().post(
//   '/application//admin/users/send-fcm-push/1',
//   {
//     message: {
//       notification: {
//         title: 'Portugal vs. Denmark',
//         body: 'great match!',
//       },
//     },
//   },
// )

export type MustReloignReq = GetUserReq
const mustReloign = (data: MustReloignReq) => {
  const { id } = data
  return promiseToResult(
    AxiosInstance().put(
      `/application/admin/users/${id}/relogin`,
    ),
  )
}

export interface TransactionsCorrectionReq {
  shares?: number
  price?: number
  projectId?: number
  userId: number
  comment?: string
  type: string
}
export interface TransactionsCorrectionRes {
  status: boolean
}
const transactionsCorrection = (data: TransactionsCorrectionReq) => {
  return promiseToResult(
    AxiosInstance().post<TransactionsCorrectionRes>(
      '/projects/admin/transactions/correction',
      data,
    ),
  )
}

export interface GetUserReferralsReq {
  id: User['id']
}
export interface GetUserReferralsRes {
  items: (UserPrev & { depth: number, referrals: number })[]
  metadata: { total: number }
}
const getUserReferrals = (data: GetUserReferralsReq) => {
  const { id } = data
  return promiseToResult(
    AxiosInstance().get<GetUserReferralsRes>(
      `/application/users/referrals/${id}?depth=1`,
    ),
  )
}

export interface GetAuthStatsReq {
  userId: number
  userAgent?: string
  ip?: string
}
export interface GetAuthStatsRes {
  byFingerprint: string[]
  byIp: number[]
  byExact: number[]
}
const getAuthStats = (data: GetAuthStatsReq) => {
  return promiseToResult(
    AxiosInstance().get<GetAuthStatsRes>(
      `/application/admin/users/auth-stats?${querystring.stringify(data)}`,
    ),
  )
}

export type GetHeirReq = IdReq
export type GetHeirRes = {
  transferredShares: number
  projectId: number
  transactionId: number | null
  transferAt: string | null
  addedAt: string | null
  id: number
}[]
const getHeir = (data: GetHeirReq) => {
  return promiseToResult(
    AxiosInstance().get<GetHeirRes>(
      `/application/admin/heir/${data.id}`,
    ),
  )
}

export interface PostHeirReq extends IdReq {
  projectId: number
  heirId: number
}
const postHeir = (data: PostHeirReq) => {
  return promiseToResult(
    AxiosInstance().post(
      `/application/admin/heir/transfer/${data.id}`,
      data,
    ),
  )
}

export const UserAPI = {
  getUsers,
  getUser,
  deleteUser,
  patchUser,
  imposter,
  disallowLogin,
  mustReloign,
  transactionsCorrection,
  getUserReferrals,
  getAuthStats,
  getHeir,
  postHeir,
}
