import AxiosInstance from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'

export interface PostLoginDataReq {
  phone: string
  password: string
}
export interface PostLoginDataRes {
  token: string
}
const postData = (data: PostLoginDataReq) => {
  return promiseToResult(
    AxiosInstance().post<PostLoginDataRes>(
      '/application/login',
      data,
    ),
  )
}

export const LoginAPI = {
  postData,
}
