import { createEffect } from 'effector'
import { toast } from 'react-toastify'

import { FinReportAPI } from '../../services/api/FinReport'
import { $navigate } from '../History/store'
import { Routes } from '../../shared/enum'

export const getCategoriesFx = createEffect(FinReportAPI.getCategories)
export const postCategoryFx = createEffect(FinReportAPI.postCategory)
export const getCategoryFx = createEffect(FinReportAPI.getCategory)
export const patchCategoryFx = createEffect(FinReportAPI.patchCategory)

export const getReportsFx = createEffect(FinReportAPI.getReports)
export const getReportFx = createEffect(FinReportAPI.getReport)
export const postReportFx = createEffect(FinReportAPI.postReport)
export const patchReportFx = createEffect(FinReportAPI.patchReport)

postCategoryFx.done.watch(({ result }) => {
  toast.success('Категория создана')
  const navigate = $navigate.getState()
  navigate(`/${Routes.COMPANY}/${Routes.REPORT}/${Routes.CATEGORY}/${result.data.data.id}`)
})

postReportFx.done.watch(({ result }) => {
  toast.success('Репорт создан')
  const navigate = $navigate.getState()
  navigate(`/${Routes.COMPANY}/${Routes.REPORT}/${result.data.data.id}`)
})

patchCategoryFx.done.watch(() => {
  toast.success('Сохранено')
})

patchReportFx.done.watch(() => {
  toast.success('Сохранено')
})
