import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'

import { Routes } from '../../shared/enum'
import { Create as OfficeCreate, Id as OfficeId, Outlet as OfficeOutlet, Root as OfficeRoot } from './Office'
import { Report } from './Report'

export const CompanyController = () => {
  return useRoutes([
    {
      path: `${Routes.OFFICE}/*`,
      element: <OfficeOutlet />,
      children: [
        { path: Routes.ROOT, element: <OfficeRoot /> },
        { path: Routes.CREATE, element: <OfficeCreate /> },
        { path: Routes.ID, element: <OfficeId /> },
      ],
    },
    {
      path: `${Routes.REPORT}/*`,
      element: <Report />,
    },
    { path: '*', element: <Navigate to="/" /> },
  ])
}
