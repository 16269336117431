import React from 'react'
import { useStore } from 'effector-react'
import { usePagination } from '../../../hooks'
import { RootLayout } from '../../../UI/RootLayout'
import { Accordion, Col, InputAutocomplete, RootTable, Select } from '../../../UI'
import { getTransactionsFx } from '../../../../states/Transaction/event'
import { $transactions, $transactionsLoading } from '../../../../states/Transaction/store'
import dayjs from 'dayjs'
import { $defaults } from '../../../../states/Defaults/store'

const col: Col[] = [
  { id: 'id', label: 'ID' },
  { id: 'user', label: 'Юзер ID' },
  { id: 'price', label: 'Цена (центы)', render: (p) => `${p / 100}$` },
  { id: 'shares', label: 'Доли кол-во' },
  { id: 'project', label: 'Проект', render: (project) => project?.symbol },

  {
    id: 'type',
    label: 'Тип',
    render: (value) => {
      const defaults = $defaults.getState()
      return defaults?.transactions?.types.find(({ key }: any) => key === value)?.value || value
    },
  },
  {
    id: 'status',
    label: 'Статус',
    render: (value) => {
      const defaults = $defaults.getState()
      return defaults?.transactions?.statuses.find(({ key }: any) => key === value)?.value || value
    },
  },
  { id: 'comment', label: 'Комментарий' },
  {
    id: 'createdAt',
    label: 'Время создания',
    render: (value) => dayjs(value as string).format('HH:mm DD.MM.YYYY'),
  },
]

export const WithdrawalRoot = () => {
  const defaults = useStore($defaults)
  const transactions = useStore($transactions)
  const transactionsLoading = useStore($transactionsLoading)

  const { query, pagination, handlerPagination, handlerQuery } = usePagination({
    defaults: {
      orderBy: 'createdAt',
      orderDirection: 'desc',
      type: 'withdrawal',
      status: 'in_progress',
      limit: '20',
      page: '1',
    },
    observerQuery: (query) => getTransactionsFx({ query }),
  })

  const onChange = (value: string, trigger: string) => {
    handlerQuery(trigger, value)
  }

  return (
    <RootLayout>
      <Accordion label="Фильтр" defaultExpanded className="filter">
        <InputAutocomplete
          label="ID"
          options={transactions?.items}
          values={query}
          trigger="id"
          isLoading={transactionsLoading}
          onChange={onChange}
        />
        <InputAutocomplete
          label="ID юзера"
          values={query}
          options={transactions?.items}
          trigger="user"
          isLoading={transactionsLoading}
          onChange={onChange}
        />
        <Select
          disabled
          label="Тип"
          isEmpty
          value={query.type}
          options={defaults?.transactions?.types}
          onSelect={(value) => onChange(value, 'type')}
        />
        <Select
          label="Статус"
          isEmpty
          value={query.status}
          options={defaults?.transactions?.statuses}
          onSelect={(value) => onChange(value, 'status')}
        />
        <InputAutocomplete
          values={query}
          label="ID проекта"
          options={transactions?.items}
          trigger="project"
          isLoading={transactionsLoading}
          onChange={onChange}
        />
      </Accordion>

      <RootTable
        title="Список транзакций"
        col={col}
        rows={transactions?.items}
        handleChangePage={(page) => handlerPagination('page', page)}
        handleChangeLimit={(limit) => handlerPagination('limit', limit)}
        handleOrderDirection={(limit) => handlerQuery('orderDirection', limit)}
        length={transactions?.metadata?.total}
        page={pagination.page}
        limit={pagination.limit}
        orderBy={query?.orderBy?.toString()}
        orderDirection={query?.orderDirection?.toString()}
      />
    </RootLayout>
  )
}
