import AxiosInstance from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'

export interface GetInfoRes {
  expiredAt: string
  isInitialized: true
  wid: string
}
const getInfo = () => {
  return promiseToResult(
    AxiosInstance().get<GetInfoRes>(
      '/application/admin/whatsapp/info',
    ),
  )
}

const putRestart = () => {
  return promiseToResult(
    AxiosInstance().put(
      '/application/admin/whatsapp/restart',
    ),
  )
}

export const WhatsappAPI = {
  getInfo,
  putRestart,
}
