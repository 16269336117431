import { combine, createStore } from 'effector'

import { GetProjectRes, GetProjectShareTimerRes, GetProjectsRes } from '../../services/api/Project'

import { createProjectFx, getProjectsFx, getProjectFx, getProjectShareFx, getProjectShareTimerFx } from './event'

export const $projects = createStore<GetProjectsRes['items']>([])
  .on(getProjectsFx.done, (_, { result }) => result.data?.data?.items)

export const $projectsOptions = combine(
  $projects,
  (projects) => projects?.map((p) => ({ key: p.id, value: p.name })),
)

export const $project = createStore<Partial<GetProjectRes>>({})
  .on(getProjectFx.done, (_, { result }) => result.data.data)

export const $projectShare = createStore([] as Record<string, any>[])
  .on(getProjectShareFx.done, (_, { result }) => result.data.data.items.reverse())

export const $projectShareTimer = createStore<Partial<GetProjectShareTimerRes>>({})
  .on(getProjectShareTimerFx.done, (_, { result }) => result.data.data)

export const $projectLoading = createStore(false)
  .on(getProjectsFx.pending, (_, pending) => pending)
  .on(getProjectFx.pending, (_, pending) => pending)
  .on(createProjectFx.pending, (_, pending) => pending)
