import React from 'react'
import { Option } from '../../../shared/UI/Input'
import { useAnalyticReferrals } from '../../../services/api/Analytic'
import { orderDirectionOptions, toDollar } from '../../../shared/utils'
import { Routes } from '../../../shared/enum'
import { NavLink } from 'react-router-dom'
import { Page } from '../../../shared/UI'

const sorterOptions: Option<string>[] = [
  { value: 'Юзеру', key: 'userId' },
  { value: 'Первой линии', key: 'firstDepthCount' },
  { value: 'Не в первой линии', key: 'otherDepthsCount' },
  { value: 'Купивших в первой линии', key: 'firstDepthPurchase' },
  // { value: 'Сумма инвестиций в первой линии', key: 'investmentsReferralsOneDepth' },
  { value: 'Купивших не в первой линии', key: 'otherDepthsPurchase' },
  { value: 'Новых рефералов', key: 'registeredBetweenCount' },
  { value: 'Сумма рефералки', key: 'referralPrizeSum' },
]

export const Referral = () => {
  const { get, handlerQueries, defaultQuery } = useAnalyticReferrals()

  return (
    <Page
      variant="root"
      title="Топ рефоводов"
      onChange={handlerQueries}
      rows={get.data?.data.data.items}
      total={get.data?.data.data.metadata.total}
      defaultFilter={defaultQuery}
      col={[
        { id: 'userId', label: 'Юзер', render: (id) => <NavLink to={`/${Routes.USER}/${id}`}>{id}</NavLink> },
        { id: 'country', label: 'Страна' },
        { id: 'userReceivedReferralsPrizesSum', label: 'Сумма реф призов', render: (price) => `${toDollar(price)} $` },
        { id: 'referralsOneDepthCount', label: 'Новых реф за период (1 линия)' },
        { id: 'referralsRegisteredBetween', label: 'Новых реф за период (вся глубина)' },
        { id: 'referralsCount.firstDepth', label: 'Реф в 1 линии' },
        // { value: 'Сумма инвестиций в первой линии', key: 'investmentsReferralsOneDepth' },
        { id: 'referralsCount.otherDepths', label: 'Реф не в 1 линии' },
        { id: 'referralsWithPurchaseCount.firstDepth', label: 'Реф купили в 1 линии' },
        { id: 'referralsWithPurchaseCount.otherDepths', label: 'Реф купили не в 1 линии' },
      ]}
      filter={[
        {
          defaultExpanded: true,
          label: 'Фильтры',
          fields: [
            { name: 'country', type: 'string', label: 'Страна' },
            { name: 'dateStart', type: 'date', label: 'Дата пользователя от' },
            { name: 'dateEnd', type: 'date', label: 'Дата пользователя до' },
            { name: 'oneDepthReferralsDateStart', type: 'date', label: 'Дата рефа 1 линии от' },
            { name: 'oneDepthReferralsDateEnd', type: 'date', label: 'Дата рефа 1 линии до' },
            { name: 'orderBy', type: 'select', label: 'Сортировать', options: sorterOptions, isEmpty: true },
            { name: 'direction', type: 'select', label: 'Упорядочить', options: orderDirectionOptions, isEmpty: true },
          ],
        },
      ]}
    />
  )
}
