import React from 'react'
import { useRoutes } from 'react-router-dom'

import { Routes } from '../../shared/enum'
import { Edit, Root } from './pages'

export const InstallmentController = () => {
  return useRoutes([
    { path: Routes.ROOT, element: <Root /> },
    { path: Routes.ID, element: <Edit /> },
  ])
}
