import React from 'react'
import { NavLink } from 'react-router-dom'
import { Page } from '../../../../shared/UI'
import { useMoneyBox } from '../../../../services/api/MoneyBox'
import { Routes } from '../../../../shared/enum'
import { toDollar } from '../../../../shared/utils'
import dayjs from 'dayjs'

export const Transactions = () => {
  const { boxId } = useMoneyBox()

  return <Page
    variant="root"
    title="Транзакции в копилке"
    col={[
      { id: 'transaction', label: 'Платеж', render: ({ id }) => <NavLink to={`/${Routes.TRANSACTION}/${id}`}>{id}</NavLink> },
      { id: 'balance', label: 'Сумма', render: (balance) => `${toDollar(balance)} $` },
      { id: 'createdAt', label: 'Дата', render: (date) => dayjs(date).format('LLL') },
      { id: 'users', label: 'Юзеры', render: (users) => users?.map((v: any) => v?.userId).join(',') },
    ]}
    rows={boxId.transactions.data?.items}
    total={boxId.transactions.data?.metadata.total}
    onChange={boxId.transactions.fetch}
    filter={[
      {
        label: 'Фильтры',
        defaultExpanded: true,
        fields: [
          { name: 'id', type: 'number', label: 'Копилка ID' },
          { name: 'userId', type: 'number', label: 'Юзер ID' },
          { name: 'dateStart', type: 'date', label: 'С даты' },
          { name: 'dateEnd', type: 'date', label: 'По дате' },
        ],
      },
    ]}
  />
}
