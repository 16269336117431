/* eslint-disable jsx-a11y/click-events-have-key-events,
jsx-a11y/no-static-element-interactions */
import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { Modal, ModalProps } from './Modal'

export interface ModalWithTriggerProps extends Omit<ModalProps, 'open'> {
  trigger: React.ReactNode
}

export interface ModalWithTriggerHandler {
  toggleModal: () => void
}

export const ModalWithTrigger = forwardRef<ModalWithTriggerHandler, ModalWithTriggerProps>(({
  trigger,
  children,
  ...props
}, ref) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const toggleModal = () => {
    setOpen((prev) => !prev)
  }

  useImperativeHandle(ref, () => ({
    toggleModal,
  }))

  return (
    <>
      <div onClick={handleOpen} style={{ cursor: 'pointer' }}>
        {trigger}
      </div>
      <Modal
        {...props}
        open={open}
        onClose={handleClose}
      >
        {children}
      </Modal>
    </>
  )
})
