/* eslint-disable no-alert */
import { createEffect, createEvent } from 'effector'
import dayjs from 'dayjs'
import { localStore, saveFile } from '../../shared/utils'
import { CommonEnum } from '../../shared/enum'
import { setAuthTokenFx } from '../Auth/event'
import { AppAPI } from '../../services/api/App'

export const setInitFx = createEffect(async () => {
  const token = localStore.get(CommonEnum.AUTH_TOKEN)
  setAuthTokenFx(token)
})

export const getHeapsnapshotFx = createEffect(async () => {
  const variant = await window.prompt('write "application" or "projects"')

  if (variant === 'application') {
    AppAPI.getHeapsnapshotApplication().then((data) => {
      saveFile(JSON.stringify(data.data), `heapsnapshot-application-${dayjs().toISOString()}.json`)
    })
  }

  if (variant === 'projects') {
    AppAPI.getHeapsnapshotProjects().then((data) => {
      saveFile(JSON.stringify(data.data), `heapsnapshot-projects-${dayjs().toISOString()}.json`)
    })
  }
})

export const setThemeModeFx = createEvent<'dark' | 'light'>()
