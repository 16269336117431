import { createEffect } from 'effector'
import { FilesAPI, GetFilesReq, PostFilesReq } from '../../services/api/Files'
import { APIResult, AxiosError } from '../../core/api/Result'

export const postFilesFx = createEffect<PostFilesReq, APIResult<typeof FilesAPI['postFiles']>, AxiosError>(
  FilesAPI.postFiles,
)

export const getFilesFx = createEffect<GetFilesReq, APIResult<typeof FilesAPI['getFiles']>, AxiosError>(
  FilesAPI.getFiles,
)

export const getFilesIdFx = createEffect(FilesAPI.getFilesId)
