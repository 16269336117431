import React from 'react'
import { useRoutes } from 'react-router-dom'
import { Routes } from '../../shared/enum'
import { Page } from '../../shared/UI'
import { Create, Edit, Root } from './Pages'

const NewsController = () => {
  return useRoutes([
    {
      path: `${Routes.ROOT}/*`,
      element: <Page
        variant="outlet"
        links={[
          { title: 'Главная', to: `/${Routes.NEWS}` },
          { title: 'Создать', to: `/${Routes.NEWS}/${Routes.CREATE}` },
        ]}
      />,
      children: [
        { path: Routes.ROOT, element: <Root /> },
        { path: Routes.CREATE, element: <Create /> },
        { path: Routes.ID, element: <Edit /> },
      ],
    },
  ])
}

export default NewsController
