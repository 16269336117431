import AxiosInstance from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'
import { querystring } from '../../shared/utils'

import { Query, WithMetadata } from './_types'
import { Status } from '../../core/api/types'

export interface Verification {
  comment: string
  createdAt: string
  id: number
  photo: string
  status: Status
}

export type GetVerificationsReq = Query
export type GetVerificationsRes = WithMetadata<Verification>
const getVerifications = (data: GetVerificationsReq) => {
  const { query } = data
  return promiseToResult(
    AxiosInstance().get<GetVerificationsRes>(
      `/application/admin/verifications?${querystring.stringify(query)}`,
    ),
  )
}

export interface GetVerificationReq {
  id: string
}
export interface GetVerificationRes extends Verification {
  passport: {
    birthDate: string
    id: number
    name: string
    surname: string
  }
  user: {
    id: number
    login: null
    phone: string
  }
}
const getVerification = (data: GetVerificationReq) => {
  const { id } = data
  return promiseToResult(
    AxiosInstance().get<GetVerificationRes>(
      `/application/admin/verifications/${id}`,
    ),
  )
}

export interface PutVerificationReq {
  id: number
  status: Status
  birthDate: string
  name: string
  surname: string
  comment: string
}
export interface PutVerificationRes {
  status: boolean
}
const putVerification = (data: PutVerificationReq) => {
  const { id, ...body } = data
  return AxiosInstance().put<PutVerificationRes>(
    `/application/admin/verifications/${id}/verify`,
    body,
  )
}

export const VerificationAPI = {
  getVerifications,
  getVerification,
  putVerification,
}
