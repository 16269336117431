import { Message, User } from '../../services/api/Chat'
import message from '../../assets/audio/message.mp4'

export const getChatName = (user?: Partial<User> | null) => {
  const name = !!user?.firstName && !!user?.lastName
    ? `${user?.firstName} ${user?.lastName}`
    : user?.phone

  return `${name}`
}

export const createMessages = (msgArr: Message[]): Message[] => {
  const map = new Map<number, Message>()
  msgArr.forEach((msg) => map.set(msg.id, msg))
  return Array.from(map.values())
}

export const messageNotification = () => {
  const audio = new Audio(message)
  audio.play()
}

export const decodeContent = (text: string) => text
  .replace(/&amp;/g, '&')
  .replace(/&gt;/g, '>')
  .replace(/&lt;/g, '<')
  .replace(/&quot;/g, '"')
  .replace(/&apos;/g, "'")
