export enum Routes {
  ROOT = '',
  CREATE = 'create',
  CATEGORY = 'category',
  ID = ':id',
  LOGIN = 'login',
  USER = 'user',
  LANGUAGE = 'language',
  TRANSLATION = 'translation',
  PAYMENT = 'payment',
  VERIFICATION = 'verification',
  PROJECT = 'project',
  PRICE = 'price',
  RECOVERY = 'recovery',
  WHATSAPP = 'whatsapp',
  TRANSACTION = 'transaction',
  PHONE = 'phone',
  FILES = 'files',
  WITHDRAWAL = 'withdrawal',
  COMPANY = 'company',
  OFFICE = 'office',
  REPORT = 'report',
  CHAT = 'chat',
  LANDING = 'landing',
  ANALYTIC = 'analytic',
  SETTINGS = 'settings',
  CANDIDATE = 'candidate',
  NEWS = 'news',
  INSTALLMENT = 'installment',
  FAQ = 'faq',
  HISTORY = 'history',
  MONEYBOX = 'money-box',
  TASK = 'task',
  NOTIFICATION = 'notification',
  SUPERVISORY = 'supervisory',
  STORY = 'story',
  HEIR = 'heir',
  WINNERS = 'winners',
  PARTICIPANTS = 'participants',
  BALANCE = 'balance',
  TEMPLATE = 'template',
}
