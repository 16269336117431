/**
 * Создание HEX цвета из строки
 *
 * @param {*} string - любая строка
 * @returns строка HEX цвета
 */
export const createHexColorFromString = (string: string) => {
  let hash = 0
  let i

  for (i = 0; i < string.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.substr(-2)
  }

  return color
}
