import React from 'react'
import { useStore } from 'effector-react'
import { Box, CircularProgress, Tooltip, Typography } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

import { RootLayout } from '../../../UI/RootLayout'

import {
  $statisticRecovery,
  $statisticRecoveryPending,
  $statisticTransaction,
  $statisticTransactionPending,
  $statisticUser,
  $statisticUserPending,
  $statisticVerification,
  $statisticVerificationPending,
} from '../../../../states/Statistic/store'
import { NavLink } from 'react-router-dom'
import { Routes } from '../../../enum'

export interface DashboardBlockProps {
  title: string
  description?: string
  to?: string
  loading?: boolean
  data: {
    title: string
    value?: string | number
  }[]
}

const DashboardBlock: React.FC<DashboardBlockProps> = ({
  title,
  description,
  data,
  loading = false,
  to,
}) => {
  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        boxShadow: 1,
        borderRadius: 2,
        p: 2,
      }}
    >
      <Typography gutterBottom variant="h5">
        {to
          ? <NavLink to={to}>
            {title}
          </NavLink>
          : title
        }
        <Tooltip title={description}>
          <HelpOutlineIcon />
        </Tooltip>
      </Typography>

      {data.map(({ title, value }) => {
        return (
          <Typography gutterBottom variant="h6" key={title}>
            {title}: {loading ? <CircularProgress size={16} /> : value}
          </Typography>
        )
      })}
    </Box>
  )
}

export const DashboardRoot = () => {
  const statisticUser = useStore($statisticUser)
  const statisticUserPending = useStore($statisticUserPending)

  const statisticRecovery = useStore($statisticRecovery)
  const statisticRecoveryPending = useStore($statisticRecoveryPending)

  const statisticVerification = useStore($statisticVerification)
  const statisticVerificationPending = useStore($statisticVerificationPending)

  const statisticTransaction = useStore($statisticTransaction)
  const statisticTransactionPending = useStore($statisticTransactionPending)

  const toAbs = (value: string | number | undefined) => {
    return Math.abs(Number(value))
  }

  const toDollar = (cent?: string | number) => {
    const dollar = Number(Number(cent || 0) / 100).toFixed(0)
    return `${toAbs(dollar)} $`
  }

  return (
    <RootLayout>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flexWrap: 'wrap',
        }}
      >
        {/* <DashboardBlock */}
        {/*  loading={statisticUserPending} */}
        {/*  title="Регистрации" */}
        {/*  description="Незаконченные регистрации" */}
        {/*  data={[ */}
        {/*    { title: 'За сегодня', value: toAbs(statisticUser?.candidates?.today) }, */}
        {/*    { title: 'За месяц', value: toAbs(statisticUser?.candidates?.lastMonth) }, */}
        {/*    { title: 'Всего', value: toAbs(statisticUser?.candidates?.total) }, */}
        {/*  ]} */}
        {/* /> */}
        <DashboardBlock
          loading={statisticUserPending}
          title="Юзеры"
          description="Юзеры которые закончили регистрацию"
          to={Routes.USER}
          data={[
            { title: 'За сегодня', value: statisticUser?.users?.today },
            { title: 'За месяц', value: statisticUser?.users?.lastMonth },
            { title: 'Всего', value: statisticUser?.users?.total },
          ]}
        />
        <DashboardBlock
          loading={statisticRecoveryPending}
          title="Восстановления"
          description="Заявки на восстановление пароля"
          to={Routes.RECOVERY}
          data={[
            { title: 'В ожидании', value: statisticRecovery?.total },
            { title: 'За сегодня', value: statisticRecovery?.today },
            { title: 'За месяц', value: statisticRecovery?.lastMonth },
          ]}
        />
        <DashboardBlock
          loading={statisticVerificationPending}
          title="Верификации"
          description="Верификации аккаунтов"
          to={Routes.VERIFICATION}
          data={[
            { title: 'В ожидании', value: statisticVerification?.total },
            { title: 'За сегодня', value: statisticVerification?.today },
            { title: 'За месяц', value: statisticVerification?.lastMonth },
          ]}
        />

        <DashboardBlock
          loading={statisticTransactionPending}
          title="Покупку"
          description="Разовые покупки"
          data={[
            { title: 'За сегодня', value: toDollar(statisticTransaction?.payment?.today) },
            { title: 'За месяц', value: toDollar(statisticTransaction?.payment?.lastMonth) },
            { title: 'Всего', value: toDollar(statisticTransaction?.payment?.total) },
          ]}
        />
        <DashboardBlock
          loading={statisticTransactionPending}
          title="Резервы фак"
          description="Оплата по резервам"
          data={[
            { title: 'За сегодня', value: toDollar(statisticTransaction?.installment?.received.today) },
            { title: 'За месяц', value: toDollar(statisticTransaction?.installment?.received.lastMonth) },
            { title: 'Всего', value: toDollar(statisticTransaction?.installment?.received.total) },
          ]}
        />
        <DashboardBlock
          loading={statisticTransactionPending}
          title="Резервы прогноз"
          description="Програнизуерамая оплата по резервам"
          data={[
            { title: 'За сегодня', value: toDollar(statisticTransaction?.installment?.expected.today) },
            { title: 'За месяц', value: toDollar(statisticTransaction?.installment?.expected.nextMonth) },
            { title: 'Всего', value: toDollar(statisticTransaction?.installment?.expected.total) },
          ]}
        />
        <DashboardBlock
          loading={statisticTransactionPending}
          title="Выводы"
          description="Сумма выводов"
          to={`${Routes.TRANSACTION}/${Routes.WITHDRAWAL}`}
          data={[
            { title: 'За сегодня', value: toDollar(statisticTransaction?.withdrawal?.today) },
            { title: 'За месяц', value: toDollar(statisticTransaction?.withdrawal?.lastMonth) },
            { title: 'Всего', value: toDollar(statisticTransaction?.withdrawal?.total) },
          ]}
        />
      </Box>
    </RootLayout>
  )
}
