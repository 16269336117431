import React from 'react'
import { useRoutes } from 'react-router-dom'

import { Routes } from '../../shared/enum'
import { Create, Id, Root } from './page'

export const StoryController = () => {
  return useRoutes([
    { path: Routes.ROOT, element: <Root /> },
    { path: Routes.CREATE, element: <Create /> },
    { path: Routes.ID, element: <Id /> },
  ])
}
