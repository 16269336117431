import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { RootLayout } from '../RootLayout'
import { PageRoot, PageRootProps } from './PageRoot'
import { PageOutlet, PageOutletProps } from './PageOutlet'
import { PageForm, PageFormProps } from './PageForm'
import { Actions, PageActionProps } from './Actions'
import { PageCustom, PageCustomProps } from './PageCustom'

type Params = { id: string } & Record<string, string> & any

type Variant<T> = PageRootProps<T> | PageOutletProps | PageFormProps<T> | PageCustomProps

export type PageProps<T> = {
  loading?: boolean[]
  onInit?: (params: Params) => void
  actions?: PageActionProps['buttons']
} & Variant<T>

export const Page = <T, >({
  loading = [],
  onInit = () => null,
  actions,
  ...props
}: PageProps<T>) => {
  const { variant } = props
  const params = useParams<Params>()

  useEffect(() => {
    onInit(params as Params)
  }, [JSON.stringify(params)])

  return (
    <RootLayout loading={loading?.find((b) => b)}>
      <Actions buttons={actions} />

      {variant === 'root' &&
      <PageRoot {...props} />
      }
      {variant === 'outlet' &&
      <PageOutlet {...props} />
      }
      {variant === 'form' &&
      <PageForm {...props} />
      }
      {variant === 'custom' &&
      <PageCustom {...props} />
      }
    </RootLayout>
  )
}
