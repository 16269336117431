import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { getStatisticFx } from '../../states/Statistic/event'

export const DashboardController = () => {
  useEffect(() => {
    getStatisticFx()
  }, [])

  return (
    <div>
      <div className="header">
        Админ панель
      </div>

      <Outlet />
    </div>
  )
}
