import enc from 'crypto-js/enc-utf8'
import AES from 'crypto-js/aes'

export const encrypt = (text: string, key: string) => {
  return AES.encrypt(text, key).toString()
}

export const decrypt = (text: string, key: string) => {
  return AES.decrypt(text, key).toString(enc)
}
