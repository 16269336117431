import { useEffect } from 'react'
import { useHashQueryStateObj } from 'use-location-state'
import { filterEmpty } from '../utils'

export declare type QueryStateValue = string | string[] | boolean | number | number[]
export declare type QueryState = Record<string, QueryStateValue>
export type QueryProps<T> = T & { limit: string, page: string }
export type ObserverQuery<T> = (query: T) => void
type QueryLib = Record<string, string | string[]>

interface UseQueryProps<T> {
  defaults?: QueryProps<T>
  observerQuery?: ObserverQuery<T>
}

export const defaultQuery = {
  limit: '15',
  page: '1',
}

export const usePagination = <T>({
  defaults = defaultQuery as QueryProps<T>,
  observerQuery = () => null,
}: UseQueryProps<T>) => {
  const [_query, setQuery] = useHashQueryStateObj(filterEmpty(defaults as any) as QueryLib)
  const query = filterEmpty(Object.assign(_query, {})) as QueryProps<T>

  const handlerQuery = (k: string, v: string | undefined): void => {
    setQuery({
      ..._query,
      page: '1',
      [k]: v || undefined,
    })
  }

  const handlerQueries = (q: Record<string, string>): void => {
    setQuery({
      ..._query,
      ...q,
    })
  }

  const handlerPagination = (key: 'limit' | 'page', value: number): void => {
    setQuery({ ...query, [key]: String(value) })
  }

  useEffect(() => {
    observerQuery(filterEmpty(_query) as T)
  }, [JSON.stringify(query)])

  return {
    query,
    pagination: { page: Number(query.page), limit: Number(query.limit) },
    handlerQuery,
    handlerQueries,
    handlerPagination,
    defaultQuery,
  }
}
