/* eslint-disable @typescript-eslint/no-unused-vars,no-restricted-syntax,guard-for-in */
import React, { useEffect } from 'react'
import { CircularProgress } from '@mui/material'
import { Col, RootTable } from '../RootTable'
import { Accordion, AccordionProps } from '../Accordion'
import { Fields, FieldsProps } from '../Fields'
import { QueryProps, usePagination } from '../../hooks'
import { Modal } from '../Modal'
import { useForm } from 'react-hook-form'
import { Button } from '../Button'
import ExcelJS from 'exceljs'
import dayjs from 'dayjs'

interface Filter extends Omit<AccordionProps, 'children' | 'defaultValue'> {
  fields: FieldsProps['fields']
}

export interface PageRootProps<T> {
  variant: 'root'
  col: Col<T>[]
  title: React.ReactNode
  rows?: T[]
  total?: number
  filter?: Filter[]
  defaultFilter?: FieldsProps['value']
  onChange?: (value: T | any) => void
  download?: boolean
}

export const PageRoot = <T extends Record<string, any>, >({
  col,
  title,
  rows = [],
  total,
  filter = [],
  defaultFilter,
  onChange = () => null,
  download,
}: PageRootProps<T>) => {
  const form = useForm({
    defaultValues: {
      loading: false,
      defaultLimit: 15,
    },
  })
  const { watch, setValue } = form
  const { loading, defaultLimit } = watch()

  const { query, handlerQueries, handlerQuery, handlerPagination, pagination } = usePagination({
    defaults: defaultFilter as QueryProps<T>,
    observerQuery: onChange,
  })

  const handlerFilter = (values: Record<string, string>) => {
    handlerQueries(values)
  }

  const handlerDownload = () => {
    setValue('loading', true)
    handlerPagination('limit', total || rows?.length)
  }

  useEffect(() => {
    if (loading && rows?.length === pagination.limit && pagination.limit !== defaultLimit) {
      const workbook = new ExcelJS.Workbook()
      const ws = workbook.addWorksheet('sheet')

      const columnName = (index: number) => {
        let cname = String.fromCharCode(65 + ((index - 1) % 26))
        if (index > 26) { cname = String.fromCharCode(64 + (index - 1) / 26) + cname }
        return cname
      }

      let headerColumn = 1
      col.map((c, cidx) => {
        // ws.getCell(columnName(headerColumn + cidx) + String(cidx)).value = c.label

        rows.map((r, idx) => {
          const value = c.render ? c.render(r[c.id], r) : String(r[c.id])
          ws.getCell(columnName(headerColumn + cidx) + String(idx)).value = typeof value === 'string' ? value : r[c.id]
        })
      })

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data],
          { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const url = window.URL.createObjectURL(blob)
        const anchor = document.createElement('a')
        anchor.href = url
        anchor.download = `${dayjs().format('LLLL')}.xlsx`
        anchor.click()
        window.URL.revokeObjectURL(url)

        setValue('loading', false)
        handlerPagination('limit', defaultLimit)
      })
    }
  }, [loading, rows])

  return (
    <>
      {filter.map(({ label, fields, ...props }) =>
        <Accordion
          label={label}
          key={String(label)}
          className="filter"
          {...props}
        >
          <Fields
            value={query}
            fields={fields}
            onChange={handlerFilter}
          />
        </Accordion>,
      )}

      {loading
        ? <CircularProgress />
        : <RootTable
            col={col}
            title={title}
            rows={rows}
            handleChangePage={(page) => handlerPagination('page', page)}
            handleChangeLimit={(limit) => handlerPagination('limit', limit)}
            handleOrderDirection={(limit) => handlerQuery('orderDirection', limit)}
            length={total || rows.length}
            page={pagination.page}
            limit={pagination.limit}
            limitOptions={[pagination.limit]}
            onDownload={download
              ? handlerDownload
              : undefined
          }
        />
      }
    </>
  )
}
