import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { useStore } from 'effector-react'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'react-phone-input-2/lib/material.css'
import ruLocale from 'dayjs/locale/ru'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { ToastContainer } from 'react-toastify'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import { AppRouter } from './app/view/AppRouter'
import AppBar from './app/shared/components/App/AppBar'
import { $defaultsLoading } from './app/states/Defaults/store'
import { setNavigate } from './app/states/History/event'
import { setInitFx } from './app/states/App/event'
import { $isInit } from './app/states/App/store'

dayjs.extend(customParseFormat)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.locale('ru')

const queryClient = new QueryClient()

function App() {
  const navigate = useNavigate()

  const isInit = useStore($isInit)

  const defaultsLoading = useStore($defaultsLoading)

  useEffect(() => {
    setInitFx()
  }, [])

  useEffect(() => {
    setNavigate(navigate)
  }, [navigate])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ruLocale}>
      <QueryClientProvider client={queryClient}>
        {isInit && !defaultsLoading ?
          <AppBar>
            <AppRouter />
          </AppBar>
          :
          <CircularProgress />
        }
        <ToastContainer />
      </QueryClientProvider>
    </LocalizationProvider>
  )
}

export default App
