import React from 'react'
import dayjs from 'dayjs'

import { Page } from '../../../shared/UI'
import { useStory } from '../../../services/api/Story'
import { Routes } from '../../../shared/enum'

export const Root = () => {
  const { get } = useStory()

  return <Page
    variant="root"
    title="Сторис"
    actions={[
      { type: 'link', to: Routes.CREATE, title: 'Создать' },
    ]}
    rows={get.data?.items}
    onChange={get.fetch}
    loading={[get.loading]}
    col={[
      { id: 'id', label: 'ID' },
      { id: 'adminName', label: 'Название' },
      { id: 'likes', label: 'Лайки' },
      { id: 'views', label: 'Просмотры' },
      { id: 'isActive', label: 'Активно' },
      { id: 'createdAt', label: 'Создано', render: (date) => dayjs(date).fromNow() },
    ]}
  />
}
