import React from 'react'
import { useStore } from 'effector-react'

import { Button, Input } from '../../../UI'
import { $info } from '../../../../states/Whatsapp/store'
import { WhatsappAPI } from '../../../../services/api/Whatsapp'
import { getInfoFx } from '../../../../states/Whatsapp/event'

export const WhatsappRoot = () => {
  const info = useStore($info)

  const handlerRestart = async () => {
    await WhatsappAPI.putRestart()
    getInfoFx(null)
  }

  return (
    <div className="controller">
      <Input
        sx={{ mt: 2 }}
        label="Номер"
        disabled
        value={(info?.wid || '').split('@')[0]}
      />

      <Button onClick={handlerRestart}>
        Перезапустить
      </Button>
    </div>
  )
}
