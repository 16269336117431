import React from 'react'
import { InputAutocomplete } from '../../../UI/Input'
import { Accordion, Col, RootLayout, RootTable } from '../../../UI'
import { useStore } from 'effector-react'
import { $isPending, $translations } from '../../../../states/Translation/store'
import { usePagination } from '../../../hooks'
import { getTranslationsFx } from '../../../../states/Translation/event'

const col: Col[] = [
  { id: 'alias', label: 'Алиас' },
  { id: 'text', label: 'Перевод' },
]

export const TranslationRoot = () => {
  const translations = useStore($translations)
  const isPending = useStore($isPending)

  const { query, pagination, handlerPagination, handlerQuery } = usePagination({
    defaults: { orderBy: 'id', orderDirection: 'desc', limit: '20', page: '1' },
    observerQuery: (query) => getTranslationsFx({ query }),
  })

  const onChange = (value: string, trigger: string) => {
    handlerQuery(trigger, value)
  }

  return (
    <RootLayout>
      <Accordion label="Фильтр" defaultExpanded className="filter">
        <InputAutocomplete
          label="Алиас"
          options={translations?.items || []}
          trigger="alias"
          values={query}
          isLoading={isPending}
          onChange={onChange}
        />
        <InputAutocomplete
          label="Перевод"
          options={translations?.items || []}
          trigger="text"
          values={query}
          isLoading={isPending}
          onChange={onChange}
        />
      </Accordion>

      <RootTable
        toLink="alias"
        rows={translations?.items || []}
        col={col}
        title="Список переводов"
        limitOptions={[10, 20, 30]}
        handleChangePage={(page) => handlerPagination('page', page)}
        handleChangeLimit={(limit) => handlerPagination('limit', limit)}
        handleOrderDirection={(limit) => handlerQuery('orderDirection', limit)}
        length={translations?.metadata?.total || 0}
        page={pagination.page}
        limit={pagination.limit}
        orderBy={query?.orderBy?.toString() || 'id'}
        orderDirection={query?.orderDirection?.toString() || 'desc'}
      />
    </RootLayout>
  )
}
