import AxiosInstance from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'
import { querystring } from '../../shared/utils'

import { Query, WithMetadata } from './_types'
import { Language } from './Language'

interface Translation {
  alias: string
  id: number
  language: Language
  text: string
}

// Get Translations
export type GetTranslationsReq = Query
export type GetTranslationsRes = WithMetadata<Translation[]>
const getTranslations = (data: GetTranslationsReq) => {
  const { query } = data
  return promiseToResult(
    AxiosInstance().get<GetTranslationsRes>(
      `/application/admin/translations?${querystring.stringify(query)}`,
    ),
  )
}

export type GetTranslationsAliasRes = Record<string, Record<string, string>>
const getTranslationsAlias = () => {
  return promiseToResult(
    AxiosInstance().get<GetTranslationsAliasRes>(
      '/application/translations',
    ),
  )
}

// Get Alias
export interface GetAliasTranslationsReq {
  id: string
}
export type GetAliasTranslationsRes = Array<Translation>
const getAliasTranslations = (data: GetAliasTranslationsReq) => {
  const { id } = data
  return promiseToResult(
    AxiosInstance().get<GetAliasTranslationsRes>(
      `/application/admin/translations/${id}`,
    ),
  )
}

// Patch Alias
export interface PatchAliasTranslationsReq {
  id: string
  alias: string
  translations: { code: string; text: string }[]
}
export type PatchAliasTranslationsRes = Array<Translation>
const patchAliasTranslations = (data: PatchAliasTranslationsReq) => {
  const { id, ...body } = data
  return promiseToResult(
    AxiosInstance().patch<PatchAliasTranslationsRes>(
      `/application/admin/translations/${id}`,
      body,
    ),
  )
}

// Add Alias
export interface PostAliasTranslationsReq {
  alias: string
  translations: { code: string; text: string }[]
}
export type PostAliasTranslationsRes = Array<Translation>
const postAliasTranslations = (data: PostAliasTranslationsReq) => {
  const { ...body } = data
  return promiseToResult(
    AxiosInstance().post<PostAliasTranslationsRes>(
      '/application/admin/translations',
      body,
    ),
  )
}

// Delete Alias
export interface DeleteAliasTranslationsReq {
  id: string
}
export interface DeleteAliasTranslationsRes {
  status: boolean
}
const deleteAliasTranslations = (data: DeleteAliasTranslationsReq) => {
  const { id } = data
  return promiseToResult(
    AxiosInstance().delete<DeleteAliasTranslationsRes>(
      `/application/admin/translations/${id}`,
    ),
  )
}

export const TranslationAPI = {
  getTranslations,
  getAliasTranslations,
  patchAliasTranslations,
  postAliasTranslations,
  deleteAliasTranslations,
  getTranslationsAlias,
}
