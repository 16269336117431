import { createEffect } from 'effector'
import { toast } from 'react-toastify'

import { APIResult, AxiosError } from '../../core/api/Result'
import {
  GetPaymentsReq,
  GetPaymentReq,
  PostPaymentReq,
  PatchPaymentReq,
  DeletePaymentReq,
  PaymentAPI,
} from '../../services/api/Payment'
import { Routes } from '../../shared/enum'
import { $navigate } from '../History/store'

export const getPaymentsFx = createEffect<GetPaymentsReq, APIResult<typeof PaymentAPI['getPayments']>, AxiosError>(
  PaymentAPI.getPayments,
)

export const getPaymentFx = createEffect<GetPaymentReq, APIResult<typeof PaymentAPI['getPayment']>, AxiosError>(
  PaymentAPI.getPayment,
)

export const createPaymentFx = createEffect<PostPaymentReq, APIResult<typeof PaymentAPI['postPayment']>, AxiosError>(
  PaymentAPI.postPayment,
)

export const updatePaymentFx = createEffect<PatchPaymentReq, APIResult<typeof PaymentAPI['patchPayment']>, AxiosError>(
  PaymentAPI.patchPayment,
)

export const removePaymentFx = createEffect<DeletePaymentReq, APIResult<typeof PaymentAPI['deletePayment']>, AxiosError>(
  PaymentAPI.deletePayment,
)

updatePaymentFx.done.watch(() => {
  toast.success('Обновлено')
})

updatePaymentFx.fail.watch(() => {
  toast.error('Ошибка')
})

createPaymentFx.done.watch(({ result }) => {
  const navigate = $navigate.getState()
  navigate(`${Routes.PAYMENT}/${result.data.data.id}`)
})

createPaymentFx.fail.watch(({ error }) => {
  toast.error(error.data.message)
})

removePaymentFx.done.watch(() => {
  const navigate = $navigate.getState()
  navigate(Routes.PAYMENT)
})

removePaymentFx.fail.watch(({ error }) => {
  toast.error(error.data.message)
})
