import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'

import { Role, Routes } from '../../shared/enum'
import { Button, RoleGuard } from '../../shared/UI'

const ProjectController = () => {
  return (
    <div>
      <div className="header">
        <NavLink to={`/${Routes.PROJECT}`}>
          <Button>
            Главная
          </Button>
        </NavLink>

        <RoleGuard role={Role.ADMIN}>
          <NavLink to={`/${Routes.PROJECT}/${Routes.CREATE}`}>
            <Button color="secondary">
              Создать
            </Button>
          </NavLink>
        </RoleGuard>
      </div>

      <Outlet />
    </div>
  )
}

export default ProjectController
