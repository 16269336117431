/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { useStore } from 'effector-react'
import { useNotification } from '../../../services/api/Notification'
import { Page } from '../../../shared/UI'
import { $defaults } from '../../../states/Defaults/store'
import { Routes } from '../../../shared/enum'

export const Create = () => {
  const defaults = useStore($defaults)
  const { post } = useNotification()

  return (
    <Page
      variant="form"
      title="Уведомление создать"
      button="Создать"
      onSubmit={post.fetch}
      actions={[
        { type: 'link', title: 'Главная', to: `/${Routes.NOTIFICATION}` },
      ]}
      fields={[
        { name: 'type', type: 'select', label: 'Тип', options: defaults.notifications.types },
        { name: 'notification.variation', type: 'string', label: 'Вариант', hide: ({ type }) => !type || type !== 'promotion' },
        { name: 'notification.group', type: 'array', label: 'Юзеры ID' },
        { name: 'notification.title.content', type: 'content', label: 'Заголовок', mode: 'text', hide: ({ type }) => !type || type === 'social' },
        { name: 'notification.description.content', type: 'content', label: 'Описание', mode: 'text', hide: ({ type }) => !type || type === 'social' },
        { name: 'notification.isActive', type: 'switch', label: 'Активен', hide: ({ type }) => !type || type === 'social' },
        { name: 'notification.photo', type: 'file', label: 'Фото', accept: 'img', hide: ({ type }) => !type || type === 'social' },
        { name: 'notification.link', type: 'string', label: 'Ссылка', hide: ({ type }) => !type || type === 'social' },
        { name: 'notification.instance', type: 'select', label: 'Методы рассылки', options: defaults.notifications.socials, hide: ({ type }) => type !== 'social' },
        { name: 'notification.text.content', type: 'content', label: 'Текст', mode: 'text', hide: ({ type }) => type !== 'social' },
        { name: 'notification.activeUntil', type: 'date', label: 'Действует до', hide: ({ type }) => type !== 'promotion' },
      ]}
    />
  )
}
