import { createStore } from 'effector'

import { GetAliasTranslationsRes, GetTranslationsRes } from '../../services/api/Translation'

import {
  deleteAliasTranslationsFx,
  getAliasTranslationsFx,
  getTranslationsFx,
  patchAliasTranslationsFx,
  postAliasTranslationsFx,
} from './event'

export const $translations = createStore<Partial<GetTranslationsRes>>({}).on(
  getTranslationsFx.done,
  (_, { result }) => result.data.data,
)

export const $aliasTranslations = createStore<GetAliasTranslationsRes>([]).on(
  getAliasTranslationsFx.done,
  (_, { result }) => result.data.data,
)

export const $isPending = createStore(false).on(getTranslationsFx.pending, (_, pending) => pending)

export const $isPendingGetAliasTranslations = createStore(false).on(
  getAliasTranslationsFx.pending,
  (_, pending) => pending,
)

export const $isPendingPatchAliasTranslations = createStore(false).on(
  patchAliasTranslationsFx.pending,
  (_, pending) => pending,
)

export const $isPendingPostAliasTranslations = createStore(false).on(
  postAliasTranslationsFx.pending,
  (_, pending) => pending,
)

export const $isPendingDeleteAliasTranslations = createStore(false).on(
  deleteAliasTranslationsFx.pending,
  (_, pending) => pending,
)
