import React from 'react'
import { ButtonWithConfirm, Page, RoleGuard } from '../../../UI'
import { getHeirFx, postHeirFx } from '../../../../states/User/event'
import { $heir, $heirLoading } from '../../../../states/User/store'
import { useStore } from 'effector-react'
import { NavLink, useParams } from 'react-router-dom'
import { Role, Routes } from '../../../enum'
import dayjs from 'dayjs'
import { getOptionsValue } from '../../../utils'
import { $projectsOptions } from '../../../../states/Project/store'

export const UserHeir = () => {
  const { id: userId } = useParams()
  const heir = useStore($heir)
  const loading = useStore($heirLoading)
  const projects = useStore($projectsOptions)

  return (
    <Page
      onInit={getHeirFx}
      variant="root"
      title={`Наследжники пользователя ID: ${userId}`}
      rows={heir}
      loading={[loading]}
      col={[
        { id: 'id', label: 'Наследник', render: (id) => <NavLink to={`/${Routes.USER}/${id}`}>{id}</NavLink> },
        { id: 'projectId', label: 'Проект', render: (value) => getOptionsValue(projects, value) },
        { id: 'transferredShares', label: 'Кол-во долей' },
        { id: 'addedAt', label: 'Установлен', render: (date) => dayjs(date).format('LLL') },
        { id: 'transferAt', label: 'Передано', render: (date) => date && dayjs(date).format('LLL') },
        { id: 'transactionId', label: 'Транзакция', render: (id) => <NavLink to={`/${Routes.TRANSACTION}/${id}`}>{id}</NavLink> },
        {
          id: 'id',
          label: '',
          render: (id, raw) =>
            <RoleGuard role={Role.ADMIN}>
              <ButtonWithConfirm
                disabled={!!raw.transactionId}
                onClick={() => postHeirFx({
                  id: userId,
                  projectId: raw.projectId,
                  heirId: raw.id,
                }).finally(() => getHeirFx({ id: userId }))}
                size="small"
              >
                Передать
              </ButtonWithConfirm>
            </RoleGuard>,
        },
      ]}
    />
  )
}
