import React from 'react'
import { useStore } from 'effector-react'

import { Page } from '../../../UI'
import { $defaults } from '../../../../states/Defaults/store'
import { $payment, $paymentLoading } from '../../../../states/Payment/store'
import { getPaymentFx, updatePaymentFx } from '../../../../states/Payment/event'

const PaymentEdit: React.FC = () => {
  const defaults = useStore($defaults)
  const payment = useStore($payment)
  const paymentLoading = useStore($paymentLoading)

  return (
    <Page
      variant="form"
      button="Сохранить"
      loading={[paymentLoading]}
      value={payment as any}
      onInit={getPaymentFx}
      onSubmit={updatePaymentFx}
      fields={[
        { name: 'id', type: 'string', label: 'ID' },
        { name: 'name', type: 'string', label: 'Название' },
        { name: 'settings.isEnabled', type: 'switch', label: 'Включен' },
        { name: 'settings.isWithdrawal', type: 'switch', label: 'Вывод' },
        { name: 'settings.visibleRoles', type: 'select', label: 'Доступен', multiple: true, options: defaults?.users?.roles },
        { name: 'type', type: 'select', label: 'Тип', options: defaults?.payments?.types },
        { name: 'title.content', type: 'content', label: 'Заголовок', mode: 'text' },
        { name: 'description.content', type: 'content', label: 'Описание', mode: 'text' },
        { name: 'icon', type: 'file', label: 'Иконка', accept: 'img' },
        { name: 'settings.secrets.paymentMin', type: 'cent', label: 'Минимальный платеж' },
        { name: 'settings.secrets.paymentMax', type: 'cent', label: 'Максимальный платеж' },
        { name: 'settings.secrets.type', type: 'select', label: 'Тип настроек', options: defaults?.payments?.types },
        // crypto
        { name: 'settings.secrets.apiKey', type: 'string', label: 'apiKey', hide: (v) => v?.settings?.secrets?.type !== 'crypto' },
        { name: 'settings.secrets.currencyId', type: 'string', label: 'currencyId', hide: (v) => v?.settings?.secrets?.type !== 'crypto' },
        { name: 'settings.secrets.withdrawalMin', type: 'cent', label: 'withdrawalMin', hide: (v) => v?.settings?.secrets?.type !== 'crypto' },
        { name: 'settings.secrets.withdrawalMax', type: 'cent', label: 'withdrawalMax', hide: (v) => v?.settings?.secrets?.type !== 'crypto' },
        { name: 'settings.secrets.currencyIcon', type: 'string', label: 'currencyIcon', hide: (v) => v?.settings?.secrets?.type !== 'crypto' },
        // themerchmoney
        { name: 'settings.secrets.methodId', type: 'string', label: 'methodId', hide: (v) => v?.settings?.secrets?.type !== 'themerchmoney' },
        // qvard
        { name: 'settings.secrets.paymentSystemId', type: 'number', label: 'paymentSystemId', hide: (v) => v?.settings?.secrets?.type !== 'qvard' },
        // discounts
        {
          name: 'settings.discounts',
          type: 'fields',
          label: 'Скидки',
          fields: [
            { name: 'discountType', type: 'select', label: 'Тип', options: defaults?.payments?.discounts?.types },
            { name: 'discountConditions', type: 'select', label: 'Условия', options: defaults?.payments?.discounts?.conditions },
            { name: 'discountValue', type: 'number', label: 'Значение скидки' },
            { name: 'min', type: 'cent', label: 'Минимальная сумма платежа' },
            { name: 'max', type: 'cent', label: 'Максимльаная сумма платежа' },
          ],
        },
      ]}
    />
  )
}

export default PaymentEdit
