import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'

import { Routes } from '../../shared/enum'

import { Button } from '../../shared/UI/Button'
import { RootLayout } from '../../shared/UI'

export const WhatsappController = () => {
  return (
    <RootLayout>
      <NavLink to={Routes.PHONE}>
        <Button color="primary" variant="contained">
          Поменять номер
        </Button>
      </NavLink>

      <Outlet />
    </RootLayout>
  )
}
