import axios from 'axios'
import { MAIN_HEADER_REQUEST } from '../../core/api/constants'
import { useMutation, useQuery } from '@tanstack/react-query'

const bin = axios.create({
  baseURL: 'https://binance.service.denistiaglin.com/api',
  headers: {
    ...MAIN_HEADER_REQUEST,
    'Content-Type': 'application/json',
  },
  validateStatus: (status) => {
    return status < 500 // Reject only if the status code is greater than or equal to 500
  },
})

export interface GetBalanceRes {
  balance: number
}
const getBalance = () => {
  return bin.get<GetBalanceRes>(
    '/withdraw/USDT/balance',
  )
}

export interface PostWithdrawReq {
  address: string
  value: number
  password: string
}
export interface PostWithdrawRes {
  info: any
  status: boolean
  error?: string
}
const postWithdraw = (data: PostWithdrawReq) => {
  return bin.post<PostWithdrawRes>(
    '/withdraw/USDT',
    data,
  )
}

export const BinanceAPI = {
  getBalance,
  postWithdraw,
}

export const useBinance = () => {
  const balance = useQuery(['BinanceAPI.getBalance'], () => BinanceAPI.getBalance(), { enabled: false })
  const withdraw = useMutation(BinanceAPI.postWithdraw)

  return {
    balance,
    withdraw,
  }
}
