import React, { useEffect } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { Box, Menu, MenuItem } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useStore } from 'effector-react'
import cx from 'classnames'
import dayjs from 'dayjs'

import styles from './VerificationEdit.module.scss'

import { Accordion, Button, DatePicker, Input, ModalWithTrigger, RootLayout, Select, RoleGuard } from '../../../UI'
import { $defaults } from '../../../../states/Defaults/store'
import { $verification, $verificationLoading, $verifications } from '../../../../states/Verification/store'
import { getVerificationFx, getVerificationsFx, putVerificationFx } from '../../../../states/Verification/event'
import { GetVerificationRes } from '../../../../services/api/Verification'

import { Status } from '../../../../core/api/types'
import { Role, Routes, VerificationStatus } from '../../../enum'
import { clearText } from '../../../utils'

const listReject = [
  {
    label: 'На фото не документы',
    value: 'You need to upload a photo of your identity document',
  },
  {
    label: 'Фото не с той стороны',
    value: 'Please upload a photo from the back of the document where your face is visible. Document number and date of issue',
  },
  {
    label: 'Фото нечитаемое',
    value: 'Unreadable photo please send a readable photo',
  },
]

export const VerificationEdit: React.FC = () => {
  const defaults = useStore($defaults)
  const verification = useStore($verification)
  const verifications = useStore($verifications)
  const verificationLoading = useStore($verificationLoading)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const { id } = useParams()
  const navigate = useNavigate()

  const method = useForm<GetVerificationRes>()
  const {
    getValues,
    watch,
    setValue,
  } = method
  const {
    status,
    passport,
    photo,
    comment,
  } = watch()

  const isRejected = status === Status.REJECTED
  const isPending = status === Status.PENDING
  const isAccepted = status === Status.ACCEPTED
  const isAcceptedDisabled = !passport?.birthDate || !status || !passport.name.trim() || !passport.surname.trim()

  useEffect(() => {
    Object
      .entries(verification)
      .map(([key, value]) => setValue(key as keyof GetVerificationRes, value))
  }, [verification])

  useEffect(() => {
    if (id) {
      getVerificationFx({ id })
    }
  }, [id])

  useEffect(() => {
    if (verification?.user?.id) {
      getVerificationsFx({
        query: {
          userId: verification?.user?.id,
          limit: 1000,
          page: 1,
        },
      })
    }
  }, [verification?.user?.id])

  const handlerUpdate = async () => {
    const values = getValues()
    await putVerificationFx({
      id: values.id,
      status: values.status,
      comment: values.comment,
      birthDate: values?.passport.birthDate,
      name: values?.passport.name.trim(),
      surname: values?.passport.surname.trim(),
    })
  }

  const handlerInput = (name: string, value: string) => {
    if (name === 'passport.birthDate') {
      return setValue(name, dayjs.utc(value.toString()).utcOffset(0, true).toISOString())
    }
    setValue(
      name as keyof GetVerificationRes,
      value,
    )
  }

  const handlerGood = () => {
    handlerInput('status', VerificationStatus.ACCEPTED)
    handlerInput('comment', '')
  }

  const handlerReject = (comment: string) => {
    handlerInput('status', VerificationStatus.REJECTED)
    handlerInput('comment', comment)
    setAnchorEl(null)
  }

  return (
    <RootLayout loading={verificationLoading} empty={!verification.id}>
      <div className="header">
        <NavLink to={`/${Routes.USER}/${verification.user?.id}`}>
          <Button>
            К юзеру - ID: {verification.user?.id}
          </Button>
        </NavLink>
      </div>

      <Select
        value={verification?.id}
        label="Все заявки"
        options={verifications?.items?.map(({ id, status }) => ({
          key: id,
          value: `ID: ${id} - ${defaults?.verifications?.types
            .find(({ key }: Record<string, string>) => key === status)?.value}`,
        }))}
        onSelect={(id) => navigate(`/${Routes.VERIFICATION}/${id}`)}
      />

      <Box className={styles.wrapper}>
        <RoleGuard role={Role.ADMIN}>
          <Accordion label="Фотография" defaultExpanded>
            <ModalWithTrigger
              trigger={
                <img
                  src={photo}
                  alt=""
                />
              }
            >
              <img
                src={photo}
                alt=""
              />
            </ModalWithTrigger>
          </Accordion>
        </RoleGuard>

        <div className={cx('controller', styles.controller)}>
          <Input
            disabled
            name="id"
            label="ID"
            value={verification.id}
            onChange={(e) => handlerInput('id', e.target.value)}
            InputLabelProps={{ shrink: !!verification.id }}
          />

          <Select
            name="status"
            label="Статус"
            options={defaults?.verifications?.types}
            value={status}
            onSelect={(value) => handlerInput('status', value)}
          />

          <Input
            name="name"
            label="Имя"
            value={passport?.name}
            onChange={(e) => setValue('passport.name', clearText(e.target.value))}
            InputLabelProps={{ shrink: !!passport?.name }}
          />

          <Input
            name="surname"
            label="Фамилия"
            value={passport?.surname}
            onChange={(e) => setValue('passport.surname', clearText(e.target.value))}
            InputLabelProps={{ shrink: !!passport?.surname }}
          />

          <DatePicker
            label="Дата рождения"
            value={dayjs.utc(passport?.birthDate)}
            onSelect={(date) => handlerInput('passport.birthDate', date.toDate().toString())}
          />

          <Input
            name="comment"
            label="Комментарий"
            value={comment}
            onChange={(e) => handlerInput('comment', e.target.value)}
            InputLabelProps={{ shrink: !!comment }}
          />

          <RoleGuard role={Role.ADMIN}>
            <Box sx={{ my: '16px', gap: '8px', display: 'flex' }}>
              <Button
                color="error"
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                Отказать
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
              >
                {listReject.map(({ label, value }) =>
                  <MenuItem
                    key={label}
                    onClick={() => handlerReject(value)}
                  >
                    {label}
                  </MenuItem>,
                )}
              </Menu>
              <Button color="success" onClick={handlerGood}>
                Все верно
              </Button>
            </Box>
          </RoleGuard>

          <RoleGuard role={Role.ADMIN}>
            {isAccepted &&
            <Button
              color="success"
              onClick={handlerUpdate}
              disabled={isAcceptedDisabled}
            >
              Подтвердить
            </Button>
            }
            {isRejected &&
            <Button
              color="error"
              onClick={handlerUpdate}
            >
              Отклонить
            </Button>
            }

            {isPending &&
            <Button
              color="primary"
              onClick={handlerUpdate}
            >
              Сохранить
            </Button>
            }
          </RoleGuard>
        </div>

      </Box>
    </RootLayout>
  )
}
