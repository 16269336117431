import { createStore } from 'effector'

import { GetLanguagesRes, Language } from '../../services/api/Language'

import {
  getLanguagesFx,
  getLanguageFx,
  patchLanguageFx,
  postLanguageFx,
  deleteLanguageFx,
} from './event'

export const $languages = createStore<GetLanguagesRes>([])
  .on(getLanguagesFx.done, (_, { result }) => result.data.data)

export const $language = createStore<Partial<Language>>({})
  .on(getLanguageFx.done, (_, { result }) => result.data.data[0])

export const $languageLoading = createStore(false)
  .on(getLanguagesFx.pending, (_, pending) => pending)
  .on(patchLanguageFx.pending, (_, pending) => pending)
  .on(postLanguageFx.pending, (_, pending) => pending)
  .on(deleteLanguageFx.pending, (_, pending) => pending)
