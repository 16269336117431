import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useStore } from 'effector-react'
import { Typography } from '@mui/material'

import { Input } from '../../UI/Input'
import { RootLayout } from '../../UI/RootLayout'

import { getFilesIdFx } from '../../../states/Files/event'
import { $filesId, $filesIdLoading } from '../../../states/Files/store'

export const FilesId = () => {
  const { id } = useParams()

  const filesId = useStore($filesId)
  const filesIdLoading = useStore($filesIdLoading)

  useEffect(() => {
    getFilesIdFx({
      id: Number(id),
    })
  }, [id])

  return (
    <RootLayout
      empty={!filesId.id}
      loading={filesIdLoading}
    >
      <Typography variant="h5">Обзор файла</Typography>

      <Input
        label="ID"
        value={filesId.id}
        disabled
      />

      <Input
        label="Название"
        value={filesId.name}
        disabled
      />

      <Input
        label="Сылка"
        value={filesId.url}
        disabled
      />

      <Input
        label="Вес"
        value={filesId.size}
        disabled
      />

      <Input
        label="Тип"
        value={filesId.contentType}
        disabled
      />
    </RootLayout>
  )
}
