import AxiosInstance from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'
import { querystring } from '../../shared/utils'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { IdReq, PaginationQuery, WithMetadata } from './_types'
import { toast } from 'react-toastify'
import { Routes } from '../../shared/enum'
import { useNavigate } from 'react-router-dom'
import { defaultQuery } from '../../shared/hooks'
import { $navigate } from '../../states/History/store'

export type GetStoriesRes = WithMetadata<any>
const getStories = (data: PaginationQuery) => {
  return promiseToResult(
    AxiosInstance().get<GetStoriesRes>(
      `/application/admin/stories?${querystring.stringify(data)}`,
    ),
  )
}

const postStories = (data: Record<string, any>) => {
  return promiseToResult(
    AxiosInstance().post<any>(
      '/application/admin/stories',
      data,
    ),
  )
}

const getStoryId = (data: IdReq) => {
  return promiseToResult(
    AxiosInstance().get<any>(
      `/application/admin/stories/${data.id}`,
    ),
  )
}

const patchStoryId = (data: IdReq) => {
  return promiseToResult(
    AxiosInstance().patch<any>(
      `/application/admin/stories/${data.id}`,
      data,
    ),
  )
}

const deleteStoryId = (data: IdReq) => {
  return promiseToResult(
    AxiosInstance().delete(
      `/application/admin/stories/${data.id}`,
    ),
  )
}

export const StoryAPI = {
  getStories,
  postStories,
  getStoryId,
  patchStoryId,
  deleteStoryId,
}

export const useStory = () => {
  const [query, setQuery] = useState({
    stories: defaultQuery as PaginationQuery,
    storyId: {} as IdReq,
  })
  const navigate = useNavigate()

  const getStories = useQuery(
    ['StoryAPI.getStories', query.stories],
    () => StoryAPI.getStories(query.stories),
    { enabled: false },
  )
  const getStoryId = useQuery(
    ['StoryAPI.getStoryId', query.storyId],
    () => StoryAPI.getStoryId(query.storyId),
    { enabled: false },
  )
  const postStories = useMutation(StoryAPI.postStories)
  const patchStoryId = useMutation(StoryAPI.patchStoryId)
  const deleteStoryId = useMutation(StoryAPI.deleteStoryId)

  return {
    get: {
      fetch: (stories: PaginationQuery) => {
        setQuery((prev) => ({ ...prev, stories }))
        setTimeout(getStories.refetch, 600)
      },
      loading: getStories.isLoading,
      data: getStories.data?.data.data,
    },
    post: {
      fetch: async (data: any) => {
        const res = await postStories.mutateAsync(data)
        toast.success('Сторис создан')
        navigate(`/${Routes.STORY}/${res.data.data.id}`)
      },
      loading: postStories.isLoading,
    },
    id: {
      get: {
        fetch: (storyId: IdReq) => {
          setQuery((prev) => ({ ...prev, storyId }))
          setTimeout(getStoryId.refetch, 500)
        },
        loading: getStoryId.isFetching,
        data: getStoryId.data?.data.data,
      },
      patch: {
        fetch: async (data: any) => {
          await patchStoryId.mutateAsync(data)
          toast.success('Сохранено')
          await getStoryId.refetch()
        },
        loading: patchStoryId.isLoading,
      },
      delete: {
        fetch: async (id: IdReq) => {
          await deleteStoryId.mutateAsync(id)
          toast.success('Удалено')
          const navigate = $navigate.getState()
          navigate(`/${Routes.STORY}`)
        },
        loading: deleteStoryId.isLoading,
      },
    },
  }
}
