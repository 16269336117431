import { createStore } from 'effector'

import { GetVerificationRes, GetVerificationsRes } from '../../services/api/Verification'

import { getVerificationsFx, getVerificationFx } from './event'

export const $verifications = createStore<Partial<GetVerificationsRes>>({})
  .on(getVerificationsFx.done, (_, { result }) => result.data.data)
export const $isPendingVerifications = createStore(false)
  .on(getVerificationsFx.pending, (_, pending) => pending)

export const $verification = createStore<Partial<GetVerificationRes>>({})
  .on(getVerificationFx.done, (_, { result }) => result.data.data)
export const $verificationLoading = createStore(false)
  .on(getVerificationFx.pending, (_, pending) => pending)
