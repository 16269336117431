import React from 'react'
import { useRoutes } from 'react-router-dom'

import { Role, Routes } from '../../shared/enum'
import { Balance, Participants, Root, Settings, TaskSettingsController, Transactions, Winners } from './pages'
import { TaskController } from './pages/Task'
import { RootRouter } from '../../shared/UI/RootRouter'

export const MoneyBox = () => {
  return useRoutes([
    { path: Routes.ROOT, element: <Root /> },
    { path: Routes.SETTINGS, element: <RootRouter role={Role.ADMIN} component={<Settings />} /> },
    {
      path: `${Routes.SETTINGS}/${Routes.TASK}/*`,
      element: <RootRouter role={Role.ADMIN} component={<TaskSettingsController />} />,
    },
    { path: `${Routes.TASK}/*`, element: <TaskController /> },
    { path: Routes.WINNERS, element: <Winners /> },
    { path: Routes.PARTICIPANTS, element: <Participants /> },
    { path: Routes.TRANSACTION, element: <Transactions /> },
    { path: Routes.BALANCE, element: <Balance /> },
  ])
}
