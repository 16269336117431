import React from 'react'
import { useStore } from 'effector-react'
import { Page } from '../../../shared/UI'
import { $defaults } from '../../../states/Defaults/store'
import { useStory } from '../../../services/api/Story'

export const Create = () => {
  const defaults = useStore($defaults)
  const { post } = useStory()

  return <Page
    variant="form"
    title="Создание сторис"
    button="Создать"
    loading={[post.loading]}
    onSubmit={post.fetch}
    fields={[
      { name: 'isActive', type: 'switch', label: 'Активно' },
      { name: 'adminName', type: 'string', label: 'Название. Для админа' },
      { name: 'likes', type: 'number', label: 'Лайки', disabled: true },
      { name: 'views', type: 'number', label: 'Просмотры', disabled: true },
      { name: 'url', type: 'content', mode: 'image', label: 'Превью 1:1' },
      {
        name: 'steps',
        label: 'Слайды',
        type: 'fields',
        fields: [
          { name: 'url', type: 'content', mode: 'image', label: 'Слайд' },
          { name: 'type', type: 'select', label: 'Тип слайда', options: defaults.stories.stepTypes },
          { name: 'duration', type: 'number', label: 'Время для слайда, милисекунды (опицонально)' },
          { name: 'header.heading', type: 'content', mode: 'text', label: 'Шапка(опционально). Заголовок' },
          { name: 'header.subheading', type: 'content', mode: 'text', label: 'Шапка. Описание' },
          { name: 'header.profileImage', type: 'file', label: 'Шапка. Фото' },
          { name: 'more.body', type: 'content', label: 'Больше(опционально). Содержание' },
          { name: 'more.url', type: 'content', mode: 'text', label: 'Больше. Сылка' },
        ],
      },
    ]}
  />
}
