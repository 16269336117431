import React, { useEffect } from 'react'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useStore } from 'effector-react'

import { Button, Input, RootLayout } from '../../../UI'
import {
  $isPendingGetAliasTranslations,
  $isPendingPatchAliasTranslations,
  $isPendingPostAliasTranslations,
} from '../../../../states/Translation/store'
import { $languages } from '../../../../states/Language/store'
import {
  postAliasTranslationsFx,
} from '../../../../states/Translation/event'
import { getLanguagesFx } from '../../../../states/Language/event'

interface FormInputs {
  alias: string
  [code: string]: string
}

export const TranslationCreate = () => {
  const languages = useStore($languages)

  const isPendingGet = useStore($isPendingGetAliasTranslations)
  const isPendingPatch = useStore($isPendingPatchAliasTranslations)
  const isPendingPost = useStore($isPendingPostAliasTranslations)

  const {
    getValues,
    setValue,
    watch,
  } = useForm<FormInputs>()
  const { alias } = watch()

  useEffect(() => {
    getLanguagesFx({})
  }, [])

  const handleSave = async () => {
    const values = getValues()
    const translations = languages.map((item) => ({
      code: item.code,
      text: values[item.code],
    })).filter(({ text }) => text)
    const newAlias = { alias: values.alias, translations }

    postAliasTranslationsFx(newAlias)
  }

  return (
    <RootLayout loading={isPendingGet}>
      <Typography variant="h5">Создание нового перевода</Typography>

      <Input
        onChange={(e) => setValue('alias', e.target.value)}
        value={alias}
        label="Алиас"
        variant="standard"
        InputLabelProps={{ shrink: !!alias }}
      />
      {languages.map(({ code, language, id }) => (
        <Input
          key={id}
          onChange={(e) => setValue(code, e.target.value)}
          value={watch(code)}
          label={language}
          variant="standard"
          InputLabelProps={{ shrink: !!watch(code) }}
        />
      ))}

      <Button color="primary" variant="contained" onClick={handleSave} loading={isPendingPatch || isPendingPost}>
        Сохранить
      </Button>
    </RootLayout>
  )
}
