import React from 'react'
import {
  Box,
  Fade,
  Modal as ModalMui,
  ModalProps as ModalPropsMui,
} from '@mui/material'

export interface ModalProps extends React.PropsWithChildren<Omit<ModalPropsMui, 'children'>> {
  minWidth?: number
  hideBox?: boolean
}

export const Modal: React.FC<ModalProps> = ({
  children,
  open,
  minWidth,
  ...props
}) => {
  return (
    <ModalMui
      {...props}
      open={open}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box
          sx={{
            minWidth,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            borderRadius: '5px',
            overflow: 'auto',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? '#F5F7FA' || theme.palette.grey[100]
                : theme.palette.grey[900],
            maxHeight: '94vh',
          }}
        >
          {children}
        </Box>
      </Fade>
    </ModalMui>
  )
}
