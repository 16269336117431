/* eslint-disable @typescript-eslint/no-unused-vars */
import AxiosInstance from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'
import { querystring, stringify } from '../../shared/utils'
import { useMutation, useQuery } from '@tanstack/react-query'
import { defaultQuery } from '../../shared/hooks'
import { Content, IdReq, WithMetadata } from './_types'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useStore } from 'effector-react'
import { $navigate } from '../../states/History/store'
import { Routes } from '../../shared/enum'
import { UserPrev } from './User'
import { Transaction } from './Transaction'

interface QuestEntries {
  id: number
  questType: string
  answerType: number
  description: {
    content: Content[]
    steps: {
      stepNumber: number
      content: Content[]
    }[]
  }
  auto: Record<string, any>
}
interface MoneyBox {
  id: number
  status: 'enabled' | 'disabled'
  balance: number
  deadlineQuestDone: string
  deadlineRelease: string
  createdAt: string
  closedQuestsAt: string
  releasedAt: string
  participantsIds: number[]
  winnersIds: number[]
}
interface Answer {
  id: null
  fileId: number | null
  answer: string | null
  questId: number
  status: string
  userId: number
  verifierId: number | null
  verifierComment: string | null
  createdAt: string
  updatedAt: string
}
interface QuestsPrev extends Omit<QuestEntries, 'id'> {
  id: number
  status: string
  questType: string
  moneyboxId: number
  userId: number
  cancelledAt: string | null
  completedAt: string | null
  createdAt: string
  updatedAt: string
}
interface Quests extends QuestsPrev {
  answer: Answer | null
}

export type GetActiveRes = MoneyBox
const getActive = () => {
  return promiseToResult(
    AxiosInstance().get<GetActiveRes>(
      '/projects/admin/moneyboxes/active',
    ),
  )
}

export interface GetBoxesReq {
  limit: string
  page: string
}
export type GetBoxesRes = WithMetadata<MoneyBox>
const getBoxes = (data: GetBoxesReq) => {
  return promiseToResult(
    AxiosInstance().get<GetBoxesRes>(
      `/projects/admin/moneyboxes?${querystring.stringify(data)}`,
    ),
  )
}

export type PostActiveReq = QuestEntries
const postActive = () => {
  return promiseToResult(
    AxiosInstance().post<PostActiveReq>(
      '/projects/admin/moneyboxes/active',
    ),
  )
}

const postRelease = (data: IdReq) => {
  return promiseToResult(
    AxiosInstance().post<PostActiveReq>(
      `/projects/admin/moneyboxes/${data.id}/release`,
    ),
  )
}

export interface GetQuestsEntriesRes {
  limit: string
  page: string
}
export type GetQuestsEntriesReq = WithMetadata<QuestEntries>
const getQuestsEntries = (data: GetQuestsEntriesRes) => {
  return promiseToResult(
    AxiosInstance().get<GetQuestsEntriesReq>(
      `/projects/admin/moneyboxes-quests-entries?${querystring.stringify(data)}`,
    ),
  )
}

export type PostQuestEntriesReq = Omit<QuestEntries, 'id'>
export type PostQuestEntriesRes = QuestEntries
const postQuestsEntries = (data: PostQuestEntriesReq) => {
  return promiseToResult(
    AxiosInstance().post<PostQuestEntriesRes>(
      '/projects/admin/moneyboxes-quests-entries',
      data,
    ),
  )
}

export type GetQuestsEntriesIdRes = QuestEntries
const getQuestsEntriesId = (data: IdReq) => {
  return promiseToResult(
    AxiosInstance().get<GetQuestsEntriesIdRes>(
      `/projects/admin/moneyboxes-quests-entries/${data.id}`,
    ),
  )
}

const patchQuestsEntriesId = (data: IdReq) => {
  return promiseToResult(
    AxiosInstance().put<GetQuestsEntriesIdRes>(
      `/projects/admin/moneyboxes-quests-entries/${data.id}/update-quests`,
    ),
  )
}

const deleteQuestsEntriesId = (data: IdReq) => {
  return promiseToResult(
    AxiosInstance().delete(
      `/projects/admin/moneyboxes-quests-entries/${data.id}`,
    ),
  )
}

export type PutQuestsEntriesIdReq = QuestEntries
const putQuestsEntriesId = (data: PutQuestsEntriesIdReq) => {
  return promiseToResult(
    AxiosInstance().put(
      `/projects/admin/moneyboxes-quests-entries/${data.id}`,
      data,
    ),
  )
}

export interface GetSettingsRes {
  alwaysReceivePrizeUserIds: string[]
  deadlines: {
    moneyboxReleasedInDays: number
    questCompletetionsInDays: number
  }
}
const getSettings = () => {
  return promiseToResult(
    AxiosInstance().get<GetSettingsRes>(
      '/projects/admin/moneyboxes/settings',
    ),
  )
}

const putSettings = (data: GetSettingsRes) => {
  return promiseToResult(
    AxiosInstance().put<GetSettingsRes>(
      '/projects/admin/moneyboxes/settings',
      data,
    ),
  )
}

export interface GetQuestsReq {
  limit: string
  page: string
  userId: string
  status: string
  moneyboxId: string
}
export type GetQuestsRes = WithMetadata<QuestsPrev>
const getQuests = (data: GetQuestsReq) => {
  return promiseToResult(
    AxiosInstance().get<GetQuestsRes>(
      `/projects/admin/moneyboxes-quests?${querystring.stringify(data)}`,
    ),
  )
}

const getQuestsId = (data: IdReq) => {
  return promiseToResult(
    AxiosInstance().get<Quests>(
      `/projects/admin/moneyboxes-quests/${data.id}`,
    ),
  )
}

export interface PostQuestsIdReq {
  userId: number
  questEntryId: number
}
const postQuestsId = (data: PostQuestsIdReq) => {
  return promiseToResult(
    AxiosInstance().post<Quests>(
      '/projects/admin/moneyboxes-quests',
      data,
    ),
  )
}

const deleteQuestsId = (data: IdReq) => {
  return promiseToResult(
    AxiosInstance().delete<Quests>(
      `/projects/admin/moneyboxes-quests/${data.id}`,
    ),
  )
}

export type PutQuestsIdReq = IdReq & Omit<QuestEntries, 'id'>
const putQuestsId = (data: PutQuestsIdReq) => {
  return promiseToResult(
    AxiosInstance().put(
      `/projects/admin/moneyboxes-quests/${data.id}`,
      data,
    ),
  )
}

export interface PostQuestsIdCheckAnswerReq extends IdReq {
  questId: number
  answerId: number
  newStatus: string
  verifierComment: string
}
const postQuestsIdCheckAnswer = (data: PostQuestsIdCheckAnswerReq) => {
  return promiseToResult(
    AxiosInstance().post(
      `/projects/admin/moneyboxes-quests/${data.id}/check-answer`,
      data,
    ),
  )
}

export type GetWinnersRes = WithMetadata<UserPrev>
const getWinners = (data: IdReq) => {
  return promiseToResult(
    AxiosInstance().get<GetWinnersRes>(
      `/projects/admin/moneyboxes/${data.id}/winners?${querystring.stringify(data)}`,
    ),
  )
}

const getParticipants = (data: IdReq) => {
  return promiseToResult(
    AxiosInstance().get<GetWinnersRes>(
      `/projects/admin/moneyboxes/${data.id}/participants?${querystring.stringify(data)}`,
    ),
  )
}

export type GetTransactionsRes = WithMetadata<{
  id: number
  balance: number
  createdAt: string
  transaction: Transaction
  users: {
    depth: number
    userId: number
  }[]
}>
const getTransactions = (data: IdReq) => {
  return promiseToResult(
    AxiosInstance().get<GetTransactionsRes>(
      `/projects/admin/moneyboxes/${data.id}/transactions?${querystring.stringify(data)}`,
    ),
  )
}

export type GetBalancesRes = WithMetadata<{
  userId: number
  balance: number
}>
const getBalances = (data: IdReq) => {
  return promiseToResult(
    AxiosInstance().get<GetBalancesRes>(
      `/projects/admin/moneyboxes/${data.id}/balances?${querystring.stringify(data)}`,
    ),
  )
}

export const MoneyBoxAPI = {
  getActive,
  postActive,
  postRelease,
  getQuestsEntries,
  postQuestsEntries,
  getQuestsEntriesId,
  patchQuestsEntriesId,
  putQuestsEntriesId,
  deleteQuestsEntriesId,
  getSettings,
  putSettings,
  getBoxes,
  getQuests,
  getQuestsId,
  postQuestsId,
  deleteQuestsId,
  putQuestsId,
  postQuestsIdCheckAnswer,
  getWinners,
  getParticipants,
  getTransactions,
  getBalances,
}

export const useMoneyBox = () => {
  const navigate = useStore($navigate)
  const [query, setQuery] = useState({
    questsEntries: defaultQuery as GetQuestsEntriesRes,
    boxes: defaultQuery as GetBoxesReq,
    questsEntriesId: {} as IdReq,
    quests: {} as GetQuestsReq,
    questsId: {} as IdReq,
    winners: {} as IdReq,
    participants: {} as IdReq,
    transactions: {} as IdReq,
    balances: {} as IdReq,
  })

  const getBoxes = useQuery(['MoneyBoxAPI.getBoxes', query.boxes], () => MoneyBoxAPI.getBoxes(query.boxes), { enabled: false })
  const getActive = useQuery(['MoneyBoxAPI.active'], MoneyBoxAPI.getActive, { enabled: false })
  const postActive = useMutation(MoneyBoxAPI.postActive)
  const postRelease = useMutation(MoneyBoxAPI.postRelease)
  const getQuestsEntries = useQuery(
    ['MoneyBoxAPI.getQuestsEntries', query.questsEntries],
    () => MoneyBoxAPI.getQuestsEntries(query.questsEntries),
    { enabled: false },
  )
  const postQuestsEntries = useMutation(MoneyBoxAPI.postQuestsEntries)
  const patchQuestsEntriesId = useMutation(MoneyBoxAPI.patchQuestsEntriesId)
  const getQuestsEntriesId = useQuery(
    ['MoneyBoxAPI.getQuestsEntriesId', query.questsEntriesId],
    () => MoneyBoxAPI.getQuestsEntriesId(query.questsEntriesId),
    { enabled: false },
  )
  const putQuestsEntriesId = useMutation(MoneyBoxAPI.putQuestsEntriesId)
  const deleteQuestsEntriesId = useMutation(MoneyBoxAPI.deleteQuestsEntriesId)
  const getSettings = useQuery(['MoneyBoxAPI.getSettings'], MoneyBoxAPI.getSettings, { enabled: false })
  const putSettings = useMutation(MoneyBoxAPI.putSettings)
  const getQuests = useQuery(
    ['MoneyBoxAPI.getQuests', query.quests],
    () => MoneyBoxAPI.getQuests(query.quests),
    { enabled: false },
  )
  const getQuestsId = useQuery(
    ['MoneyBoxAPI.getQuestsId', query.questsId],
    () => MoneyBoxAPI.getQuestsId(query.questsId),
    { enabled: false },
  )
  const postQuestsId = useMutation(MoneyBoxAPI.postQuestsId)
  const deleteQuestsId = useMutation(MoneyBoxAPI.deleteQuestsId)
  const putQuestsId = useMutation(MoneyBoxAPI.putQuestsId)
  const postQuestsIdCheckAnswer = useMutation(MoneyBoxAPI.postQuestsIdCheckAnswer)
  const getWinners = useQuery(
    ['MoneyBoxAPI.getWinners', query.winners],
    () => MoneyBoxAPI.getWinners(query.winners),
    { enabled: false },
  )
  const getParticipants = useQuery(
    ['MoneyBoxAPI.getParticipants', query.participants],
    () => MoneyBoxAPI.getParticipants(query.participants),
    { enabled: false },
  )
  const getTransactions = useQuery(
    ['MoneyBoxAPI.getTransactions', query.transactions],
    () => MoneyBoxAPI.getTransactions(query.transactions),
    { enabled: false },
  )
  const getBalances = useQuery(
    ['MoneyBoxAPI.getBalances', query.balances],
    () => MoneyBoxAPI.getBalances(query.balances),
    { enabled: false },
  )

  return {
    boxes: {
      get: {
        fetch: (boxes: GetBoxesReq) => {
          setQuery((prev) => ({ ...prev, boxes }))
          setTimeout(getBoxes.refetch, 300)
        },
        data: getBoxes.data?.data.data,
        loading: getBoxes.isLoading,
      },
    },
    active: {
      get: {
        fetch: () => getActive.refetch(),
        data: getActive.data?.data.data,
        loading: getActive.isLoading,
      },
      post: {
        fetch: async () => {
          const res = await postActive.mutateAsync()
          toast.success('Копилка создана')
          getActive.refetch()
          return res
        },
        data: postActive.data?.data.data,
      },
    },
    boxId: {
      postRelease: {
        fetch: async (data: IdReq) => {
          await postRelease.mutateAsync(data)
          toast.success('Копилка распределина')
          await postActive.mutateAsync()
          toast.success('Копилка создана')
          getActive.refetch()
          getBoxes.refetch()
        },
      },
      winners: {
        fetch: (winners: IdReq) => {
          setQuery((prev) => ({ ...prev, winners }))
          setTimeout(getWinners.refetch, 300)
        },
        data: getWinners.data?.data.data,
      },
      participants: {
        fetch: (participants: IdReq) => {
          setQuery((prev) => ({ ...prev, participants }))
          setTimeout(getParticipants.refetch, 300)
        },
        data: getParticipants.data?.data.data,
      },
      transactions: {
        fetch: (transactions: IdReq) => {
          setQuery((prev) => ({ ...prev, transactions }))
          setTimeout(getTransactions.refetch, 300)
        },
        data: getTransactions.data?.data.data,
      },
      balances: {
        fetch: (balances: IdReq) => {
          setQuery((prev) => ({ ...prev, balances }))
          setTimeout(getBalances.refetch, 300)
        },
        data: getBalances.data?.data.data,
      },
    },
    questsEntries: {
      get: {
        fetch: (questsEntries: GetQuestsEntriesRes) => {
          setQuery((prev) => ({ ...prev, questsEntries }))
          setTimeout(getQuestsEntries.refetch, 300)
        },
        data: getQuestsEntries.data?.data.data,
      },
      post: {
        fetch: async (data: PostQuestEntriesReq) => {
          const res = await postQuestsEntries.mutateAsync(data)
          toast.success('Шаблон создан')
          navigate(`/${Routes.MONEYBOX}/${Routes.SETTINGS}/${Routes.TASK}/${res.data.data.id}`)
          return res
        },
        loading: postQuestsEntries.isLoading,
      },
    },
    questsEntriesId: {
      get: {
        fetch: (questsEntriesId: IdReq) => {
          setQuery((prev) => ({ ...prev, questsEntriesId }))
          setTimeout(getQuestsEntriesId.refetch, 300)
        },
        data: getQuestsEntriesId.data?.data.data,
        loading: getQuestsEntriesId.isLoading,
      },
      put: {
        fetch: async (data: PutQuestsEntriesIdReq) => {
          await putQuestsEntriesId.mutateAsync(data)
          toast.success('Шаблон обновлен')
          getQuestsEntriesId.refetch()
        },
        loading: putQuestsEntriesId.isLoading,
      },
      delete: {
        fetch: async () => {
          await deleteQuestsEntriesId.mutateAsync({ id: String(getQuestsEntriesId.data?.data.data.id) })
          toast.success('Шаблон удален')
          navigate('')
        },
        loading: deleteQuestsEntriesId.isLoading,
      },
      patch: {
        fetch: async () => {
          await patchQuestsEntriesId.mutateAsync(query.questsEntriesId)
          toast.success('Задачи от шаблона обновлены')
          getQuestsEntriesId.refetch()
        },
        loading: patchQuestsEntriesId.isLoading,
      },
    },
    settings: {
      get: {
        fetch: getSettings.refetch,
        data: getSettings.data?.data.data,
        loading: getSettings.isLoading,
      },
      put: {
        fetch: async (data: GetSettingsRes) => {
          const res = putSettings.mutateAsync(data)
          toast.success('Настройки сохранены')
          return res
        },
        data: putSettings.data?.data.data,
      },
    },
    quests: {
      get: {
        fetch: (quests: GetQuestsReq) => {
          setQuery((prev) => ({ ...prev, quests }))
          setTimeout(getQuests.refetch, 300)
        },
        data: getQuests.data?.data.data,
      },
    },
    questsId: {
      get: {
        fetch: (questsId: IdReq) => {
          setQuery((prev) => ({ ...prev, questsId }))
          setTimeout(getQuestsId.refetch, 300)
        },
        data: getQuestsId.data?.data.data,
        loading: getQuestsId.isLoading,
      },
      post: {
        loading: postQuestsId.isLoading,
        fetch: async (data: PostQuestsIdReq) => {
          const res = await postQuestsId.mutateAsync(data)
          toast.success('Задача для юзера создана')
          navigate(`/${Routes.MONEYBOX}/${Routes.TASK}/${res.data.data.id}`)
        },
      },
      delete: {
        fetch: async () => {
          await deleteQuestsId.mutateAsync(query.questsId)
          toast.success('Задача для юзера удалено')
          navigate(`/${Routes.MONEYBOX}`)
        },
        loading: deleteQuestsId.isLoading,
      },
      put: {
        fetch: async (data: PutQuestsIdReq) => {
          await putQuestsId.mutateAsync(data)
          toast.success('Задача для юзера сохранена')
        },
      },
      postCheckAnswer: {
        fetch: async (data: PostQuestsIdCheckAnswerReq) => {
          await postQuestsIdCheckAnswer.mutateAsync(data)
          toast.success('Ответ сохранен')
          getQuestsId.refetch()
        },
        loading: postQuestsIdCheckAnswer.isLoading,
      },
    },
  }
}
