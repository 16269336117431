import React from 'react'
import { useStore } from 'effector-react'
import { NavLink } from 'react-router-dom'

import { Button, InputAutocomplete, RootTable, RootLayout, Col } from '../../shared/UI'
import { $languageLoading, $languages } from '../../states/Language/store'
import { getLanguagesFx } from '../../states/Language/event'
import { usePagination } from '../../shared/hooks'

import styles from './Language.module.scss'

const col: Col[] = [
  { id: 'id', label: 'ID' },
  { id: 'language', label: 'language' },
  { id: 'code', label: 'code' },
  { id: 'active', label: 'active' },
]

const LanguageController = () => {
  const languages = useStore($languages)
  const isPending = useStore($languageLoading)

  const { query, handlerQuery: handleQuery } = usePagination({
    defaults: { orderBy: 'id', orderDirection: 'desc', limit: '10', page: '1' },
    observerQuery: (query) => getLanguagesFx({ query }),
  })

  const onChange = (value: string, trigger: string) => {
    handleQuery(trigger, value)
  }

  return (
    <RootLayout>
      <div className={styles.wrapper}>

        <div className={styles.inputs}>
          <NavLink to="create">
            <Button color="primary" variant="contained">
              Создать
            </Button>
          </NavLink>
        </div>

        <div className={styles.inputs}>
          <InputAutocomplete
            label="Code"
            options={languages}
            trigger="code"
            isLoading={isPending}
            onChange={onChange}
            values={query}
          />
          <InputAutocomplete
            values={query}
            label="Язык"
            options={languages}
            trigger="language"
            isLoading={isPending}
            onChange={onChange}
          />
        </div>
        <RootTable rows={languages} col={col} orderBy="code" title="Список языков" />

      </div>
    </RootLayout>
  )
}

export default LanguageController
