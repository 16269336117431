import React, { useState } from 'react'
import { Avatar, Menu, MenuItem, Typography, Stack } from '@mui/material'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'

import { logOutFx } from '../../../../states/Login/event'
import { createHexColorFromString } from '../../../utils'
import { setThemeModeFx } from '../../../../states/App/event'
import { useStore } from 'effector-react'
import { $themeMode } from '../../../../states/App/store'
import { $my } from '../../../../states/My/store'

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: createHexColorFromString(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  }
}

const AppBarUser: React.FC<any> = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const themeMode = useStore($themeMode)
  const my = useStore($my)

  const open = Boolean(anchorEl)
  const handleClick = (event: any) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const handleLogout = () => {
    handleClose()
    logOutFx()
  }

  const handlerThemeMode = () => {
    setThemeModeFx(themeMode === 'dark' ? 'light' : 'dark')
  }

  return (
    <>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography>
          {my?.passport?.name} {my?.passport?.surname}
        </Typography>
        <Avatar
          {...stringAvatar(`${my?.passport?.name} ${my?.passport?.surname}`)}
          src={my?.avatar}
          onClick={handleClick}
        />
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleLogout}>Выход</MenuItem>
        <MenuItem onClick={handlerThemeMode}>
          Темная тема
          {themeMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
        </MenuItem>
      </Menu>
    </>
  )
}

export default AppBarUser
