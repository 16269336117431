import React, { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useStore } from 'effector-react'
import { Accordion, Col, DateTimePicker, Fields, InputArray, RootLayout, RootTable } from '../../../shared/UI'
import { AnalyticAPI, GetAnalyticReq } from '../../../services/api/Analytic'
import { useFilter } from '../../../shared/hooks'
import { toDollar } from '../../../shared/utils'
import { $projects, $projectsOptions } from '../../../states/Project/store'
import { $paymentsOptions } from '../../../states/Payment/store'

const paymentCountry: Col[] = [
  { id: 'code', label: 'Страна' },
  { id: 'totalPrice', label: 'Сумма покупок', render: (value) => `${toDollar(value)} $` },
  { id: 'count', label: 'Кол-во людей' },
]

const paymentBalance: Col[] = [
  {
    id: 'paymentSystemId',
    label: 'платежка',
    render: (paymentSystemId) => {
      const payment = $paymentsOptions.getState().find(({ key }) => key === paymentSystemId)
      return payment?.value
    },
  },
  { id: 'sum', label: 'Сумма', render: (value) => `${toDollar(value)} $` },
]

export const PaymentCountry = () => {
  const projectsOptions = useStore($projectsOptions)
  const projects = useStore($projects)
  const { handlerQuery, query } = useFilter<GetAnalyticReq>({
    defaultFilter: { projectIds: projects.map(({ id }) => id) },
  })

  const application = useQuery([query, 'getApplication'], () => AnalyticAPI.getApplication(query))
  const project = useQuery([query, 'getProject'], () => AnalyticAPI.getProject(query))

  const newUsersPurchase = {
    totalPrice: toDollar(application.data?.data.data.newUsersPurchase.totalPrice),
    totalUser: application.data?.data.data.newUsersPurchase.countries.reduce((acc, i) => acc + i.count, 0),
  }

  const allUsersPurchase = {
    totalPrice: toDollar(application.data?.data.data.allUsersPurchase.totalPrice),
    totalUser: application.data?.data.data.allUsersPurchase.countries.reduce((acc, i) => acc + i.count, 0),
  }

  useEffect(() => {
    const isProjects = projects.map(({ id }) => id)
    isProjects.length && handlerQuery('projectIds', isProjects)
  }, [projects])

  return (
    <RootLayout>
      <Accordion label="Фильтр" defaultExpanded className="filter">
        <InputArray
          label="Проекты"
          value={query.projectIds}
          onArray={(e) => handlerQuery('projectIds', e)}
          error={!query.projectIds.length}
          options={projectsOptions}
        />
        <DateTimePicker
          label="Начало"
          value={query.dateStart}
          onSelect={(date) => handlerQuery('dateStart', date.toISOString())}
        />
        <DateTimePicker
          label="Конец"
          value={query.dateEnd}
          onSelect={(date) => handlerQuery('dateEnd', date.toISOString())}
        />
      </Accordion>

      <Accordion label="Аналитика завершенных транзакций" className="filter">
        <Fields
          value={{
            payment: application.data?.data.data.usersCompletedPurchases,
            withdrawal: project.data?.data.data.transactions.withdrawalsSum,
          }}
          fields={[
            { name: 'payment', type: 'cent', label: 'Покупки долей', disabled: true },
            { name: 'withdrawal', type: 'cent', label: 'Выводы', disabled: true },
          ]}
        />
      </Accordion>

      <Accordion label="Покупки, зарегестрированных за период">
        <RootTable
          col={paymentCountry}
          limitOptions={[1000]}
          limit={1000}
          title={`Всего: ${newUsersPurchase.totalPrice} $. Юзеров: ${newUsersPurchase.totalUser}`}
          rows={application.data?.data?.data.newUsersPurchase.countries}
        />
      </Accordion>

      <Accordion label="Покупки за период">
        <RootTable
          col={paymentCountry}
          limitOptions={[1000]}
          limit={1000}
          title={`Всего: ${allUsersPurchase.totalPrice} $. Юзеров: ${allUsersPurchase.totalUser}`}
          rows={application.data?.data?.data.allUsersPurchase.countries}
        />
      </Accordion>

      <Accordion label="Платеженые системы">
        <RootTable
          col={paymentBalance}
          limitOptions={[1000]}
          limit={1000}
          title={`Пополнения баланса. Всего: ${toDollar(project.data?.data?.data.transactions.completedSum)} $`}
          rows={project.data?.data?.data.transactions.balancesSumGroupByPayments}
        />
      </Accordion>

      <Accordion label="Аналитика по проектам">
        <>
          {project.data?.data?.data?.projects?.map(({
            project,
            reservedInstallmentsSum,
            ...values
          }) => (
            <Accordion
              label={projectsOptions?.find(({ key }) => key === project)?.value}
              key={project}
              className="filter"
            >
              <Fields
                value={{
                  ...values,
                  reservedInstallmentsSum,
                }}
                fields={[
                  { name: 'reservedInstallmentsSum', type: 'cent', label: 'Сумма выкупа долей', disabled: true },
                  { name: 'freeShares', type: 'number', label: 'Долей доступно', disabled: true },
                  { name: 'freeSharesPercent', type: 'number', label: 'Долей доступно %', disabled: true },
                  { name: 'reservedShares', type: 'number', label: 'Долей в резерве', disabled: true },
                  { name: 'reservedSharesPercent', type: 'number', label: 'Долей в резерве %', disabled: true },
                  { name: 'soldShares', type: 'number', label: 'Продано долей', disabled: true },
                  { name: 'soldSharesPercent', type: 'number', label: 'Продано долей %', disabled: true },
                  { name: 'totalNft', type: 'number', label: 'Выпушенно EDK', disabled: true },
                  { name: 'sumNftShares', type: 'number', label: 'Долей подтвержденных EDK', disabled: true },
                  { name: 'percentageTotalNftShares', type: 'number', label: 'Долей с EDK всего, %', disabled: true },
                  { name: 'percentageTotalNftShares', type: 'number', label: '% долей с EDK', disabled: true },
                  { name: 'percentageNftSharesSold', type: 'number', label: '% долей с EDK от проданных', disabled: true },
                ]}
              />
            </Accordion>
          ))}
        </>
      </Accordion>
    </RootLayout>
  )
}
