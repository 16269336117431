import React from 'react'
import { useRoutes } from 'react-router-dom'
import { useStore } from 'effector-react'

import { Role, Routes } from '../../shared/enum'
import { Button, EditorContent, Page } from '../../shared/UI'
import { Root } from './components/Root'
import { getChatSettingsFx, putChatSettingsFx, setChatSettingsFx } from '../../states/Chat/event'
import { $chatSettings } from '../../states/Chat/store'
import { ChatSettings } from '../../services/api/Chat'
import { RoleGuard } from '../../shared/UI/RoleGuard'

const ChatController = () => {
  const chatSettings = useStore($chatSettings)

  // https://www.npmjs.com/package/chat-ui-react
  return useRoutes([
    {
      path: Routes.ROOT,
      element: <Page
        variant="outlet"
        links={[
          { title: 'Чаты', to: `/${Routes.CHAT}` },
          { title: 'Настройки', to: Routes.SETTINGS },
        ]}
      />,
      children: [
        {
          path: Routes.SETTINGS,
          element: <RoleGuard role={Role.ADMIN}>
            <Page
              variant="root"
              onInit={() => getChatSettingsFx()}
              loading={[false]}
              title={
                <>
                  Настройки чата
                  <Button
                    onClick={() => chatSettings.forEach((settings) => {
                      putChatSettingsFx(settings)
                    })}
                  >
                    Сохранить
                  </Button>
                </>
              }
              rows={chatSettings.map((c) => ({ id: c.key, ...c }))}
              col={[
                {
                  id: 'key',
                  label: 'Тип',
                },
                {
                  id: 'value',
                  label: 'Значение',
                  render: (_, row: ChatSettings) => {
                    return <EditorContent
                      value={row.value.botMessage}
                      mode="text"
                      onChange={(values) => {
                        row.value.botMessage = values
                        setChatSettingsFx({
                          ...row,
                        })
                      }}
                    />
                  },
                },
              ]}
            />
          </RoleGuard>,
        },
        { path: Routes.ROOT, element: <Root /> },
        { path: Routes.ID, element: <Root /> },
      ],
    },
  ])
}

export default ChatController
