import { createEffect } from 'effector'
import { toast } from 'react-toastify'

import { LoginAPI, PostLoginDataReq } from '../../services/api/Login'
import { localStore } from '../../shared/utils'
import { APIResult, AxiosError } from '../../core/api/Result'
import { CommonEnum } from '../../shared/enum'
import { setAuthTokenFx } from '../Auth/event'

export const logInFx = createEffect<PostLoginDataReq, APIResult<typeof LoginAPI['postData']>, AxiosError>(
  LoginAPI.postData,
)

export const logOutFx = createEffect(async () => {
  localStore.remove(CommonEnum.AUTH_TOKEN)
  setAuthTokenFx('')
})

logInFx.done.watch(({ result }) => {
  const { token } = result.data.data
  localStore.set(CommonEnum.AUTH_TOKEN, token)
  setAuthTokenFx(token)
})

logInFx.fail.watch(() => {
  toast.error('Неверный логин или пароль')
})
