import React from 'react'
import { Role, Routes } from '../enum'
import { useStore } from 'effector-react'
import { $isAuthorized } from '../../states/Auth/store'
import { $my } from '../../states/My/store'
import { Navigate, Outlet } from 'react-router-dom'
import { isRoleGuard } from '../utils'

export const RootRouter: React.FC<{component?: JSX.Element, role: Role}> = ({ component, role }) => {
  const isAuthorized = useStore($isAuthorized)
  const my = useStore($my)

  if (!isAuthorized) {
    return <Navigate to={`/${Routes.LOGIN}`} />
  }
  if (!role || (role && !isRoleGuard(role, my?.role))) {
    return <div>Вам это не доступно</div>
  }
  return component || <Outlet />
}
