import AxiosInstance from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'
import { Content } from './_types'

export interface Report {
  id: number
  reportedAt: string
  isActive: boolean
  statements: {
    price: number
    categoryId: number
  }[]
}

export interface Category {
  id: number
  color: string
  name: string
  title: {
    content: Content[]
  },
}

export type GetCategoriesRes = Category[]
const getCategories = () => {
  return promiseToResult(
    AxiosInstance().get<GetCategoriesRes>(
      '/application/admin/reports/categories',
    ),
  )
}

export type PostCategoryReq = Omit<Category, 'id'>
export type PostCategoryRes = Category
const postCategory = (data: PostCategoryReq) => {
  return promiseToResult(
    AxiosInstance().post<PostCategoryRes>(
      '/application/admin/reports/categories',
      data,
    ),
  )
}

export interface GetCategoryReq {
  id: number | string
}
export type GetCategoryRes = Category
const getCategory = (data: GetCategoryReq) => {
  return promiseToResult(
    AxiosInstance().get<GetCategoryRes>(
      `/application/admin/reports/categories/${data.id}`,
    ),
  )
}

export type PatchCategoryReq = Category
export type PatchCategoryRes = Category
const patchCategory = (data: PatchCategoryReq) => {
  return promiseToResult(
    AxiosInstance().patch<PatchCategoryRes>(
      `/application/admin/reports/categories/${data.id}`,
      data,
    ),
  )
}

export type GetReportsRes = Report[]
const getReports = () => {
  return promiseToResult(
    AxiosInstance().get<GetReportsRes>(
      '/application/admin/reports',
    ),
  )
}

export interface GetReportReq { id: number | string }
export type GetReportRes = Report
const getReport = (data: GetReportReq) => {
  return promiseToResult(
    AxiosInstance().get<GetReportRes>(
      `/application/admin/reports/${data.id}`,
    ),
  )
}

export type PostReportReq = Omit<Report, 'id'>
export type PostReportRes = Report
const postReport = (data: PostReportReq) => {
  return promiseToResult(
    AxiosInstance().post<PostReportRes>(
      '/application/admin/reports',
      data,
    ),
  )
}

export type PatchReportReq = Report
export type PatchReportRes = Report
const patchReport = (data: PatchReportReq) => {
  return promiseToResult(
    AxiosInstance().patch<PatchReportRes>(
      `/application/admin/reports/${data.id}`,
      data,
    ),
  )
}

export const FinReportAPI = {
  getCategories,
  getCategory,
  postCategory,
  patchCategory,
  getReports,
  getReport,
  postReport,
  patchReport,
}
