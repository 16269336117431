import AxiosInstance from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'
import { querystring } from '../../shared/utils'

import { Content, Query, WithMetadata } from './_types'

export interface ProjectShare {
  id: number
  price: number
  projectId: number
  appliedAt: string
  createdAt: string
}

export interface Project {
  allShares: string
  allSharesForSelling: string
  soldShares: string
  createdAt: string
  percentCompanyToOneShare: number
  pricePerOneBuilding: number
  percentCompanyToOneBuilding: number
  name: string
  icon: string
  coin: string
  settings: {
    availableForRoles: string[]
    isEnabled: boolean
  }
  id: number
  isEnabled: boolean
  symbol: string
  updatedAt: string
  sections: {
    about: {
      content: Content[]
      videoUrl: Content[]
      presentationUrl: Content[]
      awards: string[]
      patents: {
        code: string,
        status: string
        url: string
        pdf: string
      }[]
    },
    shares: {
      content: Content[]
    },
    development: {
      content: Content[]
      steps: {
        price: number
        isActive: boolean
        title: Content[]
        content: Content[]
        progresses: {
          content: Content[],
          progress: number
        }[]
      }[]
    },
    news: {
      content: Content[]
    },
    dividend: {
      content: Content[]
    }
  }
}

export type GetProjectsReq = Query
export type GetProjectsRes = WithMetadata<Project>
const getProjects = (data: GetProjectsReq) => {
  const { query } = data
  return promiseToResult(
    AxiosInstance().get<GetProjectsRes>(
      `/projects/admin/projects?${querystring.stringify(query)}`,
    ),
  )
}

export interface GetProjectReq {
  id: string
}
export type GetProjectRes = Project
const getProject = (data: GetProjectReq) => {
  const { id } = data
  return promiseToResult(
    AxiosInstance().get<GetProjectRes>(
      `/projects/admin/projects/${id}`,
    ),
  )
}

export interface PostProjectReq {
  allShares: number
  allSharesForSelling: number
  name: string
  symbol: string
}
export type PostProjectRes = Project
const postProject = (data: PostProjectReq) => {
  const { ...body } = data
  return promiseToResult(
    AxiosInstance().post<PostProjectRes>(
      '/projects/admin/projects',
      body,
    ),
  )
}

export interface DeleteProjectReq {
  id: string
}
export interface DeleteProjectRes {
  status: boolean
}
const deleteProject = (data: DeleteProjectReq) => {
  const { id } = data
  return promiseToResult(
    AxiosInstance().delete<DeleteProjectRes>(
      `/projects/admin/projects/${id}`,
    ),
  )
}

export type PatchProjectReq = Project
export type PatchProjectRes = Project
const patchProject = (data: PatchProjectReq) => {
  const { id, ...body } = data
  return promiseToResult(
    AxiosInstance().patch<PatchProjectRes>(
      `/projects/admin/projects/${id}`,
      body,
    ),
  )
}

export interface GetProjectsShareReq {
  id: string
}
export type GetProjectsShareRes = WithMetadata<ProjectShare[]>
const getProjectShare = (data: GetProjectsShareReq) => {
  const { id } = data
  return promiseToResult(
    AxiosInstance().get<GetProjectsShareRes>(
      `/projects/admin/projects/${id}/shares?limit=10`,
    ),
  )
}

export interface PostProjectShareReq extends GetProjectsShareReq {
  appliedAt: string
  percent: number
}
export interface PostProjectShareRes {
  status: boolean
}
const postProjectShare = (data: PostProjectShareReq) => {
  const { id, ...body } = data
  return promiseToResult(
    AxiosInstance().post<PostProjectShareRes>(
      `/projects/admin/projects/${id}/shares`,
      body,
    ),
  )
}

export interface GetProjectShareTimerRes {
  metadata: {
    total: number
  },
  items: {
    id: number
    percent: number
    projectId: number
    appliedAt: string
    createdAt: string
  }[]
}
const getProjectShareTimer = (data: GetProjectsShareReq) => {
  const { id, ...query } = data
  return promiseToResult(
    AxiosInstance().get<GetProjectShareTimerRes>(
      `/projects/admin/projects/${id}/share-timers?${querystring.stringify(query)}`,
    ),
  )
}

export const ProjectAPI = {
  getProjects,
  getProject,
  postProject,
  deleteProject,
  patchProject,
  getProjectShare,
  postProjectShare,
  getProjectShareTimer,
}
