import { createEffect } from 'effector'
import { toast } from 'react-toastify'

import { Routes } from '../../shared/enum'
import { APIResult, AxiosError } from '../../core/api/Result'
import {
  DeleteProjectReq,
  GetProjectReq,
  GetProjectsReq,
  GetProjectsShareReq,
  PostProjectShareReq,
  PatchProjectReq,
  PostProjectReq,
  ProjectAPI,
} from '../../services/api/Project'
import { $navigate } from '../History/store'

export const getProjectsFx = createEffect<GetProjectsReq, APIResult<typeof ProjectAPI['getProjects']>, AxiosError>(
  ProjectAPI.getProjects,
)

export const getProjectFx = createEffect<GetProjectReq, APIResult<typeof ProjectAPI['getProject']>, AxiosError>(
  ProjectAPI.getProject,
)

export const createProjectFx = createEffect<PostProjectReq, APIResult<typeof ProjectAPI['postProject']>, AxiosError>(
  ProjectAPI.postProject,
)

export const deleteProjectFx = createEffect<DeleteProjectReq, APIResult<typeof ProjectAPI['deleteProject']>, AxiosError>(
  ProjectAPI.deleteProject,
)

export const updateProjectFx = createEffect<PatchProjectReq, APIResult<typeof ProjectAPI['patchProject']>, AxiosError>(
  ProjectAPI.patchProject,
)

export const getProjectShareFx = createEffect<GetProjectsShareReq, APIResult<typeof ProjectAPI['getProjectShare']>, AxiosError>(
  ProjectAPI.getProjectShare,
)

export const createProjectShareFx = createEffect<PostProjectShareReq, APIResult<typeof ProjectAPI['postProjectShare']>, AxiosError>(
  ProjectAPI.postProjectShare,
)

export const getProjectShareTimerFx = createEffect(ProjectAPI.getProjectShareTimer)

createProjectFx.done.watch(({ result }) => {
  const navigate = $navigate.getState()
  navigate(`${Routes.PROJECT}/${result.data.data.id}`)
})
createProjectFx.fail.watch(() => {
  toast.error('Ошибка создания')
})

deleteProjectFx.done.watch(() => {
  const navigate = $navigate.getState()
  navigate(Routes.PROJECT)
  toast.success('Проект успешно удалён')
})
deleteProjectFx.fail.watch(() => {
  toast.error('Ошибка удаления')
})

updateProjectFx.done.watch(() => {
  toast.success('Проект успешно изменен')
})
updateProjectFx.fail.watch(() => {
  toast.error('Ошибка изменения')
})

createProjectShareFx.done.watch(() => {
  toast.success('Поднятие цены успешно запланирована')
})
createProjectShareFx.fail.watch(() => {
  toast.error('Ошибка планирования повышения цены')
})
