import React, { useEffect } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { Input, InputProps } from '../Input'
import {
  DateTimePicker as DateTimePickerMUI,
  DateTimePickerProps as DateTimePickerPropsMUI,
} from '@mui/x-date-pickers'

export interface DateTimePickerProps extends Omit<DateTimePickerPropsMUI<InputProps, Dayjs>, 'label' | 'onChange' | 'renderInput' | 'value'> {
  label: string,
  onSelect?: (date: Dayjs) => void
  variant?: InputProps['variant']
  value?: string | Dayjs
}

export const DateTimePicker: React.FC<DateTimePickerProps> = ({
  label,
  onSelect,
  variant = 'standard',
  value: valueProps,
  ...props
}) => {
  const [value, setValue] = React.useState<Dayjs | string | null>(valueProps ? dayjs(valueProps) : '')

  useEffect(() => {
    setValue(dayjs(valueProps ? dayjs(valueProps) : ''))
  }, [valueProps])

  const onChange: any = (date: Dayjs): void => {
    if (date && date.isValid()) {
      setValue(date)
      if (typeof onSelect === 'function') {
        onSelect(date)
      }
    }
  }

  return (
    <DateTimePickerMUI
      {...props}
      label={label}
      onChange={onChange}
      value={value as any}
      inputFormat="DD.MM.YYYY HH:mm"
      renderInput={(params) => (
        <Input
          {...params}
          error={false}
          variant={variant}
        />
      )}
    />
  )
}
