/* eslint-disable @typescript-eslint/no-unused-vars,@typescript-eslint/no-use-before-define */
import { createEffect, createEvent, createStore } from 'effector'
import { environment } from '../../../environment'
import { ChatAPI, ChatSettings, UpdateMessage, User } from '../../services/api/Chat'
import { $authToken } from '../Auth/store'
import { toast } from 'react-toastify'
import { ChatControlInit } from './store'

const to = (to: { event: string, data?: Record<string, unknown> }) => JSON.stringify(to)

const setSocketFx = createEvent<WebSocket>()
const $socket = createStore<WebSocket | null>(null)
  .on(setSocketFx, (_, socket) => socket)

export const unreadMessageIdsFx = createEvent<number[]>()
const $unreadMessageIds = createStore<number[]>([])
  .on(unreadMessageIdsFx, (_, unread) => unread)

export const setMyFx = createEvent<User>()

export const updateMessageFx = createEvent<[UpdateMessage, string]>()

export const getChatsFx = createEffect(ChatAPI.getChats)
export const getChatFx = createEffect(ChatAPI.getChat)
export const putChatUnreadFx = createEffect(ChatAPI.putChatUnread)
export const putChatReadFx = createEffect(ChatAPI.putChatRead)
export const getMessagesFx = createEffect(ChatAPI.getMessages)
export const postMessagesReadFx = createEffect(ChatAPI.postMessagesRead)
export const getChatSettingsFx = createEffect(ChatAPI.getChatSettings)
export const setChatSettingsFx = createEvent<ChatSettings>()
export const putChatSettingsFx = createEffect(ChatAPI.putChatSettings)

export const clearMessagesFx = createEvent()
export const clearChatsFx = createEvent()

export const setChatControlFx = createEvent<Partial<typeof ChatControlInit>>()

let isInit = false

export const socketOpenFx = createEffect((init?: boolean) => {
  if (init && isInit) {
    return
  }
  isInit = true

  const token = $authToken.getState()
  const lang = 'ru'
  const url = `${environment.WEBSOCKET_PREFIX}/support?token=${token}`
  const socket = new WebSocket(url)

  socket.onopen = () => {
    socket.send(to({ event: 'connect', data: { token, lang } }))
  }

  const ping = () => {
    socket.send(to({ event: 'ping' }))
  }

  const pingInterval = setInterval(ping, 1000 * 60)

  socket.onmessage = (msg) => {
    const { event, data } = JSON.parse(msg.data)

    if (event === 'send-message') {
      updateMessageFx([data, 'send'])
    }

    if (event === 'connect') {
      setMyFx(data?.user)
    }

    if (event === 'read-message') {
      updateMessageFx([data, 'read'])
    }

    if (event === 'validation' || event === 'error') {
      toast.error('Ошибка чата')
    }
  }

  socket.onclose = () => {
    clearInterval(pingInterval)
    socketOpenFx()
  }

  setSocketFx(socket)
})

export interface SocketSendProps {
  chatId: number
  content?: string
  attachedFiles?: string[]
}
export const socketSendFx = createEffect(({
  chatId,
  content,
  attachedFiles,
}: SocketSendProps) => {
  const socket = $socket.getState()

  socket?.send(to({
    event: 'send-messages',
    data: { chatId, content, attachedFiles },
  }))

  const unreadMessageIds = $unreadMessageIds.getState()
  if (unreadMessageIds.length) {
    readMessageFx(unreadMessageIds)
  }
})

export const readMessageFx = createEffect((unreadMessageIds: number[]) => {
  const socket = $socket.getState()

  socket?.send(to({
    event: 'read-messages',
    data: { messageIds: unreadMessageIds },
  }))
})

putChatSettingsFx.done.watch(() => {
  toast.success('Успешно сохранено')
})
