import React, { useMemo } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { NavLink } from 'react-router-dom'
import {
  Password as PasswordIcon,
  HowToReg as HowToRegIcon,
} from '@mui/icons-material'

import { Accordion, ButtonWithConfirm, Col, InputAutocomplete, RootLayout, RootTable } from '../../../shared/UI'
import { usePagination } from '../../../shared/hooks'
import { CandidateAPI } from '../../../services/api/Candidate'
import { Routes } from '../../../shared/enum'
import { copyText, encrypt, querystring } from '../../../shared/utils'
import { IconButton, Tooltip } from '@mui/material'
import { toast } from 'react-toastify'
import { environment } from '../../../../environment'

export const Root = () => {
  const { query, pagination, handlerQuery, handlerPagination } = usePagination({
    defaults: { limit: '15', page: '1', orderBy: '', orderDirection: '' },
  })

  const condition = useQuery(
    [query, 'getCandidates'],
    () => CandidateAPI.getCandidates({ query }),
  )

  const approve = useMutation(CandidateAPI.putApprove)

  const handlerChange = (value: string, trigger: string) => {
    if (trigger === 'phone') {
      value = value
        .replace(/\s/g, '')
        .replace('-', '')
    }
    value.trim().trimEnd()

    handlerQuery(trigger, value)
  }

  const handlerApprove = async (id: number | string) => {
    await approve.mutateAsync({ id })
    toast.success('Юзер успешно зареган')
    condition.refetch()
  }

  const handlerCopy = (hash: string) => {
    const cryptoHash = encrypt(hash, 'reg-pass')
    const url = `${environment.CABINET_PREFIX}/?${querystring.stringify({ pass: cryptoHash })}`
    copyText(url)
  }

  const col: Col[] = useMemo(() => ([
    { id: 'phone', label: 'Телефон' },
    { id: 'hash', label: 'hash' },
    { id: 'invitedUserId', label: 'Пригласивший', render: (id) => id && <NavLink to={`/${Routes.USER}/${id}`}>{id}</NavLink> },
    { id: 'telegramChatId', label: 'tg', render: (id) => id },
    { id: 'whatsAppChatId', label: 'wa', render: (id) => id },
    { id: 'lang', label: 'Язык' },
    { id: 'createdAt', label: 'Начало', render: (date) => dayjs(date).format('DD.MM.YY HH:mm') },
    { id: 'updatedAt', label: 'Последнее действие', render: (date) => dayjs(date).fromNow() },
    { id: 'hash',
      label: 'Действия',
      render: (hash, raw) => (
        <>
          <Tooltip title="Ссылка для регистрации по паролю">
            <IconButton
              color={'primary'}
              onClick={() => handlerCopy(hash)}
            >
              <PasswordIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Подтвердить регистрацию юзера">
            <ButtonWithConfirm
              content="Точно подтвердить регистрацию??"
              variant="text"
              color={'success'}
              onClick={() => handlerApprove(raw.id)}
            >
              <HowToRegIcon />
            </ButtonWithConfirm>
          </Tooltip>
        </>
      ),
    },
  ]), [])

  return (
    <RootLayout>
      <Accordion label="Фильтры" defaultExpanded className="filter">
        <InputAutocomplete
          label="Телефон"
          trigger="phone"
          isLoading={condition.isFetching}
          onChange={handlerChange}
        />
        <InputAutocomplete
          label="Пригласивший ID"
          trigger="invitedUserId"
          isLoading={condition.isFetching}
          onChange={handlerChange}
        />
        <InputAutocomplete
          label="hash"
          trigger="hash"
          isLoading={condition.isFetching}
          onChange={handlerChange}
        />
      </Accordion>

      <RootTable
        col={col}
        title="Незаконченные регистрации"
        rows={condition.data?.data.data.items}
        handleChangePage={(page) => handlerPagination('page', page)}
        handleChangeLimit={(limit) => handlerPagination('limit', limit)}
        handleOrderDirection={(limit) => handlerQuery('orderDirection', limit)}
        length={condition.data?.data.data.metadata.total}
        page={pagination.page}
        limit={pagination.limit}
        orderBy={query?.orderBy?.toString()}
        orderDirection={query?.orderDirection?.toString()}
      />
    </RootLayout>
  )
}
