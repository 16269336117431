import React from 'react'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useStore } from 'effector-react'

import { $languageLoading } from '../../../../states/Language/store'
import { postLanguageFx } from '../../../../states/Language/event'
import {
  Input,
  Button,
  RootLayout,
} from '../../../UI'
import { PostLanguageReq } from '../../../../services/api/Language'

export const LanguageCreate: React.FC = () => {
  const languageLoading = useStore($languageLoading)

  const methods = useForm<PostLanguageReq>({
    defaultValues: {
      code: '',
      language: '',
      displayName: '',
      countries: [],
    },
  })
  const {
    getValues,
    setValue,
    watch,
  } = methods
  const { code, language, displayName } = watch()

  const handleSave = async () => {
    postLanguageFx(getValues())
  }

  return (
    <RootLayout loading={languageLoading}>
      <Typography variant="h5">Добавление нового языка</Typography>

      <Input
        value={code}
        onChange={(e) => setValue('code', e.target.value)}
        label="Код языка"
        variant="standard"
        InputLabelProps={{ shrink: !!code }}
      />
      <Input
        value={language}
        onChange={(e) => setValue('language', e.target.value)}
        label="Язык"
        InputLabelProps={{ shrink: !!language }}
      />
      <Input
        value={displayName}
        onChange={(e) => setValue('displayName', e.target.value)}
        label="displayName"
        InputLabelProps={{ shrink: !!displayName }}
      />

      <Button
        onClick={handleSave}
        loading={languageLoading}
        disabled={!code || !language || !displayName}
      >
        Сохранить
      </Button>
    </RootLayout>
  )
}
