import React from 'react'
import { useMoneyBox } from '../../../../../services/api/MoneyBox'
import { Page } from '../../../../../shared/UI'
import { useStore } from 'effector-react'
import { $defaults } from '../../../../../states/Defaults/store'
import { $projectsOptions } from '../../../../../states/Project/store'

export const Create = () => {
  const defaults = useStore($defaults)
  const projectsOptions = useStore($projectsOptions)

  const { questsEntries } = useMoneyBox()

  return (
    <Page
      variant="form"
      title="Создание шаблона"
      button="Создать"
      onSubmit={questsEntries.post.fetch}
      loading={[questsEntries.post.loading]}
      fields={[
        { name: 'isActive', type: 'switch', label: 'Активно' },
        { name: 'limit', type: 'number', label: 'Лимит выполенения на юзера. 0 - безлимитно' },
        { name: 'adminDescription', type: 'string', label: 'Описание для админа' },
        { name: 'description.content', type: 'content', label: 'Описание задачи для юзера' },
        { name: 'questType', type: 'select', label: 'Тип', options: defaults.moneyboxes.questTypes },
        { name: 'projectId', type: 'select', label: 'Проект', options: projectsOptions, hide: (v) => v.questType !== 'gift' },
        { name: 'giftShares', type: 'number', label: 'Кол-во долей', hide: (v) => v.questType !== 'gift' },
        { name: 'answerType', type: 'select', label: 'Формат ответа', options: defaults.moneyboxes.questAnswersTypes },
        { name: 'auto.type', type: 'select', label: 'Тип авто задачи', options: defaults.moneyboxes.autoQuestTypes, hide: (v) => v.answerType !== 'auto' },
        { name: 'auto.data.type', type: 'select', label: 'Тип авто задачи(Необходимо продублировать)', options: defaults.moneyboxes.autoQuestTypes, hide: (v) => v.answerType !== 'auto' },
        { name: 'auto.data.sum', type: 'cent', label: 'Сумма инвестиций которую должны набрать рефералы пользователя ', hide: (v) => v.answerType !== 'auto' },
        {
          name: 'description.steps',
          type: 'fields',
          label: 'Шаги',
          fields: [
            { name: 'stepNumber', type: 'number', label: 'Номер шага' },
            { name: 'content', type: 'content', label: 'Поручение' },
          ],
          hide: () => true,
        },
      ]}
    />
  )
}
