import React from 'react'

import { Page } from '../../../shared/UI'
import { useTemplate } from '../../../services/api/Template'
import { getOptionsValue } from '../../../shared/utils'
import { useStore } from 'effector-react'
import { $defaults } from '../../../states/Defaults/store'
import { Routes } from '../../../shared/enum'

export const Root = () => {
  const defaults = useStore($defaults)
  const { get } = useTemplate()

  return <Page
    variant="root"
    title="Шаблоны для уведолмений"
    actions={[
      {
        title: 'Создать',
        type: 'link',
        to: Routes.CREATE,
      },
    ]}
    onChange={get.fetch}
    loading={[get.loading]}
    rows={get.data}
    col={[
      { id: 'id', label: 'ID' },
      { id: 'type', label: 'Тип', render: (type) => getOptionsValue(defaults.social.templates, type) },
    ]}
  />
}
