import React from 'react'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useStore } from 'effector-react'

import { Button, Select, Input, RootLayout } from '../../../UI'
import { $defaults } from '../../../../states/Defaults/store'
import { createPaymentFx } from '../../../../states/Payment/event'

const PaymentCreate: React.FC = () => {
  const defaults = useStore($defaults)

  const method = useForm({
    defaultValues: {
      type: '',
      name: '',
    },
  })
  const {
    setValue,
    getValues,
    watch,
  } = method
  const { type, name } = watch()

  const handlerSave = async () => {
    createPaymentFx(getValues())
  }

  return (
    <RootLayout loading={false} empty={false}>
      <Typography variant="h5">Создание платежной системы</Typography>

      <Input
        label="Название"
        value={name}
        onChange={(e) => setValue('name', e.target.value)}
        InputLabelProps={{ shrink: !!name }}
      />
      <Select
        label="Тип"
        options={defaults?.payments?.types}
        value={type}
        onSelect={(value) => setValue('type', value)}
      />
      <Button
        onClick={handlerSave}
        disabled={!name || !type}
      >
        Сохранить
      </Button>
    </RootLayout>
  )
}

export default PaymentCreate
