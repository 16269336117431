import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Project } from '../../../../../services/api/Project'
import { RootLayout } from '../../../../UI/RootLayout'
import { EditorContent } from '../../../../UI'

export const Dividend = () => {
  const { watch, setValue } = useFormContext()
  const values = watch() as Project

  return (
    <RootLayout>
      <EditorContent
        label="Дивиденды"
        value={values?.sections?.dividend?.content}
        onChange={(value) => setValue('sections.dividend.content', value)}
      />
    </RootLayout>
  )
}
