import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { DefaultsAPI } from '../services/api/Defaults'
import { Box } from '@mui/material'
import { Input } from '../shared/UI'

export const TempCode = () => {
  const [input, setInput] = useState('')
  const { data, isLoading } = useQuery(['DefaultsAPI.getCode'], DefaultsAPI.getCode, { refetchInterval: 3000 })

  if (isLoading) {
    return <div>loading...</div>
  }
  return (
    <>
      <div className="header">
        <Input
          value={input}
          onChange={(e) => setInput(e.target.value)}
          label="телефон"
        />
      </div>

      <div>
        {data?.data.data
          .filter(({ key }) => key.includes(input))
          .map(({ value, staticKey, key }) => (
            <Box
              key={key}
              sx={{
                display: 'flex',
                gap: '16px',
              }}
            >
              <div>{key}</div>
              <div>{value}</div>
              <div>{staticKey}</div>
            </Box>
          ))}
      </div>
    </>
  )
}
