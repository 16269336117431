import React from 'react'
import { CircularProgress } from '@mui/material'

import style from './RootLayout.module.scss'

interface ConditionComponentProps {
  condition: boolean,
  placeholder: any,
}

const ConditionComponent: React.FC<ConditionComponentProps> = ({ condition, children, placeholder = '' }) => {
  return condition ? children : placeholder
}

interface LoaderWrapperProps {
  loading?: boolean
}

export const LoaderWrapper: React.FC<LoaderWrapperProps> = ({
  children,
  loading,
}) => {
  return (
    <div className={style.root}>
      {children}
      {loading &&
        <div className={style.loader}>
          <CircularProgress size={72} />
        </div>
      }
    </div>
  )
}

export interface RootLayoutProps {
  loading?: boolean,
  empty?: boolean,
}

export const RootLayout: React.FC<RootLayoutProps> = ({
  children,
  loading,
  empty,
}) => {
  const Placeholder = <div>Пустая страница</div>

  return (
    <LoaderWrapper loading={loading}>
      <div className={style.wrapper}>
        <ConditionComponent condition={!empty} placeholder={Placeholder}>
          {children}
        </ConditionComponent>
      </div>
    </LoaderWrapper>
  )
}
