import imageCompression from 'browser-image-compression'

export const saveFile = (data: any, fileName: string) => {
  let a = document.createElement('a')
  document.body.appendChild(a)
  a.style.display = 'none'
  let blob = new Blob([data], { type: 'octet/stream' })
  let url = window.URL.createObjectURL(blob)
  a.href = url
  a.download = fileName
  a.click()
  window.URL.revokeObjectURL(url)
  document.body.removeChild(a)
}

export const getFileExtension = (filename: string) => {
  const parts = filename.split('.')
  return parts[parts.length - 1]
}

export const getFileName = (filename: string) => {
  return filename.replace(/\.[^/.]+$/, '')
}

export const blobToFile = (theBlob: Blob, fileName: string): File => {
  return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
}

export class MyFile {
  public readonly file: File

  constructor(file: File) {
    this.file = file
  }

  public isImage() {
    return !!this.file.type.match('image.*')
  }

  public isVideo() {
    return !!this.file.type.match('video.*')
  }

  public isAudio() {
    return !!this.file.type.match('audio.*')
  }

  public async compress({
    initialQuality = 0.25,
    maxWidthOrHeight,
  }: {
    initialQuality?: number
    maxWidthOrHeight?: number
  } = {}) {
    if (this.isImage()) {
      const options = { initialQuality, maxWidthOrHeight }
      return this.file
      return imageCompression(this.file, options)
    }
    return this.file
  }

  public getFilename() {
    return `${getFileName(this.file.name)}.${getFileExtension(this.file.name)}`
  }
}

export const humanFileSize = (bytes: number, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

  return `${bytes.toFixed(dp)} ${units[u]}`
}
