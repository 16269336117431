import { toast } from 'react-toastify'
import { Content } from '../../services/api/_types'

export const openUrl = (url: string) => {
  const form = document.createElement('form')
  form.setAttribute('method', 'GET')
  form.setAttribute('target', '_blank')
  form.setAttribute('action', url)
  document.body.appendChild(form)
  form.submit()
}

export const copyText = async (text: string) => {
  await navigator.clipboard.writeText(text)
  toast.info('Скопировано')
}

export const getContent = (content?: Content[], code = 'ru') => {
  const result = content?.find(({ language }) => language.toLowerCase() === code?.toLowerCase())
  return result?.content
}

export const filterEmpty = <T = Record<string, unknown>>(obj: T) => Object.entries(obj || {})
  .filter(([, v]) => v !== undefined && v !== null && v !== '')
  .reduce((a, [k, v]) => ({ ...a, [k]: v as string }), {})
