import { createStore } from 'effector'

import { GetDefaultsRes } from '../../services/api/Defaults'

import { getDefaultsFx } from './event'

export const $defaults = createStore<Partial<GetDefaultsRes>>({})
  .on(getDefaultsFx.done, (_, { result }) => result.data?.data)

export const $defaultsLoading = createStore(false)
  .on(getDefaultsFx.pending, (_, pending) => pending)
