import React from 'react'
import { Page } from '../../../shared/UI/Page'
import { useFaq, useFaqCategory } from '../../../services/api/Faq'

export const Root = () => {
  const { handlerQueries, get, defaultQuery } = useFaq()
  const { options: categoryOptions } = useFaqCategory()

  return <Page
    title="Faq"
    variant="root"
    onChange={handlerQueries}
    loading={[get.isLoading]}
    rows={get.data?.data.data.items}
    total={get.data?.data.data.metadata.total}
    col={[
      { id: 'id', label: 'ID' },
      { id: 'name', label: 'Название' },
      { id: 'categoryId', label: 'Категория', render: (id: number) => categoryOptions.find(({ key }) => key === id)?.value },
      { id: 'isActive', label: 'Активно' },
    ]}
    defaultFilter={defaultQuery}
    filter={[
      {
        defaultExpanded: true,
        label: 'Фильтры',
        fields: [
          { name: 'id', type: 'number', label: 'ID' },
          { name: 'search', type: 'string', label: 'Поиск' },
          { name: 'name', type: 'string', label: 'Название (для админа)' },
          { name: 'active', type: 'switch', label: 'Активна', isEmpty: true },
          { name: 'categoryId', type: 'select', label: 'Категории', isEmpty: true, options: categoryOptions },
        ],
      },
    ]}
  />
}
