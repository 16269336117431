import React, { useState } from 'react'
import { useStore } from 'effector-react'
import { Page } from '../../../../../shared/UI/Page'
import { useMoneyBox } from '../../../../../services/api/MoneyBox'
import { Role, Routes } from '../../../../../shared/enum'
import { $defaults } from '../../../../../states/Defaults/store'
import { Drawer } from '@mui/material'
import { $projectsOptions } from '../../../../../states/Project/store'
import { RoleGuard } from '../../../../../shared/UI'

export const Id = () => {
  const defaults = useStore($defaults)
  const projectsOptions = useStore($projectsOptions)

  const { questsId } = useMoneyBox()

  const [answer, setAnswer] = useState(false)

  return (
    <>
      <Page
        variant="form"
        title="Редактирование задания юзера"
        button="Сохранить"
        loading={[questsId.get.loading, questsId.delete.loading]}
        actions={[
          { type: 'link', title: 'Задачи', to: `/${Routes.MONEYBOX}/${Routes.TASK}` },
          { type: 'button', title: 'Ответ', onClick: () => setAnswer(true), disabled: !questsId.get.data?.answer?.id },
          { type: 'confirm', title: 'Удалить', onClick: questsId.delete.fetch, color: 'error', message: 'Вы удалите задание у юзера' },
        ]}
        onInit={questsId.get.fetch}
        onSubmit={questsId.put.fetch}
        value={{
          ...questsId.get.data,
          auto: {
            ...questsId.get.data?.auto,
            data: questsId.get.data?.auto,
          },
        } as any}
        fields={[
          { name: 'id', type: 'string', label: 'ID', disabled: true },
          { name: 'questEntryId', type: 'string', label: 'Шаблон ID', disabled: true },
          { name: 'userId', type: 'string', label: 'Юзер ID', disabled: true },
          { name: 'moneyboxId', type: 'string', label: 'Копилка ID', disabled: true },
          { name: 'status', type: 'select', label: 'Статус', options: defaults.moneyboxes.questStatuses, disabled: true },
          { name: 'adminDescription', type: 'string', label: 'Описание для админа', disabled: true },
          { name: 'limit', type: 'number', label: 'Лимит выполенения на юзера. 0 - безлимитно', disabled: true },
          { name: 'questType', type: 'select', label: 'тип', options: defaults.moneyboxes.questTypes, disabled: true },
          { name: 'projectId', type: 'select', label: 'Проект', options: projectsOptions, hide: (v) => v.questType !== 'gift', disabled: true },
          { name: 'giftShares', type: 'number', label: 'Кол-во долей', hide: (v) => v.questType !== 'gift', disabled: true },
          { name: 'answerType', type: 'select', label: 'Формат ответа', options: defaults.moneyboxes.questAnswersTypes },
          { name: 'auto.type', type: 'select', label: 'Тип авто задачи', options: defaults.moneyboxes.autoQuestTypes, hide: (v) => v.answerType !== 'auto' },
          { name: 'auto.data.type', type: 'select', label: 'Тип авто задачи(Необходимо продублировать)', options: defaults.moneyboxes.autoQuestTypes, hide: (v) => v.answerType !== 'auto' },
          { name: 'auto.data.sum', type: 'cent', label: 'Сумма инвестиций которую должны набрать рефералы пользователя', hide: (v) => v.answerType !== 'auto' },
          { name: 'auto.data.progress', type: 'string', label: 'Прогресс', hide: (v) => v.answerType !== 'auto', disabled: true },
          { name: 'description.content', type: 'content', label: 'Условия, можно изменить условия конкретно в этой задачи' },
          {
            name: 'description.steps',
            type: 'fields',
            label: 'Шаги',
            fields: [
              { name: 'stepNumber', type: 'number', label: 'Номер шага' },
              { name: 'content', type: 'content', label: 'Поручение' },
            ],
          },
          { name: 'createdAt', type: 'date', label: 'Создана', disabled: true },
          { name: 'updatedAt', type: 'date', label: 'Изменена', disabled: true },
          { name: 'completedAt', type: 'date', label: 'Выполнена', disabled: true },
          { name: 'cancelledAt', type: 'date', label: 'Отменена', disabled: true },
        ]}
      />

      <RoleGuard role={Role.ADMIN}>
        <Drawer
          anchor="right"
          open={answer}
          onClose={() => setAnswer(false)}
          PaperProps={{
            style: {
              width: '680px',
            },
          }}
        >
          <Page
            variant="form"
            title="Ответ на задание"
            loading={[questsId.postCheckAnswer.loading]}
            onSubmit={(value) => {
              questsId.postCheckAnswer.fetch({
                id: value.id,
                answerId: Number(value.answer?.id),
                questId: value.id,
                newStatus: value.answer?.status as string,
                verifierComment: value.answer?.verifierComment as string,
              })
            }}
            value={questsId.get.data}
            fields={[
              { name: 'answer.verifierId', type: 'string', label: 'Проверяющей', disabled: true },
              { name: 'answer.fileId', type: 'fileId', label: 'Фаил', disabled: true },
              { name: 'answer.answer', type: 'string', label: 'Ответ', disabled: true },
              { name: 'answer.status', type: 'select', label: 'Статус', options: defaults.moneyboxes.questAnswerStatuses },
              { name: 'answer.verifierComment', type: 'string', label: 'Комментарий к ответу' },
            ]}
            button="Сохранить"
          />
        </Drawer>
      </RoleGuard>
    </>
  )
}
