/* eslint-disable @typescript-eslint/no-unused-vars,no-console */
import React, { ChangeEventHandler, MutableRefObject, useRef } from 'react'
import { toast } from 'react-toastify'
import ExcelJS, { CellRichTextValue } from 'exceljs'

import { Button } from '../../../UI/Button'
import { postImportLanguageFx } from '../../../../states/Language/event'
import { blobToFile, isRichValue, richToString } from '../../../utils'

export const UploadExcel = () => {
  const inputFile = useRef() as MutableRefObject<HTMLInputElement>

  const handlerClick = async () => {
    inputFile.current.click()
  }

  const handlerFile: ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target.files?.item(0)
    if (!file) { return }

    let wb = new ExcelJS.Workbook()
    const reader = new FileReader()
    let errorText = ''

    reader.readAsArrayBuffer(file)
    reader.onload = () => {
      const buffer = reader.result as ExcelJS.Buffer
      wb.xlsx.load(buffer).then((workbook) => {
        workbook.eachSheet((sheet) => {
          let lang = [] as string[]
          const result = {} as Record<string, Record<string, string>>
          sheet.eachRow((row, rowIndex) => {
            if (rowIndex === 1) {
              if (Array.isArray(row.values)) {
                lang = row.values.slice(2) as string[]
                lang.forEach((code) => {
                  if (!code) {
                    errorText = `Обнаружен пустой язык: ${code}`

                    console.log(errorText)
                    return toast.error(errorText)
                  }
                  result[code] = {}
                })
              }
              return
            }

            if (Array.isArray(row.values)) {
              const data = row.values.slice(1)
              const alias = data[0] as string
              const translates = data.slice(1)
              if (translates.length > lang.length) {
                errorText = 'Обнаружен перевод не относящийся к языку'

                console.log(errorText, translates)
                toast.error('Обнаружен перевод не относящийся к языку')
                throw new Error()
              }
              if (!alias) {
                errorText = `У перевода отсутствует алиас: ${alias}`

                console.log(errorText)
                toast.error(errorText)
                throw new Error()
              }
              translates.forEach((translate, translateIndex) => {
                if (!isRichValue(translate) && typeof translate !== 'string') {
                  errorText = `${alias} - [${lang[translateIndex]}] - не строка`

                  console.log(`${errorText}: ${translate}`)
                  toast.error(errorText)
                  throw new Error()
                }

                if (isRichValue(translate)) {
                  translate = richToString(translate)
                }
                result[lang[translateIndex]][alias] = translate as string
              })
            }
          })
          const resultFile = new Blob([JSON.stringify(result)], { type: 'application/json' })
          postImportLanguageFx({ file: blobToFile(resultFile, 'file') })
        })
      })
    }
  }

  return (
    <Button onClick={handlerClick}>
      Upload Excel

      <input
        ref={inputFile}
        hidden
        type="file"
        onChange={handlerFile}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
    </Button>
  )
}
