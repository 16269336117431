import React, { forwardRef } from 'react'
import { FormControl, FormControlLabel, FormGroup, FormLabel, Switch as SwitchMUI } from '@mui/material'
import { SwitchProps as MuiSwitchProps } from '@mui/material/Switch/Switch'

export interface SwitchProps extends Omit<MuiSwitchProps, 'title' | 'value'> {
  label: React.ReactNode
  title?: React.ReactNode
}

export const Switch = forwardRef<HTMLButtonElement, SwitchProps>(({
  className,
  title = '',
  label = '',
  ...props
}, ref) => {
  return (
    <div className={className}>
      <FormControl component="fieldset" variant="standard">
        {label && <FormLabel component="legend">{label}</FormLabel>}
        <FormGroup>
          <FormControlLabel
            control={
              <SwitchMUI {...props} ref={ref} />
            }
            label={title}
          />
        </FormGroup>
      </FormControl>
    </div>
  )
})
