import React from 'react'
import { Page } from '../../../shared/UI/Page'
import { useSupervisory } from '../../../services/api/Supervisory'
import { useStore } from 'effector-react'
import { $defaults } from '../../../states/Defaults/store'
import { getOptionsValue } from '../../../shared/utils'
import { Option } from '../../../shared/UI/Input'
import { NavLink } from 'react-router-dom'
import { Routes } from '../../../shared/enum'

const orderByOptions: Option<string>[] = [
  { value: 'Приоритет', key: 'priority' },
  { value: 'Время создания', key: 'createdAt' },
  { value: 'Город', key: 'city' },
  { value: 'Страна', key: 'country' },
]

export const Root = () => {
  const defaults = useStore($defaults)
  const { supervisory } = useSupervisory()

  return <Page
    variant="root"
    title="root"
    onChange={supervisory.get.fetch}
    total={supervisory.get.data?.metadata.total}
    filter={[
      {
        label: 'Фильтры',
        defaultExpanded: true,
        fields: [
          { name: 'userId', type: 'string', label: 'Юзер ID' },
          { name: 'name', type: 'string', label: 'Имя' },
          { name: 'surname', type: 'string', label: 'Фамилия' },
          { name: 'status', type: 'select', label: 'Статус', options: defaults.supervisory.statuses, isEmpty: true },
          { name: 'country', type: 'string', label: 'Страна' },
          { name: 'city', type: 'string', label: 'Город' },
          { name: 'dateStart', type: 'date', label: 'Начало' },
          { name: 'dateEnd', type: 'date', label: 'Конец' },
          { name: 'orderBy', type: 'select', label: 'Сортировать', options: orderByOptions, isEmpty: true },
        ],
      },
    ]}
    col={[
      { id: 'id', label: 'ID' },
      { id: 'userId', label: 'Юзер', render: (id) => <NavLink to={`/${Routes.USER}/${id}`}>{id}</NavLink> },
      { id: 'name', label: 'Имя' },
      { id: 'surname', label: 'Фамилия' },
      { id: 'status', label: 'Статус', render: (s) => getOptionsValue(defaults.supervisory.statuses, s) },
      { id: 'country', label: 'Страна' },
      { id: 'city', label: 'Город' },
      { id: 'priority', label: 'Приоритет' },
      { id: 'updatedAt', label: 'Обновлен' },
    ]}
    rows={supervisory.get.data?.items}
  />
}
