export const isNumber = (str: string | number = ''): boolean => {
  if (str === '' || str === '-') {
    return true
  }
  return !Number.isNaN(str) && !Number.isNaN(parseFloat(str as string))
}

export const toDollar = (cent: number | string = 0) => {
  return Number(cent) === 0 ? 0 : Number(cent) / 100
}

export const toCent = (dollar: number | string = 0) => {
  return Number(dollar) === 0 ? 0 : Number(Number(Number(Number(dollar).toFixed(2)) * 100).toFixed(0))
}
