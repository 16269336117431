import { Query, WithMetadata } from './_types'
import promiseToResult from '../../core/api/promiseToResults'
import AxiosInstance from '../../core/api/axiosinstance'
import { querystring } from '../../shared/utils'
import { TransactionStatus, TransactionType } from '../../shared/enum'

export enum TransactionMetadataType {
  CRYPTO = 'crypto'
}

interface MetadataCrypto {
  type: TransactionMetadataType.CRYPTO
  currencyId: string
  network: string
  wallet: string
}

export interface Transaction {
  id: number
  user: number
  installment: null | number
  price: number
  shares: number
  project: null | { id: number, name: string }
  paymentSystem: null | { id: number, name: string }
  type: TransactionType
  status: TransactionStatus
  comment: string
  updatedAt: string
  createdAt: string
  metadata: MetadataCrypto
}

export type GetTransactionsReq = Query
export type GetTransactionsRes = WithMetadata<Transaction>
const getTransactions = (data: GetTransactionsReq) => {
  const { query } = data
  return promiseToResult(
    AxiosInstance().get<GetTransactionsRes>(
      `/projects/admin/transactions?${querystring.stringify(query)}`,
    ),
  )
}

export interface GetTransactionReq {
  id: number
}
export type GetTransactionRes = Transaction
const getTransaction = (data: GetTransactionReq) => {
  const { id } = data
  return promiseToResult(
    AxiosInstance().get<GetTransactionRes>(
      `/projects/admin/transactions/${id}`,
    ),
  )
}

export interface PostTransactionCancelReq {
  id: number
}
export type PostTransactionCancelRes = true
const postTransactionCancel = (data: PostTransactionCancelReq) => {
  const { id } = data
  return promiseToResult(
    AxiosInstance().post<PostTransactionCancelRes>(
      `/projects/admin/transactions/${id}/cancel`,
    ),
  )
}

export type PostTransactionCompleteReq = PostTransactionCancelReq
export type PostTransactionCompleteRes = true
const postTransactionComplete = (data: PostTransactionCompleteReq) => {
  const { id } = data
  return promiseToResult(
    AxiosInstance().post<PostTransactionCompleteRes>(
      `/projects/admin/transactions/${id}/complete`,
    ),
  )
}

export type PostTransactionRecalculateReq = PostTransactionCompleteReq
const postTransactionRecalculate = (data: PostTransactionRecalculateReq) => {
  const { id } = data
  return promiseToResult(
    AxiosInstance().post(
      `/projects/admin/transactions/${id}/recalculate`,
    ),
  )
}

export const TransactionAPI = {
  getTransactions,
  getTransaction,
  postTransactionCancel,
  postTransactionComplete,
  postTransactionRecalculate,
}
