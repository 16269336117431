import React from 'react'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'

export type ButtonProps = LoadingButtonProps

export const Button: React.FC<ButtonProps> = ({
  color = 'primary',
  variant = 'contained',
  children,
  ...props
}) => {
  return (
    <LoadingButton
      {...props}
      color={color}
      variant={variant}
    >
      {children}
    </LoadingButton>
  )
}
