export const stringify = <T>(params: T | undefined) => {
  const url = new URLSearchParams(params as any)
  return url.toString()
}

export const parse = (str: string) => {
  const url = new URLSearchParams(str)
  return [...url.entries()].reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
}

export const querystring = {
  stringify,
  parse,
}
