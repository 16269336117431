import React from 'react'
import { useStore } from 'effector-react'
import { Page } from '../../../shared/UI'
import { $defaults } from '../../../states/Defaults/store'
import { useTemplate } from '../../../services/api/Template'
import { Routes } from '../../../shared/enum'

export const Id = () => {
  const defaults = useStore($defaults)
  const { id } = useTemplate()

  return (
    <Page
      variant="form"
      actions={[
        {
          title: 'Шаблоны',
          type: 'link',
          to: `/${Routes.TEMPLATE}`,
        },
        {
          title: 'Удаить',
          type: 'confirm',
          color: 'error',
          onClick: () => id.delete.fetch({ id: id.get.data?.type }),
        },
      ]}
      loading={[id.get.loading, id.patch.loading, id.delete.loading]}
      onInit={id.get.fetch}
      onSubmit={id.patch.fetch}
      value={id.get.data}
      fields={[
        { name: 'type', type: 'select', label: 'Тип', disabled: true, options: defaults.social.templates },
        { name: 'telegram.text.content', type: 'content', mode: 'text', label: 'Телеграм. Текст' },
        { name: 'whatsapp.text.content', type: 'content', mode: 'text', label: 'Ватцап. Текст' },
        { name: 'sms.text.content', type: 'content', mode: 'text', label: 'Смс. Текст' },
        { name: 'email.subject.content', type: 'content', mode: 'text', label: 'Почта. Заголовок' },
        { name: 'email.html.content', type: 'content', label: 'Почта. Содержание' },
        { name: 'email.from', type: 'string', label: 'Почта. Отправитель' },
      ]}
      button="Сохранить"
    />
  )
}
