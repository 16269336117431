/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { TranslationAPI } from '../../../../services/api/Translation'
import { Button } from '../../../UI/Button'
import ExcelJS from 'exceljs'
import dayjs from 'dayjs'

export const DownloadExcel = () => {
  const handler = async () => {
    const { data: { data } } = await TranslationAPI.getTranslationsAlias()

    const col: string[] = [
      '',
      ...Object.entries(data).map(([code]) => code),
    ]
    const rows: string[] = Object.entries(data[col[1]]).map(([alias]) => alias)

    const workbook = new ExcelJS.Workbook()
    const ws = workbook.addWorksheet('alias')

    const columnName = (index: number) => {
      let cname = String.fromCharCode(65 + ((index - 1) % 26))
      if (index > 26) { cname = String.fromCharCode(64 + (index - 1) / 26) + cname }
      return cname
    }

    let headerColumn = 1
    col.map((code, cidx) => {
      ws.getCell(columnName(headerColumn + cidx) + String(1)).value = code

      if (code === '') {
        return rows.map((alias, idx) => {
          const value = alias
          ws.getCell(columnName(headerColumn + cidx) + String(idx + 2)).value = value
        })
      }

      return rows.map((alias, idx) => {
        const value = data[code][alias]
        ws.getCell(columnName(headerColumn + cidx) + String(idx + 2)).value = value
      })
    })

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const url = window.URL.createObjectURL(blob)
      const anchor = document.createElement('a')
      anchor.href = url
      anchor.download = `Translates - ${dayjs().format('LLLL')}.xlsx`
      anchor.click()
      window.URL.revokeObjectURL(url)
    })
  }
  return (
    <Button onClick={handler}>
      Download Excel
    </Button>
  )
}
