import React from 'react'
import { useStore } from 'effector-react'
import { useRoutes } from 'react-router-dom'

import { Page } from '../../../shared/UI'
import { getCategoriesFx, getCategoryFx, patchCategoryFx, postCategoryFx } from '../../../states/FinReport/event'
import { $isPendingCategories, $categories, $category, $isPendingCategory } from '../../../states/FinReport/store'
import { Routes } from '../../../shared/enum'
import { PatchCategoryReq, PostCategoryReq } from '../../../services/api/FinReport'

export const Category = () => {
  const categories = useStore($categories)
  const category = useStore($category)
  const isPendingCategories = useStore($isPendingCategories)
  const isPendingCategory = useStore($isPendingCategory)

  return useRoutes([
    {
      path: Routes.ROOT,
      element: <Page
        variant="outlet"
        links={[
          { title: 'Создать категорию', to: Routes.CREATE },
        ]}
      />,
      children: [
        {
          path: Routes.ROOT,
          element: <Page
            loading={[isPendingCategories]}
            onInit={() => getCategoriesFx()}
            variant="root"
            col={[
              { id: 'id', label: 'ID' },
              { id: 'name', label: 'Название' },
            ]}
            rows={categories}
            title="Категории для фин. отчета"
          />,
        },
        {
          path: Routes.CREATE,
          element: <Page
            title="Создание категории для фин. отчета"
            variant="form"
            button="Создать"
            onSubmit={(v) => postCategoryFx(v as PostCategoryReq)}
            fields={[
              { name: 'name', type: 'string', label: 'Название (для админа)' },
              { name: 'title.content', type: 'content', label: 'Заголовок', mode: 'text' },
              { name: 'color', type: 'string', label: 'Цвет - HEX (#FFFFFF)' },
            ]}
          />,
        },
        {
          path: Routes.ID,
          element: <Page
            title="Редактирование категории для фин. отчета"
            variant="form"
            button="Сохранить"
            loading={[isPendingCategory]}
            onInit={({ id }) => getCategoryFx({ id: String(id) })}
            value={category}
            onSubmit={(v) => patchCategoryFx(v as PatchCategoryReq)}
            fields={[
              { name: 'id', type: 'string', label: 'ID', disabled: true },
              { name: 'name', type: 'string', label: 'Название (для админа)' },
              { name: 'title.content', type: 'content', label: 'Заголовок', mode: 'text' },
              { name: 'color', type: 'string', label: 'Цвет - HEX (#FFFFFF)' },
            ]}
          />,
        },
      ],
    },
  ])
}
