import React from 'react'
import { useFormContext } from 'react-hook-form'

import { RootLayout, Switch } from '../../../UI'
import { User } from '../../../../services/api/User'

export const UserMore = () => {
  const { watch } = useFormContext<User>()

  const value = watch()

  return (
    <RootLayout>
      <Switch
        disabled
        label="Telegram"
        checked={value.socials.telegram}
      />
      <Switch
        disabled
        label="WhatsApp"
        checked={value.socials.whatsApp}
      />
      <Switch
        disabled
        label="Пароль"
        checked={value.hasPassword}
      />
    </RootLayout>
  )
}
