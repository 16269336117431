import React from 'react'
import { Page } from '../../../shared/UI'
import { useMoneyBox } from '../../../services/api/MoneyBox'
import { Routes } from '../../../shared/enum'

export const Settings = () => {
  const { settings } = useMoneyBox()

  return <Page
    variant="form"
    title="Настройки копилки"
    actions={[
      { title: 'Копилка', to: `/${Routes.MONEYBOX}`, type: 'link' },
      { title: 'Шаблоны', to: Routes.TASK, type: 'link' },
    ]}
    onInit={settings.get.fetch}
    value={settings.get.data}
    onSubmit={settings.put.fetch}
    loading={[settings.get.loading]}
    button="Сохранить"
    fields={[
      { name: 'alwaysReceivePrizeUserIds', type: 'array', label: 'Юзер ID, которым всегда начисляется приз' },
      { name: 'deadlines.moneyboxReleasedInDays', type: 'number', label: 'Количество дней, когда копилка выпустится' },
      { name: 'deadlines.questCompletetionsInDays', type: 'number', label: 'Количество дней, когда задания должны пользователи завершить' },
    ]}
  />
}
