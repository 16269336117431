import AxiosInstance, { AxiosInstanceType } from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'
import { querystring } from '../../shared/utils'

import { Query } from './_types'

export interface Language {
  active: boolean
  code: string
  language: string
  displayName: string
  id: number
  icon: string
  countries: string[]
}

export type GetLanguagesReq = Partial<Query>
export type GetLanguagesRes = Language[]

const getLanguages = (data: GetLanguagesReq) => {
  const { query } = data
  return promiseToResult(
    AxiosInstance().get<GetLanguagesRes>(
      `/application/admin/languages?${querystring.stringify(query)}`,
    ),
  )
}

export interface GetLanguageReq { id: string }
export type GetLanguageRes = Language[]

const getLanguage = (data: GetLanguageReq) => {
  const { id } = data
  return promiseToResult(
    AxiosInstance().get<GetLanguageRes>(
      `/application/admin/languages?id=${id}`,
    ),
  )
}

export type PatchLanguageReq = Language
export interface PatchLanguageRes extends Language {
  translations: { alias: string; id: number; text: string }[]
}
const patchLanguage = (data: PatchLanguageReq) => {
  const { id, ...body } = data
  return promiseToResult(
    AxiosInstance().patch<PatchLanguageRes>(
      `/application/admin/languages/${id}`,
      body,
    ),
  )
}

export type PostLanguageReq = Omit<Language, 'id' | 'active' | 'icon'>
export interface PostLanguageRes extends Language {
  translations: { alias: string; id: number; text: string }[]
}
const postLanguage = (data: PostLanguageReq) => {
  return promiseToResult(
    AxiosInstance().post<PostLanguageRes>(
      '/application/admin/languages',
      data,
    ),
  )
}

export interface DeleteLanguageReq { id: string }
export interface DeleteLanguageRes { status: boolean }
const deleteLanguage = (data: DeleteLanguageReq) => {
  const { id } = data
  return promiseToResult(
    AxiosInstance().delete<DeleteLanguageRes>(
      `/application/admin/languages/${id}`,
    ),
  )
}

export type GetExportLanguageRes = Record<string, any>
const getExport = () => {
  return promiseToResult(
    AxiosInstance().get<GetExportLanguageRes>(
      '/application/admin/languages/export',
    ),
  )
}

export interface PostImportLanguageReq {
  file: File
}
export interface PostImportLanguageRes {
  status: boolean
}
const postImport = (data: PostImportLanguageReq) => {
  const form = new FormData()
  form.append('file', data.file)

  return promiseToResult(
    AxiosInstance(AxiosInstanceType.FORMDATA).post<PostImportLanguageRes>(
      '/application/admin/languages/import',
      form,
    ),
  )
}

export const LanguageAPI = {
  getLanguages,
  getLanguage,
  patchLanguage,
  postLanguage,
  deleteLanguage,
  getExport,
  postImport,
}
