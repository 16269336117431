import React from 'react'
import { useStore } from 'effector-react'

import { Page } from '../../../../../shared/UI'
import { $defaults } from '../../../../../states/Defaults/store'
import { useMoneyBox } from '../../../../../services/api/MoneyBox'
import { $projectsOptions } from '../../../../../states/Project/store'

export const Id = () => {
  const defaults = useStore($defaults)
  const projectsOptions = useStore($projectsOptions)

  const { questsEntriesId } = useMoneyBox()

  return <Page
    variant="form"
    title="Редактирование шаблона"
    button="Сохранить"
    actions={[
      { type: 'button', title: 'Обновить выданные задачи', onClick: questsEntriesId.patch.fetch },
      { type: 'confirm', title: 'Удалить', onClick: questsEntriesId.delete.fetch, color: 'error' },
    ]}
    loading={[
      questsEntriesId.get.loading,
      questsEntriesId.put.loading,
      questsEntriesId.patch.loading,
      questsEntriesId.delete.loading,
    ]}
    onInit={({ id }) => questsEntriesId.get.fetch({ id: String(id) })}
    onSubmit={questsEntriesId.put.fetch}
    value={{
      ...questsEntriesId.get.data,
      auto: {
        ...questsEntriesId.get.data?.auto,
        data: questsEntriesId.get.data?.auto,
      },
    } as any}
    fields={[
      { name: 'id', type: 'string', label: 'ID', disabled: true },
      { name: 'isActive', type: 'switch', label: 'Активно' },
      { name: 'limit', type: 'number', label: 'Лимит выполенения на юзера. 0 - безлимитно' },
      { name: 'adminDescription', type: 'string', label: 'Описание для админа' },
      { name: 'description.content', type: 'content', label: 'Описание задачи для юзера' },
      { name: 'questType', type: 'select', label: 'Тип', options: defaults.moneyboxes.questTypes },
      { name: 'projectId', type: 'select', label: 'Проект', options: projectsOptions, hide: (v) => v.questType !== 'gift' },
      { name: 'giftShares', type: 'number', label: 'Кол-во долей', hide: (v) => v.questType !== 'gift' },
      { name: 'answerType', type: 'select', label: 'Формат ответа', options: defaults.moneyboxes.questAnswersTypes },
      { name: 'auto.type', type: 'select', label: 'Тип авто задачи', options: defaults.moneyboxes.autoQuestTypes, hide: (v) => v.answerType !== 'auto' },
      { name: 'auto.data.type', type: 'select', label: 'Тип авто задачи(Необходимо продублировать)', options: defaults.moneyboxes.autoQuestTypes, hide: (v) => v.answerType !== 'auto' },
      { name: 'auto.data.sum', type: 'cent', label: 'Сумма инвестиций которую должны набрать рефералы пользователя ', hide: (v) => v.answerType !== 'auto' },
      {
        name: 'description.steps',
        type: 'fields',
        label: 'Шаги',
        fields: [
          { name: 'stepNumber', type: 'number', label: 'Номер шага' },
          { name: 'content', type: 'content', label: 'Поручение' },
        ],
        hide: () => true,
      },
    ]}
  />
}
