import { combine, createStore } from 'effector'

import { GetPaymentRes, GetPaymentsRes } from '../../services/api/Payment'

import { getPaymentsFx, getPaymentFx } from './event'

const paymentsInit = {
  metadata: {
    limit: 0,
    page: 0,
    total: 0,
  },
  items: [],
}

export const $payments = createStore<GetPaymentsRes>(paymentsInit)
  .on(getPaymentsFx.done, (_, { result }) => result.data.data)

export const $paymentsOptions = combine(
  $payments,
  (payments) => payments.items?.map((p) => ({ key: p.id, value: p.name })),
)

export const $payment = createStore<Partial<GetPaymentRes>>({})
  .on(getPaymentFx.done, (_, { result }) => result.data.data)
export const $paymentLoading = createStore(false)
  .on(getPaymentFx.pending, (_, pending) => pending)
