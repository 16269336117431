import AxiosInstance from '../../core/api/axiosinstance'
import promiseToResult from '../../core/api/promiseToResults'
import { querystring } from '../../shared/utils'
import { useQuery } from '@tanstack/react-query'
import { usePagination } from '../../shared/hooks'

export interface GetAnalyticReq {
  projectIds: number[]
  dateStart?: string
  dateEnd?: string
}

export interface GetApplicationRes {
  usersCompletedPurchases: number
  newUsersPurchase: {
    countries: {
      code: string,
      totalPrice: number
      count: number
    }[]
    totalPrice: number
  }
  allUsersPurchase: {
    countries: {
      code: string,
      totalPrice: number
      count: number
    }[]
    totalPrice: number
  }
}
const getApplication = (data: GetAnalyticReq) => {
  return promiseToResult(
    AxiosInstance().get<GetApplicationRes>(
      `/application/admin/analytics?${querystring.stringify(data)}`,
    ),
  )
}

export interface GetProjectRes {
  projects: {
    freeShares: number
    freeSharesPercent: number
    reservedShares: number
    reservedSharesPercent: number
    reservedInstallmentsSum: number
    soldShares: number
    soldSharesPercent: number
    project: number
  }[]
  transactions: {
    withdrawalsSum: number
    completedSum: number
    balancesSumGroupByPayments: {
      paymentSystemId: number
      sum: number
    }[]
  }
}
const getProject = (data: GetAnalyticReq) => {
  return promiseToResult(
    AxiosInstance().get<GetProjectRes>(
      `/projects/admin/analytics?${querystring.stringify(data)}`,
    ),
  )
}

export interface GetReferralsReq {
  country: string
  dateStart: string
  dateEnd: string
  orderBy: string
  direction: string
  page: string
  limit: string
}
export interface GetReferralsRes {
  items: {
    userId: number
    country: string
    userReceivedReferralsPrizesSum: number
    referralsCount: { firstDepth: number, otherDepths: number }
    referralsRegisteredBetween: number
    referralsWithPurchaseCount: { firstDepth: number, otherDepths: number }
  }[]
  metadata: {
    limit: number
    page: number
    total: number
  }
}
const getReferrals = (data: GetReferralsReq) => {
  return promiseToResult(
    AxiosInstance().get<GetReferralsRes>(
      `/application/admin/analytics/referrals?${querystring.stringify(data)}`,
    ),
  )
}

export const AnalyticAPI = {
  getApplication,
  getProject,
  getReferrals,
}

export const useAnalyticReferrals = () => {
  const defaultQuery = {
    limit: '10',
    page: '1',
  }

  const { query, handlerQueries } = usePagination({
    defaults: defaultQuery,
  })
  const get = useQuery(['AnalyticAPI.getReferrals', query], () => AnalyticAPI.getReferrals(query))

  return {
    get,
    handlerQueries,
    defaultQuery,
  }
}
