import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useStore } from 'effector-react'

import { Button, RootTable, RootLayout, Col } from '../../shared/UI'
import { getPaymentsFx } from '../../states/Payment/event'
import { $payments } from '../../states/Payment/store'

import styles from './Payment.module.scss'

const col: Col[] = [
  { id: 'id', label: 'ID' },
  { id: 'name', label: 'Имя' },
  { id: 'type', label: 'Тип' },
  { id: 'description', label: 'Описание' },
]

const PaymentController = () => {
  const payments = useStore($payments)

  useEffect(() => {
    getPaymentsFx({})
  }, [])

  return (
    <RootLayout>
      <div className={styles.wrapper}>

        <div className={styles.inputs}>
          <NavLink to="create">
            <Button color="primary" variant="contained">
              Создать
            </Button>
          </NavLink>
        </div>

        <RootTable rows={payments.items} col={col} orderBy="code" title="Список платежных систем" />

      </div>
    </RootLayout>
  )
}

export default PaymentController
