import React from 'react'
import { useStore } from 'effector-react'

import { Accordion, Col, RootLayout, RootTable, InputAutocomplete } from '../../../UI'
import { $isPending, $users } from '../../../../states/User/store'
import { getUsersFx } from '../../../../states/User/event'
import { usePagination } from '../../../hooks'

const col: Col[] = [
  { id: 'id', label: 'ID' },
  { id: 'login', label: 'Логин' },
  { id: 'phone', label: 'Телефон' },
  { id: 'email', label: 'Почта' },
]

export const UserRoot = () => {
  const users = useStore($users)
  const isPending = useStore($isPending)

  const { query, pagination, handlerPagination, handlerQuery } = usePagination({
    defaults: { orderBy: 'id', orderDirection: 'desc', limit: '20', page: '1' },
    observerQuery: (query) => getUsersFx({ query }),
  })

  const onChange = (value: string, trigger: string) => {
    if (trigger === 'phone') {
      value = value.replace(/\s/g, '').replace('-', '')
    }
    value.trim().trimEnd()

    handlerQuery(trigger, value)
  }

  return (
    <RootLayout>
      <Accordion label="Фильтр" defaultExpanded className="filter">
        <InputAutocomplete
          label="ID"
          options={users?.items}
          trigger="id"
          values={query}
          isLoading={isPending}
          onChange={onChange}
        />
        <InputAutocomplete
          label="Login"
          options={users?.items}
          values={query}
          trigger="login"
          isLoading={isPending}
          onChange={onChange}
        />
        <InputAutocomplete
          label="Phone"
          options={users?.items}
          values={query}
          trigger="phone"
          isLoading={isPending}
          onChange={onChange}
        />
        <InputAutocomplete
          label="Email"
          options={users?.items}
          values={query}
          trigger="email"
          isLoading={isPending}
          onChange={onChange}
        />
      </Accordion>

      <Accordion label="legacy" className="filter">
        <InputAutocomplete
          label="weter login"
          options={users?.items}
          values={query}
          trigger="weterLogin"
          isLoading={isPending}
          onChange={onChange}
        />
        <InputAutocomplete
          label="gorod login"
          options={users?.items}
          values={query}
          trigger="gorodLogin"
          isLoading={isPending}
          onChange={onChange}
        />
        <InputAutocomplete
          label="weter id"
          options={users?.items}
          values={query}
          trigger="weterId"
          isLoading={isPending}
          onChange={onChange}
        />
        <InputAutocomplete
          label="gorod id"
          options={users?.items}
          values={query}
          trigger="gorodId"
          isLoading={isPending}
          onChange={onChange}
        />
      </Accordion>

      <RootTable
        rows={users.items}
        col={col}
        title="Пользователи"
        limitOptions={[10, 20, 30]}
        handleChangePage={(page) => handlerPagination('page', page)}
        handleChangeLimit={(limit) => handlerPagination('limit', limit)}
        handleOrderDirection={(limit) => handlerQuery('orderDirection', limit)}
        length={users?.metadata?.total || 0}
        page={pagination.page}
        limit={pagination.limit}
        orderBy={query?.orderBy?.toString() || 'id'}
        orderDirection={query?.orderDirection?.toString() || 'desc'}
      />
    </RootLayout>
  )
}
