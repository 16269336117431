import { createStore } from 'effector'
import { getUserFx, getRecoveryFx, getVerificationFx, getTransactionFx } from './event'
import { GetRecoveryRes, GetTransactionRes, GetUserRes, GetVerificationRes } from '../../services/api/Statistic'

export const $statisticUser = createStore<Partial<GetUserRes>>({})
  .on(getUserFx.done, (_, { result }) => result.data.data)
export const $statisticUserPending = createStore<boolean>(false)
  .on(getUserFx.pending, (_, pending) => pending)

export const $statisticRecovery = createStore<Partial<GetRecoveryRes>>({})
  .on(getRecoveryFx.done, (_, { result }) => result.data.data)
export const $statisticRecoveryPending = createStore<boolean>(false)
  .on(getRecoveryFx.pending, (_, pending) => pending)

export const $statisticVerification = createStore<Partial<GetVerificationRes>>({})
  .on(getVerificationFx.done, (_, { result }) => result.data.data)
export const $statisticVerificationPending = createStore<boolean>(false)
  .on(getVerificationFx.pending, (_, pending) => pending)

export const $statisticTransaction = createStore<Partial<GetTransactionRes>>({})
  .on(getTransactionFx.done, (_, { result }) => result.data.data)
export const $statisticTransactionPending = createStore<boolean>(false)
  .on(getTransactionFx.pending, (_, pending) => pending)
