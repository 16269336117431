import { useStore } from 'effector-react'
import { $projectLoading, $projects } from '../../../../states/Project/store'
import React, { useEffect } from 'react'
import { getProjectsFx } from '../../../../states/Project/event'
import { RootLayout } from '../../../UI/RootLayout'
import { Col, RootTable } from '../../../UI'

const col: Col[] = [
  { id: 'id', label: 'ID' },
  { id: 'name', label: 'Название' },
  { id: 'symbol', label: 'Аббревиатура' },
  { id: 'saleShare', label: 'Продано долей' },
  { id: 'allShare', label: 'Всего долей' },
]

export const ProjectRoot = () => {
  const projects = useStore($projects)
  const projectLoading = useStore($projectLoading)

  useEffect(() => {
    const query = { limit: 30, page: 1 }
    getProjectsFx({ query })
  }, [])

  return (
    <RootLayout loading={projectLoading}>
      <RootTable
        rows={projects}
        col={col}
        orderBy="id"
        title="Проекты"
      />
    </RootLayout>
  )
}
